import _ from 'lodash';
import {classnames} from 'src/utils/general';
import React, {useContext, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import './styles.sass';

//icons
import {Close} from 'src/lib/svg-icon-helper';
import {
    LeftOutlined,
    RightOutlined,
    QuestionOutlined,
} from '@ant-design/icons';

//components
import Button from 'src/Components/Button';

//types
import {genericFn} from 'src/Types/CommonTypes/index';

//libs
import {OnboardContext, onboardStatus} from 'src/hooks/useOnboarding';
import {brandsOnboarding} from 'src/lib/onboarding/brands';
import {brandCardAssetsOnboarding} from 'src/lib/onboarding/brandCardAssets';
import {contactsOnboarding} from 'src/lib/onboarding/contacts';
import {updateOnboarding} from 'src/api/User/user-api';
import {assetsOnboarding} from 'src/lib/onboarding/assets';
import {tasksOnboarding} from 'src/lib/onboarding/tasks';
import {welcomeOnboarding} from 'src/lib/onboarding/welcome';

const onboardingByType = {
    'assets': assetsOnboarding,
    'brands': brandsOnboarding,
    'brandCardAssets': brandCardAssetsOnboarding,
    'contacts': contactsOnboarding,
    'tasks': tasksOnboarding,
    'welcome': welcomeOnboarding,
};

interface OnboardingTemplate {
    header?: string
    text?: string
    render?: genericFn
    targetId?: string
    knowledgebaseLink?: string
    targetClassesToApply?: string[]
    onShowOnboarding?: genericFn
    onHideOnboarding?: genericFn
}

interface Props {
    className?: string
    onboardType: keyof typeof onboardStatus
    onShowOnboarding?: genericFn
    onHideOnboarding?: genericFn
}

const Onboarding: React.FC<Props> = ({
    className = '',
    onboardType,
    onShowOnboarding,
    onHideOnboarding,
}) => {
    const {storeId} = useParams<{storeId: string}>();
    const {onboardStatus, setOnboardStatus} = useContext(OnboardContext);
    const computedClassName = classnames(
        'Onboarding',
        {
            [className]: className,
        }
    );


    const onboarding = onboardingByType[onboardType];
    const onboardingKeys = Object.keys(onboarding);
    const currentUserOnboardingKey = _.get(onboardStatus, [onboardType, 'progress']) || onboardingKeys[0];
    const currentUserOnboarding: OnboardingTemplate = onboarding[currentUserOnboardingKey];
    const onboardingIndex = _.indexOf(onboardingKeys, currentUserOnboardingKey);
    const previousElementForHighlight = useRef<Element | null>();
    const previousClassesForHighlight = useRef<string[]>();

    useEffect(() => {
        const applyOnboardingAnimation = (targetedElementForHighlight: Element | null) => {
            if (targetedElementForHighlight && currentUserOnboarding.targetClassesToApply && currentUserOnboarding.targetClassesToApply.length) {
                previousClassesForHighlight.current = currentUserOnboarding.targetClassesToApply;
                for (const className of currentUserOnboarding.targetClassesToApply) {
                    targetedElementForHighlight.classList.remove(className);
                    setTimeout(() => {
                        targetedElementForHighlight.classList.add(className);
                    });
                }
            } else {
                //retry, some things are async... make better someday
                setTimeout(() => {
                    const targetedElementForHighlight = document.querySelector(`#${currentUserOnboarding.targetId}`);
                    applyOnboardingAnimation(targetedElementForHighlight);
                }, 200);
            }
        };

        for (const className of previousClassesForHighlight.current || []) {
            previousElementForHighlight.current?.classList.remove(className);
        }

        if (onboardStatus && currentUserOnboarding && _.get(onboardStatus, [onboardType, 'dismissed']) === false) {
            if (currentUserOnboarding.targetId) {
                previousElementForHighlight.current = document.querySelector(`#${currentUserOnboarding.targetId}`);
                applyOnboardingAnimation(previousElementForHighlight.current);
            }
            if (onShowOnboarding) {
                onShowOnboarding(currentUserOnboardingKey);
            }
            if (currentUserOnboarding.onShowOnboarding) {
                currentUserOnboarding.onShowOnboarding();
            }
        }
    }, [onboardStatus]);

    if (!onboardStatus || _.get(onboardStatus, [onboardType, 'dismissed']) === true) {
        return null;
    }

    const previousOnboarding = () => {
        const newState = Object.assign({}, onboardStatus, {
            [onboardType]: {
                dismissed: _.get(onboardStatus, [onboardType, 'dismissed'], false),
                progress: onboardingKeys[onboardingIndex - 1],
            },
        });
        updateOnboarding(storeId, newState);
        setOnboardStatus(newState);
    };

    const nextOnboarding = () => {
        const newState = Object.assign({}, onboardStatus, {
            [onboardType]: {
                dismissed: _.get(onboardStatus, [onboardType, 'dismissed'], false),
                progress: onboardingKeys[onboardingIndex + 1],
            },
        });
        updateOnboarding(storeId, newState);
        setOnboardStatus(newState);
    };

    const dismissOnboarding = () => {
        const newState = Object.assign({}, onboardStatus, {
            [onboardType]: {
                dismissed: true,
                progress: _.get(onboardStatus, [onboardType, 'progress']),
            },
        });
        updateOnboarding(storeId, newState);
        setOnboardStatus(newState);
        if (onHideOnboarding) {
            onHideOnboarding();
        }
    };

    const selectOnboarding = (onboardingKey: string) => {
        return () => {
            const newState = Object.assign({}, onboardStatus, {
                [onboardType]: {
                    dismissed: _.get(onboardStatus, [onboardType, 'dismissed'], false),
                    progress: onboardingKey,
                },
            });
            updateOnboarding(storeId, newState);
            setOnboardStatus(newState);
        };
    };

    return (
        <div className={computedClassName}>
            {(onboardingIndex > 0) && (
                <Button className='onboarding-regress-button btn-circle btn-icon' onClick={previousOnboarding}><LeftOutlined /></Button>
            )}
            {(onboardingIndex < 1) && (
                <div className='onboarding-button-placeholder' />
            )}
            <div className='onboarding-container'>
                {currentUserOnboarding.header && (
                    <div className='onboarding-header'>
                        {currentUserOnboarding.header}
                    </div>
                )}
                {currentUserOnboarding.text && (
                    <div className='onboarding-body'>
                        {currentUserOnboarding.text}
                        {currentUserOnboarding.knowledgebaseLink && (
                            <a className='onboarding-kb-button btn-circle btn-icon' href={currentUserOnboarding.knowledgebaseLink}>
                                <QuestionOutlined />
                            </a>
                        )}
                    </div>
                )}
                {currentUserOnboarding.render && currentUserOnboarding.render()}
                {Boolean(onboardingKeys && onboardingKeys.length > 1) && (
                    <div className='select-balls'>
                        {onboardingKeys.map((onboardingKey) => (
                            <div
                                className={classnames(
                                    'select-ball',
                                    {
                                        'select-ball--active': currentUserOnboardingKey === onboardingKey,
                                    }
                                )}
                                onClick={selectOnboarding(onboardingKey)}
                                key={onboardingKey} />
                        ))}
                    </div>
                )}
            </div>
            {(onboardingIndex < (onboardingKeys.length - 1)) && (
                <Button className='onboarding-progress-button btn-circle btn-icon' onClick={nextOnboarding}><RightOutlined /></Button>
            )}
            {(onboardingIndex >= (onboardingKeys.length - 1)) && (
                <div className='onboarding-button-placeholder' />
            )}
            <Button className='dismiss-onboarding-button btn-circle btn-icon' onClick={dismissOnboarding}><Close /></Button>
        </div>
    );
};

export default Onboarding;
