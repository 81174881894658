import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, matchPath, useLocation, useNavigate} from 'react-router-dom';
import {getStores} from 'src/redux/stores/actions';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import UserPage from '../UserPage';
import {OnboardContext} from 'src/hooks/useOnboarding';
import {RootState} from 'src/redux/rootReducer';
import {updateMainMenuPin, updateOnboarding} from 'src/api/User/user-api';
import Assets from 'src/Views/Store/Assets';
import Brands from 'src/Views/Store/Brands';
import BrandCardContainer from 'src/Views/Store/BrandCardContainer';
import Contacts from 'src/Views/Store/Contacts';
import Teams from 'src/Views/Store/Teams';
import Tasks from 'src/Views/Store/Tasks';
import Button from 'src/Components/Button';
import HeaderBar from 'src/Components/HeaderBar';
import Modal from 'src/Components/Modal';
import Welcome from '/src/assets/images/welcome.png';
import './styles.sass';
import useWindow from 'src/hooks/useWindow';
import {classnames} from 'src/utils/general';
import Plans from 'src/Components/Plans';
import Payments from 'src/Components/Payments';
import {Prompt, Props as PromptProps} from 'src/Components/ModalPrompt';
import ConfettiSuccess from 'src/Components/Payments/ConfettiSuccess';
import {PlanGroup} from 'src/constants/subscriptionPlans';
import Delete from 'src/Components/Plans/Delete';
import _ from 'lodash';
import {deleteStore, subscribeStore} from 'src/api/Store/api-store';
import {formatDate} from 'src/lib/date-helper';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';
import AssetViewer from 'src/Views/Store/AssetViewer';
import {AuthRoutes} from 'src/constants/userRoles';

const Store = (): JSX.Element => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);
    const {windowIsSmall, windowIsTiny, modalSize} = useWindow();
    const [currentModal, setCurrentModal] = useState<'plan' | 'payment' | 'paymentSuccess'>('plan');
    const [selectedPlan, setSelectedPlan] = useState<PlanGroup>();
    const [appVersion, setAppVersion] = useState<string>();

    const {pathname} = useLocation();
    const matchedPath = matchPath({path: '/store/:storeId/*'}, pathname);
    const viewingAsset = matchPath({path: '/store/:storeId/asset-viewer/*'}, pathname);
    const storeId = _.get(matchedPath, 'params.storeId');
    const storeStatus = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const currentStoreStatus = storeStatus?.find((currentStore: StoreStatus) => `${currentStore.id}` === storeId);
    const locationId = useRef<number>(Date.now());

    useEffect(() => {
        dispatch(getStores());
        dispatch(getStoreStatus());
    }, [dispatch]);

    const handleForceRefresh = async() => {
        await new Prompt().notice(
            {
                body: 'BrandKeep has been updated. Please refresh your screen to use the latest version.',
                title: 'New BrandKeep Version Available',
                displayType: 'notice',
                props: {confirmText: 'Refresh'},
                onYes: async() => {
                    window.location.reload();
                },
            }
        );
    };

    const [showMenu, setShowMenu] = useState(false);
    const [pinMenu, setPinMenu] = useState(auth.isMainMenuPinned);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
    const {onboardStatus, setOnboardStatus} = useContext(OnboardContext);

    useEffect(() => {

        if (auth.isLoading === false
            && auth.isSignedIn
            && onboardStatus
            && storeId
        ) {
            if (!isWelcomeModalOpen
                && ((onboardStatus.welcome && !onboardStatus.welcome.dismissed)
                || !onboardStatus.welcome)
            ) {
                setIsWelcomeModalOpen(true);
            }
        }
    }, [onboardStatus, auth, storeId]);

    useEffect(() => {
        if (currentStoreStatus && currentStoreStatus.version && !appVersion) {
            setAppVersion(currentStoreStatus.version);
        }
        if (currentStoreStatus && currentStoreStatus.version && appVersion && (currentStoreStatus.version !== appVersion)) {
            setAppVersion(currentStoreStatus.version);
            handleForceRefresh();
        }
        if (auth.isLoading === false
            && auth.isSignedIn
            && onboardStatus
            && storeId
            && !isWelcomeModalOpen
            && currentStoreStatus
            && !currentStoreStatus.hasTasks
            && matchPath({path: '/store/:storeId/tasks'}, pathname)
        ) {
            //attempting to access tasks dashboard without permission
            navigate(AuthRoutes.storeBrands(storeId));
        }
    }, [currentStoreStatus]);

    useEffect(() => {
        if (auth && auth.isMainMenuPinned) {
            setPinMenu(true);
        }
    }, [auth]);

    const dismissWelcome = () => {
        const newState = Object.assign({}, onboardStatus, {
            welcome: {
                dismissed: true,
            },
        });
        updateOnboarding(storeId, newState);
        setOnboardStatus(newState);
        setIsWelcomeModalOpen(false);
    };

    const handleShowMenu = () => {
        if (showMenu) {
            setShowMenu(false);
        } else {
            setShowMenu(true);
        }
    };
    const handleHideMenu = () => {
        setShowMenu(false);
    };

    const handleShowPlanModal = () => {
        if (showPlanModal) {
            setShowPlanModal(false);
            setCurrentModal('plan');
            dispatch(getStoreStatus());
        } else {
            setShowPlanModal(true);
        }
    };

    const handlePlanSelect = async(plan: PlanGroup) => {
        setSelectedPlan(plan);
        if (plan.cost) {
            setCurrentModal('payment');
        } else {
            await subscribeStore(storeId, plan.name);
            setCurrentModal('paymentSuccess');
        }
    };

    const handleResetModal = () => {
        setCurrentModal('plan');
    };

    const handlePaymentSuccess = () => {
        setCurrentModal('paymentSuccess');
    };

    const handleTerminateAccount = async() => {
        await new Prompt().yesNoDanger(
            {
                Component: ({onYes = _.noop, onNo = _.noop, ...props}: PromptProps) => <Delete onDelete={onYes} onCancel={onNo} {...props} />,
                className: 'plan-modal',
                onYes: async() => {
                    await deleteStore(storeId);
                },
                width: modalSize < 1200 ? modalSize : 1400,
            }
        );
    };

    const handleSidbarClick = () => {
        locationId.current = Date.now();
    };

    const handleTogglePinSidebar = () => {
        setPinMenu((pinMenu) => {
            updateMainMenuPin(storeId, !pinMenu);
            return !pinMenu;
        });
    };

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=1000, user-scalable=yes');

    return (
        <>
            {!viewingAsset && (
                <HeaderBar
                    className='scene-store'
                    onMenuClick={handleShowMenu}
                    onShowPlanModal={handleShowPlanModal}
                    hideMenu={pinMenu} />
            )}
            <UserPage
                onClick={handleSidbarClick}
                showMenu={viewingAsset ? false : showMenu}
                pinMenu={viewingAsset ? false : Boolean(pinMenu)}
                onHideMenu={handleHideMenu}
                onTogglePinSidebar={handleTogglePinSidebar}>
                <Routes>
                    <Route path=':storeId/*'>
                        <Route path='brands'>
                            <Route index element={<Brands />} />
                            {/* how to handle optional perams in react router 6? */}
                            <Route
                                path=':brandCardId'
                                element={<BrandCardContainer />} />
                            <Route
                                path=':brandCardId/:brandCardTabId'
                                element={<BrandCardContainer />} />
                            <Route
                                path=':brandCardId/:brandCardTabId/:brandCardItemId'
                                element={<BrandCardContainer />} />
                        </Route>
                        <Route path='assets'>
                            <Route index element={<Assets />} />
                            <Route path=':assetId' element={<Assets />} />
                        </Route>
                        <Route path='asset-viewer'>
                            <Route path=':assetId' element={<AssetViewer />} />
                        </Route>
                        <Route path='contacts/*' element={<Contacts />} />
                        <Route path='my-store/*' element={<Teams />} />
                        {currentStoreStatus && currentStoreStatus.hasTasks && (
                            <Route
                                path='tasks/*'
                                element={<Tasks locationId={locationId.current} />} />
                        )}
                    </Route>
                </Routes>
            </UserPage>
            <Modal
                className='plan-modal'
                width={modalSize < 1200 ? modalSize : 1400}
                footer={false}
                destroyOnClose
                open={showPlanModal}
                onCancel={handleShowPlanModal}>
                {currentModal === 'plan' && <Plans onPlanSelect={handlePlanSelect} onTerminateAccount={handleTerminateAccount} />}
                {currentModal === 'payment'
                    && selectedPlan
                    && <Payments
                        plan={selectedPlan}
                        onCancel={handleShowPlanModal}
                        onBack={handleResetModal}
                        onSuccess={handlePaymentSuccess} />}
                {currentModal === 'paymentSuccess'
                    && selectedPlan
                    && <ConfettiSuccess
                        title=''
                        body='Success!'
                        footer={currentStoreStatus?.isTrial
                            ? `Your BrandKeep ${_.capitalize(selectedPlan.name)} plan will be active after your trial expires on ${formatDate(currentStoreStatus.planEnd)}.`
                            : `Your BrandKeep ${_.capitalize(selectedPlan.name)} plan is now active!`}
                        onCancel={handleShowPlanModal} />}
            </Modal>
            <Modal
                className={classnames(
                    'onboarding-welcome-modal',
                    {'onboarding-welcome-modal--is-small': windowIsSmall},
                    {'onboarding-welcome-modal--is-tiny': windowIsTiny}
                )}
                open={isWelcomeModalOpen}
                onCancel={dismissWelcome}
                width={modalSize}
                footer={false}>
                <div className='modal-body'>
                    <div className='welcome-header'>
                        Welcome to BrandKeep
                    </div>
                    <div className='welcome-body'>
                        {'Stay organized with BrandKeep by centralizing assets and information on brand cards.\
                        If you get stuck along the way, check out our comprehensive '}
                        <a href='https://intercom.help/brandkeep' className='knowledge-base-link' target='_blank' rel='noreferrer'> knowledge base</a>.
                    </div>
                    <Button
                        onClick={dismissWelcome}
                        className='btn-secondary'>
                        Get started with brand cards
                    </Button>
                </div>
                <div className='modal-footer' />
                <img className='modal-background-image bottom-center' src={Welcome} />
            </Modal>
        </>
    );
};

export default Store;
