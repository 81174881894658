import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {message} from 'antd';
import {RootState} from 'src/redux/rootReducer';
import {useDispatch, useSelector} from 'react-redux';
import './styles.sass';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

//icons
import {ArrowLeftOutlined} from '@ant-design/icons';
import amexIcon from 'src/assets/images/cc-logos/amex.png';
import discoverIcon from 'src/assets/images/cc-logos/discover.png';
import mastercardIcon from 'src/assets/images/cc-logos/mastercard.png';
import visaIcon from 'src/assets/images/cc-logos/visa.png';

//components
import Button from 'src/Components/Button';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import ExpiresDate from './ExpiresDate';

//apis
import {getPaymentInfo, subscribeStore, savePaymentInfo, GetPaymentInfoResponse} from 'src/api/Store/api-store';

//types
import {genericFn} from 'src/Types/CommonTypes/index';

//libs
import {classnames, getErrorMessage} from 'src/utils/general';
import {PlanGroup} from 'src/constants/subscriptionPlans';
import {formatDate} from 'src/lib/date-helper';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';
import {clearServerErrors} from 'src/redux/global/actions';

interface Props {
    className?: string
    onCancel?: genericFn
    onBack?: genericFn
    onSuccess?: genericFn
    plan?: PlanGroup
}

const creditCardImageByType = {
    VISA: visaIcon,
    MC: mastercardIcon,
    AMEX: amexIcon,
    DISC: discoverIcon,
};

const creditCardNameByType = {
    VISA: 'Visa',
    MC: 'MasterCard',
    AMEX: 'American Express',
    DISC: 'Discover',
};

const Payments: React.FC<Props> = ({
    className = '',
    onCancel = _.noop,
    onBack,
    onSuccess = _.noop,
    plan,
}): JSX.Element | null => {
    //being in the topbar, useParams is not available, as it is outside of a Route
    const {pathname} = useLocation();
    const path = matchPath({path: '/store/:storeId/*'}, pathname);
    const storeId = _.get(path, 'params.storeId');
    const serverErrors = useSelector((state: RootState) => state.global.serverErrors);
    const billDay = dayjs().format('Do');
    const dispatch = useDispatch();

    const storeStatus = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const currentStore = storeStatus?.find((currentStore: StoreStatus) => `${currentStore.id}` === storeId);
    const isTrial = currentStore && currentStore.isTrial;

    const [currentPaymentInfo, setCurrentPaymentInfo] = useState<GetPaymentInfoResponse>();

    const computedClassName = classnames(
        'Payments modal-body',
        {
            [className]: className,
        }
    );

    useEffect(() => {
        const getCurrentPaymentInfo = async() => {
            const paymentInfo = await getPaymentInfo(storeId);
            setCurrentPaymentInfo(paymentInfo || undefined);
        };

        if (!isTrial && !plan) {
            getCurrentPaymentInfo();
        }
    }, [isTrial, plan]);

    useEffect(() => {
        const serverError = serverErrors
            && (getErrorMessage('storesSubscribe', serverErrors)
            || getErrorMessage('storesPaymentInfo', serverErrors)
            || getErrorMessage('storesSubscribeFree', serverErrors)
            || getErrorMessage('storesSubscribeBase', serverErrors)
            || getErrorMessage('storesSubscribePro', serverErrors));
        if (serverError) {
            message.error(serverError, 4, () => {
                dispatch(clearServerErrors());
            });
        }
    }, [serverErrors]);

    const handleExpiryDisplay = (expiry: string): string => {
        return `${expiry.length === 4 ? expiry.slice(0, 2) : expiry.slice(0, 1)}/${expiry.slice(-2)}`;
    };

    const validateSchema = () => Yup.object().shape({
        account: Yup.string().label('Card #').min(13).max(19).required(),
        expiryMonth: Yup.string().test('len', 'Must be exactly 1 or 2 characters', (val) => Boolean(val && (val.length === 1 || val.length === 2))).required(),
        expiryYear: Yup.string().test('len', 'Must be exactly 2 characters', (val) => Boolean(val && val.length === 2)).required(),
        cvv2: Yup.string().label('Security Code').min(3).max(4).required(),
        name: Yup.string().max(30).required(),
        address: Yup.string().max(30).required(),
        address2: Yup.string().max(30).optional(),
        city: Yup.string().max(30).required(),
        region: Yup.string().label('State').max(20).required(),
        postal: Yup.string().label('Zip Code').min(5).max(9).required(),
        country: Yup.string().max(2).required(),
        phone: Yup.string().max(15).optional(),
    });

    const defaultSchema = validateSchema();
    const handleSubmitPayment = async(values: any, {setSubmitting}: any) => {
        const month = values.expiryMonth.length === 2 ? values.expiryMonth : `0${values.expiryMonth}`;
        const postBody = Object.assign(
            {},
            values,
            {expiry: `${month}${values.expiryYear}`}
        );
        delete postBody['expiryMonth'];
        delete postBody['expiryYear'];
        if (plan) {
            await subscribeStore(storeId, plan.name, postBody);
        } else {
            //simply updating payment info, process new card and generate tokens on backend
            await savePaymentInfo(storeId, postBody);
        }
        setSubmitting(false);
        onSuccess();
    };

    const {
        handleSubmit,
        values,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues: {
            account: undefined,
            expiryMonth: undefined,
            expiryYear: undefined,
            cvv2: undefined,
            name: undefined,
            address: undefined,
            address2: undefined,
            city: undefined,
            region: undefined,
            postal: undefined,
            country: 'US',
            phone: undefined,
        },
        validationSchema: defaultSchema,
        onSubmit: handleSubmitPayment,
        enableReinitialize: true,
    });

    return (
        <form onSubmit={handleSubmit} id='submit-payment'>
            <div className={computedClassName}>
                <div className='Payments--header'>
                    {plan && !isTrial && (
                        <div className='selected-plan--info'>
                            You&apos;ve selected BrandKeep <span className='primary-color'>{(_.capitalize(plan.name))}</span> plan. You will be billed  <span className='primary-color'>${(plan.cost / 100)}</span> on the  <span className='primary-color'>{billDay}</span> of every month.
                        </div>
                    )}
                    {!plan && !isTrial && (
                        <>
                            <div className='selected-plan--info'>
                                Update Payment Information
                            </div>
                            {currentPaymentInfo && (
                                <div className='credit-card-block'>
                                    <div className='credit-card-info'>
                                        Current Payment Info
                                        <div className='credit-card-details'>
                                            {_.capitalize(currentPaymentInfo.creditCardType)} ending in {currentPaymentInfo.creditCardLastFour} (expires on {handleExpiryDisplay(currentPaymentInfo.expiry)})
                                        </div>
                                    </div>
                                    {creditCardImageByType[currentPaymentInfo.creditCardType] && (
                                        <div className='credit-card-image'>
                                            <img src={creditCardImageByType[currentPaymentInfo.creditCardType]} alt={creditCardNameByType[currentPaymentInfo.creditCardType] || ''} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    {plan && isTrial && (
                        <div className='selected-plan--info'>
                            Your first payment of <span className='primary-color'>${(plan.cost / 100)}</span> will be processed when your free trial ends on {formatDate(currentStore.planEnd)}.<br /> Your ongoing subscription will then be billed on the <span className='primary-color'>{dayjs(currentStore.planEnd).format('Do')}</span> of each month thereafter.
                        </div>
                    )}
                </div>
                <div className='Payments--body'>
                    <div className='Payments-body-header'>
                        <span>Payment</span>
                    </div>
                    <div className='Payments-body-form'>
                        <div>
                            <Input
                                containerClassName='Payments-account'
                                errors={errors.account && touched.account && errors.account}
                                required
                                autoComplete='cc-number'
                                autoFocus
                                label='Card #'
                                placeholder='Add credit card information'
                                {...getFieldProps('account')} />
                            <ExpiresDate
                                onChangeMonth={getFieldProps('expiryMonth').onChange}
                                onChangeYear={getFieldProps('expiryYear').onChange}
                                monthInputName={getFieldProps('expiryMonth').name}
                                yearInputName={getFieldProps('expiryYear').name}
                                month={values.expiryMonth}
                                year={values.expiryYear} />
                            <Input
                                containerClassName='Payments-cvv2'
                                errors={errors.cvv2 && touched.cvv2 && errors.cvv2}
                                required
                                autoComplete='cc-csc'
                                label='Security Code'
                                placeholder='Add security code'
                                {...getFieldProps('cvv2')} />
                        </div>
                        <div>
                            <Input
                                errors={errors.name && touched.name && errors.name}
                                required
                                autoComplete='cc-name'
                                label='Name on Card'
                                placeholder='Add name on card'
                                {...getFieldProps('name')} />
                        </div>
                        <div>
                            <Input
                                containerClassName='Payments-address'
                                errors={errors.address && touched.address && errors.address}
                                required
                                autoComplete='address-line1'
                                label='Billing Address'
                                placeholder='Address line 1'
                                {...getFieldProps('address')} />
                            <Input
                                errors={errors.address2 && touched.address2 && errors.address2}
                                label='&nbsp;'
                                autoComplete='address-line2'
                                placeholder='Address line 2'
                                {...getFieldProps('address2')} />
                        </div>
                        <div>
                            <Input
                                errors={errors.city && touched.city && errors.city}
                                required
                                autoComplete='address-level2'
                                label='City'
                                placeholder='Add city'
                                {...getFieldProps('city')} />
                        </div>
                        <div>
                            <Input
                                errors={errors.region && touched.region && errors.region}
                                required
                                autoComplete='address-level1'
                                label='State'
                                placeholder='Add state'
                                {...getFieldProps('region')} />
                            <Input
                                errors={errors.postal && touched.postal && errors.postal}
                                required
                                autoComplete='postal-code'
                                label='Postal Code'
                                placeholder='Add postal code'
                                {...getFieldProps('postal')} />
                            <Input
                                errors={errors.country && touched.country && errors.country}
                                required
                                disabled
                                label='Country'
                                placeholder='Add country'
                                {...getFieldProps('country')} />
                            <Input
                                errors={errors.phone && touched.phone && errors.phone}
                                label='Phone'
                                autoComplete='tel'
                                placeholder='Add phone'
                                {...getFieldProps('phone')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                {onBack && (
                    <div
                        className='link-button back-button'
                        onClick={onBack}>
                        <ArrowLeftOutlined className='icon left-icon' />Back To Plans
                    </div>
                )}
                <div
                    className='cancel-button'
                    onClick={onCancel}>
                    Cancel
                </div>
                <Button
                    disabled={isSubmitting}
                    type='submit'
                    form='submit-payment'
                    className='btn-secondary'>
                    {isSubmitting && (
                        <Loading inline />
                    )}
                    {plan && !isTrial && (<span>Confirm &amp; Pay ${(plan.cost / 100)} Now</span>)}
                    {plan && isTrial && 'Confirm'}
                    {!plan && (<span>Submit Payment Information</span>)}
                </Button>
            </div>
        </form>
    );
};

export default Payments;
