/* globals window */

const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const COGNITO_USERNAME = 'COGNITO_USERNAME';

export const getAccessToken = (): string | null => {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
    return accessToken;
};

export const getRefreshToken = (): string | null => {
    return window.localStorage.getItem(REFRESH_TOKEN);
};

export const getCognitoUsername = (): string | null => {
    return window.localStorage.getItem(COGNITO_USERNAME);
};

// eslint-disable-next-line camelcase
export const setTokens = async(accessToken: string, refreshToken: string, username?: string): Promise<void> => {
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
    if (username) {
        window.localStorage.setItem(COGNITO_USERNAME, username);
    }
};

export const setAccessToken = async(accessToken: string): Promise<void> => {
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const clearTokens = async(): Promise<void> => {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(REFRESH_TOKEN);
    window.localStorage.removeItem(COGNITO_USERNAME);
};
