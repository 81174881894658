import {genericFn} from 'src/Types/CommonTypes';
import {isDateOld} from 'src/utils/general';
import _ from 'lodash';

type TaskProps = {
    init: boolean,
};

interface Ping {
    frequency: number //in seconds
    unit?: string
    task: (arg0: TaskProps) => any
    id: string
    lastRan?: Date
    onError: genericFn
    pingOnInit?: boolean
}

class Pings {
    basePingFrequency: number;
    initialTime: Date;
    isPinging: boolean;
    taskDefinitions: Ping[];
    constructor() {
        this.initialTime = new Date();
        //minutes
        this.basePingFrequency = 5;
        this.isPinging = false;
        this.taskDefinitions = [];
        this.handlePingLoop = this.handlePingLoop.bind(this);
    }

    //currently runs every 100ms, lower to 10 if absolutely needed...
    handlePingLoop(): void {
        setInterval(() => {
            for (const taskDefinition of this.taskDefinitions) {
                if (taskDefinition.lastRan) {
                    if (isDateOld(taskDefinition.lastRan, taskDefinition.frequency * (taskDefinition.unit === 'minute' ? 60 : 1))) {
                        taskDefinition.lastRan = new Date();
                        try {
                            taskDefinition.task({init: false});
                        } catch (error) {
                            taskDefinition.onError(error);
                        }
                    }
                } else {
                    taskDefinition.lastRan = new Date();
                }
            }
        }, 100);
    }

    startPings(): void {
        if (!this.isPinging) {
            this.isPinging = true;
            this.handlePingLoop();
        }
    }

    addPing(ping: Ping): void {
        if (!_.find(this.taskDefinitions, {id: ping.id})) {
            if (ping.pingOnInit) {
                ping.lastRan = new Date();
                try {
                    ping.task({init: true});
                } catch (error) {
                    ping.onError(error);
                }
            }
            if (!ping.unit) {
                ping.unit = 'minute';
            }
            this.taskDefinitions.push(ping);
        }
    }

    removePing(pingId: string): void {
        this.taskDefinitions = _.filter(this.taskDefinitions, (task) => task.id !== pingId);
    }

    //extremely dangerous, only call this on logout! it will break token refreshing, etc
    removeAllPings(): void {
        this.taskDefinitions = [];
    }

    isPingRunning(pingId: string): boolean {
        return _.some(this.taskDefinitions, (task) => task.id === pingId);
    }
}

const PingInstance = new Pings();

export default PingInstance;
