import React from 'react';
import {useNavigate} from 'react-router-dom';
import HeaderBar from '../../../Components/HeaderBar';
import {AuthRoutes} from '../../../constants/userRoles';
import InviteUsersForm from './Form';
import useWindow from 'src/hooks/useWindow';
import './styles.sass';

const InviteUsers: React.FC = () => {
    const navigate = useNavigate();
    const {windowIsPhone} = useWindow();

    const goToHome = () => {
        navigate(AuthRoutes.home);
    };

    const displayMobileMessage = () => {
        navigate(AuthRoutes.onboardingMobileMessage);
    };

    return (
        <>
            <HeaderBar className='scene-onboarding-invite' hideMenu={true} />
            <div className='inviteUsers'>
                <h1 className='header'>
                    BrandKeep is better with your team - let’s invite them in!
                </h1>
                <div className='wrapper'>
                    <div className='form'>
                        <InviteUsersForm redirectHandler={windowIsPhone ? displayMobileMessage : goToHome} />
                    </div>
                    <div className='skip' onClick={windowIsPhone ? displayMobileMessage : goToHome}>
                        <a>Skip for now</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InviteUsers;
