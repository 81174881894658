import {useEffect, useState} from 'react';

const useDocumentTitle = (title: string): [string, React.Dispatch<React.SetStateAction<string>>] => {
    const [documentTitle, setDocumentTitle] = useState<string>(title);

    useEffect(() => {
        document.title = documentTitle.length ? `BrandKeep - ${documentTitle}` : 'BrandKeep | Where Retail Organizes';
    }, [documentTitle]);

    return [documentTitle, setDocumentTitle];
};

export default useDocumentTitle;
