import React, {useState} from 'react';
import {WarningFilled} from '@ant-design/icons';
import _ from 'lodash';
import './styles.sass';

//components
import Button from 'src/Components/Button';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';

//redux
import store from 'src/redux/store';
import {promptModalOptions} from 'src/redux/global/actions';

//types
import {genericFn} from 'src/Types/CommonTypes';

//lib
import {classnames, _get} from 'src/utils/general';


export interface Props {
    body?: string | JSX.Element
    title?: string | JSX.Element
    onYes?: genericFn
    onNo?: genericFn
    displayType?: 'delete' | 'notice'
    show?: boolean
    props?: any
    Component?: any
    width?: string | number
    className?: string
    hideXButton?: boolean
}

const headerIconByType = {
    'delete': <WarningFilled className='icon' />,
};

export class Prompt {

    yesNoDanger({onYes = _.noop, onNo = _.noop, ...options}: Props): Promise<boolean> {
        let confirmed: boolean | undefined = undefined;
        const handleOnYes = async(props) => {
            await onYes(props);
            this.hideModal();
            confirmed = true;
        };
        const handleOnNo = async() => {
            await onNo();
            this.hideModal();
            confirmed = false;
        };
        this.showModal({
            onYes: handleOnYes,
            onNo: handleOnNo,
            ...options,
        });

        return new Promise((resolve) => {
            const wait = setInterval(() => {
                if (typeof confirmed !== 'undefined') {
                    clearInterval(wait);
                    resolve(confirmed);
                }
            }, 20);
        });
    }

    notice({onYes = _.noop, ...options}: Props): Promise<boolean> {
        let confirmed: boolean | undefined = undefined;
        const handleOnYes = async(props) => {
            await onYes(props);
            this.hideModal();
            confirmed = true;
        };
        this.showModal({
            onYes: handleOnYes,
            ...options,
        });
        return new Promise((resolve) => {
            const wait = setInterval(() => {
                if (typeof confirmed !== 'undefined') {
                    clearInterval(wait);
                    resolve(confirmed);
                }
            }, 20);
        });
    }

    showModal(options: Props) {
        store.dispatch(promptModalOptions({show: true, ...options}));
    }

    hideModal() {
        store.dispatch(promptModalOptions({show: false, body: '', title: ''}));
    }
}

const confirmMessageByType = {
    delete: 'Delete',
    notice: 'Close',
};

const ModalPrompt: React.FC<Props> = ({
    title,
    body,
    displayType,
    show,
    onYes = _.noop,
    onNo = _.noop,
    Component,
    width = 700,
    className = '',
    hideXButton = false,
    props = {},
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onClose = async() => {
        await onNo();
    };
    const handleConfirm = async() => {
        setIsSubmitting(true);
        await onYes(props);
        setIsSubmitting(false);
    };

    const modalTitle = title && (
        <div className='modal-prompt-title'>
            {_get(headerIconByType, displayType)} {title}
        </div>
    );

    const hasCancelButton = displayType !== 'notice';

    const classes = classnames(
        'modal-prompt',
        {[className]: className}
    );

    return (
        <Modal
            className={classes}
            maskStyle={{zIndex: 2000}}
            width={width}
            open={show}
            title={modalTitle}
            footer={false}
            hideXButton={hideXButton}
            onCancel={hasCancelButton ? onClose : handleConfirm}
            maskClosable={false}
            destroyOnClose>
            {Component && <Component onYes={onYes} onNo={onClose} {...props} />}
            {!Component && (
                <>
                    <div className='modal-body'>
                        {_.isFunction(body) ? body(props) : body}
                    </div>
                    <div className='modal-footer'>
                        {hasCancelButton && (
                            <div
                                className='cancel-button'
                                onClick={onClose}>
                                Cancel
                            </div>
                        )}
                        <Button
                            disabled={isSubmitting}
                            className={displayType === 'delete' ? 'btn-delete' : 'btn-secondary'}
                            onClick={handleConfirm}>
                            {isSubmitting && (
                                <Loading inline />
                            )}
                            {(props.confirmText || (displayType && confirmMessageByType[displayType]) || 'OK')}
                        </Button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default ModalPrompt;
