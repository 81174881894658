
export const formatDate = (date?: string): string => {
    const dateObj = date ? new Date(date) : new Date();
    return dateObj.toLocaleDateString('en-US', {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
};

export const formatDateTime = (date?: string): string => {
    const dateObj = date ? new Date(date) : new Date();
    return dateObj.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        // month: 'short',
        // weekday: 'long',
        // weekday: 'short',
    });
};
