import {FormikHelpers} from 'formik';
import {useDispatch} from 'react-redux';
import {signInApi} from 'src/api/Auth';
import {getUserStatus} from 'src/redux/auth/actions';
import {setTokens} from 'src/utils/token.utils';
import {FormValues} from 'src/Views/Login/Forms/ForgotPassForm/useValidationSchema';

const useSubmit = (): (values: FormValues, actions: FormikHelpers<FormValues>) => Promise<void> => {
    const dispatch = useDispatch();
    return async(values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
        try {
            const response = await signInApi({username: values.email, password: values.password});
            if (response.challenge) {
                throw new Error('Error (auth challenge not supported)');
            } else { // sign in final step
                setTokens(response.tokens!.accessToken, response.tokens!.refreshToken, response.username);
                dispatch(getUserStatus());
            }
        } catch (e: any) {
            actions.setErrors({error: e.combinedMessage});
        }
    };
};

export default useSubmit;
