import {StoreStatus, StoreStatusActions, StoreStatusActionTypes} from './actionTypes';

export const getStoreStatus = (): StoreStatusActions => ({
    type: StoreStatusActionTypes.GET_STORE_STATUS,
});

export const getStoreStatusSuccess = (stores: Array<StoreStatus>): StoreStatusActions => ({
    type: StoreStatusActionTypes.GET_STORE_STATUS_SUCCESS,
    payload: {
        stores,
    },
});

export const getStoreStatusFailure = (): StoreStatusActions => ({
    type: StoreStatusActionTypes.GET_STORE_STATUS_FAILURE,
});

export const clearStoreStatus = (): StoreStatusActions => ({
    type: StoreStatusActionTypes.CLEAR_STORE_STATUS,
    payload: {
        stores: undefined,
    },
});
