//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';

//specific
import {Task} from 'src/api/Tasks/api-tasks';
import {filterMap} from 'src/utils/general';

interface DataBuilder {
    allTasks: Task[]
}

enum Status {
    complete = 'Completed',
    incomplete = 'Incomplete',
    pastDue = 'Past Due',
}

interface StatusOption {
    id: keyof typeof Status
    title: Status
}

const defaultOptions: StatusOption[] = [
    {id: 'complete', title: Status.complete},
    {id: 'incomplete', title: Status.incomplete},
    {id: 'pastDue', title: Status.pastDue},
];

const defaultOptionsById = _.keyBy(defaultOptions, 'id');

export default buildFilter({
    key: 'taskStatus',
    name: 'Status',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters) => appliedFilters,
    dataBuilder: (): StatusOption[] => defaultOptions,
    detailRenderer: (taskStatus: StatusOption) => taskStatus.title,
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {taskStatus}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Status'
                labelKey='title'
                labelTextRenderer={(taskStatus) => taskStatus.title}
                onChange={handleOnChangeFactory('taskStatus', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('taskStatus', onChange, values)}
                options={taskStatus}
                value={(values && values.taskStatus) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (taskStatus: StatusOption[], searchObject, key: string) => {
        searchObject[key] = _.map(taskStatus, 'id');
        return searchObject;
    },
    buildValuesFromQueryString: (UNUSED: any, {taskStatus}) => {
        return taskStatus ? filterMap(taskStatus, (status?: string) => status && defaultOptionsById[status]) : [];
    },
    enabled: ({allTasks}: DataBuilder) => Boolean(allTasks && allTasks.length),
    show: ({allTasks}: DataBuilder) => Boolean(allTasks && allTasks.length),
});
