import {Store, StoreActions, StoreActionTypes} from './actionTypes';

export const createStore = (storeName: string): StoreActions => ({
    type: StoreActionTypes.CREATE_STORE,
    payload: {label: storeName},
});

export const createStoreSuccess = (store: Store): StoreActions => ({
    type: StoreActionTypes.CREATE_STORE_SUCCESS,
    payload: {
        store,
    },
});

export const createStoreFailure = (error: string): StoreActions => ({
    type: StoreActionTypes.CREATE_STORE_FAILURE,
    payload: {
        error,
    },
});

export const clearCreateStore = (): StoreActions => ({
    type: StoreActionTypes.CLEAR_STORE,
    payload: {
        store: undefined,
    },
});
