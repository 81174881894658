import * as Yup from 'yup';

const validateSchema = () =>
    Yup.object().shape({
        code: Yup.string().required('Required'),
        password: Yup.string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.'),
        // .matches(
        //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //     'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        // ),
        confirmPass: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Password confirm is required'),
    });

export const defaultSchema = validateSchema();

const useValidationSchema = validateSchema;

export type FormValues = Yup.InferType<typeof defaultSchema>;
export default useValidationSchema;
