import {StoreStatusState, StoreStatusActions, StoreStatusActionTypes} from './actionTypes';

const initialState: StoreStatusState = {
    loading: null,
    error: '',
    storeStatus: [],
};

const StoresReducer = (state = initialState, action: StoreStatusActions): StoreStatusState => {
    switch (action.type) {
        case StoreStatusActionTypes.GET_STORE_STATUS: {
            return {...state, loading: true};
        }

        case StoreStatusActionTypes.GET_STORE_STATUS_SUCCESS: {
            return {
                ...state,
                loading: false,
                storeStatus: action.payload.stores,
            };
        }

        case StoreStatusActionTypes.GET_STORE_STATUS_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case StoreStatusActionTypes.CLEAR_STORE_STATUS: {
            return {
                ...state,
                loading: false,
                storeStatus: undefined,
            };
        }

        default:
            return state;
    }
};

export default StoresReducer;
