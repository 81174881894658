import {useDispatch} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {NonAuthRoutes} from 'src/constants/userRoles';
import {logout} from 'src/redux/auth/actions';
import {clearStores} from 'src/redux/stores/actions';
import {clearStoreStatus} from 'src/redux/storeStatus/actions';
import {clearToken} from 'src/redux/token/actions';
import {clearTokens} from 'src/utils/token.utils';
import {genericFn} from 'src/Types/CommonTypes';
import {clearCreateStore} from 'src/redux/createStore/actions';
import Pings from 'src/lib/pings';

interface Logout {
    logoutUser: genericFn
}

const useLogout = (): Logout => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const logoutUser = async() => {
        Pings.removeAllPings();
        dispatch(clearStores());
        dispatch(clearCreateStore());
        dispatch(clearStoreStatus());
        dispatch(logout());
        dispatch(clearToken());
        clearTokens();
        navigate(NonAuthRoutes.home, {state: {requestedPath: location && location.pathname}});
    };

    return {logoutUser};
};

export default useLogout;
