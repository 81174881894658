import {Table} from 'antd';
import React, {useState} from 'react';
import Button from 'src/Components/Button';
import './styles.sass';
import Steps from 'src/Components/Step';
import Loading from 'src/Components/Loading';

interface Props {
    importType?: string
    stepsNames: string[]
    headers: string[]
    currentStep: number
    tableData: Array<Record<any, any>>
    // isNextStepDisabled: boolean
    nextStepHandler: () => void
    prevStepHandler: () => void
    importFileHandler: () => void
    newFilesValue: number
    updatedFilesValue: number
    disableImport: boolean
}

const PreviewFileStep: React.FC<Props> = ({
    importType = 'brand',
    stepsNames,
    currentStep,
    newFilesValue,
    headers,
    tableData,
    updatedFilesValue,
    nextStepHandler,
    prevStepHandler,
    importFileHandler,
    disableImport = false,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    return (
        <div className='modalBody'>
            <div className='topInfo'>
                <div className='topInfo_message'>
                    Creating <span className='newFilesValue'>{newFilesValue}</span> new {importType}s
                </div>
                <div className='topInfo_message'>
                    Updating <span className='creatingBrands'>{updatedFilesValue}</span> existing {importType}s
                </div>
                <div className='topInfo_desc'>
                    Updates to existing {importType}s will replace imported data with current data. <br />
                    Any empty row data will be ignored and will not delete existing infromation
                </div>
            </div>
            <div className='table'>
                <Table
                    dataSource={tableData}
                    columns={headers.map((header) => {
                        return {
                            key: header,
                            title: header,
                            dataIndex: header,
                            width: 250,
                            render: (dataRow: any) => (
                                <span className='import-cell'>
                                    {dataRow}
                                </span>
                            ),
                        };
                    })}
                    pagination={false}
                />
            </div>
            <div className='tableMessage'>
                    If your import doesn’t look correct:<br />
                    Required columns are missing or in wrong order, file isn’t using a comma as the delimiter, file isn’t in
                    UTF-8 formatted.
            </div>

            <Steps
                steps={stepsNames}
                currentStep={currentStep}
                rightElement={
                    <div className='button_wrapper'>
                        <Button
                            type='secondary'
                            disabled={disableImport || isSubmitting}
                            className='button'
                            onClick={async() => {
                                setIsSubmitting(true);
                                await importFileHandler();
                                setIsSubmitting(false);
                                nextStepHandler();
                            }}>
                            {isSubmitting && (
                                <Loading inline />
                            )}
                            Import
                        </Button>
                    </div>
                }
                leftElement={
                    <div className='button_wrapper' onClick={prevStepHandler}>
                        <div className='backButton'>Back</div>
                    </div>
                }/>
        </div>
    );
};

export default PreviewFileStep;
