import {Store, StoresActions, StoresActionTypes} from './actionTypes';

export const getStores = (): StoresActions => ({
    type: StoresActionTypes.GET_STORES,
});

export const getStoresSuccess = (stores: Array<Store>): StoresActions => ({
    type: StoresActionTypes.GET_STORES_SUCCESS,
    payload: {
        stores,
    },
});

export const getStoresFailure = (error: string): StoresActions => ({
    type: StoresActionTypes.GET_STORES_FAILURE,
    payload: {
        error,
    },
});

export const clearStores = (): StoresActions => ({
    type: StoresActionTypes.CLEAR_STORES,
    payload: {
        stores: undefined,
    },
});
