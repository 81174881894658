//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';

//specific
import {Workspace, ApiWorkspace} from 'src/api/Workspace/api-workspace';
import {ID} from 'src/Types/CommonTypes';

interface FilterWorkspace {
    title: string
    id: ID[]
}

const labelTextRenderer = (workspace: FilterWorkspace) => workspace.title;

export default buildFilter({
    key: 'workspace',
    name: 'Workspace',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters, {workspace: workspaces}: Record<'workspace', any>) => {
        const rebuiltValues = [];
        for (const workspace of appliedFilters) {
            for (const id of workspace.id) {
                rebuiltValues.push({
                    id,
                    title: workspace.title,
                });
            }
        }

        const validatedWorkspaces = _.filter(rebuiltValues, (filter) => {
            const goodFilterValues = new Set(_(workspaces).map('id').flatten().value());
            return goodFilterValues.has(_.get(filter, 'id'));
        });

        const workspacesByTitle = _.groupBy(validatedWorkspaces, 'title');
        const combinedWorkspaces = _.map(workspacesByTitle, (workspaces: Workspace[]) => {
            return {
                title: workspaces[0].title,
                id: _.map(workspaces, 'id'),
            };
        });
        return combinedWorkspaces;
    },
    dataBuilder: ({workspaces}: Record<'workspaces', Workspace[]>) => {
        const workspacesByTitle = _.groupBy(workspaces, 'title');
        const combinedWorkspaces = _.map(workspacesByTitle, (workspaces: Workspace[]) => {
            return {
                title: workspaces[0].title,
                id: _.map(workspaces, 'id'),
            };
        });
        // blank/id: 0 denotes "no workspace" selection
        return [
            {
                title: '(No Workspace)',
                id: [0],
            },
            ..._.sortBy(combinedWorkspaces, (workspace) => _.toLower(workspace.title)),
        ];
    },
    detailRenderer: (workspace: any) => workspace.title,
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {workspace: workspaces}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Workspaces'
                disabled={!(workspaces && workspaces.length)}
                labelKey='title'
                labelTextRenderer={labelTextRenderer}
                onChange={handleOnChangeFactory('workspace', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('workspace', onChange, values)}
                options={workspaces}
                placeholder='Type to Search Workspaces'
                value={(values && values.workspace) || []}
                valueKey='title' />
        );
    },
    transformForAPI: (workspaces: any[], searchObject: Record<any, any>, key: string) => {
        searchObject[key] = _(workspaces).map('id').flatten().value();
        return searchObject;
    },
    buildValuesFromQueryString: ({workspaces}, {workspace}) => {
        const valueIdSet = new Set(workspace);
        // blank/id: 0 denotes "no workspace" selection
        const selectedWorkspaces = _.filter([{
            title: '(No Workspace)',
            id: 0,
        },
        ...workspaces,
        ], (tag) => valueIdSet.has(tag.id));

        const workspacesByTitle = _.groupBy(selectedWorkspaces, 'title');
        const combinedWorkspaces = _.map(workspacesByTitle, (workspaces: Workspace[]) => {
            return {
                title: workspaces[0].title,
                id: _.map(workspaces, 'id'),
            };
        });
        return combinedWorkspaces;
    },
    enabled: ({workspaces}: Record<'workspaces', ApiWorkspace[]>) => Boolean(workspaces && workspaces.length),
});
