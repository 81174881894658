export const tasksOnboarding = {
    createTask: {
        header: 'Use the ‘+ Task’ button to add new tasks and assign them to yourself or team members.',
        text: 'You can also associate tasks to brand cards - this is especially useful for keeping track of order deadlines or setting reminders on revising current orders in the future.',
        knowledgebaseLink: '',
        targetId: 'create-task-button',
        targetClassesToApply: ['pulse'],
    },
    completedTasks: {
        header: 'By default, the tasks dashboard will show your ‘Incomplete’ tasks.',
        text: 'Easily remove this filter or apply a filter to show your ‘Completed’ tasks if you’d like to refer back to work that has already been accomplished.',
        knowledgebaseLink: '',
        targetId: 'filter-task-button',
        targetClassesToApply: ['pulse-large'],
    },
    multiSelectTasks: {
        header: 'Utilize the multi-select Actions menu.',
        text: 'Here you can complete or mark incomplete, edit, or delete multiple tasks at one time.',
        knowledgebaseLink: '',
        targetId: 'multi-select-task-button',
        targetClassesToApply: ['pulse-large'],
    },
};
