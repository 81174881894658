import {useFormik, FormikHelpers} from 'formik';
import {message} from 'antd';
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from 'src/redux/rootReducer';
import {updateUser} from 'src/api/User/user-api';
import {User} from 'src/Types/User/types';
import {updateUser as updateUserAdmin} from 'src/api/Admin/User/api-admin-user';
import Button from 'src/Components/Button';
import Select from 'src/Components/Select';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import PopIcon from 'src/Components/PopIcon';
import {ID, genericFn} from 'src/Types/CommonTypes';
import _ from 'lodash';
import './styles.sass';
import {RolesEnum} from 'src/Types/StoreRoles/types';
import {getErrorMessage} from 'src/utils/general';
import tipIcon from 'src/assets/icons/tip-hex.svg';
import {clearServerErrors} from 'src/redux/global/actions';


interface Role {
    createdAt: string
    id: ID
    role: typeof RolesEnum[keyof typeof RolesEnum]
    storeId: ID
    updatedAt: string
    userId: ID
}

interface Props {
    isModalOpen: boolean
    onModalClose: genericFn
    user?: User
    isAdmin?: boolean
    canEditRole?: boolean
    canEditDetails?: boolean
    storeId?: ID
}

const TeamEdit: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    storeId,
    user,
    canEditRole = false, //currentUserRole === 'retail_owner'
    canEditDetails = false,
    isAdmin = false,
}) => {
    const [selectedRole, setSelectedRole] = useState<any>(_.get(user, ['storeRoles', 0]));
    const serverErrors = useSelector((state: RootState) => state.global.serverErrors);
    const dispatch = useDispatch();


    useEffect(() => {
        const initUser = () => {
            setSelectedRole(_.get(user, ['storeRoles', 0]));
        };
        initUser();
    }, [user]);

    useEffect(() => {
        const serverError = serverErrors && getErrorMessage('storesUsers', serverErrors);
        if (serverError) {
            message.error(serverError);
        }
    }, [serverErrors, isModalOpen]);

    const handleModalClose = (shouldReload = false) => {
        dispatch(clearServerErrors('storesUsers'));
        onModalClose(shouldReload);
    };

    const initialValues = {
        firstName: user && user.firstName,
        lastName: user && user.lastName,
        email: user && user.email,
    };

    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
        resetForm,
        values,
    } = useFormik({
        initialValues,
        onSubmit: async(values, actions: FormikHelpers<any>) => {
            try {
                const roleObj = selectedRole || _.get(user, ['storeRoles', 0]);
                const userId = _.get(user, 'id');
                if (userId) {
                    if (isAdmin) {
                        await updateUserAdmin(storeId, _.get(user, 'id'), {...values, role: roleObj.role});
                    } else {
                        await updateUser(storeId, _.get(user, 'id'), {...values, role: roleObj.role});
                    }
                }
                actions.setSubmitting(false);
                handleModalClose(true);
            } catch (e: any) {
                for (const error of e.errors) {
                    actions.setFieldError(_.camelCase(error.field) || 'firstName', error.message);
                }
            }
        },
        enableReinitialize: true,
    });

    if (!user) {
        return null;
    }

    const roles = [
        {role: 'retail_read_only', name: 'Viewer'},
        {role: 'retail_user', name: 'Editor'},
    ];

    if (isAdmin || canEditRole) {
        roles.push({role: 'retail_owner', name: 'Owner'});
    }

    const handleChangeRole = (UNUSED: any, role: Role) => {
        setSelectedRole(role);
    };


    return (
        <form id='edit-user' onSubmit={handleSubmit}>
            <Modal
                className='edit-user-modal'
                open={isModalOpen}
                title='Edit Team Member'
                width={600}
                footer={false}
                onCancel={() => {
                    setSelectedRole(_.get(user, ['storeRoles', 0]));
                    handleModalClose();
                    resetForm();
                }}>
                <div className='modal-body'>
                    <div className='edit-user-row'>
                        <div>
                            <label htmlFor='firstName' className='form-label'>
                                First Name
                            </label>
                            <Input
                                autoFocus
                                disabled={!(canEditDetails || isAdmin)}
                                errors={errors.firstName && touched.firstName && errors.firstName}
                                className='input'
                                placeholder='First Name'
                                {...getFieldProps('firstName')} />
                        </div>
                        <div>
                            <label htmlFor='lastName' className='form-label'>
                                Last Name
                            </label>
                            <Input
                                disabled={!(canEditDetails || isAdmin)}
                                errors={errors.lastName && touched.lastName && errors.lastName}
                                className='input'
                                placeholder='Last Name'
                                {...getFieldProps('lastName')} />
                        </div>
                    </div>
                    <div className='edit-user-row'>
                        <div>
                            <label htmlFor='email' className='form-label'>
                                Email
                            </label>
                            <Input
                                disabled
                                errors={errors.email && touched.email && errors.email}
                                className='input'
                                placeholder='Email'
                                value={values.email} />
                        </div>
                        <div>
                            <div className='pop-label'>
                                <label className='form-label' htmlFor='role'>
                                    Role
                                </label>
                                <PopIcon
                                    className='btn-tip'
                                    type='tip'
                                    content='Users invited as owners have access to view and edit all aspects of the platform while Editors have access to view everything, but limited edit access to overall store and account information.'
                                    origin={<img src={tipIcon} />} />
                            </div>
                            <Select
                                options={roles}
                                labelKey='name'
                                valueKey='role'
                                disabled={!(canEditRole || isAdmin)}
                                className='input'
                                placeholder='Role'
                                onChange={handleChangeRole}
                                name='role'
                                id='role'
                                value={selectedRole || _.get(user, ['storeRoles', 0])} />
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <div
                        className='cancel-button'
                        onClick={() => {
                            setSelectedRole(_.get(user, ['storeRoles', 0]));
                            handleModalClose();
                            resetForm();
                        }}>
                        Cancel
                    </div>
                    <Button
                        type='submit'
                        className='btn-secondary submit-button'
                        form='edit-user'
                        disabled={isSubmitting || !_.isEmpty(errors)}>
                        {isSubmitting && (
                            <Loading inline />
                        )}
                        Update
                    </Button>
                </div>
            </Modal>
        </form>
    );
};

export default TeamEdit;
