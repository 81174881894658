import {BrandsActions, BrandsActionTypes, BrandsState} from './actionTypes';

const initialState: BrandsState = {
    loading: false,
    error: '',
    brands: [],
    currentBrand: null,
};

const BrandsReducer = (state = initialState, action: BrandsActions): BrandsState => {
    switch (action.type) {
        case BrandsActionTypes.GET_BRANDS: {
            return {...state, loading: true, brands: []};
        }

        case BrandsActionTypes.CRATE_BRAND_CARD: {
            return {...state, loading: true};
        }

        case BrandsActionTypes.GET_BRANDS_SUCCESS: {
            return {
                ...state,
                loading: false,
                brands: action.payload.brands,
            };
        }

        case BrandsActionTypes.GET_BRAND_SUCCESS: {
            return {
                ...state,
                loading: false,
                currentBrand: action.payload.brand,
            };
        }

        case BrandsActionTypes.CRATE_BRAND_CARD_SUCCESS: {
            return {
                ...state,
                loading: false,
                brands: [...state.brands, action.payload.brandCard],
                currentBrand: action.payload.brandCard,
            };
        }

        case BrandsActionTypes.UPDATE_BRAND_CARD_SUCCESS: {
            return {
                ...state,
                loading: false,
                currentBrand: action.payload.brandCard,
            };
        }

        case BrandsActionTypes.GET_BRANDS_FAILURE:
        case BrandsActionTypes.GET_BRAND_FAILURE:
        case BrandsActionTypes.UPDATE_BRAND_CARD_FAILURE:
        case BrandsActionTypes.CRATE_BRAND_CARD_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }

        default:
            return state;
    }
};

export default BrandsReducer;
