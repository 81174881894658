import _ from 'lodash';
import React from 'react';
import './styles.sass';
import WelcomeLeftConfetti from '/src/assets/images/confetti/left.png';
import WelcomeRightConfetti from '/src/assets/images/confetti/right.png';


//components
import Button from 'src/Components/Button';

//types
import {genericFn} from 'src/Types/CommonTypes/index';

//libs
import {classnames} from 'src/utils/general';

interface Props {
    className?: string
    onCancel?: genericFn
    title?: string
    body?: string
    footer?: string
}

const ConfettiSuccess: React.FC<Props> = ({
    className = '',
    onCancel = _.noop,
    title = '',
    body = '',
    footer = '',
}): JSX.Element => {

    const computedClassName = classnames(
        'ConfettiSuccess',
        {
            [className]: className,
        }
    );

    return (
        <div className={computedClassName}>
            <img className='modal-background-image bottom-left' src={WelcomeLeftConfetti} />
            <img className='modal-background-image top-right' src={WelcomeRightConfetti} />
            <div className='modal-body'>
                <div className='Success-header'>
                    {title}
                </div>
                <div className='Success-body'>
                    {body}
                </div>
                <div className='Success-footer'>
                    {footer}
                </div>
            </div>
            <div className='modal-footer'>
                <Button
                    className='btn-secondary' onClick={onCancel}>
                        Close
                </Button>
            </div>
        </div>
    );
};

export default ConfettiSuccess;
