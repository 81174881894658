import React from 'react';
import {useSelector} from 'react-redux';
import ErrorMessage from 'src/Components/ErrorLabel';
import HeaderBar from 'src/Components/HeaderBar';
import {RootState} from 'src/redux/rootReducer';
import CreateStoreForm from './Form';
import './styles.sass';

const CreateStore: React.FC = () => {
    const error = useSelector((state: RootState) => state.store.error);

    return (
        <>
            <HeaderBar className='scene-onboarding-create-store' hideMenu={true} />
            <div className='createStore'>
                <h1 className='header'>You are one step closer to organizational bliss.</h1>
                <div className='wrapper'>
                    <div className='form'>
                        <CreateStoreForm />
                    </div>
                    <div>{error && <ErrorMessage message={error} />}</div>
                </div>
            </div>
        </>
    );
};

export default CreateStore;
