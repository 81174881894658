import apiHelper from 'src/api/api-helper';
import {ID, ApiMeta} from 'src/Types/CommonTypes';
import {TagToApiSave, ApiTag} from 'src/Types/Tags/types';

export interface ContactBase {
    firstName?: string
    lastName?: string
    phone?: string
    email?: string
    notes?: string
}

export enum ContactRefTypes {
    Store = 'store',
    BrandCards = 'brand_cards',
}

export interface Contact {
    id: ID
    firstName: string
    lastName: string
    phone: string
    email: string
    refType: ContactRefTypes
    brandCardId: number
    storeId: number
    roles?: TagToApiSave[] | ApiTag
    notes?: string
    useKeepMail?: boolean
}

export interface DataMetaReturn {
    data: Contact[],
    meta: ApiMeta
}

//todo update
export interface ParamsForStoreContacts {
    filterByBrand: string,
    filterByName: string,
    contactTag: ID[],
}

// export const getContacts = (params: {refType: string; refId: number}) => {
//     return apiHelper.get('/contacts', {refType, refId});
// };

export const getStoreContacts = (storeId: ID, params?: ParamsForStoreContacts): Promise<DataMetaReturn> => {
    return apiHelper.get(`/stores/${storeId}/contacts`, {...params, refType: 'brand_cards'});
};

export const getContactById = (contactId: ID): Promise<Contact> => {
    return apiHelper.get(`/contacts/${contactId}`);
};

export const createContact = (contact: Partial<Contact>): Promise<Contact> => {
    return apiHelper.post('/contacts', contact);
};

export const updateContact = (contactId: ID, contact: ContactBase | Contact): Promise<Contact> => {
    return apiHelper.put(`/contacts/${contactId}`, contact);
};

export const deleteContact = (contactId: ID): Promise<void> => {
    return apiHelper.delete(`/contacts/${contactId}`);
};

export const deleteContacts = (storeId: ID, contactIds: ID[]): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/contacts/delete`, {contactIds});
};
