import {nanoid} from 'nanoid';
import Papa, {ParseError, ParseResult} from 'papaparse';
import {useMemo, useState} from 'react';
import {FileError} from 'react-dropzone';

const useValidation = () => {
    const [headers, setHeaders] = useState<Array<string>>([]);
    const [data, setData] = useState<Array<Record<any, any>>>([]);
    const [errors, setErrors] = useState<Array<FileError>>([]);

    const requiredColumns = useMemo(() => ['First', 'Last', 'Brand Name', 'Roles', 'Email', 'Phone Number'], []);

    const validateHandler = async(file: File) => {
        async function parseCsv(file: File): Promise<any> {
            return new Promise((resolve, reject) => {
                Papa.parse<string>(file, {
                    delimiter: '',
                    chunkSize: 3,
                    header: true,
                    download: true,
                    complete: (results: ParseResult<string>) => {
                        return resolve(results);
                    },
                    error: (error: ParseError) => {
                        return reject(error);
                    },
                });
            });
        }
        const result = await parseCsv(file);
        const parsedHeaders = result.meta.fields;
        setData(result.data);
        setHeaders(parsedHeaders);

        const errors: Array<FileError | 0> = requiredColumns
            .map((requiredColumn, index) => {
                if (parsedHeaders.includes(requiredColumn)) {
                    if (parsedHeaders[index] === requiredColumn) {
                        return 0;
                    } else {
                        return {code: nanoid(), message: `${requiredColumn} column is on the wrong position`};
                    }
                } else {
                    return {code: nanoid(), message: `${requiredColumn} column does not exist in your file`};
                }
            })
            .filter((value) => value);

        const filteredErrors = (errors.filter((err) => {
            if (err === 0) {
                return false;
            }
            return true;
        }) as FileError[]) || ([] as FileError[]);

        if (!result.data.length) {
            filteredErrors.push({code: nanoid(), message: 'Data is empty'});
        }

        setErrors(filteredErrors);
        return filteredErrors;
    };
    return {headers, data, validateHandler, requiredColumns, errors, setErrors};
};

export default useValidation;
