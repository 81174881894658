import React from 'react';
import './styles.sass';
import {classnames} from 'src/utils/general';

interface Props {
    type: 'info' | 'danger' | 'success' | 'warn' | 'tip'
    className?: string
    children: [React.ReactNode, React.ReactNode | string]
}

const Callout: React.FC<Props> = ({type = 'info', children, className}) => {
    const classes = classnames(
        `Callout type-${type}`,
        {[className]: className}
    );
    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default Callout;
