//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    validateFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
    handleDeDupe,
} from 'src/lib/filter-helper';

//specific
import {Store} from 'src/api/Store/api-store';
import {Plan} from 'src/api/Admin/Store/api-admin-store';
import {filterMap} from 'src/utils/general';

interface DataBuilder {
    stores: Store[]
    allStores: Store[]
}

interface BuiltPlan {
    id: string
    originKeys: string[]
}

//will have none

export default buildFilter({
    key: 'plan',
    name: 'Plan',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters, {plan}: Record<'plan', Plan[]>) => {
        return validateFilter(appliedFilters, plan);
    },
    dataBuilder: ({allStores}: DataBuilder) => {
        const plans = filterMap(allStores, (store) => !store.plan.isTrial, 'plan');
        for (const plan of plans) {
            if (plan.key.includes('_legacy') && !plan.name.includes('(Free)')) {
                plan.name = `${plan.name} (Free)`;
            }
        }
        return handleDeDupe([{id: 0, name: 'Trial'}, ...plans], 'name', 'id', 'id');
    },
    detailRenderer: (plan) => plan && plan.id ? plan.id.toString() : '',
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {plan: plans}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Plans'
                disabled={!(plans && plans.length)}
                labelKey='id'
                labelTextRenderer={(plan: BuiltPlan) => plan.id}
                onChange={handleOnChangeFactory('plan', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('plan', onChange, values)}
                options={_.sortBy(plans, (plan) => _.toLower(plan.id))}
                placeholder='Type to Search Plans'
                value={(values && values.plan) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (plans: BuiltPlan[], searchObject, key: string) => {
        searchObject[key] = _(plans).map((plan) => plan.originKeys).flatten().uniq().value();
        return searchObject;
    },
    buildValuesFromQueryString: ({allStores}, {plan: selectedPlans}) => {
        const valueIdSet = new Set(selectedPlans);
        const availablePlans = filterMap(allStores, (store) => valueIdSet.has(store.plan.id), 'plan');
        for (const plan of availablePlans) {
            if (plan.key.includes('_legacy') && !plan.name.includes('(Free)')) {
                plan.name = `${plan.name} (Free)`;
            }
        }
        const allAvailablePlans = valueIdSet.has(0) ? [{id: 0, name: 'Trial'}, ...availablePlans] : availablePlans;
        return handleDeDupe(allAvailablePlans, 'name', 'id', 'id');
    },
    enabled: ({allStores}: DataBuilder) => Boolean(allStores && allStores.length),
});
