//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    validateFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
    handleDeDupe,
} from 'src/lib/filter-helper';

//specific
import {NormalizedTag, BuiltTag} from 'src/Types/Tags/types';
import {Contact} from 'src/api/Contact/api-contact';

interface DataBuilder {
    tags: NormalizedTag[]
    allContacts: Contact[]
}

//will have none

export default buildFilter({
    key: 'contactTag',
    name: 'Role',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters, {contactTag: contactTags}: Record<'contactTag', NormalizedTag[]>) => validateFilter(appliedFilters, contactTags),
    dataBuilder: ({tags, allContacts}: DataBuilder) => {
        //filter out any tag not assigned to an available contact
        const usedTagSet = new Set(
            _(allContacts)
                .map('roles')
                .flatten()
                .map('id')
                .compact()
                .value()
        );
        const availableTags = _.filter(tags, (tag) => usedTagSet.has(tag.id || ''));
        return handleDeDupe([{id: 0, title: '(No Role)'}, ...availableTags], 'title', 'id', 'id');
    },
    detailRenderer: (contactTag: NormalizedTag) => contactTag && contactTag.id ? contactTag.id.toString() : '',
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {contactTag: contactTags}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Roles'
                disabled={!(contactTags && contactTags.length)}
                labelKey='id'
                labelTextRenderer={(brandTag: BuiltTag) => brandTag.id}
                onChange={handleOnChangeFactory('contactTag', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('contactTag', onChange, values)}
                options={_.sortBy(contactTags, (tag) => _.toLower(tag.id))}
                placeholder='Type to Search Roles'
                value={(values && values.contactTag) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (contactTags: BuiltTag[], searchObject, key: string) => {
        searchObject[key] = _(contactTags).map((tag) => tag.originKeys).flatten().value();
        return searchObject;
    },
    buildValuesFromQueryString: ({tags}, {contactTag: selectedContactTags}) => {
        const valueIdSet = new Set(selectedContactTags);
        const availableTags = _.filter(tags, (tag) => valueIdSet.has(tag.id));
        const allAvailableTags = valueIdSet.has(0) ? [{id: 0, title: '(No Role)'}, ...availableTags] : availableTags;
        return handleDeDupe(allAvailableTags, 'title', 'id', 'id');
    },
    enabled: ({tags}: Record<'tags', NormalizedTag[]>) => Boolean(tags && tags.length),
});
