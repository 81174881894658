import {classnames} from 'src/utils/general';

interface Props {
    className?: string
}

interface CustomIconProps extends Props {
    filled?: boolean
}

export const Pen: React.FC<Props> = ({className}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='15' height='15' viewBox='0 0 15 15'>
            <defs>
                <clipPath id='clip-path'>
                    <rect id='Rectangle_2133' data-name='Rectangle 2133' width='15' height='15' transform='translate(0 0.416)' fill='none'/>
                </clipPath>
                <clipPath id='clip-Pen'>
                    <rect width='15' height='15'/>
                </clipPath>
            </defs>
            <g id='Pen' clipPath='url(#clip-Pen)'>
                <rect width='15' height='15' fill='none'/>
                <g id='Group_6655' data-name='Group 6655' transform='translate(0 0.113)'>
                    <g id='Group_5933' data-name='Group 5933' transform='translate(0 -0.529)' clipPath='url(#clip-path)'>
                        <path id='Path_4277' fill='currentColor' data-name='Path 4277' d='M14.832,3.806,11.1.078a.266.266,0,0,0-.376,0L8.919,1.887l4.1,4.1,1.809-1.808a.266.266,0,0,0,0-.377M1.143,9.663l0,0a.272.272,0,0,0-.071.127H1.071L.006,14.586h0a.252.252,0,0,0,.256.323A.268.268,0,0,0,.323,14.9V14.9l4.792-1.065v-.005a.264.264,0,0,0,.132-.067l7.4-7.4-4.1-4.1Zm3.649,3.7-4.176.928.928-4.176h1.65v1.331a.266.266,0,0,0,.266.266H4.792Z' transform='translate(0 0.506)' />
                    </g>
                </g>
            </g>
        </svg>
    </span>
);

export const Note: React.FC<Props> = ({className}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='14' height='16' viewBox='0 0 14 16'>
            <defs>
                <clipPath id='clip-path'>
                    <rect id='Rectangle_2417' data-name='Rectangle 2417' width='14' height='16' transform='translate(0.398)' />
                </clipPath>
                <clipPath id='clip-Notes'>
                    <rect width='14' height='16' />
                </clipPath>
            </defs>
            <g id='Note' clipPath='url(#clip-Notes)'>
                <rect width='14' height='16' fill='none' />
                <g id='Group_6742' data-name='Group 6742' transform='translate(-0.398)' clipPath='url(#clip-path)'>
                    <path id='Path_4754' data-name='Path 4754' fill='currentColor' d='M4.7.294a.294.294,0,0,0-.587,0V1.762H4.7Zm1.762,0a.294.294,0,1,0-.587,0V1.762H6.46Zm-3.524,0a.294.294,0,0,0-.587,0V1.762h.587ZM12.92,1.762H11.745V3.817a.294.294,0,1,1-.587,0V1.762H9.983V3.817a.294.294,0,0,1-.587,0V1.762H8.222V3.817a.294.294,0,0,1-.587,0V1.762H6.46V3.817a.294.294,0,1,1-.587,0V1.762H4.7V3.817a.294.294,0,0,1-.587,0V1.762H2.936V3.817a.294.294,0,0,1-.587,0V1.762H1.175A1.175,1.175,0,0,0,0,2.936V15.269a1.175,1.175,0,0,0,1.175,1.175H12.92a1.175,1.175,0,0,0,1.175-1.175V2.936A1.175,1.175,0,0,0,12.92,1.762M11.451,12.332H2.643a.294.294,0,0,1,0-.587h8.809a.294.294,0,0,1,0,.587m-9.1-2.055a.294.294,0,0,1,.294-.294H9.69a.294.294,0,1,1,0,.587H2.643a.293.293,0,0,1-.294-.294m9.1-1.468H2.643a.294.294,0,0,1,0-.587h8.809a.294.294,0,1,1,0,.587m0-1.762H2.643a.294.294,0,0,1,0-.587h8.809a.294.294,0,1,1,0,.587M11.745.294a.294.294,0,1,0-.587,0V1.762h.587Zm-1.762,0a.294.294,0,0,0-.587,0V1.762h.587Zm-1.762,0a.294.294,0,1,0-.587,0V1.762h.587Z' transform='translate(0.304)' />
                </g>
            </g>
        </svg>
    </span>
);

export const FilterIcon: React.FC<Props> = ({className}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='18' height='12' viewBox='0 0 18 12'>
            <defs>
                <clipPath id='clip-BK-Filter_Icon'>
                    <rect width='18' height='12' />
                </clipPath>
            </defs>
            <g id='BK-Filter_Icon' data-name='BK-Filter Icon' clipPath='url(#clip-BK-Filter_Icon)'>
                <rect width='18' height='12' fill='none' />
                <g id='Group_2' data-name='Group 2' transform='translate(-3 -6)'>
                    <g id='filter_list_black_24dp'>
                        <path id='Path' fill='none' d='M0,0H24V24H0Z' />
                        <path id='Shape' fill='currentColor' d='M7,12V10h4v2ZM3,7V5H15V7ZM0,2V0H18V2Z' transform='translate(3 6)' />
                    </g>
                </g>
            </g>
        </svg>
    </span>
);

export const Kabob: React.FC<Props> = ({className}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='14' height='16' viewBox='0 0 14 16'>
            <defs>
                <clipPath id='clip-Kabob'>
                    <rect width='14' height='16'/>
                </clipPath>
            </defs>
            <g id='Kabob' clipPath='url(#clip-Kabob)'>
                <rect width='14' height='16' fill='none'/>
                <path id='Shape' fill='currentColor' d='M0,14a2,2,0,1,1,2,2A2.006,2.006,0,0,1,0,14ZM0,8a2,2,0,1,1,2,2A2.006,2.006,0,0,1,0,8ZM0,2A2,2,0,1,1,2,4,2.006,2.006,0,0,1,0,2Z' transform='translate(5 -0.001)' />
            </g>
        </svg>
    </span>
);

export const Close: React.FC<Props> = ({className}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='14' height='14' viewBox='0 0 14 14'>
            <defs>
                <clipPath id='clip-_X_'>
                    <rect width='14' height='14' />
                </clipPath>
            </defs>
            <g id='_X_' data-name='“X”' clipPath='url(#clip-_X_)'>
                <path id='Path' fill='currentColor' d='M14.081,1.418,12.663,0,7.04,5.622,1.418,0,0,1.418,5.622,7.04,0,12.663l1.418,1.418L7.04,8.459l5.622,5.622,1.418-1.418L8.459,7.04Z' transform='translate(14.08 -0.001) rotate(90)' />
            </g>
        </svg>
    </span>
);

export const Download: React.FC<Props> = ({className}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20'>
            <defs>
                <clipPath id='clip-Download_icon'>
                    <rect width='20' height='20'/>
                </clipPath>
            </defs>
            <g id='Download_icon' data-name='Download icon' clipPath='url(#clip-Download_icon)'>
                <rect width='20' height='20' fill='none' />
                <g id='noun-download-1174592_1_' data-name='noun-download-1174592 (1)' transform='translate(-231.617 -149.497)'>
                    <path id='Path_4805' fill='currentColor' data-name='Path 4805' d='M248.57,383.47H234.186a.942.942,0,0,0,0,1.883H248.57a.942.942,0,1,0,0-1.883Z' transform='translate(0 -215.857)' />
                    <path id='Path_4806' fill='currentColor' data-name='Path 4806' d='M278,164.924a.989.989,0,0,0,.551.267l.119.011a.888.888,0,0,0,.144-.014l.044-.005a.931.931,0,0,0,.2-.07.919.919,0,0,0,.1-.054,1.216,1.216,0,0,0,.112-.081.384.384,0,0,0,.066-.051l4.4-4.4a.944.944,0,0,0,0-1.331.967.967,0,0,0-1.331,0l-2.794,2.793V150.438a.941.941,0,1,0-1.883,0v11.551L274.93,159.2a.941.941,0,1,0-1.331,1.331Z' transform='translate(-37.287 0)' />
                </g>
            </g>
        </svg>
    </span>
);

export const Hamburger: React.FC<Props> = ({className}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='27' height='24' viewBox='0 0 27 24'>
            <g>
                <path d='M25.1,2.1H1.9C1,2.1,0.3,2.8,0.3,3.7S1,5.4,1.9,5.4h23.1c0.9,0,1.7-0.7,1.7-1.7S26,2.1,25.1,2.1z' />
                <path d='M25.1,10.3H1.9c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7h23.1c0.9,0,1.7-0.7,1.7-1.7C26.7,11.1,26,10.3,25.1,10.3L25.1,10.3z' />
                <path d='M1.9,18.6h23.1c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7H1.9c-0.9,0-1.7-0.7-1.7-1.7S1,18.6,1.9,18.6L1.9,18.6z' />
            </g>
        </svg>
    </span>
);

export const CheckboxIcon: React.FC<CustomIconProps> = ({className = '', filled}) => (
    <span className={classnames(
        'CheckboxIcon',
        {'CheckboxIcon--filled': filled},
        {[className]: className}
    )} />
);

interface SortProps extends Props {
    up?: boolean
    down?: boolean
}

export const Sort: React.FC<SortProps> = ({className = '', up = false, down = false}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='12' height='16' viewBox='0 0 12 16'>
            <g transform='translate(0 124.486) rotate(-90)'>
                <path
                    fill={up ? 'currentColor' : undefined}
                    d='M120.7,5.7h-4.5V4.4h4.5V2.5l2.6,2.6l-2.6,2.6V5.7z' />
                <path
                    fill={down ? 'currentColor' : undefined}
                    d='M111.7,8.9l2.6-2.6v1.9h4.5v1.3h-4.5v1.9L111.7,8.9z' />
            </g>
        </svg>
    </span>
);

export const Checkmark: React.FC<Props> = ({className = ''}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='14' height='14' viewBox='0 0 14 14'>
            <g>
                <path
                    d='M13.3,2.2c0-0.6-0.5-1.1-1.1-1.1c-0.3,0-0.6,0.1-0.8,0.4l0,0L4.8,8.6L2.6,6.4l0,0C2.2,6,1.4,6,1,6.4
                    c-0.4,0.4-0.5,1-0.1,1.5l0,0L4,12.4l0,0c0.4,0.5,1.1,0.6,1.6,0.3c0.1-0.1,0.2-0.2,0.3-0.2l0,0l7.3-9.5l0,0
                    C13.3,2.7,13.3,2.5,13.3,2.2z'
                    fill='currentColor' />
            </g>
        </svg>
    </span>
);

export const Share: React.FC<Props> = ({className = ''}) => (
    <span className={className || undefined}>
        <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='50' height='50' viewBox='0 0 50 50'>
            <defs>
                <clipPath id='clip-Share_Icon'>
                    <rect width='50' height='50'/>
                </clipPath>
            </defs>
            <g id='Share_Icon' data-name='Share Icon' clipPath='url(#clip-Share_Icon)'>
                <rect width='50' height='50' fill='#fff'/>
                <g id='layer1' transform='translate(-0.001 -1036.363)'>
                    <path id='path2985' d='M-9.1,2.105a6.92,6.92,0,0,1-6.92,6.92,6.92,6.92,0,0,1-6.92-6.92,6.92,6.92,0,0,1,6.92-6.92A6.92,6.92,0,0,1-9.1,2.105Z' transform='translate(58.546 1042.177)'/>
                    <path id='path2985-1' d='M-9.1,2.105a6.92,6.92,0,0,1-6.92,6.92,6.92,6.92,0,0,1-6.92-6.92,6.92,6.92,0,0,1,6.92-6.92A6.92,6.92,0,0,1-9.1,2.105Z' transform='translate(58.546 1076.78)'/>
                    <path id='path2985-1-7' d='M-9.1,2.105a6.92,6.92,0,0,1-6.92,6.92,6.92,6.92,0,0,1-6.92-6.92,6.92,6.92,0,0,1,6.92-6.92A6.92,6.92,0,0,1-9.1,2.105Z' transform='translate(23.943 1059.478)'/>
                    <path id='path3791' d='M37.6,3,3,20.3,37.6,37.6' transform='translate(4.92 1041.282)' fill='none' stroke='#000' strokeWidth='2'/>
                </g>
            </g>
        </svg>

    </span>
);
