import _ from 'lodash';
import {createContext} from 'react';

export interface Status {
    dismissed?: boolean
    progress?: string
}

export type OnboardingTypes = 'brands' | 'brandCardAssets' | 'contacts' | 'assets' | 'tasks' | 'welcome';

export type OnboardStatus = {
    [type in OnboardingTypes]?: Status
};

//these are "defaults", if you will
export const onboardStatus: OnboardStatus = {
    brands: {
        dismissed: false,
    },
    brandCardAssets: {
        dismissed: false,
    },
    contacts: {
        dismissed: false,
    },
    assets: {
        dismissed: false,
    },
    tasks: {
        dismissed: false,
    },
    // team: {
    //     dismissed: false,
    // },
    welcome: {
        dismissed: false,
    },
};

export const OnboardContext = createContext({onboardStatus, setOnboardStatus: _.noop});
