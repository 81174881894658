import apiHelper from '../api-helper';
import {ID, Suggested, Note, API_ID} from 'src/Types/CommonTypes';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';

export interface Workspace {
    brandCardId?: ID
    title: string
    notes?: Note[]
}

export interface ApiWorkspace {
    id: API_ID
    brandCardId: number | string
    brandCard: BrandCard
    orderNumber: number
    title: string
    notes: Note[]
    searchName: string
    createdAt: string
}

export const getWorkspaces = async(brandCardId: ID): Promise<ApiWorkspace[]> => {
    return apiHelper.get(`/brand_cards/${brandCardId}/seasons`);
};

export const getWorkspaceById = async(workspaceId: ID): Promise<ApiWorkspace> => {
    return apiHelper.get(`/brand_cards/seasons/${workspaceId}`);
};

export const createWorkspace = async(brandCardId: ID, workspace: Workspace): Promise<ApiWorkspace> => {
    return apiHelper.post(`/brand_cards/${brandCardId}/seasons`, workspace);
};

export const reorderWorkspaces = async(brandCardId: ID, workspaceIds: ID[]) => {
    return apiHelper.put(`/brand_cards/${brandCardId}/seasons/reorder`, {workspaceIds});
};

export const updateWorkspace = async(workspaceId: ID, workspace: Workspace): Promise<ApiWorkspace> => {
    return apiHelper.put(`/brand_cards/seasons/${workspaceId}`, workspace);
};

export const deleteWorkspace = async(workspaceId: ID): Promise<void> => {
    return apiHelper.delete(`/brand_cards/seasons/${workspaceId}`);
};

export const getSuggestWorkspace = async(storeId: ID, search: string, count: number): Promise<Suggested[]> => {
    return apiHelper.get(`stores/${storeId}/seasons/suggest`, {search, count});
};

export const getAllWorkspaces = async(storeId: ID): Promise<ApiWorkspace[]> => {
    return apiHelper.get(`stores/${storeId}/seasons`);
};

export const logWorkspaceViewed = (storeId: ID, brandCardId: ID): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/brand_cards/${brandCardId}/workspace_activity`, {});
};
