export const brandsOnboarding = {
    createBrand: {
        header: 'Use the ‘+ Brand Card’ button to create a new Brand Card within your team space.',
        text: 'Brand Cards provide a place to store workbooks, key assets, contacts, important links, and more.',
        knowledgebaseLink: '',
        targetId: 'create-brand-button',
        targetClassesToApply: ['pulse'],
        //stop animating the dropdown menu
        onShowOnboarding: (): void => {
            setTimeout(() => {
                const targetedElementForHighlight = document.querySelector('#brand-import-menu');
                if (targetedElementForHighlight) {
                    targetedElementForHighlight.classList.remove('pulse');
                }
            });
        },
    },
    //note, import brand has custom functionality located in Brands/index
    //getting the dropdown menu to animate is a bear
    importBrand: {
        header: 'Looking for a faster way to get your shop built out in BrandKeep?',
        text: 'Utilize Brand Card imports to create several Brand Cards at once or make bulk updates to those that already exist.',
        knowledgebaseLink: '',
        targetId: 'create-brand-button',
        targetClassesToApply: ['pulse', 'origin-bottom'],
        onShowOnboarding: (): void => {
            setTimeout(() => {
                const targetedElementForHighlight = document.querySelector('#brand-import-menu');
                if (targetedElementForHighlight) {
                    targetedElementForHighlight.classList.add('pulse');
                    targetedElementForHighlight.classList.add('origin-top');
                }
            });
        },
    },
    filterBrand: {
        header: 'Utilize the Search and Filter functions to quickly find the Brand Card you’re looking for.',
        text: 'Example: Use the filter to view a list of all Brand Cards tagged as ‘Footwear’ to see your footwear brands. Or filter to a particular buyer to see all brand cards they are responsible for.',
        knowledgebaseLink: '',
        targetId: 'filter-brand-button',
        targetClassesToApply: ['pulse-large'],
        //stop animating the dropdown menu
        onShowOnboarding: (): void => {
            setTimeout(() => {
                const targetedElementForHighlight = document.querySelector('#brand-import-menu');
                if (targetedElementForHighlight) {
                    targetedElementForHighlight.classList.remove('pulse');
                }
            });
        },
    },
};
