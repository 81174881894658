import {all, call, put, takeEvery} from 'redux-saga/effects';
import {getStores as getStoresApi} from 'src/api/Store/api-store';
import {getStoresFailure, getStoresSuccess} from './actions';
import {Store, StoresActionTypes} from './actionTypes';


function* getStores() {
    try {
        const store: Array<Store> = yield call(getStoresApi);
        yield put(getStoresSuccess(store));
    } catch (error) {
        yield put(getStoresFailure('Something went wrong. Please Try again'));
    }
}

function* watchGetStores() {
    yield takeEvery(StoresActionTypes.GET_STORES, getStores);
}

export default function* storesSagas() {
    yield all([watchGetStores()]);
}
