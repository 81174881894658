import {ID, ApiMeta} from 'src/Types/CommonTypes';
import apiHelper from '../api-helper';
import {User} from 'src/Types/User/types';


export interface Task {
    id: ID
    name: string
    workspaceId: ID
    storeId: ID
    brandCardId: ID
    notes?: string | null
    assignedBy: ID
    assignedTo?: ID
    assignee?: User
    assignor?: User
    dueDate: string
    priority: 'low' | 'medium' | 'high'
    createdAt: string
    updatedAt: string
    status: 'complete' | 'incomplete'
    isPrivate: boolean
}

export interface TaskForApi {
    name?: string
    workspaceId?: ID | null
    storeId?: ID | null
    brandCardId?: ID | null
    notes?: string | null
    assignedTo?: ID | null
    dueDate?: string
    priority?: 'low' | 'medium' | 'high' | null
    isPrivate?: boolean
}

export interface DataMetaReturn {
    data: Task[],
    meta: ApiMeta
}

export interface ParamsForTasks {
    name?: string,
}

export const getTasks = (storeId: ID, params?: ParamsForTasks): Promise<Task[]> => {
    return apiHelper.post(`/stores/${storeId}/tasks`, params);
};

export const markTask = (storeId: ID, taskId: ID, status: string): Promise<void> => {
    return apiHelper.put(`stores/${storeId}/tasks/${taskId}/status`, {status});
};

export const createTask = (storeId: ID, task: TaskForApi): Promise<void> => {
    return apiHelper.post(`stores/${storeId}/tasks/create`, task);
};

export const updateTasks = (storeId: ID, updatedTasks: TaskForApi[]): Promise<void> => {
    return apiHelper.put(`/stores/${storeId}/tasks`, {updatedTasks});
};

export const deleteTasks = (storeId: ID, taskIds: ID[]): Promise<void> => {
    return apiHelper.post(`/stores/${storeId}/tasks/delete`, {taskIds});
};

