import apiHelper from '../api-helper';
import {ID} from 'src/Types/CommonTypes';
import {RolesEnum} from 'src/Types/StoreRoles/types';
import {User} from 'src/Types/User/types';

export interface StoreRole {
    userId: ID
    role: string
}

export interface StoreInfoApi {
    createdAt: string
    id: ID
    role: typeof RolesEnum[keyof typeof RolesEnum]
    storeId: ID
    updatedAt: string
    user: User
    userId: ID
}

export const getStoreRoles = async(storeId: ID, userId: ID) => {
    return apiHelper.get(`/stores/${storeId}/roles`, {userId});
};

export const getStoreRolesInfo = (storeId: ID, userId: ID): Promise<StoreInfoApi[]> => {
    return apiHelper.get(`/stores/${storeId}/users_roles`, {userId});
};

export const createStoreRole = async(storeId: ID, storeRole: StoreRole) => {
    return apiHelper.post(`/stores/${storeId}/roles`, storeRole);
};

export const getStoreRoleById = async(storeRoleId: ID) => {
    return apiHelper.get(`/stores/roles/${storeRoleId}`);
};

export const updateUserRole = async(roleId: ID, storeRole: StoreRole) => {
    return apiHelper.put(`/stores/users_roles/${roleId}`, storeRole);
};

export const deleteStore = async(storeRoleId: ID) => {
    return apiHelper.delete(`/stores/roles/${storeRoleId}`);
};
