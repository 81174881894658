import {DownloadOutlined} from '@ant-design/icons';
import React from 'react';
import {FileError} from 'react-dropzone';
import Button from 'src/Components/Button';
import DropZone, {UploadableFile} from 'src/Components/DropZone';
import './styles.sass';
import Steps from 'src/Components/Step';
import Tip from 'src/Components/Tip';

interface Props {
    importType?: string
    stepsNames: string[]
    requiredColumns: string[]
    currentStep: number
    isNextStepDisabled: boolean
    files: Array<UploadableFile>
    errors: Array<FileError>
    validateHandler: (file: File) => Promise<Array<FileError>>
    nextStepHandler: () => void
    submitFileHandler: () => Promise<void>
    downloadSampleHref: string
    setFiles: (files: Array<UploadableFile>) => void
}

const UploadFileStep: React.FC<Props> = ({
    importType = 'brand',
    stepsNames,
    currentStep,
    isNextStepDisabled,
    requiredColumns,
    files,
    errors,
    downloadSampleHref,
    validateHandler,
    nextStepHandler,
    submitFileHandler,
    setFiles,
}) => {
    return (
        <div className='modalBody'>
            <div className='title'>Rapidly import {importType}s by uploading a CSV with {importType} information</div>
            <div className='additionalInfo'>
                <div className='additionalInfo__firstLine'>Required columns in this order:</div>
                <div className='additionalInfo__secondLine'>{requiredColumns.join(' | ')}</div>
                <div className='actionButtons'>
                    <a href={downloadSampleHref} rel='noreferrer' target='_blank' className='actionButton'>
                        <div className='actionButton_icon'>
                            <DownloadOutlined />
                        </div>
                        Download sample .csv template
                    </a>
                </div>
                <div className='uploadFileSection'>
                    <div className='uploadFileInput'>
                        <DropZone
                            className='dragAndDrop'
                            acceptedFiles={['.csv']}
                            maxFileSize={1000 * 1024}
                            maxFilesQuantity={1}
                            uploadHandler={(files: Array<UploadableFile>) => {
                                setFiles(files);
                            }}
                            validateFileHandler={validateHandler}>
                            <p className='dragAndDrop_title'>Click or drag CSV file to this area to upload</p>
                            <p className='dragAndDrop_otherInfo'>Must be .csv format</p>
                            {files.length
                                ? files.map((file) => {
                                    return (
                                        <p className='uploadedFile' key={file.id}>
                                            {' '}
                                            Uploaded file: {file.file.name}
                                        </p>
                                    );
                                })
                                : ''}
                        </DropZone>
                    </div>
                    <div className='tip'>
                        <Tip>
                            <p>
                                A CSV (comma-separated values) is used to import and export spreadsheet information. Most spreadsheet
                                apps can open and save CSV files.
                            </p>
                            <p>
                                When saving your spreadsheet as a .csv - Your delimiter should be set as a comma and the format should
                                be UTF-8.
                            </p>
                        </Tip>
                    </div>
                </div>
                <div className='errors'>
                    {errors.map((error) => {
                        return (
                            <div className='error' key={error.code}>
                                {error.message}
                            </div>
                        );
                    })}
                </div>
            </div>
            <Steps
                steps={stepsNames}
                currentStep={currentStep}
                rightElement={
                    <Button
                        type='secondary'
                        className='button'
                        disabled={isNextStepDisabled}
                        onClick={() => {
                            nextStepHandler();
                            submitFileHandler();
                        }}>
                        Next
                    </Button>
                } />
        </div>
    );
};

export default UploadFileStep;
