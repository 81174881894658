import {ID} from 'src/Types/CommonTypes';

export enum StoresActionTypes {
    GET_STORES = 'GET_STORES',
    GET_STORES_SUCCESS = 'GET_STORES_SUCCESS',
    GET_STORES_FAILURE = 'GET_STORES_FAILURE',
    CLEAR_STORES = 'CLEAR_STORES'
}

export interface Store {
    createdAt: string
    description: string | null
    id: ID
    title: string
    updatedAt: string
    assetMailbox: string
    assetMailboxSuffix: string
    useKeepMail: boolean
    logoUrl?: string
}
export interface IGetStores {
    type: StoresActionTypes.GET_STORES
}

export interface IGetStoresSuccess {
    type: StoresActionTypes.GET_STORES_SUCCESS
    payload: {
        stores: Array<Store>
    }
}

export interface IGetStoresFailure {
    type: StoresActionTypes.GET_STORES_FAILURE
    payload: {
        error: string
    }
}

export interface IClearStores {
    type: StoresActionTypes.CLEAR_STORES
    payload: {
        stores: undefined
    }
}

export interface StoresState {
    error: string
    loading: boolean | null
    stores?: Array<Store>
}

export type StoresActions = IGetStores | IGetStoresSuccess | IGetStoresFailure | IClearStores;
