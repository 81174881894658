import React from 'react';
import Modal from 'src/Components/Modal';
import BrandCardForm from './BrandCardForm';
import {ID, genericFn} from 'src/Types/CommonTypes';
import './styles.sass';
import _ from 'lodash';

interface Props {
    open: boolean
    onCreate: genericFn
    onCancel: genericFn
    storeId?: ID
}

const BrandModal: React.FC<Props> = ({open, onCancel, storeId, onCreate}) => {

    return (
        <Modal
            className='create-brandcard-modal'
            width='1100px'
            open={open}
            title={<div className='create-brand-card-title'>Create Brand Card</div>}
            footer={false}
            onCancel={onCancel}
            maskClosable={false}
            destroyOnClose>
            <BrandCardForm storeId={storeId} refetchBrands={_.noop} onSelectBrandId={onCreate} onCancel={onCancel} />
        </Modal>
    );
};

export default BrandModal;
