// import 'lodash';
// declare const _:any;
import _ from 'lodash';

import React from 'react';
import {lodashGetType} from 'src/utils/general';
import {QueryParams} from 'src/lib/url';

//can this be better?
export type Value = any;

export interface FilterForBuild {
    //must always equal the object's key!
    key: string
    //Human-readable name
    name: string
    //customize name render in selector
    nameRenderer?: (name: string) => string | JSX.Element,
    //if filters should appear in multiple groups, key by this
    filterGroupCode?: string
    //used to ensure a filter's selected values are still valid
    appliedFilterValidation: (appliedFilters: AppliedFilter[], values: Record<string, Value>) => AppliedFilter[]
    //transform the data before it reaches `component`, if need be
    dataBuilder: (rawData: unknown) => unknown,
    //how the filter will display if there is a seperate section for displaying selected filters
    detailRenderer: (value: Value) => string | JSX.Element,
    preserveFormatting?: boolean, //prevents detailRenderer from applying formatting (lowercase/capitalizing, etc)
    //handles showing the selection UI and associated value
    component: (values: Record<string, Value>, onChange: onChangeCb, options: unknown[]) => string | JSX.Element
    //checks if the selected filter value is valid. Used for custom values.
    //prevents the filter from being shown via `detailRenderer` AND intercepts `transformForAPI`, blocking the selection from
    //making it to the backend, BUT still allows caching of the value in the URL.
    filterValidationOnChange?: (value: Value, filters: Dummy[]) => boolean
    //not implemented. use to determine load priority for filters. useful if one filter relies on another's data, for example.
    loadAfter?: string[] //array of `key`s
    //configure the data into the shape needed for the backend
    transformForAPI: (value: Value, postBody: Record<string, Value>, key: string, rawData?: unknown) => Record<string, unknown> | string
    //recreate selected values from querystring params
    buildValuesFromQueryString: (rawData: unknown, queryParams: QueryParams | Record<string, any>) => any,
    enabled: (rawData: unknown) => boolean //enable filter
    //hide filter, but leave validation, etc if enabled
    show?: (rawData: unknown) => boolean
}

export interface Filter extends FilterForBuild {
    //key used to ensure a unique selector for a filter value
    valueKey?: string,
    show: (rawData: unknown) => boolean
}

//does this work? lol
type Dummy = Filter;

export interface Filters {
    [key: string]: Filter
}

export interface AppliedFilter {
    [key: string]: any
}
export type onChangeCb = (value: Record<any, any>[]) => void;
type onChange = (newValues: any, key: string, callback: onChangeCb, topState: any, radioGroup?: string) => void;
type onChangeFactory = (key: string, callback: onChangeCb, topState: unknown, radioGroup?: string) => (newValues: Value) => void;
type onChangeCheckboxFactory = (key: string, callback: onChangeCb, topState: any) => (newValues: any) => void;

const baseFilter = {
    valueKey: 'id',
    dataBuilder: (rawData: unknown) => rawData,
    preserveFormatting: false,
    detailRenderer: (value: string) => value,
    enabled: () => true,
    show: () => true,
    buildValuesFromQueryString: (rawData: any, queryParams: QueryParams) => queryParams,
};

//debounce rate for how oftern filter value changes can be made via the UI before an API request is sent
export const GLOBAL_FILTER_DEBOUNCE_RATE = 450;

export const buildFilter = (filter: FilterForBuild): Filter => {
    return Object.assign({}, baseFilter, filter);
};

export const validateFilter = (appliedFilters: AppliedFilter[], values: Value[], selector: any = 'id') => {
    const goodFilterValues = new Set(_.map(values, selector));
    return _.filter(appliedFilters, (filter) => {
        return goodFilterValues.has(_.get(filter, selector));
    });
};

export const stringFormatter = (option: Record<string, string>, optionLabel: string) => {
    return (
        <span>{option[optionLabel]}</span>
    );
};

export const stringFormatterLower = (option: Record<string, string>, optionLabel: string) => {
    return (
        <span style={{textTransform: 'capitalize'}}>
            {_.toLower(option[optionLabel])}
        </span>
    );
};

const handleOnChange: onChange = (newValues, key, callback, topState, radioGroup): void => {
    const state = _.cloneDeep(topState);
    state[key] = newValues && newValues.target ? newValues.target.value : newValues;
    if ((_.isArray(state[key]) && !state[key].length) || !state[key]) {
        delete state[key];
    }
    //key is in a group with radioGroup, remove the value of radioGroup
    if (radioGroup) {
        delete state[radioGroup];
    }
    callback(state);
};

export const handleOnChangeFactory: onChangeFactory = (key, callback, topState, radioGroup) => {
    return (newValues) => {
        const state = _.cloneDeep(topState);
        handleOnChange(newValues, key, callback, state, radioGroup);
    };
};

export const removeFilterFactory = (filterValues, handleSetFilter) => {
    return (key, value, filter, valueKey) => {
        const newFilterValues = _.cloneDeep(filterValues);

        if (_.isArray(newFilterValues[key]) && newFilterValues[key].length > 1) {
            const index = filter.valueKey === 'ARRAY___INDEX'
                ? valueKey
                : _.findIndex(newFilterValues[key], {[filter.valueKey || 'id']: valueKey});
            newFilterValues[key].splice(index, 1);
        } else {
            delete newFilterValues[key];
        }

        handleSetFilter(newFilterValues);
    };
};

export const areFilterValuesEmpty = (filterValues: any[]): boolean => {
    return _.isEmpty(filterValues)
        || _.every(filterValues, (value) => _.isEmpty(value))
        || false;
};

//use this when the input (checkboxes only?) is setting values to an array in state
export const handleOnChangeCheckboxFactory: onChangeCheckboxFactory = (key, callback, topState) => {
    return (newValues) => {
        const state = _.cloneDeep(topState);
        const value = (newValues && newValues.target) ? newValues.target.value : newValues;
        if (state[key] && state[key].length) {
            const exists = _.isObject(value) ? _.findIndex(state[key], value) : _.indexOf(state[key], value);
            if (exists > -1) {
                //item exists, remove it
                state[key].splice(exists, 1);

                //if that was the last one, clear out the entry
                if (!state[key].length) {
                    delete state[key];
                }
            } else {
                //add item
                state[key].push(value);
            }
        } else {
            //make array, add items
            state[key] = [value];
        }
        callback(state);
    };
};

export const handleDeDupe = (options: any[], accessor: lodashGetType, keyAccessor: lodashGetType, index = 'id') => {
    const values: Record<string, boolean> = {};
    const results = [];
    for (let i = 0; i < options.length; i++) {
        const item = options[i];
        const value = _.isFunction(accessor)
            ? accessor(item)
            : _.get(item, accessor);
        const key = _.isFunction(keyAccessor)
            ? keyAccessor(item)
            : _.get(item, keyAccessor);
        if (value in values || !value) {
            const result = _.find(results, {[index]: value});
            if (result) {
                //we dont want a new entry, but we do need that key
                result.originKeys.push(key);
            }
            continue;
        }

        values[value] = true;
        results.push({
            [index]: value,
            originKeys: [key],
        });
    }
    return results;
};

//stopgap, make better
const ignoredQueryParams = new Set([
    'page',
    'perpage',
    'search',
    'filterByName',
    'textSearch',
]);

export const handleFilterValidations = (filters: Record<string, Filter>, filterDependencies: Record<string, any>, queryParams: QueryParams) => {
    const urlFilterValues: Record<string, any> = {};
    const updatedQueryParamObject: Record<string, any> = {};

    //reconstruct `filterValues` from querystring params
    for (const [key, filter] of Object.entries(filters)) {
        urlFilterValues[key] = filter.buildValuesFromQueryString(filterDependencies, queryParams);
    }

    /* MUCH OF THIS WILL NEED UPDATING IF WE EVER INFORM THE USER ANYTHING WAS CHANGED */
    /* of major note is that buildValuesFromQueryString removes invalid values prematurely! */
    // let didFiltersChange = false;

    //there are sometimes when filters change that this variable does not detect, but the current set of features
    //dont need to know that, so we rely on this for now.
    let didFiltersObviouslyChange = false;


    // const removedFilters = [];
    for (const filterKey of Object.keys(queryParams)) {
        //implement 'loadAfter' if needed
        const filterValue = urlFilterValues[filterKey];
        //dont vailidate params that are not filters
        if (ignoredQueryParams.has(filterKey)) {
            // didFiltersObviouslyChange = true;
            // console.log("didFiltersObviouslyChange1", didFiltersObviouslyChange);
            // didFiltersChange = true;
            // removedFilters.push({key: filterKey, value: filterValue});
            //prompt notification someday
            updatedQueryParamObject[filterKey] = queryParams[filterKey];
            continue;
        }
        //entire filter no long exists, remove entries for it
        if (!filters[filterKey]) {
            didFiltersObviouslyChange = true;
            // didFiltersChange = true;
            // removedFilters.push({key: filterKey, value: filterValue});
            //prompt notification someday
            delete urlFilterValues[filterKey];
            continue;
        }
        const filterValidValue = filters[filterKey].appliedFilterValidation(filterValue, urlFilterValues);
        if (_.isEmpty(filterValidValue)) {
            didFiltersObviouslyChange = true;
            // didFiltersChange = true;
            delete urlFilterValues[filterKey];
            // removedFilters.push({key: filterKey, value: filterValue});
        } else {
            /*********
             * This area is the most broken. it happens when you have multiple options selected from the same filter, and one is still valid
             * Fortunately, we dont need to know quite yet is any are invalid, only that one IS valid, so this block can just stay here for now.
            */
            //filter is valid, but some value are not. happens with multi-select
            // console.log("filterValidValue", filterValidValue);
            // console.log("queryParams[filterKey]", queryParams[filterKey]);
            // if (!_.isEqual(filterValidValue, queryParams[filterKey])) {
            // didFiltersObviouslyChange = true;
            // console.log("didFiltersObviouslyChange4", didFiltersObviouslyChange);
            // didFiltersChange = true;
            // removedFilters.push({key: filterKey, value: filterValue});
            // }
            urlFilterValues[filterKey] = filterValidValue;
        }
    }

    for (const [filterKey, filter] of Object.entries(filters)) {
        if (_.get(filter, 'transformForAPI') && !_.isEmpty(urlFilterValues[filterKey])) {
            filters[filterKey].transformForAPI(
                _.cloneDeep(urlFilterValues[filterKey]),
                updatedQueryParamObject,
                filterKey,
                filterDependencies
            );
        } else {
            updatedQueryParamObject[filterKey] = undefined;
        }
    }
    return {
        didFiltersObviouslyChange,
        urlFilterValues,
        updatedQueryParamObject,
    };
};
