/*
 * SYNCHRONIZE WITH BACKEND FileTypeHelper UNTIL WE COME UP WITH SOME KIND OF SHARED LIB PARADIGM!
*/
import _ from 'lodash';

export enum FILE_TYPES {
    AUDIO = 'AUDIO',
    COMPRESSED = 'COMPRESSED',
    DOCUMENT = 'DOCUMENT',
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
    PDF = 'PDF',
    SLIDESHOW = 'SLIDESHOW',
    SPREADSHEET = 'SPREADSHEET',
    VIDEO = 'VIDEO',
    WEB = 'WEB',
}

//defined as the ones we currently support for AssetPreview
export enum SUPPORTED_FILE_TYPES {
    DOCUMENT = 'DOCUMENT',
    IMAGE = 'IMAGE',
    PDF = 'PDF',
    SLIDESHOW = 'SLIDESHOW',
    SPREADSHEET = 'SPREADSHEET',
    VIDEO = 'VIDEO',
}

export enum FILE_TYPES_FOR_CONVERSION {
    DOCUMENT = 'DOCUMENT',
    SPREADSHEET = 'SPREADSHEET',
    SLIDESHOW = 'SLIDESHOW',
}

export type SupportedFileTypes = keyof typeof SUPPORTED_FILE_TYPES;
export type FileTypes = keyof typeof FILE_TYPES;

export const typesByFileEnding = {
    // AUDIO
    m4a: FILE_TYPES.AUDIO,
    mp3: FILE_TYPES.AUDIO,
    wma: FILE_TYPES.AUDIO,

    // COMPRESSED
    gz: FILE_TYPES.COMPRESSED,
    zip: FILE_TYPES.COMPRESSED,

    // DOCUMENT
    doc: FILE_TYPES.DOCUMENT,
    docx: FILE_TYPES.DOCUMENT,
    pages: FILE_TYPES.DOCUMENT,
    txt: FILE_TYPES.DOCUMENT,
    rtf: FILE_TYPES.DOCUMENT,

    // IMAGE
    apng: FILE_TYPES.IMAGE,
    avif: FILE_TYPES.IMAGE,
    bmp: FILE_TYPES.IMAGE,
    gif: FILE_TYPES.IMAGE,
    //jpg formats
    jfif: FILE_TYPES.IMAGE,
    pjpeg: FILE_TYPES.IMAGE,
    pjp: FILE_TYPES.IMAGE,
    jpeg: FILE_TYPES.IMAGE,
    jpg: FILE_TYPES.IMAGE,
    png: FILE_TYPES.IMAGE,
    svg: FILE_TYPES.IMAGE,
    webp: FILE_TYPES.IMAGE,

    // PDF
    pdf: FILE_TYPES.PDF,

    // SLIDESHOW
    key: FILE_TYPES.SLIDESHOW,
    ppt: FILE_TYPES.SLIDESHOW,
    pptx: FILE_TYPES.SLIDESHOW,

    // SPREADSHEET
    csv: FILE_TYPES.SPREADSHEET,
    numbers: FILE_TYPES.SPREADSHEET,
    xlsx: FILE_TYPES.SPREADSHEET,
    xlsb: FILE_TYPES.SPREADSHEET,
    xls: FILE_TYPES.SPREADSHEET,
    ods: FILE_TYPES.SPREADSHEET,
    prn: FILE_TYPES.SPREADSHEET,

    // VIDEO
    m4v: FILE_TYPES.VIDEO,
    // mov: FILE_TYPES.VIDEO,
    mp4: FILE_TYPES.VIDEO,
    // mpg: FILE_TYPES.VIDEO,
    // wmv: FILE_TYPES.VIDEO,

    //WEB
    html: FILE_TYPES.WEB,
};


export function getFileNoExtension(filename: string): string {
    const periodIndex = (filename || '').lastIndexOf('.');
    return (periodIndex === -1)
        ? filename
        : filename.substring(0, periodIndex);
}

export function getFileExtension(filename: string): string {
    const periodIndex = (filename || '').lastIndexOf('.');
    return (periodIndex === -1)
        ? ''
        : filename.substring(periodIndex + 1);
}

export default function getFileType(filename: string): FileTypes {
    const fileEnding = _.toLower(getFileExtension(filename));
    return typesByFileEnding[fileEnding] || FILE_TYPES.OTHER;
}
