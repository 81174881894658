import React, {useEffect} from 'react';
import loadScript from 'load-script';

const GOOGLE_ACCOUNT_SDK_URL = 'https://accounts.google.com/gsi/client';
const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js';


interface Props {
    onChange: (result: google.picker.ResponseObject) => void;
    onAuthenticate: (oauthToken: string) => void;
    onAuthFailed: (response: any) => void;
    disabled?: boolean;
    children: any;
}

const GooglePicker: React.FC<Props> = ({
    onChange,
    onAuthenticate,
    onAuthFailed,
    disabled = false,
    children,
}) => {
    const GOOGLE_APP_ID = ENV_VARS.googleAppId;
    const GOOGLE_API_KEY = ENV_VARS.googleApiKey;
    const GOOGLE_CLIENT_ID = ENV_VARS.googleClientID;
    let googleToken = '';
    let scriptLoadingStarted = false;

    const isGoogleReady = () => {
        return Boolean(window.google);
    };

    const isGoogleAuthReady = () => {
        return Boolean(window.google?.accounts);
    };

    const isGooglePickerReady = () => {
        return Boolean(window.google?.picker);
    };

    const showPicker = (oauthToken: string) => {
        const googleViewId = google.picker.ViewId['DOCS'];
        const view = new window.google.picker.View(googleViewId);

        if (!view) {
            throw new Error('Can\'t find view by viewId');
        }

        const picker = new window.google.picker.PickerBuilder()
            .addView(view)
            .setAppId(GOOGLE_APP_ID)
            .setOAuthToken(oauthToken)
            .setDeveloperKey(GOOGLE_API_KEY)
            .setCallback(onChange);

        picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);
        picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);

        picker.build()
            .setVisible(true);
    };

    const loadGoogleToken = () => {
        // Use this for temp access token, it lasts one hour
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'https://www.googleapis.com/auth/drive.file',
            callback: (response: { access_token: string; scope: string | string[]; }) => {
                if (response.access_token && response.scope.includes('drive.file')) {
                    googleToken = response.access_token;
                    onAuthenticate(response.access_token);
                    showPicker(response.access_token);
                } else {
                    onAuthFailed(response);
                }
            },
            prompt: '',
        });

        tokenClient.requestAccessToken({prompt: ''});
    };

    const onGoogleSdkLoad = () => {
        window.gapi.load('picker');
    };

    const onChoose = () => {
        if (!isGoogleReady() || !isGoogleAuthReady() || !isGooglePickerReady() || disabled) {
            return null;
        }

        if (googleToken) {
            showPicker(googleToken);
        } else {
            loadGoogleToken();
        }
    };

    useEffect(() => {
        if (!scriptLoadingStarted && (!isGoogleReady() || !isGoogleAuthReady() || !isGooglePickerReady())) {
            scriptLoadingStarted = true;
            loadScript(GOOGLE_ACCOUNT_SDK_URL);
            loadScript(GOOGLE_SDK_URL, onGoogleSdkLoad);
        }
    });

    return (
        <div onClick={onChoose}>
            {
                children
                    ? children
                    : <button>Open google chooser</button>
            }
        </div>
    );
};

export default GooglePicker;
