import {Props as PromptModalProps} from 'src/Components/ModalPrompt';

export enum GlobalActionTypes {
    PROMPT_MODAL_OPTIONS = 'PROMPT_MODAL_OPTIONS',
    SERVER_ERRORS = 'SERVER_ERRORS',
    CLEAR_SERVER_ERRORS = 'CLEAR_SERVER_ERRORS'
}

export interface ServerError {
    config?: any,
    error?: any,
    key?: string
}

interface PromptModalOption {
    promptModalOptions: PromptModalProps
}

export interface ServerErrors {
    type: GlobalActionTypes.SERVER_ERRORS
    payload: ServerError
}

export interface ClearServerErrors {
    type: GlobalActionTypes.CLEAR_SERVER_ERRORS
    payload: ServerError
}

export interface PromptModalOptions {
    type: GlobalActionTypes.PROMPT_MODAL_OPTIONS
    payload: PromptModalOption
}

export interface GlobalState {
    serverErrors: ServerError[]
    promptModalOptions: PromptModalProps
}

export type GlobalActions = ServerErrors | ClearServerErrors | PromptModalOptions;
