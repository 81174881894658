import {GlobalActionTypes, GlobalActions, ServerError} from './actionTypes';
import {Props as PromptModalProps} from 'src/Components/ModalPrompt';

export const serverErrors = ({config, error}: ServerError): GlobalActions => ({
    type: GlobalActionTypes.SERVER_ERRORS,
    payload: {
        config,
        error,
    },
});

export const clearServerErrors = (key?: string): GlobalActions => {
    return {
        type: GlobalActionTypes.CLEAR_SERVER_ERRORS,
        payload: {key},
    };
};

export const promptModalOptions = (promptModalOptions: PromptModalProps): GlobalActions => ({
    type: GlobalActionTypes.PROMPT_MODAL_OPTIONS,
    payload: {promptModalOptions},
});
