import {PlusCircleOutlined} from '@ant-design/icons';
import {useFormik} from 'formik';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {inviteUsers} from 'src/api/User/user-api';
import Button from 'src/Components/Button';
import {AuthRoutes} from 'src/constants/userRoles';
import {RootState} from 'src/redux/rootReducer';
import getValidationSchema from './getValidationSchema';
import {getStores} from 'src/redux/stores/actions';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import './styles.sass';
import Input from 'src/Components/Input';
import _ from 'lodash';
import Loading from 'src/Components/Loading';
import Select from 'src/Components/Select';
import {filterMap} from 'src/utils/general';

interface Props {
    redirectHandler: () => void
}
interface Invites {
    email: string
    role: string
}

const InviteUsersForm: React.FC<Props> = ({redirectHandler}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stores = useSelector((state: RootState) => state.stores);
    let currentStoreId = useSelector((state: RootState) => state.store.createdStore?.id);

    useDocumentTitle('Invite Users');

    useEffect(() => {
        dispatch(getStores());
        dispatch(getStoreStatus());
    }, [dispatch]);

    useEffect(() => {
        if (!stores.loading) {
            if (stores.stores?.length && !currentStoreId) {
                currentStoreId = stores.stores?.length ? stores.stores[0].id : undefined;
            }

            if (!currentStoreId) {
                navigate(AuthRoutes.onboardingCreateStore);
            }
        }
    }, [stores]);

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        values,
    } = useFormik({
        initialValues: {
            invites: [
                {email: '', role: {}},
                {email: '', role: {}},
                {email: '', role: {}},
            ],
        },
        validationSchema: getValidationSchema,
        onSubmit: async(values, actions) => {
            const requestPayload: Invites[] = filterMap(values.invites, ({email}: Record<'email', string>) => email, ({email, role}) => {
                return {
                    email,
                    role: role.id,
                };
            });
            if (requestPayload.length) {
                await inviteUsers(currentStoreId, {invites: requestPayload});
            }
            actions.setSubmitting(false);
            redirectHandler();
        },
        validateOnMount: true,
        isInitialValid: false,
    });

    return (
        <form className='form' onSubmit={handleSubmit} id='invite-users-form'>
            <div className='wrapper'>
                {values.invites && values.invites.map((invite, index: number) => (
                    <div className='row' key={index}>
                        <Input
                            errors={_.get(touched, ['invites', index, 'email']) && _.get(errors, ['invites', index, 'email'])}
                            name={`invites.${index}.email`}
                            // label={`Quick Link Name #${index + 1}`}
                            placeholder='name@example.com'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.invites[index].email} />
                        <Select
                            labelKey='label'
                            valueKey='id'
                            expectScroll={false}
                            placeholder='Role'
                            options={[
                                {
                                    label: 'Viewer',
                                    id: 'retail_read_only',
                                    // key: 'retail_read_only',
                                },
                                {
                                    label: 'Editor',
                                    id: 'retail_user',
                                    // key: 'retail_user',
                                },
                                {
                                    label: 'Owner',
                                    id: 'retail_owner',
                                    // key: 'retail_owner',
                                },
                            ]}
                            onChange={(UNUSED: any, value) => {
                                setFieldValue(`invites.${index}.role`, value);
                            }}
                            value={values.invites[index].role} />
                    </div>
                ))}
                <div className='addEmailWrapper'>
                    <div
                        className='addEmailLabel'
                        onClick={() => {
                            setFieldValue('invites', [...values.invites, {email: '', role: {}}]);
                        }}>
                        <div className='icon'>
                            <PlusCircleOutlined />
                        </div>
                        Add more
                    </div>
                </div>
            </div>
            <Button
                type='submit'
                className='btn-secondary submit-button'
                form='invite-users-form'
                disabled={isSubmitting || !_.isEmpty(errors)}>
                {isSubmitting && (
                    <Loading inline />
                )}
                Add teammates
            </Button>
        </form>
    );
};

export default InviteUsersForm;
