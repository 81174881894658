import React, {useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {matchPath, useLocation, useNavigate} from 'react-router-dom';
import SideBarMenu from 'src/Components/SideBarMenu';
import {AuthRoutes, NonAuthRoutes} from 'src/constants/userRoles';
import {RootState} from 'src/redux/rootReducer';
import './styles.sass';
import {ID, genericFn} from 'src/Types/CommonTypes';
import _ from 'lodash';
import {Store} from 'src/redux/stores/actionTypes';
import {Prompt, Props as PromptProps} from 'src/Components/ModalPrompt';
import useWindow from 'src/hooks/useWindow';
import useLogout from 'src/hooks/useLogout';
import Button from 'src/Components/Button';
import {classnames} from 'src/utils/general';
import {NewContentContext} from 'src/hooks/useNewContent';
import {logAssetsViewed} from 'src/api/Assets/api-asset';

interface Props {
    children: JSX.Element
    onClick?: genericFn
    showMenu?: boolean
    onHideMenu: genericFn
    onTogglePinSidebar: genericFn
    pinMenu: boolean
}


const UserPage: React.FC<Props> = ({
    children,
    onClick = _.noop,
    showMenu = false,
    pinMenu = false,
    onHideMenu = _.noop,
    onTogglePinSidebar = _.noop,
}) => {
    const navigate = useNavigate();
    const stores = useSelector((state: RootState) => state.stores.stores);
    const loading = useSelector((state: RootState) => state.stores.loading);
    const {pathname} = useLocation();
    const matchedPath = matchPath({path: '/store/:storeId/*'}, pathname);
    const storeId = _.get(matchedPath, 'params.storeId');
    const viewingAsset = matchPath({path: '/store/:storeId/asset-viewer/*'}, pathname);
    const selectedStore = Boolean(stores && stores.length) && (_.find(stores, ((currentStore: Store) => `${currentStore.id}` === storeId)) || stores[0]);
    const storeStatus = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const storeStatusById = stores && stores.length ? _.keyBy(storeStatus, 'id') : {};
    const auth = useSelector((state: RootState) => state.auth);
    const {modalSize} = useWindow();
    const {logoutUser} = useLogout();
    const {newContent, setNewContent} = useContext(NewContentContext);

    useEffect(() => {
        if (loading === false) {
            if (!storeId && stores && stores?.length) {
                navigate(`/store/${stores[0].id}/brands`);
            }

            if (stores && !stores.length) {
                if (auth.hasStores) {
                    //user is part of a store, but is restricted somehow (ie, the store's plan)
                    new Prompt().yesNoDanger({
                        Component: ({onNo = _.noop}: PromptProps) => (
                            <div className='user-restricted-modal'>
                                <div className='user-restricted-modal--body'>
                                    Your team does not have enough active licenses for you to log in. Please contact your store’s administrator to choose a plan with additional licenses.
                                </div>
                                <div className='modal-footer'>
                                    <Button
                                        className='btn-secondary'
                                        onClick={() => {
                                            logoutUser();
                                            onNo();
                                        }}>
                                        Return to Login
                                    </Button>
                                </div>
                            </div>
                        ),
                        hideXButton: true,
                        className: 'plan-modal',
                        width: modalSize < 1200 ? modalSize : 1400,
                    });
                    navigate(`${NonAuthRoutes.signin}?redirect=false`);
                } else {
                    navigate(AuthRoutes.onboardingCreateStore);
                }
            }
        }
    }, [stores, loading]);

    const subMenu = [
        {
            name: 'Brands',
            type: 'brands',
            redirectHandler: (id: ID) => {
                navigate(`/store/${id}/brands`);
            },
        },
        {
            name: 'Assets',
            type: 'assets',
            unmountHandler: (storeId: ID) => {
                if (newContent.length) {
                    setNewContent(_.filter(newContent, (asset) => Number(asset.storeId || asset.brand?.storeId) !== Number(storeId)));
                }
                logAssetsViewed(storeId);
            },
            redirectHandler: (id: ID) => {
                navigate(`/store/${id}/assets`);
            },
        },
        {
            name: 'Contacts',
            type: 'contacts',
            redirectHandler: (id: ID) => {
                navigate(`/store/${id}/contacts`);
            },
        },
        {
            name: 'Tasks',
            type: 'tasks',
            shouldHide: (storeId: ID) => storeId && _.get(storeStatusById, [storeId, 'hasTasks']),
            redirectHandler: (id: ID) => {
                navigate(`/store/${id}/tasks?taskStatus[]="incomplete"`);
                onClick();
            },
        },
        {
            name: 'My Store',
            type: 'my-store',
            redirectHandler: (id: ID) => {
                navigate(`/store/${id}/my-store`);
            },
        },
    ];


    return (
        <div
            className={classnames(
                'pageWrapper',
                {'no-topbar': Boolean(viewingAsset)}
            )}>
            <SideBarMenu
                store={selectedStore}
                storeId={storeId}
                subMenu={subMenu}
                showMenu={showMenu}
                isSidebarPinnedOpen={pinMenu}
                onTogglePinSidebar={onTogglePinSidebar}
                onHideMenu={onHideMenu} />
            {selectedStore && viewingAsset && (
                <>
                    {children}
                </>
            )}
            {selectedStore && !viewingAsset && (
                <div className='internalPage' id='internalPage'>
                    {children}
                </div>
            )}
            {!loading && !selectedStore && (
                'Not Found'
            )}
        </div>
    );
};

export default UserPage;
