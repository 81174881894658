import axios, {AxiosError, AxiosInstance} from 'axios';
import {logout} from 'src/redux/auth/actions';
import {clearStores} from 'src/redux/stores/actions';
import {clearStoreStatus} from 'src/redux/storeStatus/actions';
import {serverErrors} from 'src/redux/global/actions';
import store from '../redux/store';
import {clearToken} from 'src/redux/token/actions';
import {getAccessToken, getRefreshToken, getCognitoUsername, setAccessToken} from 'src/utils/token.utils';
import _ from 'lodash';
const baseUrl = MACRO_BASE_URL;

interface AxiosResponse {
    data: Record<any, any>,
    //HTTP status code
    status: number,
    //HTTP status message
    statusText: string,
    //HTTP headers that the server responded with
    headers: Record<any, any>,
    //config that was provided to `axios` for the request
    config: Record<any, any>,
    // `request` is the request that generated this response
    // It is the last ClientRequest instance in node.js (in redirects)
    // and an XMLHttpRequest instance in the browser
    request: Record<any, any>
}

interface GetRawProps {
    transformKeysToSnakeCase?: boolean
    useNoAuth?: boolean
}

//sometimes lodash's casing functions don't operate like you might expect. add exceptions here!
const camelCaseRejects: Record<string, string> = {
    'b2b_notes': 'b2bNotes',
    'b2b_url': 'b2bUrl',
};
const snakeCaseRejects: Record<string, string> = {
    'b2bNotes': 'b2b_notes',
    'b2bUrl': 'b2b_url',
    'cvv2': 'cvv2',
};

const handleCamelCase = (string: string) => {
    if (camelCaseRejects[string]) {
        return camelCaseRejects[string];
    }
    return _.camelCase(string);
};
const handleSnakeCase = (string: string) => {
    if (snakeCaseRejects[string]) {
        return snakeCaseRejects[string];
    }
    return _.snakeCase(string);
};

const transformKeysCase = (object: Record<string, any>, caseType = 'camel', reqOrRes: string) => {
    if (_.isArray(object)) {
        return object;
    }
    const casing = caseType === 'camel'
        ? handleCamelCase
        : handleSnakeCase;
    const newObj: Record<string, any> = {};
    for (let initialKey of Object.keys(object)) {
        const initialKeyCached = initialKey;
        if (reqOrRes === 'request' && (initialKey.includes('workspace') || initialKey.includes('Workspace'))) {
            initialKey = initialKey.replace('workspace', 'season').replace('Workspace', 'Season');
        }
        if (reqOrRes === 'response' && (initialKey.includes('season') || initialKey.includes('Season'))) {
            initialKey = initialKey.replace('season', 'workspace').replace('Season', 'Workspace');
        }
        newObj[casing(initialKey)] = object[initialKeyCached];
    }
    return newObj;
};

const transformKeysCaseRecursive = (data: any, caseType = 'camel', reqOrRes: string) => {
    if (_.isObject(data) && !(data instanceof FormData)) {
        data = transformKeysCase(data, caseType, reqOrRes);
        for (let rowKey of Object.keys(data)) {
            const rowKeyCached = rowKey;
            if (reqOrRes === 'request' && (rowKey.includes('workspace') || rowKey.includes('Workspace'))) {
                rowKey = rowKey.replace('workspace', 'season').replace('Workspace', 'Season');
            }
            if (reqOrRes === 'response' && (rowKey.includes('season') || rowKey.includes('Season'))) {
                rowKey = rowKey.replace('season', 'workspace').replace('Season', 'Workspace');
            }
            if (_.isObject(data[rowKeyCached])) {
                data[rowKey] = transformKeysCaseRecursive(data[rowKeyCached], caseType, reqOrRes);
            }
        }
    }
    return data;
};

class Api {
    baseUrl: string;
    instance: AxiosInstance;
    instanceNoAuth: AxiosInstance;
    rawInstance: AxiosInstance;
    rawInstanceNoAuth: AxiosInstance;
    constructor() {
        this.baseUrl = baseUrl;
        this.instance = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.instanceNoAuth = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.rawInstance = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.rawInstanceNoAuth = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.instance.interceptors.response.use(
            (response: AxiosResponse) => {
                return transformKeysCaseRecursive(response.data, 'camel', 'response');
            },
            (error: AxiosError) => {
                if (
                    //access_token expired
                    error.response?.status === 401
                    //refresh_token expired
                    || (error.response?.status === 500 && _.get(error, 'message') === 'Refresh Token has expired')
                ) {
                    store.dispatch(clearStores());
                    store.dispatch(clearStoreStatus());
                    store.dispatch(logout());
                    store.dispatch(clearToken());
                } else {
                    let errorMessage = '';
                    if (error.response) {
                        errorMessage = error?.response?.data?.errors
                            ? error.response.data.errors.map((error: Error) => error.message).join(' | ')
                            : error?.response?.data?.message || 'An unknown error occurred';
                    } else {
                        errorMessage = error.message;
                    }

                    //generate server-level error for the FE
                    store.dispatch(serverErrors({
                        config: error.config,
                        error: errorMessage,
                    }));

                    //throw field-level errors for the FE
                    throw {
                        errors: _.get(error, 'response.data.errors'),
                        combinedMessage: errorMessage,
                    };
                }
            }
        );

        this.instanceNoAuth.interceptors.response.use(
            (response: AxiosResponse) => {
                return transformKeysCaseRecursive(response.data, 'camel', 'response');
            },
            (error: AxiosError) => {
                //access_token expired
                if (error.response?.status !== 401) {
                    let errorMessage = '';
                    if (error.response) {
                        errorMessage = error?.response?.data?.errors
                            ? error.response.data.errors.map((error: Error) => error.message).join(' | ')
                            : error?.response?.data?.message || 'An unknown error occurred';
                    } else {
                        errorMessage = error.message;
                    }
                    store.dispatch(serverErrors({
                        config: error.config,
                        error: errorMessage,
                    }));
                    throw new Error(errorMessage);
                }
            }
        );

        this.rawInstance.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                if (
                    //access_token expired
                    error.response?.status === 401
                    //refresh_token expired
                    || (error.response?.status === 500 && _.get(error, 'message') === 'Refresh Token has expired')
                ) {
                    store.dispatch(clearStores());
                    store.dispatch(clearStoreStatus());
                    store.dispatch(logout());
                    store.dispatch(clearToken());
                } else {
                    let errorMessage = '';
                    if (error.response) {
                        errorMessage = error?.response?.data?.errors
                            ? error.response.data.errors.map((error: Error) => error.message).join(' | ')
                            : error?.response?.data?.message || 'An unknown error occurred';
                    } else {
                        errorMessage = error.message;
                    }
                    store.dispatch(serverErrors({
                        config: error.config,
                        error: errorMessage,
                    }));
                    throw new Error(errorMessage);
                }
            }
        );

        this.rawInstanceNoAuth.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                //access_token expired
                if (error.response?.status !== 401) {
                    let errorMessage = '';
                    if (error.response) {
                        errorMessage = error?.response?.data?.errors
                            ? error.response.data.errors.map((error: Error) => error.message).join(' | ')
                            : error?.response?.data?.message || 'An unknown error occurred';
                    } else {
                        errorMessage = error.message;
                    }
                    store.dispatch(serverErrors({
                        config: error.config,
                        error: errorMessage,
                    }));
                    throw new Error(errorMessage);
                }
            }
        );
    }

    //todo do values need to be URL encoded?
    async get<T>(url: string, params: any = {}, transformKeysToSnakeCase = true, useNoAuth = false, rawHeaders = {}) {
        const cleanedParams = transformKeysToSnakeCase
            ? transformKeysCase(params, 'snake', 'request')
            : params;
        const instance = useNoAuth ? this.instanceNoAuth : this.instance;
        const headers = _.isEmpty(rawHeaders)
            ? {
                Authorization: `Bearer ${getAccessToken()}`,
            }
            : Object.assign({Authorization: `Bearer ${getAccessToken()}`}, rawHeaders);

        return instance.get<T>(url, {
            headers,
            params: cleanedParams,
        });
    }

    async getRaw<T>(
        url: string,
        params: any = {},
        {
            transformKeysToSnakeCase = true,
            useNoAuth = false,
        }: GetRawProps = {}
    ) {
        const cleanedParams = transformKeysToSnakeCase
            ? transformKeysCase(params, 'snake', 'request')
            : params;
        const instance = useNoAuth ? this.rawInstanceNoAuth : this.rawInstance;
        return instance.get<T>(url, {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
            },
            responseType: 'blob',
            params: cleanedParams,
        });
    }

    async getWithAccessToken<T>(
        accessToken: string,
        url: string,
        params: any = {},
        {
            transformKeysToSnakeCase = true,
            useNoAuth = false,
        }: GetRawProps = {}
    ) {
        const cleanedParams = transformKeysToSnakeCase
            ? transformKeysCase(params, 'snake', 'request')
            : params;
        const instance = useNoAuth ? this.instanceNoAuth : this.instance;
        return instance.get<T>(url, {
            headers: {
                Authorization: `Bearer ${accessToken || getAccessToken()}`,
            },
            params: cleanedParams,
        });
    }

    async post(
        url: string,
        data: any,
        headers: any = {},
        params = {},
        transformKeysToSnakeCase = true
    ) {

        const cleanedData = transformKeysToSnakeCase
            ? transformKeysCaseRecursive(data, 'snake', 'request')
            : params;
        const cleanedParams = transformKeysToSnakeCase
            ? transformKeysCase(params, 'snake', 'request')
            : params;
        const accessToken = getAccessToken();
        return this.instance.post(url, cleanedData, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                ...headers,
            },
            params: cleanedParams,
        });
    }

    async postWithAccessToken(
        accessToken: string,
        url: string,
        data: any,
        headers: any = {},
        params = {},
        transformKeysToSnakeCase = true
    ) {

        const cleanedData = transformKeysToSnakeCase
            ? transformKeysCaseRecursive(data, 'snake', 'request')
            : params;
        const cleanedParams = transformKeysToSnakeCase
            ? transformKeysCase(params, 'snake', 'request')
            : params;
        return this.instance.post(url, cleanedData, {
            headers: {
                Authorization: `Bearer ${accessToken || getAccessToken()}`,
                ...headers,
            },
            params: cleanedParams,
        });
    }

    async put(url: string, data: any, headers: any = {}, params = {}, transformKeysToSnakeCase = true) {

        const cleanedData = transformKeysToSnakeCase
            ? transformKeysCaseRecursive(data, 'snake', 'request')
            : params;

        const cleanedParams = transformKeysToSnakeCase
            ? transformKeysCase(params, 'snake', 'request')
            : params;

        return this.instance.put(url, cleanedData, {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
                ...headers,
            },
            params: cleanedParams,
        });
    }

    async delete(url: string, params = {}, headers: any = {}, transformKeysToSnakeCase = true) {
        const cleanedParams = transformKeysToSnakeCase
            ? transformKeysCase(params, 'snake', 'request')
            : params;
        return this.instance.delete(url, {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`,
                ...headers,
            },
            params: cleanedParams,
        });
    }

    async forceTokenRefresh() {
        const refreshToken = getRefreshToken();
        const username = getCognitoUsername();
        if (refreshToken && username) {
            const refreshResponse = await this.post('/auth/refresh', {
                refreshToken,
                username,
            });
            if (refreshResponse && refreshResponse.accessToken) {
                setAccessToken(refreshResponse.accessToken);
            }
        }
    }
}

export default new Api();
