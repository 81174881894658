import {classnames} from 'src/utils/general';
import './styles.sass';
import React, {useState} from 'react';
import {Popover} from 'antd';


interface Props {
    className?: string
    popClassName?: string
    content: string | JSX.Element
    useHover?: boolean
    origin: string | JSX.Element
    placement?: 'topLeft' | 'top' | 'topRight' | 'bottomLeft' | 'bottom' | 'bottomRight' | 'rightTop' | 'right' | 'rightBottom' | 'leftTop' | 'left' | 'leftBottom'
    type?: 'info' | 'tip' | 'base'
}

const PopIcon: React.FC<Props> = ({
    className = '',
    popClassName = '',
    content,
    origin,
    type = 'base',
    useHover = false,
    placement = 'rightTop',
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const computedClassName = classnames(
        'PopIcon-container',
        {
            [`PopIcon-container--${type}`]: type,
        },
        {
            [className]: className,
        }
    );
    const computedPopClassName = classnames(
        'PopIcon-pop',
        {
            [`PopIcon-pop--${type}`]: type,
        },
        {
            [popClassName]: popClassName,
        }
    );

    return (
        <div className={computedClassName}>
            <Popover
                arrowPointAtCenter={type !== 'tip'}
                overlayClassName={computedPopClassName}
                content={content}
                trigger={useHover ? 'hover' : 'click'}
                placement={placement}
                open={isVisible}
                onOpenChange={setIsVisible}>
                {origin}
            </Popover>
        </div>
    );
};

export default PopIcon;
