//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';

//specific
import {Task} from 'src/api/Tasks/api-tasks';
import {filterMap} from 'src/utils/general';

interface DataBuilder {
    allTasks: Task[]
}

enum Priority {
    high = 'High',
    medium = 'Medium',
    low = 'Low',
    none = 'None',
}

interface PriorityOption {
    id: keyof typeof Priority
    title: Priority
}

const defaultOptions: PriorityOption[] = [
    {id: 'high', title: Priority.high},
    {id: 'medium', title: Priority.medium},
    {id: 'low', title: Priority.low},
    {id: 'none', title: Priority.none},
];

const defaultOptionsById = _.keyBy(defaultOptions, 'id');

export default buildFilter({
    key: 'taskPriority',
    name: 'Priority',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters) => appliedFilters,
    dataBuilder: (): PriorityOption[] => defaultOptions,
    detailRenderer: (taskPriority: PriorityOption) => taskPriority.title,
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {taskPriority}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Priority'
                labelKey='title'
                labelTextRenderer={(taskPriority) => taskPriority.title}
                onChange={handleOnChangeFactory('taskPriority', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('taskPriority', onChange, values)}
                options={taskPriority}
                value={(values && values.taskPriority) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (taskPriority: PriorityOption[], searchObject, key: string) => {
        searchObject[key] = _.map(taskPriority, 'id');
        return searchObject;
    },
    buildValuesFromQueryString: (UNUSED: any, {taskPriority}) => {
        return taskPriority ? filterMap(taskPriority, (priority?: string) => priority && defaultOptionsById[priority]) : [];
    },
    enabled: ({allTasks}: DataBuilder) => Boolean(allTasks && allTasks.length),
    show: ({allTasks}: DataBuilder) => Boolean(allTasks && allTasks.length),
});
