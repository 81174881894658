import React, {useEffect, useState, useCallback, useRef, useContext} from 'react';
import {useParams} from 'react-router-dom';
import './styles.sass';

//icons
import {MinusCircleOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import tipIcon from 'src/assets/icons/tip-hex.svg';

//components
import {Checkbox, Divider, Space, Typography} from 'antd';
import {arrayMoveImmutable as move} from 'array-move';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Button from 'src/Components/Button';
import Callout from 'src/Components/Callout';
import Modal from 'src/Components/Modal';
import PopIcon from 'src/Components/PopIcon';
import Select from 'src/Components/Select';

//apis
import {
    createWorkspace,
    deleteWorkspace,
    getWorkspaces,
    reorderWorkspaces,
    updateWorkspace,
    getAllWorkspaces,
    ApiWorkspace,
    Workspace,
} from 'src/api/Workspace/api-workspace';
import {removeWorkspaceAssetAssociations} from 'src/api/Assets/api-asset';
import infoIcon from 'src/assets/icons/info.svg';
import _ from 'lodash';

//types
import {ID} from 'src/Types/CommonTypes';

//libs
import {nanoid} from 'nanoid';
import {NewContentContext} from 'src/hooks/useNewContent';
import {classnames} from 'src/utils/general';

interface Props {
    WorkspaceChild: React.Component
    childType: 'assets'
    brandCardId: ID
    readOnly?: boolean
}

const nameByType = {
    'assets': 'Asset',
};

const DragHandle = SortableHandle(() => (
    <div className='dragButton'>
        <div className='dragLine'></div>
        <div className='dragLine'></div>
        <div className='dragLine'></div>
        <div className='dragLine'></div>
    </div>
));

const SortableItem = SortableElement(({
    allWorkspaces,
    childType,
    workspace,
    itemIndex,
    onRemoveWorkspace,
    onSetWorkspace,
    onWorkspaceSearchTextChange,
    onKeyDownWorkspace,
    onAddWorkspace,
    newWorkspace,
    workspaceIdsForDelete,
    workspaceIdsForChildDelete,
    setWorkspaceIdsForChildDelete,
    hasAnyWorkspaces,
}) => {
    const hasAssets = Boolean(workspace && workspace.assets && workspace.assets.length);
    const markedForDeletion = workspaceIdsForDelete.includes(workspace.id);
    return (
        <div className='workspaceItem'>
            <DragHandle />
            <Select
                notFoundContent={hasAnyWorkspaces || newWorkspace.length > 0
                    ? (<div/>)
                    : (<div>No Workspaces added yet</div>)}
                className='workspace-select'
                disabled={markedForDeletion}
                labelKey='title'
                onChange={onSetWorkspace(itemIndex)}
                onSearch={onWorkspaceSearchTextChange}
                onKeyDown={onKeyDownWorkspace(itemIndex)}
                options={allWorkspaces}
                valueKey='title'
                dropdownRender={(menu) => (
                    <>
                        {newWorkspace.length > 0 && (
                            <Space align='center' style={{padding: '0 8px 4px'}}>
                                <Typography.Link onClick={onAddWorkspace(itemIndex)} style={{whiteSpace: 'nowrap'}}>
                                    <PlusOutlined /> Add new <b>{newWorkspace}</b>
                                </Typography.Link>
                            </Space>
                        )}
                        {menu}
                        <Divider style={{margin: '8px 0'}} />
                    </>
                )}
                allowClear={false}
                value={workspace} />
            <MinusCircleOutlined
                className='icon'
                onClick={onRemoveWorkspace(itemIndex, hasAssets)}
            />
            {hasAssets && markedForDeletion && (
                <Callout type='danger'>
                    <Checkbox
                        value='delete'
                        className='single-line-ellipsis'
                        onChange={() => setWorkspaceIdsForChildDelete((workspaceIdsForChildDelete) => {
                            const newIds = _.cloneDeep(workspaceIdsForChildDelete);
                            if (workspaceIdsForChildDelete.includes(workspace.id)) {
                                const indexToRemove = newIds.indexOf(workspace.id);
                                newIds.splice(indexToRemove, 1);
                            } else {
                                newIds.push(workspace.id);
                            }
                            return newIds;
                        })}
                        checked={workspaceIdsForChildDelete.includes(workspace.id)}>
                        {`Delete associated ${childType}.`}
                    </Checkbox>
                    <PopIcon
                        type='info'
                        placement='bottomRight'
                        content={
                            <>
                                <div>
                                    {`By default, BrandKeep will move ${childType} from a deleted workspace to the ‘All’ tab so they are still accessible.
                                Checking the box to ‘Delete associated ${childType}’ will delete them along with the workspace and they will no longer be available.`}
                                </div>
                            </>
                        }
                        origin={<div className='btn btn-circle btn-white btn-icon-small'><img className='svg-icon' src={infoIcon} /></div>} />
                </Callout>
            )}
        </div>
    );
});

const SortableList = SortableContainer(({
    allWorkspaces,
    workspaces,
    onSetWorkspace,
    onWorkspaceSearchTextChange,
    onKeyDownWorkspace,
    onRemoveWorkspace,
    onAddWorkspace,
    newWorkspace,
    workspaceIdsForDelete,
    workspaceIdsForChildDelete,
    setWorkspaceIdsForChildDelete,
    childType,
    hasAnyWorkspaces,
}) => (
    <div className='workspaceNames'>
        {Object.values(workspaces).map((workspace, index) => (
            <SortableItem
                key={`item-${workspace.id}`}
                itemIndex={index}
                index={index}
                childType={childType}
                allWorkspaces={allWorkspaces}
                workspace={workspace}
                onSetWorkspace={onSetWorkspace}
                onWorkspaceSearchTextChange={onWorkspaceSearchTextChange}
                onKeyDownWorkspace={onKeyDownWorkspace}
                newWorkspace={newWorkspace}
                onAddWorkspace={onAddWorkspace}
                onRemoveWorkspace={onRemoveWorkspace}
                workspaceIdsForDelete={workspaceIdsForDelete}
                workspaceIdsForChildDelete={workspaceIdsForChildDelete}
                setWorkspaceIdsForChildDelete={setWorkspaceIdsForChildDelete}
                hasAnyWorkspaces={hasAnyWorkspaces} />
        ))}
    </div>
));


const WorkspaceSidebar: React.FC<Props> = ({brandCardId, readOnly, WorkspaceChild = <span />, childType = 'assets'}) => {
    const {storeId} = useParams<{storeId: string}>();
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<ID>('ALL');
    const [workspaces, setWorkspaces] = useState<ApiWorkspace[]>();
    const [allWorkspaces, setAllWorkspaces] = useState<ApiWorkspace[]>();
    const [editWorkspaceModal, setEditWorkspaceModalOpen] = useState(false);
    const [workspaceIdsForChildDelete, setWorkspaceIdsForChildDelete] = useState<ID[]>([]);
    const [workspaceIdsForDelete, setWorkspaceIdsForDelete] = useState<ID[]>([]);
    const [workspaceValues, setWorkspaceValues] = useState<ApiWorkspace[]>([{title: '', id: nanoid(), isCreated: false}]);
    const [newWorkspace, setNewWorkspace] = useState<string>('');
    const [usedTitles, setUsedTitles] = useState<Set<string>>(new Set());
    const [workspacesUpdated, setWorkspacesUpdated] = useState(false);
    const {newContent} = useContext(NewContentContext);

    const callId = useRef<string | null>(null);

    const handleGetWorkspaces = useCallback(async(currentCallId) => {
        if (callId.current === currentCallId) {
            const workspaces = await getWorkspaces(brandCardId);
            setWorkspaces(workspaces);
            const newValues = _.cloneDeep(workspaces);
            if (newValues.length === 0) {
                newValues.push({title: '', id: nanoid(), isCreated: false});
            }
            setWorkspaceValues(newValues);
            setUsedTitles(new Set(_.map(workspaces, 'title')));
        }
    }, [brandCardId]);

    const saveWorkspaces = async() => {
        const orderOfItems: Record<string, any> = {};
        const workspacesToCreate: Workspace[] = [];
        const idsToDelete = new Set([...workspaceIdsForDelete, ...workspaceIdsForChildDelete]);
        const idsOfExistingWorkspaces = [];
        const existingWorkspaces: ApiWorkspace[] = [];
        const workspacesToUpdate: ApiWorkspace[] = [];

        workspaceValues.forEach((workspace, index) => {
            if (workspace.createdAt) {
                orderOfItems[workspace.id] = {oldId: workspace.id, newIndex: index};
                idsOfExistingWorkspaces.push(workspace.id);
                existingWorkspaces.push({...workspace, newIndex: index});
            } else if (workspace.title && workspace.title.length) {
                workspacesToCreate.push({...workspace, oldId: workspace.id, newIndex: index});
            }
        });

        (workspaces || []).forEach((workspace) => {
            const currentWorkspace = existingWorkspaces.find((existingWorkspace) => existingWorkspace.id === workspace.id);
            if (!currentWorkspace) {
                idsToDelete.add(workspace.id);
                delete orderOfItems[workspace.id];
            } else if (workspace.title !== currentWorkspace.title) {
                workspacesToUpdate.push(currentWorkspace);
            }
        });
        const idsForWorkspaceChildDisassociation = [];
        for (const idToDelete of Array.from(idsToDelete)) {
            if (!workspaceIdsForChildDelete.includes(idToDelete)) {
                //workspace for deleting was NOT marked to delete its child-items! disassociate them before they are deleted
                idsForWorkspaceChildDisassociation.push(idToDelete);
            }
        }
        if (idsForWorkspaceChildDisassociation.length) {
            if (childType === 'assets') {
                await removeWorkspaceAssetAssociations(brandCardId, idsForWorkspaceChildDisassociation);
            }
        }
        for (const idToDelete of Array.from(idsToDelete)) {
            await deleteWorkspace(idToDelete);
        }
        if (idsToDelete.has(selectedWorkspaceId)) {
            setSelectedWorkspaceId('ALL');
        }

        for (const workspaceToCreate of workspacesToCreate) {
            const createdWorkspace = await createWorkspace(brandCardId, {title: workspaceToCreate.title});
            orderOfItems[workspaceToCreate.oldId] = {...createdWorkspace, newIndex: workspaceToCreate.newIndex};
        }

        for (const workspaceToUpdate of workspacesToUpdate) {
            const updatedWorkspace = await updateWorkspace(workspaceToUpdate.id, {title: workspaceToUpdate.title});
            orderOfItems[workspaceToUpdate.id] = {...updatedWorkspace, id: updatedWorkspace.id, newIndex: workspaceToUpdate.newIndex};
        }

        const sortedWorkspaces = Object.values(orderOfItems).sort((a, b) => {
            return a.newIndex - b.newIndex;
        });

        const sortedIdsOfWorkspaces = sortedWorkspaces.map((workspace) => workspace.oldId || workspace.id);

        const newWorkspaces = await reorderWorkspaces(brandCardId, sortedIdsOfWorkspaces);
        setWorkspaces(newWorkspaces);
        setWorkspacesUpdated(true);
    };


    const handleGetAllWorkspaces = async() => {
        const allWorkspaces = await getAllWorkspaces(storeId);
        setAllWorkspaces(_(allWorkspaces)
            .uniqBy('title')
            .sortBy('title')
            .value());
    };

    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;

        handleGetWorkspaces(currentCallId);
    }, [brandCardId, editWorkspaceModal, handleGetWorkspaces]);

    useEffect(() => {
        handleGetAllWorkspaces();
    }, [editWorkspaceModal]);

    const refreshWorkspaces = () => {
        const currentCallId = nanoid();
        callId.current = currentCallId;

        handleGetWorkspaces(currentCallId);
    };

    const handleDismissModal = () => {
        setEditWorkspaceModalOpen(false);
        setWorkspaceIdsForDelete([]);
        setWorkspaceIdsForChildDelete([]);
        setNewWorkspace('');
    };

    const handleSetWorkspace = (index) => {
        return (title) => {
            const newValues = _.cloneDeep(workspaceValues);
            newValues[index] = {title, id: nanoid(), isCreated: false};
            setWorkspaceValues(newValues);
            setUsedTitles(new Set(_.map(newValues, 'title')));
        };
    };

    const handleWorkspaceSearchTextChange = (searchText: string) => {
        setNewWorkspace(searchText);
    };

    const handleOnKeyDownWorkspace = (index) => {
        return (e: any) => {
            if (e.key === 'Enter') {
                const values = _.filter(workspaceValues, (workspace) => {
                    return workspace.title.toLowerCase().includes(_.toLower(newWorkspace));
                });
                if (values.length === 0) {
                    const newValues = _.cloneDeep(workspaceValues);
                    newValues[index] = {title: newWorkspace, id: nanoid(), isCreated: false};
                    setWorkspaceValues(newValues);
                    setUsedTitles(new Set(_.map(newValues, 'title')));
                    setNewWorkspace('');
                }
            }
        };
    };

    //adds workspace to brandcard
    const handleAddWorkspaceRow = () => {
        const newValues = _.cloneDeep(workspaceValues);
        newValues.push({title: '', id: nanoid(), isCreated: false});
        setWorkspaceValues(newValues);
    };

    const handleRemoveWorkspaceRow = (index, hasChildrenItems) => {
        return () => {
            if (hasChildrenItems) {
                const newIds = _.cloneDeep(workspaceIdsForDelete);
                newIds.push(workspaceValues[index].id);
                setWorkspaceIdsForDelete(newIds);
            } else {
                const newValues = _.cloneDeep(workspaceValues);
                newValues.splice(index, 1);
                setWorkspaceValues(newValues);
                setUsedTitles(new Set(_.map(newValues, 'title')));
            }
        };
    };

    //adds workspace to store
    const handleAddWorkspace = (index) => {
        return (event: ReactAnchorEvent) => {
            event.preventDefault();
            const newValues = _.cloneDeep(workspaceValues);
            newValues[index] = {title: newWorkspace, id: nanoid(), isCreated: false};
            setWorkspaceValues(newValues);
            setUsedTitles(new Set(_.map(newValues, 'title')));
            setNewWorkspace('');
        };
    };

    const handleSubmit = () => {
        saveWorkspaces(workspaceValues);
        setEditWorkspaceModalOpen(false);
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newValues = move(_.cloneDeep(workspaceValues), oldIndex, newIndex);
        setWorkspaceValues(newValues);
    };

    return (workspaces && (
        <span className='workspaces-sidebar'>
            {editWorkspaceModal && (
                <Modal
                    className='workspaces-sidebar-modal'
                    centered
                    title={<span>
                        Add &amp; Edit Workspaces
                        <PopIcon
                            className='btn-tip'
                            type='tip'
                            content='Workspaces create a method for you to organize your assets by season, collection, or any other use your team can think of. Once created, a workspace will be selectable in your other brand cards to ensure consistency.'
                            origin={<img src={tipIcon} />} />
                    </span>
                    }
                    open={editWorkspaceModal}
                    onCancel={handleDismissModal}
                    footer={false}>
                    <SortableList
                        allWorkspaces={_.filter(allWorkspaces, (workspace) => !usedTitles.has(workspace.title))}
                        hasAnyWorkspaces={_.filter(workspaceValues, (workspace) => (workspace.title && workspace.title.length > 0)).length}
                        workspaces={workspaceValues}
                        onSetWorkspace={handleSetWorkspace}
                        onWorkspaceSearchTextChange={handleWorkspaceSearchTextChange}
                        onKeyDownWorkspace={handleOnKeyDownWorkspace}
                        onRemoveWorkspace={handleRemoveWorkspaceRow}
                        onAddWorkspace={handleAddWorkspace}
                        newWorkspace={newWorkspace}
                        workspaceIdsForDelete={workspaceIdsForDelete}
                        workspaceIdsForChildDelete={workspaceIdsForChildDelete}
                        setWorkspaceIdsForChildDelete={setWorkspaceIdsForChildDelete}
                        onSortEnd={onSortEnd}
                        helperClass='sortableHelper'
                        childType={childType}
                        useDragHandle />
                    <div
                        className='add-row-button'
                        onClick={handleAddWorkspaceRow}>
                        <div className='spar' />
                        <div className='add-row-button-text'>Add More <PlusCircleOutlined /></div>
                        <div className='spar' />
                    </div>

                    <div className='modal-footer'>
                        <div className='cancel-button' onClick={handleDismissModal}>
                            Cancel
                        </div>
                        <Button className='submitButton' type='secondary' onClick={handleSubmit}>
                            Update
                        </Button>
                    </div>
                </Modal>
            )}
            <div className='workspacesBlock'>
                <div className='menu'>
                    <Button
                        disabled={readOnly}
                        className='add-workspace-button btn-action'
                        onClick={() => setEditWorkspaceModalOpen(true)}>
                        <PlusOutlined className='icon left-icon' />Add & Edit Workspaces
                    </Button>
                    <div className='workspace-options'>
                        <div className={selectedWorkspaceId === 'ALL' ? 'workspaceButton workspaceButton__active' : 'workspaceButton'} key='ALL' onClick={() => {
                            setSelectedWorkspaceId('ALL');
                        }}>
                            <Typography.Text>{`All ${nameByType[childType]}s`}</Typography.Text>
                        </div>

                        {workspaces.map((workspace) => {
                            const classNames = classnames(
                                'workspaceButton single-line-ellipsis',
                                {'workspaceButton__active': workspace.id === selectedWorkspaceId},
                                {'new-content-bubble': _.some(newContent, (asset) => asset.workspaceId === workspace.id)}
                            );

                            return (
                                <div className={classNames} key={workspace.id} onClick={() => {
                                    setSelectedWorkspaceId(workspace.id);
                                }}>
                                    <Typography.Text>{workspace.title}</Typography.Text>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='body'>
                    {selectedWorkspaceId && (
                        <WorkspaceChild brandCardId={brandCardId} workspaceId={selectedWorkspaceId} reloadWorkspaces={refreshWorkspaces} readOnly={readOnly} workspacesUpdated={workspacesUpdated} />
                    )}
                </div>
            </div>
        </span>
    )) || null;
};

export default WorkspaceSidebar;
