import apiHelper from '../api-helper';
import {ID} from 'src/Types/CommonTypes';

export interface ApiLink {
    id: ID
    url: string
    name: string
    refType: string
    scope: string
    scopeId: ID
}

export interface Link {
    id?: ID
    url?: string
    name?: string
    refType?: string
    scope?: string
    scopeId?: ID
}

export enum LinkRefTypes {
    QuickLink = 'brand_card_quick_link',
    MarketingLink = 'brand_card_marketing_link',
}

export const createLink = (storeId: ID, link: Link): Promise<ApiLink> => {
    return apiHelper.post('/links', link);
};

export const getLinks = (refType: string, scope: string, scopeId?: ID): Promise<ApiLink[]> => {
    return apiHelper.get('/links', {refType, scope, scopeId});
};

export const updateLink = (linkId: ID, link: Link): Promise<ApiLink> => {
    return apiHelper.put(`/links/${linkId}`, link);
};

export const deleteLink = (linkId: ID): Promise<void> => {
    return apiHelper.delete(`/links/${linkId}`);
};
