import {PlanGroup} from '../constants/subscriptionPlans';
import {StoreStatus} from '../redux/storeStatus/actionTypes';
import dayjs from 'dayjs';

export const proratePlanCost = (currentPlan: StoreStatus, newPlan: PlanGroup): number => {
    if (
        currentPlan
        && currentPlan.planStart
        && currentPlan.planEnd
    ) {
        const daysInLastPeriod = dayjs(currentPlan.planEnd).diff(currentPlan.planStart, 'days');
        const daysLeft = dayjs(currentPlan.planEnd).diff(new Date(), 'days');
        if (daysInLastPeriod && daysLeft) {
            return Math.floor(((newPlan.cost - currentPlan.planCharge) / daysInLastPeriod) * daysLeft);
        }

    }
    return newPlan.cost;
};
