import {ID} from 'src/Types/CommonTypes';

export interface StoreRoleDTO {
    createdAt: string
    id: ID
    role: string
    storeId: ID
    updatedAt: string
    userId: ID
}

export interface StoreRole {
    createdAt: string
    id: ID
    role: string
    storeId: ID
    updatedAt: string
    userId: ID
    roleId?: ID
}

export interface RolesTypes {
    retail_read_only: string
    retail_user: string
    retail_owner: string
}

//note: example of a enum not using TS enum
export const Roles: RolesTypes = {
    retail_read_only: 'Viewer',
    retail_user: 'Editor',
    retail_owner: 'Owner',
};

export enum RolesEnum {
    retail_read_only = 'retail_read_only',
    retail_user = 'retail_user',
    retail_owner = 'retail_owner',
}
