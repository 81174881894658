//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    validateFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';
import {nameFormatter} from 'src/lib/name-helper';

//specific
import {
    Task,
} from 'src/api/Tasks/api-tasks';
import {User} from 'src/Types/User/types';

interface DataBuilder {
    allTasks: Task[]
    authUser: User
}

const buildPrivateNames = (authUser: User) => {
    const userNameObj = {
        firstName: undefined,
        lastName: undefined,
        email: authUser.email,
    };
    if (authUser) {
        if (authUser.name) {
            userNameObj.firstName = authUser.name;
        }
        if (authUser.familyName) {
            userNameObj.lastName = `${authUser.familyName} (private)`;
        } else if (userNameObj.firstName) {
            userNameObj.firstName = `${userNameObj.firstName} (private)`;
        } else {
            userNameObj.email = `${userNameObj.email} (private)`;
        }
    }
    return userNameObj;
};

export default buildFilter({
    key: 'taskAssignee',
    name: 'Assignee',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters, {taskAssignee: taskAssignees}: Record<'taskAssignee', Task[]>) => validateFilter(appliedFilters, taskAssignees),
    dataBuilder: ({allTasks, authUser}: DataBuilder) => {
        const assignees = _(allTasks)
            .uniqBy('assignedTo')
            .map((task) => {
                if (task.assignee) {
                    task.assignee.key = task.assignee.id;
                    return task.assignee;
                }
                return null;
            })
            .filter((assignee) => assignee && assignee.id !== authUser.id)
            .compact()
            .value();

        return [
            {id: 0, firstName: '(No Assignee)'},
            {
                ...buildPrivateNames(authUser),
                id: `${authUser.id}-PRIVATE`,
            },
            {
                firstName: authUser && authUser.name,
                lastName: authUser && authUser.familyName,
                email: authUser && authUser.email,
                id: authUser.id,
            },
            ..._.sortBy(assignees, (assignee) => assignee && _.toLower(nameFormatter(assignee))),
        ];
    },
    detailRenderer: (assignee: User) => nameFormatter(assignee),
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {taskAssignee}: Record<string, any>
    ) => {

        return (
            <FilterMultiSelect
                compactLabel='Assignees'
                disabled={!(taskAssignee && taskAssignee.length)}
                labelKey='id'
                labelTextRenderer={nameFormatter}
                onChange={handleOnChangeFactory('taskAssignee', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('taskAssignee', onChange, values)}
                options={taskAssignee}
                placeholder='Type to Search Assignees'
                value={(values && values.taskAssignee) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (taskAssignee: any[], searchObject, key: string) => {
        searchObject[key] = _.map(taskAssignee, 'id');
        return searchObject;
    },
    buildValuesFromQueryString: ({allTasks, authUser}, {taskAssignee: assigneeIds}) => {
        const valueAssigneeIdSet = assigneeIds ? new Set(assigneeIds) : new Set();

        const validAssigneesCustom = valueAssigneeIdSet.has(0) ? [{id: 0, firstName: '(No Assignee)'}] : [];

        if (_.some(assigneeIds, (assigneeId) => assigneeId === `${authUser.id}-PRIVATE`)) {
            validAssigneesCustom.push({
                ...buildPrivateNames(authUser),
                isPrivate: true,
                id: `${authUser.id}-PRIVATE`,
            });
        }

        const validAssignees = _(allTasks)
            .filter((task) => valueAssigneeIdSet.has(
                _.get(task, 'assignedTo')
            ))
            .uniqBy('assignedTo')
            .map('assignee')
            .value();

        return [...validAssigneesCustom, ...validAssignees];
    },
    enabled: ({allTasks}: DataBuilder) => _.some(allTasks, 'assignedTo'),
    show: ({allTasks}: DataBuilder) => _.some(allTasks, 'assignedTo'),
});
