import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
    createBrandCard,
    getBrandCardById,
    getBrandCards,
    ParamsForBrandCard,
    updateBrandCard,
} from '../../api/BrandCard/api-brand-card';
import {
    createBrandCardSuccess,
    getBrandsFailure,
    getBrandsSuccess,
    getBrandSuccess,
    updateBrandCardSuccess,
} from './actions';
import {Brand, BrandsActionTypes, ICreateBrandCard, IGetBrand, IGetBrands, IUpdateBrandCard} from './actionTypes';
import _ from 'lodash';

function* getBandsSaga(action: IGetBrands) {
    try {
        const brands: Array<Brand> = yield call(getBrandCards, action.payload.storeId, {
            [ParamsForBrandCard.Assets]: true,
            [ParamsForBrandCard.Contacts]: true,
            [ParamsForBrandCard.Workspace]: true,
        });
        yield put(getBrandsSuccess(brands));
    } catch (error) {
        yield put(getBrandsFailure('Something went wrong. Please Try again'));
    }
}

function* watchGetBrands() {
    yield takeEvery(BrandsActionTypes.GET_BRANDS, getBandsSaga);
}

function* createBrandCardSaga(action: ICreateBrandCard) {
    try {
        const savedBrandCard: Brand = yield call(createBrandCard, action.payload.storeId, action.payload.brandCard);
        if (_.get(savedBrandCard, 'id')) {
            const brand: Brand = yield call(getBrandCardById, savedBrandCard.id);
            yield put(createBrandCardSuccess(brand));
        }
    } catch (error) {
        yield put(getBrandsFailure('Something went wrong. Please Try again'));
    }
}

function* watchCreateBrandCard() {
    yield takeEvery(BrandsActionTypes.CRATE_BRAND_CARD, createBrandCardSaga);
}

function* updateBrandCardSaga(action: IUpdateBrandCard) {
    try {
        yield call(updateBrandCard, action.payload.brandId, action.payload.brandCard);
        const savedBrandCard: Brand = yield call(getBrandCardById, action.payload.brandId);
        yield put(updateBrandCardSuccess(savedBrandCard));
    } catch (error) {
        yield put(getBrandsFailure('Something went wrong. Please Try again'));
    }
}

function* watchUpdateBrandCard() {
    yield takeEvery(BrandsActionTypes.UPDATE_BRAND_CARD, updateBrandCardSaga);
}

function* getBrandCardSaga(action: IGetBrand) {
    try {
        const brandCard: Brand = yield call(getBrandCardById, action.payload.brandId);
        yield put(getBrandSuccess(brandCard));
    } catch (error) {
        yield put(getBrandsFailure('Something went wrong. Please Try again'));
    }
}

function* watchGetBrandCard() {
    yield takeEvery(BrandsActionTypes.GET_BRAND, getBrandCardSaga);
}

export default function* brandsSagas() {
    yield all([watchGetBrands(), watchCreateBrandCard(), watchGetBrandCard(), watchUpdateBrandCard()]);
}
