import {BrandCard} from '../../api/BrandCard/api-brand-card';
import {Brand, BrandsActions, BrandsActionTypes} from './actionTypes';
import {ID} from 'src/Types/CommonTypes';

export const getBrands = (storeId: ID): BrandsActions => ({
    type: BrandsActionTypes.GET_BRANDS,
    payload: {storeId},
});

export const getBrandsSuccess = (brands: Array<Brand>): BrandsActions => ({
    type: BrandsActionTypes.GET_BRANDS_SUCCESS,
    payload: {
        brands,
    },
});

export const getBrandsFailure = (error: string): BrandsActions => ({
    type: BrandsActionTypes.GET_BRANDS_FAILURE,
    payload: {
        error,
    },
});

export const getBrand = (brandId: ID): BrandsActions => ({
    type: BrandsActionTypes.GET_BRAND,
    payload: {brandId},
});

export const getBrandSuccess = (brand: Brand): BrandsActions => ({
    type: BrandsActionTypes.GET_BRAND_SUCCESS,
    payload: {
        brand,
    },
});

export const getBrandFailure = (error: string): BrandsActions => ({
    type: BrandsActionTypes.GET_BRAND_FAILURE,
    payload: {
        error,
    },
});

export const createBrandCard = (storeId: ID, brandCard: BrandCard): BrandsActions => ({
    type: BrandsActionTypes.CRATE_BRAND_CARD,
    payload: {storeId, brandCard},
});

export const createBrandCardSuccess = (brandCard: Brand): BrandsActions => ({
    type: BrandsActionTypes.CRATE_BRAND_CARD_SUCCESS,
    payload: {
        brandCard,
    },
});

export const createBrandCardFailure = (error: string): BrandsActions => ({
    type: BrandsActionTypes.CRATE_BRAND_CARD_FAILURE,
    payload: {
        error,
    },
});

export const updateBrandCard = (brandId: ID, brandCard: BrandCard): BrandsActions => ({
    type: BrandsActionTypes.UPDATE_BRAND_CARD,
    payload: {brandId, brandCard},
});

export const updateBrandCardSuccess = (brandCard: Brand): BrandsActions => ({
    type: BrandsActionTypes.UPDATE_BRAND_CARD_SUCCESS,
    payload: {
        brandCard,
    },
});

export const updateBrandCardFailure = (error: string): BrandsActions => ({
    type: BrandsActionTypes.UPDATE_BRAND_CARD_FAILURE,
    payload: {
        error,
    },
});
