import _ from 'lodash';
import {createContext} from 'react';
import {API_ID} from 'src/Types/CommonTypes';

export type NewContent = {
    brand: {
        id: API_ID,
        storeId: API_ID,
    },
    brandCardId?: API_ID,
    id: API_ID,
    workspaceId?: API_ID,
    storeId?: API_ID,
    logAction: string
    promptLoad?: boolean
};

//these are "defaults", if you will
export const newContent: NewContent[] = [];

export const NewContentContext = createContext({newContent, setNewContent: _.noop});
