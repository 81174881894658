import _ from 'lodash';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {getStoreStatus as getStoreStatusApi} from 'src/api/Store/api-store';
import {
    // getStoreStatusFailure,
    getStoreStatusSuccess,
} from './actions';
import {StoreStatus, StoreStatusActionTypes} from './actionTypes';

function* getStoreStatus() {
    try {
        const store: StoreStatus[] = yield call(getStoreStatusApi);
        if (store && store.length && _.isArray(store) && store[0].id) {
            yield put(getStoreStatusSuccess(store));
        }
    } catch (error) {
        // yield put(getStoreStatusFailure('Something went wrong. Please Try again'));
    }
}

function* watchGetStoreStatus() {
    yield takeEvery(StoreStatusActionTypes.GET_STORE_STATUS, getStoreStatus);
}

export default function* storeStatusSagas() {
    yield all([watchGetStoreStatus()]);
}
