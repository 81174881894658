import {useState} from 'react';
import {signUpApi, resendVerificationEmail} from 'src/api/Auth';
import {FormValues} from 'src/Views/Login/Forms/SignUpForm/useValidationSchema';

const useSubmit = () => {
    const [message, setMessage] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isResent, setIsResent] = useState(false);
    const [username, setUsername] = useState<string>('');

    const submitHandler = async(values: FormValues) => {
        try {
            const response = await signUpApi({
                username: values.email,
                newPassword: values.password,
                firstName: values.name,
                lastName: values.secondName,
            });
            setMessage(response.msg);
            setIsSuccess(true);
            setUsername(values.email);
        } catch (e: any) {
            setMessage(e.combinedMessage);
        }
    };

    const resendInviteHandler = async() => {
        try {
            const response = await resendVerificationEmail({
                username,
            });
            if (response) {
                setIsResent(true);
            }
        } catch (e: any) {
            setMessage(e.combinedMessage);
        }
    };

    return {submitHandler, message, isSuccess, resendInviteHandler, isResent};
};

export default useSubmit;
