import './styles.sass';
import _ from 'lodash';
import {genericFn} from 'src/Types/CommonTypes';
import React from 'react';

interface Props {
    submit?: boolean
    type?: 'button' | 'submit' | 'reset' | undefined
    className?: string
    disabled?: boolean
    onClick?: genericFn
    form?: string
    id?: string
    children?: React.ReactNode
}

const Button: React.FC<Props> = (props) => {
    const classes = _.compact(['Button btn', props.type ? `btn-${props.type}` : '', props.className]);

    return <button
        {..._.omit(props, ['className', 'children'])}
        className={classes.join(' ')}>
        {props.children}
    </button>;
};

export default Button;
