import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {RootState} from 'src/redux/rootReducer';
import './styles.sass';
import _ from 'lodash';

//components
import TaskManager from 'src/Components/TaskManager';
import Onboarding from 'src/Components/Onboarding';

import {StoreInfoApi, getStoreRolesInfo} from 'src/api/StoreRole/api-store-role';
import {_get} from 'src/utils/general';
import useDocumentTitle from 'src/hooks/useDocumentTitle';

const TaskDashboard = (props: any): JSX.Element => {
    const location = useLocation();
    const {storeId} = useParams<{storeId: any}>();
    const [userStoreInfo, setUserStoreInfo] = useState<StoreInfoApi>();
    const userId = useSelector((state: RootState) => state.auth.id);
    const readOnly = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_read_only';

    useDocumentTitle('Tasks');

    const handleBrandCardClose = () => {
        //clear out location state
        window.history.replaceState({}, document.title);
    };

    useEffect(() => {
        const fetchUserStoreInfoTags = async() => {
            const userInfo = await getStoreRolesInfo(storeId, userId);
            setUserStoreInfo(userInfo[0]);
        };
        fetchUserStoreInfoTags();
        if (_.get(location, 'state.from') === 'brandCard') {
            handleBrandCardClose();
        }
    }, [storeId, userId]);

    return (
        <div className='task-dashboard'>
            {!readOnly && (<Onboarding onboardType='tasks' />)}
            <TaskManager {...props} readOnly={readOnly} />
        </div>
    );
};

export default TaskDashboard;
