import {API_ID} from 'src/Types/CommonTypes';

export enum StoreStatusActionTypes {
    GET_STORE_STATUS = 'GET_STORE_STATUS',
    GET_STORE_STATUS_SUCCESS = 'GET_STORE_STATUS_SUCCESS',
    GET_STORE_STATUS_FAILURE = 'GET_STORE_STATUS_FAILURE',
    CLEAR_STORE_STATUS = 'CLEAR_STORE_STATUS'
}

export interface StoreStatus {
    id: API_ID
    title: string
    planId: API_ID
    isTrial: boolean
    plan: string
    planEnd: string
    planStart: string
    planCharge: number
    hasProfile: boolean
    nextBill: number
    nextPlan: string
    planName: string
    areBrandCardsAtLimit: boolean
    areAssetsAtLimit: boolean
    hasKeepmail: boolean
    hasTasks: boolean
    version: string
}
export interface GetStoreStatus {
    type: StoreStatusActionTypes.GET_STORE_STATUS
}

export interface GetStoreStatusSuccess {
    type: StoreStatusActionTypes.GET_STORE_STATUS_SUCCESS
    payload: {
        stores: Array<StoreStatus>
    }
}

export interface GetStoreStatusFailure {
    type: StoreStatusActionTypes.GET_STORE_STATUS_FAILURE
}

export interface ClearStoreStatus {
    type: StoreStatusActionTypes.CLEAR_STORE_STATUS
    payload: {
        stores: undefined
    }
}

export interface StoreStatusState {
    error: string
    loading: boolean | null
    storeStatus?: Array<StoreStatus>
}

export type StoreStatusActions = GetStoreStatus | GetStoreStatusSuccess | GetStoreStatusFailure | ClearStoreStatus;
