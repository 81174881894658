import {ID} from 'src/Types/CommonTypes';

export enum StoreActionTypes {
    CREATE_STORE = 'CREATE_STORE',
    CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS',
    CREATE_STORE_FAILURE = 'CREATE_STORE_FAILURE',
    CLEAR_STORE = 'CLEAR_STORE',
}

export interface Store {
    id: ID
    title: string
    description: string
    createdAt: string
    updatedAt: string
}

export interface ICreateStore {
    type: StoreActionTypes.CREATE_STORE
    payload: {label: string}
}

export interface ICreateStoreSuccess {
    type: StoreActionTypes.CREATE_STORE_SUCCESS
    payload: {
        store: Store
    }
}

export interface ICreateStoreFailure {
    type: StoreActionTypes.CREATE_STORE_FAILURE
    payload: {
        error: string
    }
}

export interface IClearStore {
    type: StoreActionTypes.CLEAR_STORE
    payload: {
        store: undefined
    }
}

export interface StoreState {
    error: string
    loading: boolean
    createdStore?: Store
}

export type StoreActions = ICreateStore | ICreateStoreFailure | ICreateStoreSuccess | IClearStore;
