import ContactManager from 'src/Components/ContactManager';
import {ID} from 'src/Types/CommonTypes';

interface Props {
    brandId: ID
    readOnly: boolean
}

const BrandContacts: React.FC<Props> = ({brandId, readOnly}) => {

    // const cancelButtonHandler = () => {
    //     if (isEdit) {
    //         setStep(Steps.EDIT_BRAND_CARD_INFO);
    //     } else {
    //         onCancel();
    //     }
    // };

    return (
        <div className='brand-contacts'>
            <ContactManager
                brandCardId={brandId} readOnly={readOnly} />
        </div>
    );
};

export default BrandContacts;
