import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {nanoid} from 'nanoid';
import _ from 'lodash';

//icons
import {PlusOutlined, DeleteFilled} from '@ant-design/icons';
import {Kabob, Pen} from 'src/lib/svg-icon-helper';
import disabledLogo from 'src/assets/images/bk-disabled-2.svg';

//components
import {Upload, message} from 'antd';
import Button from 'src/Components/Button';
import ConfettiSuccess from 'src/Components/Payments/ConfettiSuccess';
import ControlHeader from 'src/Components/ControlHeader';
import Delete from 'src/Components/Plans/Delete';
import Downgrade from 'src/Components/Plans/Downgrade';
import DropdownButton from 'src/Components/DropdownButton';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import Payments from 'src/Components/Payments';
import Plans from 'src/Components/Plans';
import {Prompt, Props as PromptProps} from 'src/Components/ModalPrompt';
import StoreEdit from 'src/Components/StoreEdit';
import Table, {SortBy} from 'src/Components/Table';
import TeamCreate from 'src/Components/TeamCreate';
import TeamEdit from 'src/Components/TeamEdit';
import Upgrade from 'src/Components/Plans/Upgrade';
import EmptyState from 'src/Components/EmptyState';
import NoResults from 'src/Components/NoResults';

//apis
import {
    getUsers,
    deleteUser,
    resendInvite,
    resetPassword,
} from 'src/api/User/user-api';
import {
    getStoreById,
    Store,
    subscribeStore,
    upsertStoreLogo,
    deleteStore,
} from 'src/api/Store/api-store';
import {getStoreRolesInfo, StoreInfoApi} from 'src/api/StoreRole/api-store-role';

//types
import {ID, UploadProps} from 'src/Types/CommonTypes';
import {Roles, RolesTypes} from 'src/Types/StoreRoles/types';
import {fileTypes} from 'src/constants/fileTypes';
import {RootState} from 'src/redux/rootReducer';
import {User} from 'src/Types/User/types';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';

//libs
import {Filters, Filter, handleFilterValidations, removeFilterFactory, GLOBAL_FILTER_DEBOUNCE_RATE} from 'src/lib/filter-helper';
import {classnames, displayFileSize, debouncePromise, _get} from 'src/utils/general';
import {getQueryParams, HistoryHelperFactory} from 'src/lib/url';
import {formatDate} from 'src/lib/date-helper';
import userStatusFilter from 'src/lib/filters/user-status';
import userRoleFilter from 'src/lib/filters/user-role';
import useLogout from 'src/hooks/useLogout';
import useWindow from 'src/hooks/useWindow';
import {PlanGroup, plans} from 'src/constants/subscriptionPlans';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import {proratePlanCost} from 'src/lib/price-helper';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import {clearServerErrors} from 'src/redux/global/actions';

interface FilterDependencies {
    allUsers?: User[]
}
/****************** TEAMS IS NOW MY STORE!! ********************/
const Teams = (): JSX.Element => {
    const dispatch = useDispatch();
    const queryParams = getQueryParams(useLocation());
    const historyHelper = HistoryHelperFactory(useNavigate());
    const {storeId} = useParams<{storeId: string}>();
    const userId = useSelector((state: RootState) => state.auth.id);
    const [currentUser, setCurrentUser] = useState<User>();
    const [textSearchValue, setTextSearchValue] = useState(queryParams.textSearch || '');
    const [store, setStore] = useState<Store>();
    const [users, setUsers] = useState<User[]>();
    const [allUsers, setAllUsers] = useState<User[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showCreateUsersModal, setShowCreateUsersModal] = useState<boolean>(false);
    const [showTeamEditModal, setShowTeamEditModal] = useState<boolean>(false);
    const [isHoveringStoreImage, setIsHoveringStoreImage] = useState<boolean>(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState<boolean>(true);
    const [selectedUser, setSelectedUser] = useState<User>();
    const {modalSize} = useWindow();
    const {logoutUser} = useLogout();
    const storeStatus = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const currentStore = storeStatus?.find((currentStore: StoreStatus) => `${currentStore.id}` === storeId);
    const [userStoreInfo, setUserStoreInfo] = useState<StoreInfoApi>();
    const readOnly = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_read_only';
    const isOwner = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_owner';
    const currentPlan = currentStore && plans[currentStore.plan];

    const [showPlanModal, setShowPlanModal] = useState(false);
    const [currentModal, setCurrentModal] = useState<'plan' | 'downgrade' | 'upgrade' | 'payment' | 'paymentSuccess'>('plan');
    const [selectedPlan, setSelectedPlan] = useState<PlanGroup>();
    const [isSubmittingUpgrade, setIsSubmittingUpgrade] = useState(false);

    const [sortBy, setSortBy] = useState<SortBy>({
        key: 'lastName',
        title: 'Last Name',
    });

    const callId = useRef<string | null>(null);

    const [filterData, setFilterData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>({});

    const initialFilters = [
        userStatusFilter,
        userRoleFilter,
    ];
    const rawFilters = _.keyBy(initialFilters, 'key');
    const filterDependencies: FilterDependencies = {
        allUsers,
    };
    const [filters, setFilters] = useState<Record<string, Filter>>(
        _.omitBy(rawFilters, (filter) => {
            return !filter.enabled(filterDependencies) && !filter.show(filterDependencies);
        })
    );

    useDocumentTitle('Teams');

    const getFilters = (filterDependencies: FilterDependencies) => {
        const initialFilters = [
            userStatusFilter,
            userRoleFilter,
        ];
        //filters here must still be key'ed to their respective 'key'
        const rawFilters = _.keyBy(initialFilters, 'key');
        const filters = _.omitBy(
            rawFilters,
            (filter) => !filter.enabled(filterDependencies) && !filter.show(filterDependencies)
        );
        setFilters(filters);
    };

    const getStoreHandler = useCallback(async(
        currentCallId,
        {
            init = false,
            textSearchValue = undefined,
            filterValuesFromState = filterValues,
        }
    ) => {
        if (callId.current === currentCallId) {
            setIsLoading(true);
            if (init) {
                setStore(undefined);
            }
            const searchObject = {};
            if (textSearchValue) {
                searchObject.textSearch = textSearchValue;
            }
            for (const [filterKey, filterValue] of Object.entries(filterValuesFromState)) {
                if (_.get(filters, [filterKey, 'transformForAPI'])) {
                    filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies);
                }
            }
            const finalSearchObject = init ? queryParams : searchObject;
            if (init) {
                const [store, allUsers, currentUser] = await Promise.all([
                    getStoreById(storeId),
                    getUsers(storeId),
                    getStoreRolesInfo(storeId, userId),
                ]);
                setAllUsers(allUsers);
                setStore(store);
                if (currentUser) {
                    setCurrentUser(currentUser[0]);
                }

                getFilters({
                    allUsers,
                });

                const {
                    updatedQueryParamObject,
                    urlFilterValues,
                    didFiltersObviouslyChange,
                } = handleFilterValidations(
                    filters,
                    {
                        allUsers,
                    },
                    queryParams
                );

                setFilterValues(urlFilterValues);
                historyHelper.updateQueryParams(updatedQueryParamObject, false);
                if (didFiltersObviouslyChange) {
                    //filters changed, short-circuit this request and fire new query
                    const currentCallId = nanoid();
                    callId.current = currentCallId;
                    getStoreHandler(
                        currentCallId,
                        {filterValuesFromState: urlFilterValues}
                    );
                    return;
                } else {
                    const users = await getUsers(storeId, updatedQueryParamObject);
                    setUsers(users);
                }
            } else {
                const users = await getUsers(storeId, finalSearchObject);
                setUsers(users);
                historyHelper.updateQueryParams(searchObject);
            }
            setIsLoading(false);
        }
    }, [storeId]);

    const initFilters = (filterDependenciesInit = filterDependencies) => {

        const filterData: Filters = {};
        for (const [filterKey, filter] of Object.entries(filters)) {
            if (!filter.loadAfter && filter.show(filterDependenciesInit) && filter.enabled(filterDependenciesInit)) {
                filterData[filterKey] = filter.dataBuilder(filterDependenciesInit);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    if (!isLoading && filters && !filterData && _.every(Object.values(filterDependencies))) {
        initFilters();
    }

    useEffect(() => {
        const fetchUserStoreInfoTags = async() => {
            const userInfo = await getStoreRolesInfo(storeId, userId);
            setUserStoreInfo(userInfo[0]);
        };
        fetchUserStoreInfoTags();
    }, [storeId, userId]);

    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        getStoreHandler(currentCallId, {init: true, storeId});
    }, [storeId]);

    useEffect(() => {
        if (store && !isLoading) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => getStoreHandler(currentCallId, {textSearchValue, filterValuesFromState: filterValues})
            )();
        }
    }, [filterValues, textSearchValue]);

    useEffect(() => {
        if (allUsers) {
            initFilters({
                allUsers,
            });
        }
    }, [allUsers]);

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    const handleHover = () => {
        setIsHoveringStoreImage(true);
    };

    const handleHoverOff = () => {
        setIsHoveringStoreImage(false);
    };

    const handleDeleteImage = async() => {
        if (store) {
            setStore(Object.assign({}, store, {logoUrl: undefined}));
            await upsertStoreLogo(storeId);
        }
    };

    const hasStoreImage = Boolean(store && store.logoUrl);
    const addStoreLogo = async(file: UploadProps) => {
        if (store) {
            setIsUploadingLogo(true);
            const formData = new FormData();
            if (file) {
                formData.append('file', file.file);
            }
            formData.append('name', file.file.name);
            const storeReturn = await upsertStoreLogo(storeId, formData);
            setStore(Object.assign({}, store, {logoUrl: storeReturn.logo}));
        }
    };

    const imageIsLoaded = () => {
        if (store) {
            setIsUploadingLogo(false);
        }
    };

    const uploadProps = {
        name: 'file',
        showUploadList: false,
        beforeUpload: () => false,
        onChange(store: UploadProps) {
            addStoreLogo(store);
        },
    };

    const handleStoreEditModalClose = (shouldReload = false) => {
        if (shouldReload) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            getStoreHandler(currentCallId, {init: true, textSearchValue});
        }
        setShowEditModal(false);
    };

    const handlePayment = async(plan: PlanGroup) => {
        setIsSubmittingUpgrade(true);
        const planToSubmit = plan || selectedPlan;
        if (planToSubmit && currentStore) {
            if (currentStore?.hasProfile || !plan.cost) {
                //submit payment, show success modal
                try {
                    await subscribeStore(storeId, planToSubmit.name);
                    setCurrentModal('paymentSuccess');
                } catch (error: any) {
                    if (error && error.combinedMessage) {
                        message.error(error.combinedMessage, 4, () => {
                            dispatch(clearServerErrors());
                        });
                    }
                    setIsSubmittingUpgrade(false);
                }
            } else {
                setCurrentModal('payment');
            }
        }
        setIsSubmittingUpgrade(false);
    };

    const handlePlanSelect = (plan: PlanGroup) => {
        if (currentPlan && plan) {
            setSelectedPlan(plan);
            if (currentStore.isTrial || !plan.cost) {
                handlePayment(plan);
            } else if (plan.cost < currentPlan.cost) {
                setCurrentModal('downgrade');
            } else {
                setCurrentModal('upgrade');
            }
        }
    };

    const handlePaymentSuccess = async() => {
        //return from enting in payment info and successfully paying
        setCurrentModal('paymentSuccess');
    };

    const handleTerminateAccount = async() => {
        await new Prompt().yesNoDanger(
            {
                Component: ({onYes = _.noop, onNo = _.noop, ...props}: PromptProps) => <Delete onDelete={onYes} onCancel={onNo} plan={currentStore && currentStore.plan} {...props} />,
                className: 'plan-modal',
                onYes: async() => {
                    await deleteStore(storeId);
                    logoutUser();
                },
                width: modalSize < 1200 ? modalSize : 1400,
            }
        );
    };

    const handleResetModal = () => {
        setSelectedPlan(undefined);
        setCurrentModal('plan');
    };

    const handleHidePlanModal = () => {
        setShowPlanModal(false);
        handleResetModal();
        dispatch(getStoreStatus());
    };

    const handleTeamEditModalClose = (shouldReload = false) => {
        if (shouldReload) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            getStoreHandler(currentCallId, {init: false, textSearchValue, filterValuesFromState: filterValues});
        }
        setShowTeamEditModal(false);
    };

    const handleTeamCreateModalClose = (shouldReload = false) => {
        if (shouldReload) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            getStoreHandler(currentCallId, {init: false, textSearchValue, filterValuesFromState: filterValues});
        }
        setShowCreateUsersModal(false);
    };

    const handleDeleteUser = async(id: ID) => {
        await new Prompt().yesNoDanger(
            {
                body: 'Are you sure you want to delete this user?',
                title: 'Delete User',
                displayType: 'delete',
                onYes: async() => {
                    try {
                        await deleteUser(storeId, id);
                        const currentCallId = nanoid();
                        callId.current = currentCallId;
                        getStoreHandler(currentCallId, {init: false, textSearchValue, filterValuesFromState: filterValues});
                    } catch (ex) {
                        // This also allows the modal to close
                    }
                },
            }
        );
    };

    const handleEditUser = (user?: User) => {
        setSelectedUser(user);
        setShowTeamEditModal(true);
    };

    const handleResetPassword = async(userId: ID) => {
        message.loading({content: 'Sending Password reset email...', key: 'RESET_PASSWORD'});
        await resetPassword(storeId, userId);
        message.success({content: 'Password reset email sent', key: 'RESET_PASSWORD'});

    };
    const handleResendInvite = async(userId: ID) => {
        message.loading({content: 'Resending Invite...', key: 'RESEND_INVITE'});
        await resendInvite(storeId, userId);
        message.success({content: 'Invite Resent', key: 'RESEND_INVITE'});
    };

    const usingKeepmail = (store && store.useKeepMail && store.assetMailbox);
    const isTrial = currentStore && currentStore.isTrial;
    const hasAnyUsers = Boolean(allUsers && allUsers.length > 0);

    const columns = [{
        title: 'First',
        dataIndex: 'firstName',
        key: 'firstName',
        width: '12%',
        ellipsis: true,
    },
    {
        title: 'Last',
        dataIndex: 'lastName',
        key: 'lastName',
        width: '12%',
        ellipsis: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
        render: (email) => <a href={`mailto:${email}`}>{email}</a>,
    },
    {
        title: 'Permissions',
        dataIndex: 'storeRoles',
        key: 'storeRoles',
        ellipsis: true,
        width: '12%',
        render: (UNUSED: any, user: User) =>
            _.get(user, ['storeRoles', 0, 'role']) && Roles[user.storeRoles[0].role as keyof RolesTypes],
    },
    {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        width: 72,
        ellipsis: true,
        render: (UNUSED: any, user: User) => user.isActive ? 'Active' : 'Inactive',
    },
    {
        title: 'Last Login',
        dataIndex: 'lastActive',
        key: 'lastActive',
        width: 105,
        ellipsis: true,
        render: (UNUSED: any, user: User) => Boolean(user.activities && user.activities.length)
            && formatDate(user.activities[0].createdAt),
    }];

    if (!readOnly) {
        columns.push({
            key: 'actions',
            className: 'actions-td',
            width: 55,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, user: User) => (
                <DropdownButton
                    className='action-button primary-color'
                    items={[
                        {
                            key: 'edit',
                            visible: isOwner || (_.get(user, ['storeRoles', 0, 'role']) !== 'retail_owner'),
                            onClick: () => handleEditUser(user),
                            text: 'Edit',
                        },
                        {
                            key: 'reset',
                            disabled: !user.isActive,
                            onClick: () => handleResetPassword(user.id),
                            text: 'Reset Password',
                        },
                        {
                            key: 'resend',
                            visible: !user.isActive,
                            onClick: () => handleResendInvite(user.id),
                            text: 'Resend Invite',
                        },
                        {
                            visible: isOwner || (_.get(user, ['storeRoles', 0, 'role']) !== 'retail_owner'),
                            key: 'remove',
                            disabled: userId === user.id,
                            onClick: () => handleDeleteUser(user.id),
                            text: 'Remove User',
                        },
                    ]}>
                    <Kabob />
                </DropdownButton>
            ),
        });
    }

    return (
        <div className='store-detail'>
            <div className='store-detail-header'>
                <div className='store-detail-body'>
                    <div
                        className='store-main-image-container'
                        onMouseOver={handleHover}
                        onMouseLeave={handleHoverOff}>
                        {store && (
                            <img
                                className='store-main-image'
                                src={hasStoreImage
                                    ? `${BASE_API || BASE_URL}${store.logoUrl}`
                                    : disabledLogo}
                                onLoad={imageIsLoaded} />
                        )}
                        {!store && (
                            <img
                                className='store-main-image'
                                src={disabledLogo} />
                        )}
                        {isUploadingLogo && (
                            <Loading fill size={56} />
                        )}
                        {store && !isUploadingLogo && (
                            <div className={classnames(
                                'main-image-controls',
                                {'is-hovered': isHoveringStoreImage}
                            )}>
                                <Upload accept={fileTypes.image.join(', ')} className='store-image-upload' {...uploadProps}>
                                    <Button
                                        className='btn-white btn-circle btn-icon'>
                                        <Pen className='edit-store-image-icon' />
                                    </Button>
                                </Upload>
                                {hasStoreImage && (
                                    <Button
                                        className='btn-white btn-circle btn-icon'
                                        onClick={handleDeleteImage}>
                                        <DeleteFilled />
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className='store-detail-info'>
                        <div className='store-title-block'>
                            <div className='store-title'>
                                {store ? store.title : ' '}
                            </div>
                        </div>
                        <div className='store-detail-info-block'>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Account Number #:</span>
                                {_.get(store, 'accountNumber', '')}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Store Created:</span>
                                {formatDate(_.get(store, 'createdAt'))}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Next Billing Amount:</span>
                                {Boolean(currentStore && currentStore.nextBill) && (
                                    <span>{(currentStore.nextBill / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</span>
                                )}
                                {!(currentStore && currentStore.nextBill) && (
                                    <span>-</span>
                                )}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Next Billing Date:</span>
                                {currentStore && currentStore.planEnd && (
                                    <span>{formatDate(currentStore.planEnd)}</span>
                                )}
                                {!(currentStore && currentStore.planEnd) && (
                                    <span>-</span>
                                )}
                            </div>
                        </div>
                        <div className='store-detail-info-block'>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Plan:</span>
                                {currentStore && _.capitalize(currentStore.nextPlan || currentStore.planName)}
                                {currentStore && currentStore.nextPlan && (
                                    <span> ({currentStore.planName} until {formatDate(currentStore.planEnd)})</span>
                                )}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Number of Brand Cards:</span>
                                {_.get(store, 'brandCardCount', 0)}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Storage Used:</span>
                                {(store && store.assetUsage) ? displayFileSize(store.assetUsage) : '0 mb'}
                            </div>
                            <div className='store-detail-info-row'>
                                <span className='info-row-title'>Keepmail Address:</span>
                                {usingKeepmail && (
                                    <a href={`mailto:${store.assetMailbox}${store.assetMailboxSuffix}`}>
                                        {store.assetMailbox}{store.assetMailboxSuffix}
                                    </a>
                                )}
                                {!usingKeepmail && 'Disabled'}
                            </div>
                        </div>
                    </div>
                    {!readOnly && (
                        <div className='store-detail-controls'>
                            <div
                                className='btn btn-action'
                                onClick={() => {
                                    setShowEditModal(true);
                                }}>
                                Edit Store Info
                            </div>
                            {isOwner && currentStore && currentStore.hasProfile && (
                                <div
                                    className='btn btn-action'
                                    onClick={async() => {
                                        await new Prompt().yesNoDanger(
                                            {
                                                Component: ({onYes = _.noop, onNo = _.noop, ...props}: PromptProps) => <Payments onCancel={onNo} onSuccess={onYes} {...props} />,
                                                onYes: _.noop,
                                                width: modalSize,
                                            }
                                        );
                                    }}>
                                    Edit Payment Info
                                </div>
                            )}
                            {isOwner && currentStore && (
                                <div
                                    className='btn btn-action'
                                    onClick={() => setShowPlanModal(true)}>
                                    {isTrial && !currentStore.nextPlan && 'Sign Up Now'}
                                    {isTrial && currentStore.nextPlan === 'Free' && 'Upgrade Now'}
                                    {isTrial && currentStore.nextPlan && currentStore.nextPlan !== 'Free' && 'Change Plan'}
                                    {!isTrial && (currentStore.plan === 'free' || currentStore.nextPlan === 'Free') && 'Upgrade Now'}
                                    {!isTrial && currentStore.plan !== 'free' && currentStore.nextPlan !== 'Free' && 'Change Plan'}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className='store-users'>
                <div className='store-users-header'>
                    <ControlHeader
                        setSearch={setTextSearchValue}
                        search={textSearchValue}
                        disableSearch={isLoading}
                        placeholder='Search by user name'
                        handleSetFilter={handleSetFilter}
                        handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                        filterValues={filterValues}
                        filterData={filterData}
                        filters={filters}
                        showSort={!isLoading && Boolean(allUsers && allUsers.length)}
                        sortOptions={[
                            {
                                key: 'email',
                                title: 'Email',
                            },
                            {
                                key: 'firstName',
                                title: 'First Name',
                            },
                            {
                                key: 'lastName',
                                title: 'Last Name',
                            },
                            {
                                key: 'lastActive',
                                title: 'Last Login',
                                func: (user: User) => Boolean(user.activities && user.activities.length)
                                    && formatDate(user.activities[0].createdAt),
                            },
                            {
                                key: 'permissions',
                                title: 'Permissions',
                                func: (user: User) =>
                                    _.get(user, ['storeRoles', 0, 'role']) && Roles[user.storeRoles[0].role as keyof RolesTypes],
                            },
                            {
                                key: 'isActive',
                                title: 'Status',
                                func: (user: User) => user.isActive ? 'Active' : 'Inactive',
                            },
                        ]}
                        setSortOption={setSortBy}
                        activeSort={sortBy} />
                    <Button
                        className='btn btn-action add-user-button'
                        disabled={readOnly}
                        onClick={() => setShowCreateUsersModal(true)}>
                        <PlusOutlined className='icon left-icon' />Team Member
                    </Button>
                </div>
                {isLoading && (
                    <Loading fill size={56} />
                )}
                {!isLoading && (
                    <Table
                        locale={hasAnyUsers
                            ? {emptyText: (<NoResults />)}
                            : {emptyText: (<EmptyState objectName='Team Member' handleCreate={() => setShowCreateUsersModal(true)} />)}}
                        columns={columns}
                        data={users}
                        rowClassName={(user) => user.isLimitedByPlan ? 'disabled-table-row--allow-actions' : undefined}
                        shouldScroll={true}
                        sortBy={sortBy}
                        id='TEAM_TABLE'
                        scrollParentSelector='.store-detail-table .ant-table-body'
                        className='store-detail-table' />
                )}
            </div>
            {store && (
                <StoreEdit
                    canUseKeepMail={Boolean(
                        currentUser && currentUser.role === 'retail_owner'
                        && _.get(store, 'brandCardCount')
                        && currentStore && currentStore.hasKeepmail
                    )}
                    isModalOpen={showEditModal}
                    onModalClose={handleStoreEditModalClose}
                    store={store} />
            )}
            <TeamEdit
                canEditRole={isOwner}
                canEditDetails={_.get(selectedUser, 'id') === _.get(currentUser, 'userId')}
                isModalOpen={showTeamEditModal}
                onModalClose={handleTeamEditModalClose}
                storeId={store && store.id}
                user={selectedUser} />
            <TeamCreate
                isOwner={isOwner}
                isModalOpen={showCreateUsersModal}
                onModalClose={handleTeamCreateModalClose}
                storeId={store && store.id} />
            <Modal
                className='plan-modal'
                width={modalSize < 1200 ? modalSize : 1400}
                footer={false}
                destroyOnClose
                open={showPlanModal}
                onCancel={handleHidePlanModal}>
                {currentModal === 'plan'
                    && <Plans
                        onPlanSelect={handlePlanSelect}
                        disabledPlans={(currentStore && !currentStore.isTrial) ? [currentStore.plan] : undefined}
                        onTerminateAccount={handleTerminateAccount} />
                }
                {currentModal === 'downgrade' && currentPlan && selectedPlan
                    && <Downgrade
                        onContinue={handlePayment}
                        onCancel={handleHidePlanModal}
                        planEnd={currentStore && currentStore.planEnd}
                        planName={currentPlan && currentPlan.name}
                        newPlan={selectedPlan} />
                }
                {currentModal === 'upgrade' && currentPlan && selectedPlan
                    && <Upgrade
                        onContinue={handlePayment}
                        onCancel={handleHidePlanModal}
                        newPlan={selectedPlan}
                        isSubmitting={isSubmittingUpgrade}
                        isTrial={currentStore.isTrial}
                        trialEnds={currentStore && formatDate(currentStore.planEnd)}
                        costDiff={currentStore.isTrial
                            ? (selectedPlan.cost / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
                            : (proratePlanCost(currentStore, selectedPlan) / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})} />
                }
                {currentModal === 'payment' && selectedPlan && <Payments plan={selectedPlan} onCancel={handleHidePlanModal} onBack={handleResetModal} onSuccess={handlePaymentSuccess} />}
                {currentModal === 'paymentSuccess'
                    && selectedPlan
                    && <ConfettiSuccess
                        title=''
                        body='Success!'
                        footer={currentStore?.isTrial
                            ? `Your BrandKeep ${_.capitalize(selectedPlan.name)} plan will be active after your trial expires on ${formatDate(currentStore.planEnd)}.`
                            : `Your BrandKeep ${_.capitalize(selectedPlan.name)} plan is now active!`}
                        onCancel={handleHidePlanModal} />}
            </Modal>
        </div>
    );
};

export default Teams;
