import {Popover} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {matchPath, useNavigate, useLocation} from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import {AuthRoutes, navigateToSameRouteInStore} from 'src/constants/userRoles';
import {RootState} from 'src/redux/rootReducer';
import {classnames, _get} from 'src/utils/general';
import Button from 'src/Components/Button';
import FatFinger from 'src/Components/FatFinger';
import {genericFn} from 'src/Types/CommonTypes';
import './styles.sass';
import _ from 'lodash';
import {Hamburger} from 'src/lib/svg-icon-helper';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';
import dayjs from 'dayjs';
import useLogout from 'src/hooks/useLogout';
import {NewContentContext} from 'src/hooks/useNewContent';

//apis
import {getStoreRolesInfo, StoreInfoApi} from 'src/api/StoreRole/api-store-role';

interface PropTypes {
    className: string
    isAdmin?: boolean
    onMenuClick?: genericFn
    onShowPlanModal?: genericFn
    hideMenu?: boolean
    onNavigate?: genericFn
}

const HeaderBar = ({
    className,
    isAdmin = false,
    onMenuClick = _.noop,
    onShowPlanModal = _.noop,
    hideMenu = false,
    onNavigate = _.noop,
}: PropTypes): JSX.Element => {
    const userInfo = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const {logoutUser} = useLogout();
    // TODO add info about user avatar url  after creating store in redux
    const avatarUrl = null;

    const storeStatus = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const stores = useSelector((state: RootState) => state.stores.stores);

    const {newContent} = useContext(NewContentContext);

    const {pathname} = useLocation();
    const path = matchPath({path: '/store/:storeId/*'}, pathname);
    const storeId = _.get(path, 'params.storeId');
    const currentStore = Boolean(storeStatus && storeStatus.length) && _.find(storeStatus, (currentStore: StoreStatus) => `${currentStore.id}` === storeId);
    const timeLeft = currentStore && currentStore.planEnd && dayjs(currentStore.planEnd).diff(new Date(), 'days');
    const [userStoreInfo, setUserStoreInfo] = useState<StoreInfoApi>();
    const isOwner = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_owner';
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const logoutHandler = () => {
        logoutUser();
    };

    useEffect(() => {
        const fetchUserStoreInfo = async() => {
            const userStoreInfo = await getStoreRolesInfo(storeId, userInfo.id);
            setUserStoreInfo(userStoreInfo[0]);
        };
        if (storeId) {
            fetchUserStoreInfo();
        }
    }, [storeId, userInfo]);

    const AccountMenu = (
        <div>
            {userInfo.canUseAdminDashboard && isAdmin && (
                <div onClick={() => {
                    navigate(AuthRoutes.home);
                }} className='account-menu-option'>
                    Go to BrandKeep App
                </div>
            )}
            {userInfo.canUseAdminDashboard && !isAdmin && (
                <div onClick={() => {
                    navigate('/admin/stores');
                }} className='account-menu-option'>
                    Go to Admin
                </div>
            )}
            {!isAdmin && stores && stores.length > 1 && stores.map((store) => (
                <div
                    key={store.id}
                    onClick={() => {
                        if (store.id) {
                            navigateToSameRouteInStore(navigate, location, store.id);
                            onNavigate();
                            setIsPopoverOpen(false);
                        }
                    }}
                    className={classnames(
                        {'bold': currentStore && store.id === currentStore.id},
                        {
                            'new-content-bubble': currentStore
                                && store.id !== currentStore.id
                                && _.some(newContent, (asset) => Number(asset.storeId || asset.brand?.storeId) === Number(store.id)),
                        },
                        'account-menu-option'
                    )}>
                    {store.logoUrl && <img className='account-logo' src={store.logoUrl} />}
                    <span className='single-line-ellipsis'>{store.title}</span>
                </div>
            ))}
            <div onClick={logoutHandler} className='account-menu-option'>
                Sign Out
            </div>
        </div>
    );

    return (
        <div className={classnames(
            {[className]: className},
            'headerBar'
        )}>
            {!hideMenu && !isAdmin && (
                <div className='main-menu-button' onClick={onMenuClick}><Hamburger /><FatFinger howFat={15} /></div>
            )}
            <img src={logo} className='logo' />
            {userInfo.isSignedIn && (
                <div className='rightSection'>
                    {(isOwner && currentStore && ((currentStore.isTrial && !currentStore.nextPlan) || currentStore.plan === 'free' || currentStore.nextPlan === 'Free')) && (
                        <Button className='btn-custom subscribe-button theme-purple theme-background' onClick={onShowPlanModal}>
                            {currentStore.isTrial && Boolean(timeLeft) && !currentStore.nextPlan && (
                                <span className='menu-trial-time-left'>{timeLeft} Days Left in Trial</span>
                            )}
                            {currentStore.isTrial && !currentStore.nextPlan && 'Sign Up Now'}
                            {(currentStore.plan === 'free' || currentStore.nextPlan === 'Free') && 'Upgrade Now'}
                        </Button>
                    )}
                    <Popover
                        overlayClassName='account-menu'
                        placement='bottomRight'
                        content={AccountMenu}
                        open={isPopoverOpen}
                        onOpenChange={setIsPopoverOpen}
                        trigger='click'>
                        <div className='userInfo'>
                            {avatarUrl ? <img className='avatar' /> : <div className='avatar'>{userInfo.name[0]}</div>}
                            {`${userInfo.name} ${userInfo.familyName}`}
                        </div>
                    </Popover>
                </div>
            )}
        </div>
    );
};

export default HeaderBar;
