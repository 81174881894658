import React, {useEffect} from 'react';
import {genericFn} from 'src/Types/CommonTypes';

const useOutsideClick = (menuRef: React.RefObject<HTMLElement>, callback: genericFn, showMenu = false): void => {

    useEffect(() => {
        const handleClick = (event: any) => {
            if (showMenu && menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest('.main-menu-button')) {
                callback();
                event.stopPropagation();
                event.preventDefault();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [callback, menuRef, showMenu]);
};

export default useOutsideClick;
