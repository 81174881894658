/* globals window */
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'src/redux/rootReducer';

const InitIntercom = (): JSX.Element | null => {
    const auth = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (ENV_VARS.intercomAppId && auth.isLoading === false && auth.isSignedIn && auth.intercomUserId) {
            window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: ENV_VARS.intercomAppId,
                user_id: auth.id,
                email: auth.email,
                // name: '',
                // action_color: '#000000',
                // background_color: '#ffffff',
                // alignment: 'left',           //Customize left or right position of messenger
                // horizontal_padding: 20,      //Customize horizontal padding
                // vertical_padding: 20         //Customize vertical padding
            });
        }
    }, [auth]);

    // window.Intercom('shutdown');

    return auth.isSignedIn ? (
        <span />
    ) : null;
};

export default InitIntercom;
