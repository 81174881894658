import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {confirmSignUpApi, resendVerificationEmail} from 'src/api/Auth';
import {setTokens} from 'src/utils/token.utils';

const useSubmit = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isResent, setIsResent] = useState(false);

    const submitHandler = async(code: string) => {
        try {
            const response = await confirmSignUpApi(code);
            if (response.tokens) {
                setTokens(response.tokens.accessToken, response.tokens.refreshToken, response.username);
                navigate('/');
            }
        // eslint-disable-next-line no-empty
        } catch (e: any) {
            setMessage(e.combinedMessage);
            setIsError(true);
        }
    };

    const resendEmailVerify = async(username: string) => {
        try {
            const response = await resendVerificationEmail({
                username,
            });
            if (response) {
                setIsResent(true);
            }
        } catch (e: any) {
            setMessage(e.combinedMessage);
        }
    };

    return {submitHandler, message, isError, resendEmailVerify, isResent};
};

export default useSubmit;
