import {all, takeEvery} from 'redux-saga/effects';
import {GlobalActionTypes} from './actionTypes';

function* getErrors() {
    yield Promise.resolve(false);
}

function* watchGetErrors() {
    yield takeEvery(GlobalActionTypes.SERVER_ERRORS, getErrors);
}

export default function* globalSagas() {
    yield all([watchGetErrors()]);
}
