import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    getContactsForImport,
    getImportInfo,
    moveContactsToStore,
    postFileForImport,
} from 'src/api/ContactImport/api-contact-import';
import Pings from 'src/lib/pings';
import _ from 'lodash';
import {nameFormatter} from 'src/lib/name-helper';

export interface ImportInfo {
    updated: number
    new: number
}

export interface ImportMessage {
    message: string
}

const useSubmit = () => {
    const {storeId} = useParams<{storeId: string}>();
    const [importId, setImportId] = useState('');
    const [updatedCount, setUpdatedCount] = useState(0);
    const [createdCount, setCreatedCount] = useState(0);
    const [errors, setErrors] = useState<Array<any>>([]);
    const [importInfo, setImportInfo] = useState<ImportInfo>({
        updated: 0,
        new: 0,
    });
    const [isProcessing, setIsProcessing] = useState<boolean>(false);


    const getUpdateInfo = async(importId: string, importInfo: any) => {
        const contactsImportInfo = await getContactsForImport(importId, storeId);
        let updatedCount = 0;
        let createdCount = 0;
        let skipCount = 0;
        const errors: Array<any> = [];
        contactsImportInfo.forEach((contact: any, rowId) => {
            if (contact.conflicts || (contact.importMessages && contact.importMessages.length)) {
                errors.push({
                    rowId: rowId + 1,
                    message: contact.importMessages,
                    name: nameFormatter(contact),
                });
                skipCount++;
            } else if (contact.isNew) {
                createdCount++;
            } else if (contact.isUpdated) {
                updatedCount++;
            } else {
                skipCount++;
            }
        });
        setUpdatedCount(updatedCount);
        setCreatedCount(createdCount);
        setErrors(errors);
        setImportId(importId);
        return ((updatedCount + createdCount + skipCount) / importInfo.rowCount) === 1;
    };

    const submitFileHandler = async(file: File) => {
        setIsProcessing(true);
        setUpdatedCount(0);
        setCreatedCount(0);
        const {importId} = await postFileForImport(storeId, file);
        const importInfo = await getImportInfo(importId, storeId);
        setImportInfo({updated: Number(importInfo.updated), new: Number(importInfo.new)});
        getUpdateInfo(importId, importInfo);
        Pings.addPing({
            id: 'importContactsPing',
            pingOnInit: true,
            frequency: .5,
            unit: 'seconds',
            task: async() => {
                const isComplete = await getUpdateInfo(importId, importInfo);
                if (isComplete) {
                    setIsProcessing(false);
                    Pings.removePing('importContactsPing');
                }
            },
            onError: _.noop,
        });
    };

    const importFileHandler = async() => {
        await moveContactsToStore(importId);
    };

    return {submitFileHandler, importId, importInfo, importFileHandler, createdCount, updatedCount, errors, isProcessing};
};

export default useSubmit;
