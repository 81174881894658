import React, {useRef} from 'react';
import './styles.sass';

//libs
import {classnames} from 'src/utils/general';
import {genericFn} from 'src/Types/CommonTypes';

interface PropTypes {
    className?: string,
    onClick?: genericFn,
    howFat?: number, //how many pixels extra around the parent element we want to detect interaction
}

/***** IMPLEMENTATION NOTE: <FatFinger>'s parent MUST have relativity! *******/
const FatFinger = ({className = '', onClick, howFat = 5}: PropTypes): JSX.Element => {
    const fatRef = useRef<any>(null);

    const handleClick = (event: any) => {
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <span
            className={classnames(
                'FatFinger',
                {[className]: className}
            )}
            style={{margin: `-${howFat}px`, padding: `${howFat}px`}}
            onClick={handleClick}
            ref={fatRef} />
    );
};

export default FatFinger;
