import {all} from 'redux-saga/effects';
import authSagas from './auth/saga';
import brandsSagas from './brands/saga';
import globalSagas from './global/saga';
import storeSagas from './createStore/saga';
import storesSagas from './stores/saga';
import storeStatusSagas from './storeStatus/saga';
import usersSagas from './teams/saga';
import tokenSagas from './token/saga';

export default function* rootSaga() {
    yield all([authSagas(), globalSagas(), storeSagas(), storesSagas(), storeStatusSagas(), brandsSagas(), usersSagas(), tokenSagas()]);
}
