import {combineReducers} from 'redux';
import AuthReducer from './auth/reducer';
import BrandsReducer from './brands/reducer';
import GlobalReducer from './global/reducer';
import StoreReducer from './createStore/reducer';
import StoresReducer from './stores/reducer';
import StoreStatusReducer from './storeStatus/reducer';
import TeamsReducer from './teams/reducer';
import TokenReducer from './token/reducer';

const RootReducer = combineReducers({
    auth: AuthReducer,
    store: StoreReducer,
    stores: StoresReducer,
    storeStatus: StoreStatusReducer,
    brands: BrandsReducer,
    teams: TeamsReducer,
    token: TokenReducer,
    global: GlobalReducer,
});

export default RootReducer;

export type RootState = ReturnType<typeof RootReducer>;
