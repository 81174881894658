import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {classnames} from 'src/utils/general';
import './styles.sass';

interface Props {
    className?: string
    fill?: boolean
    size?: number
    inline?: boolean
    loadingText?: string
}

const antIcon = <LoadingOutlined spin />;

const Loading: React.FC<Props> = ({className, fill = false, size = 20, inline = false, loadingText}) => {

    const spin = <Spin className={className} indicator={antIcon} style={{fontSize: size}} tip={loadingText} />;

    const loadingClassName = classnames(
        'Loading',
        {'is-inline': inline}
    );

    return (
        <div className={loadingClassName}>
            {fill
                ? (
                    <div className='loading-backdrop'>
                        {spin}
                    </div>
                )
                : spin
            }
        </div>
    );
};

export default Loading;
