import {useField as formikUseField} from 'formik';
import {nanoid} from 'nanoid';
import React, {useCallback, useEffect, useState} from 'react';
import {FileError, FileRejection, useDropzone} from 'react-dropzone';
import './styles.sass';

interface DragAndDropProps {
    className?: string
    acceptedFiles?: Array<string>
    maxFileSize?: number
    maxFilesQuantity?: number
    validateFileHandler?: (file: File) => Promise<Array<FileError>>
    isAddToExistingFiles?: boolean
}

interface DragAndDropPropsWithFormik extends DragAndDropProps {
    name: string
    uploadHandler?: never
}

interface DragAndDropPropsWithOutFormik extends DragAndDropProps {
    uploadHandler: (file: Array<UploadableFile>) => void
    name?: never
}

export interface UploadableFile {
    id: string
    file: File
    errors: FileError[]
    isHaveErrors: boolean
    url?: string
}

const DropZone: React.FC<DragAndDropPropsWithFormik | DragAndDropPropsWithOutFormik> = ({
    children,
    className,
    name,
    maxFilesQuantity,
    validateFileHandler,
    maxFileSize,
    acceptedFiles,
    isAddToExistingFiles,
    uploadHandler,
}) => {
    const useField = name ? formikUseField : () => [];
    const [, , helpers] = useField(name!);
    const [files, setFiles] = useState<UploadableFile[]>([]);

    const onDrop = useCallback(async(accFiles: File[], rejFiles: FileRejection[]) => {
        const mappedAcc = accFiles.map(async(file) => {
            const errors = validateFileHandler ? await validateFileHandler(file) : [];
            return {
                file,
                id: nanoid(),
                errors,
                isHaveErrors: Boolean(errors.length),
            };
        });
        const mappedRej = rejFiles.map(async(file) => ({
            ...file,
            errors: validateFileHandler ? [...file.errors, ...(await validateFileHandler(file.file))] : [...file.errors],
            id: nanoid(),
            isHaveErrors: true,
        }));

        const mappedAccResolved = await Promise.all(mappedAcc);
        const mappedRejResolved = await Promise.all(mappedRej);
        if (isAddToExistingFiles) {
            setFiles((uploadableFile) => [...uploadableFile, ...mappedAccResolved, ...mappedRejResolved]);
        } else {
            setFiles([...mappedAccResolved, ...mappedRejResolved]);
        }
    }, [isAddToExistingFiles, validateFileHandler]);

    // --------------------- Use it If we need upload status of element in future -------------------
    // function onUpload(file: File, url: string) {
    //   setFiles((uploadableFiles) =>
    //     uploadableFiles.map((uploadableFile) => {
    //       if (uploadableFile.file === file) {
    //         return { ...uploadableFile, url }
    //       }
    //       return uploadableFile
    //     })
    //   )
    // }

    // --------------------- Use it If we need ability to delete file  in future -------------------
    // function onDelete(file: File) {
    //   setFiles((uploadableFiles) => uploadableFiles.filter((uploadableFile) => uploadableFile.file !== file))
    // }

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: acceptedFiles,
        maxSize: maxFileSize,
        maxFiles: maxFilesQuantity,
        multiple: maxFilesQuantity !== 1,
    });

    useEffect(() => {
        if (name) {
            helpers.setValue(files);
        }
        if (uploadHandler) {
            uploadHandler(files);
        }
    }, [files, helpers, uploadHandler, name]);
    return (
        <div {...getRootProps({className: ['dragAndDropContainer', className].join(' ')})}>
            <input {...getInputProps()} />
            {children ? (
                children
            ) : (
                <p className='title'>Drag&apos;n&apos;drop some files here, or click to select files</p>
            )}
        </div>
    );
    // <Dropzone onDrop={handleDrop} minSize={1024} maxSize={3072000}>
    //   {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
    //     const additionalClass = isDragAccept ? 'accept' : isDragReject ? 'reject' : ''

    //     return (
    //       <div
    //         {...getRootProps({
    //           className: 'dropzone',
    //         })}
    //       >
    //         <input {...getInputProps()} />
    //         <p className='dropZoneHeader'>Click or drag file to this area to upload</p>
    //         <p className='dropZoneText'>
    //           Single or bulk upload. Accepted formats - pdf, images, gif’s. No videos.
    //         </p>
    //       </div>
    //     )
    //   }}
    // </Dropzone>
};

export default DropZone;
