import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import './styles.sass';
import _ from 'lodash';

//icons
import {PlusOutlined} from '@ant-design/icons';
import {Pen, Note} from 'src/lib/svg-icon-helper';
import tipIcon from 'src/assets/icons/tip-hex.svg';
import ContactLeftConfetti from '/src/assets/images/confetti/contact-left.png';

//components
import {Switch, message} from 'antd';
import BrandRenderer from 'src/Components/BrandRenderer';
import Button from 'src/Components/Button';
import ControlHeader from 'src/Components/ControlHeader';
import DropdownButton, {Props as DropdownButtonProps} from 'src/Components/DropdownButton';
import ImportExportTeamModal from 'src/Views/Store/Teams/ImportExportTeamModal';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import Onboarding from 'src/Components/Onboarding';
import PopIcon from 'src/Components/PopIcon';
import {Prompt} from 'src/Components/ModalPrompt';
import Select from 'src/Components/Select';
import Table, {SortBy} from 'src/Components/Table';
import TagsEditable from 'src/Components/TagsEditable';
import TagsRenderer from 'src/Components/TagsRenderer';
import NoResults from 'src/Components/NoResults';
import EmptyState from 'src/Components/EmptyState';

//apis
import useContactsApi, {MultiEditContact} from 'src/Views/Store/Contacts/useContactsApi';
import {getSignedExportUrl} from 'src/api/ContactsExport/api-contacts-export';

//types
import {AlignType, ID, ReactEvent, ReactTextareaEvent} from 'src/Types/CommonTypes';
import {ApiBrand} from 'src/Types/Brand';
import {Contact, ContactRefTypes} from 'src/api/Contact/api-contact';
import {NormalizedTag, ApiTag} from 'src/Types/Tags/types';
import {Tag} from 'src/api/Tags/api-tags';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';
import {RootState} from 'src/redux/rootReducer';

//libs
import {Filters, removeFilterFactory} from 'src/lib/filter-helper';
import {_get, invisibleCharacter, classnames, getErrorMessage} from 'src/utils/general';
import useWindow from 'src/hooks/useWindow';
import {clearServerErrors} from 'src/redux/global/actions';

const unchangedMultiSelectId = `(Several)${invisibleCharacter}`;


interface PropTypes {
    brandCardId?: ID
    readOnly?: boolean
}

const Contacts = ({brandCardId, readOnly}: PropTypes): JSX.Element => {
    const stores = useSelector((state: RootState) => state.stores.stores);
    const {storeId} = useParams<{storeId: any}>();
    const store = stores?.find((currentStore) => `${currentStore.id}` === storeId);
    const {
        allContacts,
        contacts,
        isLoading,
        deleteContacts,
        filterByFirstName,
        setFilterByFirstName,
        saveContactsApi,
        refetchContacts,
        allBrands,
        tags,
        filters,
        filterData,
        setFilterData,
        filterValues,
        setFilterValues,
    } = useContactsApi(storeId, brandCardId);
    const {windowIsSmall, windowIsTiny} = useWindow();

    const [multiSelectItems, setMultiSelectItems] = useState<Contact[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ID[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [contactFirstName, setContactFirstName] = useState<string>('');
    const [contactLastName, setContactLastName] = useState<string>('');
    const [contactEmail, setContactEmail] = useState<string>('');
    const [contactPhone, setContactPhone] = useState<string>('');
    const [contactBrand, setSelectedContactBrand] = useState<BrandCard>();
    const [initialBrand, setInititalBrand] = useState<BrandCard>();
    const [contactRole, setSelectedContactRole] = useState<NormalizedTag[]>();
    const [contactUseKeepMail, setContactUseKeepMail] = useState<boolean>(false);
    const [contactNotes, setContactNotes] = useState<string>('');
    const [contactToEdit, setContactToEdit] = useState<Contact>();
    const [isSubmittingEditContacts, setIsSubmittingEditContacts] = useState<boolean>(false);
    const [isDeletingContact, setIsDeletingContact] = useState<boolean>(false);
    const [forceImportDropdownVisible, setForceImportDropdownVisible] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const shouldMultiEdit = multiSelectItems.length > 1 && !contactToEdit && !isCreating;
    const [sortBy, setSortBy] = useState<SortBy>({
        key: 'lastName',
        title: 'Last Name',
    });
    const serverErrors = useSelector((state: RootState) => state.global.serverErrors);
    const dispatch = useDispatch();

    useEffect(() => {
        const serverError = serverErrors
            && (getErrorMessage('contacts', serverErrors));

        if (serverError) {
            message.error(serverError, 4, () => {
                dispatch(clearServerErrors());
            });
        }
    }, [serverErrors]);

    const [isImportExportModalOpen, setIsImportExportModalOpen] = useState(false);
    const openImportModalHandler = () => {
        setIsImportExportModalOpen(true);
    };

    const exportContacts = async() => {
        const {url} = await getSignedExportUrl(storeId);
        window.open(`${MACRO_BASE_URL}${url.substring(4)}`, '_blank');
    };

    const closeImportModalHandler = () => {
        setIsImportExportModalOpen(false);
    };

    const handleCreateContact = () => {
        setShowEditModal(true);
        setIsCreating(true);
        setContactToEdit(undefined);
        setContactUseKeepMail(Boolean(brandCardId));
    };

    const handleEditContacts = () => {
        const testBrandCard = _.get(multiSelectItems, [0, 'brandCard']);
        if (_.every(multiSelectItems, (contact) => _.get(contact, 'brandCard.id') === _.get(testBrandCard, 'id'))) {
            setSelectedContactBrand(testBrandCard);
            setInititalBrand(testBrandCard);
        } else if (multiSelectItems.length) {
            setSelectedContactBrand({id: unchangedMultiSelectId});
            setInititalBrand(testBrandCard);
        }

        const testRoles = _.get(multiSelectItems, [0, 'roles']);
        const selectedContactRole = [];
        if (testRoles && testRoles.length) {
            for (const role of testRoles) {
                if (_.every(multiSelectItems, (contact) => {
                    const roleSet = new Set(_.map(contact.roles, 'id'));
                    return roleSet.has(role.id);
                })) {
                    selectedContactRole.push(role);
                }
            }
            setSelectedContactRole(selectedContactRole);
        }

        if (multiSelectItems.length === 1) {
            setContactFirstName(multiSelectItems[0].firstName);
            setContactLastName(multiSelectItems[0].lastName);
            setContactNotes(multiSelectItems[0].notes);
            setContactEmail(multiSelectItems[0].email);
            setContactPhone(multiSelectItems[0].phone);
            setContactUseKeepMail(multiSelectItems[0].useKeepMail);
        }

        setShowEditModal(true);
    };

    const hideEditModal = () => {
        setIsSubmittingEditContacts(false);
        setShowEditModal(false);
        setIsCreating(false);
        setContactToEdit(undefined);
        setContactFirstName('');
        setContactLastName('');
        setContactEmail('');
        setContactPhone('');
        setSelectedContactBrand(undefined);
        setSelectedContactRole(undefined);
        setContactNotes('');
        setContactUseKeepMail(false);
        setInititalBrand(undefined);
    };

    const handleDeleteContacts = async() => {
        await new Prompt().yesNoDanger(
            {
                body: multiSelectItems.length > 1 ? 'Are you sure you want to delete these contacts?' : 'Are you sure you want to delete this contact?',
                title: multiSelectItems.length > 1 ? 'Delete Contacts' : 'Delete Contact',
                displayType: 'delete',
                onYes: async() => {
                    await deleteContacts(_.map(multiSelectItems, 'id'));
                    setMultiSelectItems([]);
                },
            }
        );
    };

    const handleDeleteContact = async() => {
        await new Prompt().yesNoDanger(
            {
                body: 'Are you sure you want to delete this contact?',
                title: 'Delete Contact',
                displayType: 'delete',
                onYes: async() => {
                    setIsDeletingContact(true);
                    await deleteContacts([_.get(contactToEdit, 'id', '')]);
                    setIsDeletingContact(false);
                    hideEditModal();
                },
            }
        );
    };

    const handleSetContactFirstName = (event: ReactEvent) => {
        setContactFirstName(event.target.value);
    };

    const handleSetContactLastName = (event: ReactEvent) => {
        setContactLastName(event.target.value);
    };

    const handleSetContactEmail = (event: ReactEvent) => {
        setContactEmail(event.target.value);
    };

    const handleSetContactPhone = (event: ReactEvent) => {
        setContactPhone(event.target.value);
    };

    const handleSetContactBrand = (id: ID, selectedBrand: BrandCard) => {
        setSelectedContactBrand(selectedBrand);
        if (isCreating) {
            setContactUseKeepMail(Boolean(selectedBrand));
        }
    };

    const handleSelectRole = (UNUSED: any, selectedRole: ApiTag[]) => {
        setSelectedContactRole(selectedRole);
    };

    const handleSetContactNotes = (event: ReactTextareaEvent) => {
        setContactNotes(event.target.value);
    };

    const handleSetUseKeepMailChange = (value: boolean) => {
        setContactUseKeepMail(value);
    };

    const handleSubmitContactEdit = async() => {
        setIsSubmittingEditContacts(true);
        //these ignores cover when multiSelectItems have several brands, but the user does not wish to remove them
        const ignoreBrandUpdate = Boolean(contactBrand && contactBrand.id === unchangedMultiSelectId);
        const masterContact: MultiEditContact = {};
        const singleEdit = multiSelectItems.length <= 1;

        if (!ignoreBrandUpdate) {
            if (contactBrand) {
                masterContact.brandCardId = _get(contactBrand, 'id');
                masterContact.refType = ContactRefTypes.BrandCards;
                masterContact.storeId = null;
            } else {
                masterContact.storeId = storeId;
                masterContact.refType = ContactRefTypes.Store;
                masterContact.brandCardId = null;
            }
        }

        //creating from inside a brandCard, over-ride and set to brand-level
        if (isCreating && brandCardId) {
            masterContact.brandCardId = brandCardId;
            masterContact.refType = ContactRefTypes.BrandCards;
            masterContact.storeId = null;
        }

        if (singleEdit) {
            masterContact.firstName = contactFirstName || null;
            masterContact.lastName = contactLastName || null;
            masterContact.phone = contactPhone || null;
            masterContact.email = contactEmail || null;
            masterContact.notes = contactNotes || null;
            masterContact.useKeepMail = contactUseKeepMail;
        }

        const rolesAbleToBeRemoved = _.get(contactToEdit, 'roles', []);
        if (!contactToEdit) {
            const testRoles = _.get(multiSelectItems, [0, 'roles']);
            for (const role of (testRoles || [])) {
                if (_.every(multiSelectItems, (contact) => {
                    const roleSet = new Set(_.map(contact.roles, 'id'));
                    return roleSet.has(role.id);
                })) {
                    rolesAbleToBeRemoved.push(role);
                }
            }
        }

        try {
            await saveContactsApi(
                masterContact,
                _.cloneDeep(contactRole),
                rolesAbleToBeRemoved,
                contactToEdit ? [contactToEdit] : multiSelectItems
            );
            hideEditModal();
            setMultiSelectItems([]);
        } catch (ex) {
            setIsSubmittingEditContacts(false);
        }
    };

    const editContactsModalTitle = () => {
        let titleText = 'Create Contact';
        if (!isCreating) {
            if (shouldMultiEdit) {
                titleText = `Edit Contacts (${multiSelectItems.length})`;
            } else {
                titleText = 'Edit Contact';
            }
        }

        return (
            <div className='edit-contacts-modal-title'>
                {titleText}
            </div>
        );
    };

    const handleShowOnboarding = (onboardingKey: string) => {
        if (onboardingKey === 'importContact') {
            setForceImportDropdownVisible(true);
        } else {
            setForceImportDropdownVisible(false);
        }
    };

    const handleHideOnboarding = () => {
        setForceImportDropdownVisible(false);
    };

    const handleBodyClick = () => {
        if (forceImportDropdownVisible) {
            setForceImportDropdownVisible(false);
        }
    };

    const hasAnyContacts = Boolean(allContacts && allContacts.length);
    const hasContacts = Boolean(contacts && contacts.length);

    //filter options is raw input data
    const filterDependencies = {
        allContacts,
        tags,
        contacts,
        allBrands,
        brandFilterHasEmptyFilterOption: true,
    };

    const initFilters = (filterDependenciesInit = filterDependencies) => {
        const filterData: Filters = {};
        for (const [filterKey, filter] of Object.entries(filters)) {
            if (!filter.loadAfter && filter.show(filterDependenciesInit) && filter.enabled(filterDependenciesInit)) {
                filterData[filterKey] = filter.dataBuilder(filterDependenciesInit);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    useEffect(() => {
        if (hasAnyContacts && allContacts && tags && contacts && allBrands) {
            initFilters({
                allContacts,
                tags,
                contacts,
                allBrands,
                brandFilterHasEmptyFilterOption: true,
            });
        }
    }, [allContacts, tags, contacts, allBrands]);

    const shouldDisableContactEditSubmit = () => {
        return isSubmittingEditContacts
            || (
                !shouldMultiEdit
                && !contactFirstName
                && !contactLastName
                && !contactEmail
                && !contactPhone
            );
    };

    const computedClassName = classnames(
        'contact-manager',
        {'dashboard-container': !brandCardId},
        {'dashboard-container--no-content': !brandCardId && !hasAnyContacts}
    );

    const contactImportProps: DropdownButtonProps = {
        hasMainAction: true,
        menuId: 'contact-import-menu',
        items: [
            {
                key: 'import',
                onClick: openImportModalHandler,
                text: 'Import Contacts',
                disabled: readOnly,
            },
            {
                key: 'export',
                onClick: exportContacts,
                text: 'Export Contacts',
            },
        ],
    };

    if (forceImportDropdownVisible) {
        contactImportProps.open = true;
    }

    const locale = () => {
        if (hasAnyContacts) {
            return {emptyText: (<NoResults/>)};
        } else if (readOnly || brandCardId) {
            return {emptyText: (<EmptyState objectName='Contact' handleCreate={handleCreateContact} readOnly={readOnly}/>)};
        } else {
            return {emptyText: (
                <div
                    className={classnames(
                        'no-content',
                        {'no-content--is-small': windowIsSmall},
                        {'no-content--is-tiny': windowIsTiny}
                    )}>
                    <img className='no-content-background-image bottom-left' src={ContactLeftConfetti} />
                    <div className='no-content-header'>
                        Contacts are the lifeblood of business.
                    </div>
                    <div className='no-content-body'>
                        {'Bring all of the critical contacts across your team members into one place to empower an efficient \
                        workplace. Reps, sales managers, credit contacts, dealer services… they all have a place.'}
                        <Button className='btn-action' onClick={handleCreateContact}>Create Your First Contact</Button>
                    </div>
                </div>)};
        }
    };


    const handleMultiSelect = (ids: number[], selectedContacts: Contact[], {type}: Record<'type', string>) => {
        if (type === 'all') {
            if (multiSelectItems.length && !selectedContacts.length) {
                setMultiSelectItems([]);
                setSelectedRowKeys([]);
            } else if (contacts) {
                setMultiSelectItems(contacts);
                setSelectedRowKeys(_.map(contacts, 'id'));
            }
        } else {
            setMultiSelectItems(selectedContacts);
            setSelectedRowKeys(_.map(selectedContacts, 'id'));
        }
    };

    const columns = [{
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        width: windowIsTiny ? '15%' : '10%',
        ellipsis: true,
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        width: windowIsTiny ? '15%' : '10%',
        ellipsis: true,
    },
    {
        title: 'Brand Card',
        dataIndex: 'brandCard',
        width: '15%',
        key: 'brandCard',
        filtered: Boolean(brandCardId),
        ellipsis: true,
        render: (brandCard: ApiBrand) => <BrandRenderer brandCard={brandCard} origin='contacts' />,
    },
    {
        title: 'Role',
        dataIndex: 'roles',
        key: 'tag',
        width: windowIsTiny ? '18%' : '12%',
        filtered: windowIsTiny,
        ellipsis: true,
        render: (roles: Tag[]) => <TagsRenderer tags={roles} tagClassName='contact-tag' />,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
        render: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
    },
    {
        title: 'Phone Number',
        dataIndex: 'phone',
        key: 'phone',
        // filtered: windowIsTinyOnBrandCard,
        width: 125,
        ellipsis: true,
        render: (phone: string) => <a href={`tel:${phone}`}>{phone}</a>,
    },
    {
        title: 'KeepMail',
        dataIndex: 'useKeepMail',
        key: 'useKeepMail',
        filtered: !_.get(store, 'useKeepMail') || windowIsSmall,
        width: '6%',
        ellipsis: true,
        render: (useKeepMail: boolean) => useKeepMail ? 'ON' : 'OFF',
    },
    {
        title: 'Notes',
        key: 'notes',
        className: 'notes-td',
        align: AlignType.center,
        width: 60,
        // eslint-disable-next-line react/display-name
        render: (UNUSED: any, contact: Contact) => {
            return contact && contact.notes && (
                <PopIcon
                    className='action-button primary-color'
                    placement='bottomRight'
                    content={contact.notes}
                    origin={<span><Note /></span>} />
            );
        },
    }];

    if (!readOnly) {
        columns.push({
            key: 'actions',
            className: 'actions-td',
            title: '',
            align: AlignType.center,
            width: 55,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, contact: Contact) => (
                <div className='action-button primary-color' onClick={() => {
                    setShowEditModal(true);
                    setContactToEdit(contact);
                    setContactFirstName(contact.firstName);
                    setContactLastName(contact.lastName);
                    setContactPhone(contact.phone);
                    setContactEmail(contact.email);
                    setSelectedContactBrand(_get(contact, 'brandCard'));
                    setInititalBrand(_get(contact, 'brandCard'));
                    setSelectedContactRole(contact.roles);
                    setContactNotes(_get(contact, 'notes', ''));
                    setContactUseKeepMail(contact.useKeepMail || false);
                }}>
                    <Pen />
                </div>
            ),
        });
    }

    const limitedBrands = _.filter(allBrands, (brandCard) => !brandCard.isLimitedByPlan);

    return (
        <>
            {!readOnly && (
                <Onboarding onboardType='contacts' onShowOnboarding={handleShowOnboarding} onHideOnboarding={handleHideOnboarding} />
            )}
            <div className={computedClassName} onClick={handleBodyClick}>
                <div className='contact-manager-header'>
                    <ControlHeader
                        setSearch={setFilterByFirstName}
                        search={filterByFirstName}
                        disableSearch={!contacts || isLoading}
                        multiSelectActions={[
                            {
                                key: 'edit',
                                onClick: handleEditContacts,
                                text: 'Edit',
                                disabled: readOnly || !multiSelectItems.length,
                            },
                            {
                                key: 'delete',
                                onClick: handleDeleteContacts,
                                text: 'Delete',
                                disabled: readOnly || !multiSelectItems.length,
                            },
                        ]}
                        placeholder='Search by contact name'
                        multiSelectActionsId='multi-select-contact-button'
                        handleSetFilter={handleSetFilter}
                        handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                        filterValues={filterValues}
                        filterData={filterData}
                        filters={filters}
                        showSort={hasContacts}
                        sortOptions={[
                            {
                                key: 'brandCard.name',
                                title: 'Brand Card',
                            },
                            {
                                key: 'email',
                                title: 'Email',
                            },
                            {
                                key: 'firstName',
                                title: 'First Name',
                            },
                            {
                                key: 'lastName',
                                title: 'Last Name',
                            },
                        ]}
                        setSortOption={setSortBy}
                        activeSort={sortBy} />
                    {brandCardId && (
                        <Button
                            onClick={handleCreateContact}
                            disabled={readOnly}
                            className='create-contact-button btn-action'>
                            <PlusOutlined className='icon left-icon' />Contact
                        </Button>
                    )}
                    {!brandCardId && (
                        <div className='import-contact-button-wrapper' id='create-contact-button'>
                            <DropdownButton {...contactImportProps}>
                                <Button
                                    onClick={handleCreateContact}
                                    disabled={readOnly}
                                    className='create-contact-button btn-action dropdown-action-button'>
                                    <PlusOutlined className='icon left-icon' />Contact
                                </Button>
                            </DropdownButton>
                        </div>
                    )}
                    <ImportExportTeamModal
                        open={isImportExportModalOpen}
                        onCancel={closeImportModalHandler}
                        refetch={refetchContacts} />
                </div>
                {isLoading && (
                    <Loading fill size={56} />
                )}
                {!isLoading && (
                    <Table
                        locale={locale()}
                        columns={columns}
                        shouldScroll={true}
                        data={contacts}
                        id={brandCardId ? 'BRAND_CARD_CONTACT_TABLE' : 'CONTACT_TABLE'}
                        useCheckboxes={true}
                        onCheckboxClick={handleMultiSelect}
                        selectedRowKeys={selectedRowKeys}
                        sortBy={sortBy}
                        scrollParentSelector='.contact-table .ant-table-body'
                        className='contact-table' />
                )}
                <Modal
                    className='edit-contact-modal'
                    open={showEditModal}
                    title={editContactsModalTitle()}
                    footer={false}
                    onCancel={hideEditModal}
                    destroyOnClose>
                    <div className='modal-body'>
                        {!shouldMultiEdit && (
                            <>
                                <Input
                                    name='contact-first-name'
                                    label='First Name'
                                    onChange={handleSetContactFirstName}
                                    value={contactFirstName} />
                                <Input
                                    name='contact-last-name'
                                    label='Last Name'
                                    onChange={handleSetContactLastName}
                                    value={contactLastName} />
                                <Input
                                    name='contact-email'
                                    label='Email'
                                    onChange={handleSetContactEmail}
                                    value={contactEmail} />
                                <Input
                                    name='contact-phone'
                                    label='Phone'
                                    onChange={handleSetContactPhone}
                                    value={contactPhone} />
                            </>
                        )}
                        {!(isCreating && brandCardId) && (
                            <div>
                                <div className='pop-label'>
                                    <label className='form-label' htmlFor='contact-brand'>
                                        Brand Card
                                    </label>
                                    <PopIcon
                                        className='btn-tip'
                                        type='tip'
                                        content={shouldMultiEdit
                                            ? 'The Brand Card field is a dropdown menu with all of your created brand cards. Choosing a brand will associate this contact to that particular brand and make it viewable in the Brand Card. If this field says ‘several’ it means your selected contacts are associated with different brands and any changes will uniformly be applied to all.'
                                            : 'The Brand Card field is a dropdown menu with all of your created brand cards. Choosing a brand will associate this contact to that particular brand and make it viewable in the Brand Card.'
                                        }
                                        origin={<img src={tipIcon} />} />
                                </div>
                                <Select
                                    notFoundContent={allBrands?.length
                                        ? (<div>No results found</div>)
                                        : (<div>No Brands added yet</div>)}
                                    className='contact-brand-select'
                                    id='contact-brand'
                                    message={((_.get(initialBrand, 'id') === _.get(contactBrand, 'id')) || !brandCardId)
                                        ? undefined
                                        : `Once this action is confirmed, ${shouldMultiEdit ? 'these contacts' : 'this contact'} will no longer be viewable in the ${_.get(initialBrand, 'name')} Brand Card.`}
                                    labelKey='name'
                                    expectScroll={!shouldMultiEdit}
                                    onChange={handleSetContactBrand}
                                    options={limitedBrands}
                                    valueKey='id'
                                    value={contactBrand} />
                            </div>
                        )}
                        <div>
                            <div className='pop-label'>
                                <label className='form-label' htmlFor='tags'>
                                    Role
                                </label>
                                <PopIcon
                                    className='btn-tip'
                                    type='tip'
                                    content={shouldMultiEdit
                                        ? 'Contact Role tags allow you to organize your contacts. Some common contact roles are: Sales Manager, Primary Rep, Credit Manager, Brand President, Marketing Manager. If a tag is showing up here, it means it is applied to ALL of your selected contacts. Adding tags to multiple contacts at once will not remove existing tags that may only be applied to a few.'
                                        : 'Contact Role tags allow you to organize your contacts. Some common contact roles are: Sales Manager, Primary Rep, Credit Manager, Brand President, Marketing Manager.'
                                    }
                                    origin={<img src={tipIcon} />} />
                            </div>
                            <TagsEditable
                                notFoundContent={(
                                    <div>No Roles added yet</div>
                                )}
                                tags={tags}
                                placeholder='Role'
                                id='tags'
                                isEdit={true}
                                expectScroll={!shouldMultiEdit}
                                onChange={handleSelectRole}
                                selectedTags={contactRole} />
                        </div>
                        {!shouldMultiEdit && _.get(store, 'useKeepMail') && (
                            <div className='keepmail-switch'>
                                <label htmlFor='useKeepMail' className='form-label use-keepmail-label'>
                                    KeepMail
                                </label>
                                <PopIcon
                                    className='keepmail-info btn-tip'
                                    popClassName='keepmail-info-pop'
                                    type='tip'
                                    content='If KeepMail Automation is turned on - when emails with files are sent from this contact to the store KeepMail address, BrandKeep will automatically take any files 25 MB or less and place them in the ‘All Assets’ section of the associated Brand Card.'
                                    origin={<img src={tipIcon} /> } />
                                <div className='use-keepmail-toggle'>
                                    <Switch
                                        disabled={!contactBrand && !brandCardId}
                                        checked={contactUseKeepMail}
                                        onChange={handleSetUseKeepMailChange} />
                                </div>
                            </div>
                        )}
                        {!shouldMultiEdit && (
                            <div>
                                <div className='pop-label'>
                                    <label className='form-label' htmlFor='notes'>
                                        Notes
                                    </label>
                                    <PopIcon
                                        className='btn-tip'
                                        type='tip'
                                        content='Use notes to keep track of useful information that can be viewed by you and the rest of your team.'
                                        origin={<img src={tipIcon} />} />
                                </div>
                                <textarea
                                    id='notes'
                                    className='form-textarea'
                                    maxLength={250}
                                    value={contactNotes}
                                    placeholder='Add Notes (250 characters max)'
                                    onChange={handleSetContactNotes} />
                            </div>
                        )}
                    </div>
                    <div className='modal-footer'>
                        {(!isCreating && !shouldMultiEdit) && (
                            <Button
                                onClick={handleDeleteContact}
                                className='btn-delete'>
                                {isDeletingContact && (
                                    <Loading inline />
                                )}
                                Delete Contact
                            </Button>
                        )}
                        <div
                            className='cancel-button'
                            onClick={hideEditModal}>
                            Cancel
                        </div>
                        <Button
                            disabled={shouldDisableContactEditSubmit()}
                            className='btn-secondary'
                            onClick={handleSubmitContactEdit}>
                            {isSubmittingEditContacts && (
                                <Loading inline />
                            )}
                            Save
                        </Button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Contacts;
