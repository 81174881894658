import {ID} from 'src/Types/CommonTypes';

//standardized item, could be from an asset, task, user, contact
interface Item {
    firstName?: string | null
    lastName?: string | null
    email?: string | null
}

interface Auth {
    id: ID
    name?: string | null
    familyName?: string | null
    email: string
}


export const nameFormatter = (nameObj?: Item, emailBackup = true): string => {
    if (nameObj) {
        if (nameObj.firstName || nameObj.lastName) {
            return [nameObj.firstName, nameObj.lastName].join(' ');
        } else if (emailBackup && nameObj.email) {
            return nameObj.email;
        }
    }
    return '';
};

export const authNameFormatter = (nameObj?: Auth, emailBackup = true): string | false => {
    if (nameObj) {
        if (nameObj.name || nameObj.familyName) {
            return [nameObj.name, nameObj.familyName].join(' ');
        } else if (emailBackup && nameObj.email) {
            return nameObj.email;
        }
    }
    return false;
};
