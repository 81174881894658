import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {RootState} from 'src/redux/rootReducer';
import _ from 'lodash';
import './styles.sass';

//icons
import {LinkOutlined, PlusOutlined} from '@ant-design/icons';
import infoIcon from 'src/assets/icons/info.svg';
import CenterConfetti from '/src/assets/images/confetti/center.png';

//components
import {Checkbox, Radio, RadioChangeEvent, message} from 'antd';
import BrandCardForm from 'src/Components/BrandModal/BrandCardForm';
import BrandModal from 'src/Components/BrandModal';
import BrandTile from 'src/Components/BrandTile';
import BrandRenderer from 'src/Components/BrandRenderer';
import Button from 'src/Components/Button';
import Callout from 'src/Components/Callout';
import ControlHeader from 'src/Components/ControlHeader';
import DropdownButton, {Props as DropdownButtonProps} from 'src/Components/DropdownButton';
import ImportExportBrandModal from 'src/Views/Store/Brands/ImportExportBrandModal';
// import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import PopIcon from 'src/Components/PopIcon';
import ModalPrompt, {Prompt} from 'src/Components/ModalPrompt';
import Table, {SortBy} from 'src/Components/Table';
import TagsRenderer from 'src/Components/TagsRenderer';
import NoResults from 'src/Components/NoResults';
import EmptyState from 'src/Components/EmptyState';

//apis
import {getStoreRolesInfo, StoreInfoApi} from 'src/api/StoreRole/api-store-role';
import {BrandCard as BrandCardType, getApiKeyForBrandCard, populateMarketingLogos} from 'src/api/BrandCard/api-brand-card';
import useBrandCards from 'src/hooks/api-hooks/useBrandCards';
import {NewContentContext} from 'src/hooks/useNewContent';
import {removeAssetAssociations} from 'src/api/Assets/api-asset';
import {getSignedExportUrl} from 'src/api/BrandCardExport/api-brand-card-export';

//types
import {LinkRefTypes} from 'src/api/Link/api-link';
import {ID} from 'src/Types/CommonTypes';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';

//libs
import {Filters, removeFilterFactory} from 'src/lib/filter-helper';
import {buildUrlFromPartial, classnames, _get} from 'src/utils/general';
import useWindow from 'src/hooks/useWindow';
import Onboarding from 'src/Components/Onboarding';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import {AuthRoutes} from 'src/constants/userRoles';
import {Kabob} from 'src/lib/svg-icon-helper';
import Input from 'src/Components/Input';

const Brands: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isImportExportModalOpen, setIsImportExportModalOpen] = useState(false);
    const [viewType, setViewType] = useState<'grid' | 'table'>('table');
    const [userStoreInfo, setUserStoreInfo] = useState<StoreInfoApi>();
    const [showCreateBrandCardModal, setShowCreateBrandCardModal] = useState(false);
    const [multiSelectItems, setMultiSelectItems] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ID[]>([]);
    const [shouldDeleteBrandAssets, setShouldDeleteBrandAssets] = useState(false);
    const [showMultiSelectDeleteModal, setShowMultiSelectDeleteModal] = useState(false);
    const [brandIdForEdit, setBrandIdForEdit] = useState<ID>();
    const apiKeyRef= useRef<HTMLInputElement>(null);
    const {storeId} = useParams<{storeId: string}>();
    const userId = useSelector((state: RootState) => state.auth.id);
    const storeStatuses = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const currentStoreStatus = storeStatuses?.find((status: StoreStatus) => `${status.id}` === storeId);
    const [forceImportDropdownVisible, setForceImportDropdownVisible] = useState<boolean>(false);
    const readOnly = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_read_only';
    const {windowIsSmall, windowIsTiny} = useWindow();
    const {newContent} = useContext(NewContentContext);
    const [sortBy, setSortBy] = useState<SortBy>({
        key: 'name',
        title: 'Name',
    });

    useDocumentTitle('Brands');

    const handleCreateBrandCard = () => {
        setShowCreateBrandCardModal(true);
    };

    const openImportModalHandler = () => {
        setIsImportExportModalOpen(true);
    };

    const closeImportModalHandler = () => {
        setIsImportExportModalOpen(false);
    };

    const {
        isLoading,
        allBrandCards,
        // setNextPage,
        brandCards,
        refetchBrands,
        deleteBrandCards,
        setSearch,
        search,
        brandTags,
        workspaces,
        filters,
        filterData,
        setFilterData,
        filterValues,
        setFilterValues,
    } = useBrandCards(storeId, navigate);

    //filter options is raw input data
    const filterDependencies = {
        allBrands: allBrandCards,
        brandTags,
        workspaces,
    };

    const initFilters = () => {
        const filterData: Filters = {};
        for (const [filterKey, filter] of Object.entries(filters)) {
            if (!filter.loadAfter && filter.show(filterDependencies)) {
                filterData[filterKey] = filter.dataBuilder(filterDependencies);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    const handleBrandCardClose = () => {
        //clear out location state
        window.history.replaceState({}, document.title);
    };

    useEffect(() => {
        const fetchUserStoreInfoTags = async() => {
            const userInfo = await getStoreRolesInfo(storeId, userId);
            setUserStoreInfo(userInfo[0]);
        };
        fetchUserStoreInfoTags();
        if (_.get(location, 'state.from') === 'brandCard') {
            handleBrandCardClose();
        }
    }, [storeId, userId]);

    useEffect(() => {
        if (filters
            && _.every(Object.values(filterDependencies))
        ) {
            initFilters();
        }
    }, [brandCards, brandTags, workspaces, filters]);

    const BrandModalComponent = useMemo(
        () => (
            <BrandModal
                open={showCreateBrandCardModal}
                onCancel={() => setShowCreateBrandCardModal(false)}
                onCreate={(id: string | number) => {
                    setShowCreateBrandCardModal(false);
                    navigate(`${AuthRoutes.storeBrands(storeId)}/${id}`);
                }}
                storeId={storeId} />
        ),
        [showCreateBrandCardModal, storeId]
    );

    const handleCloseEditModal = () => {
        setBrandIdForEdit(undefined);
    };

    const handleSetView = (event: RadioChangeEvent) => {
        setViewType(event.target.value);
    };

    const handleShowOnboarding = (onboardingKey: string) => {
        if (onboardingKey === 'importBrand') {
            setForceImportDropdownVisible(true);
        } else {
            setForceImportDropdownVisible(false);
        }
    };

    const handleHideOnboarding = () => {
        setForceImportDropdownVisible(false);
    };

    const handleBodyClick = () => {
        if (forceImportDropdownVisible) {
            setForceImportDropdownVisible(false);
        }
    };

    const handleDeleteBrandCards = () => {
        setShowMultiSelectDeleteModal(true);
    };

    const exportBrands = async() => {
        const {url} = await getSignedExportUrl(storeId);
        window.open(`${MACRO_BASE_URL}${url.substring(4)}`, '_blank');
    };

    const handlePopulateMarketingLogos = async() => {
        const hide = message.loading('Getting the latest brand logos...', 0);
        await populateMarketingLogos(storeId);
        hide();
        message.success('Your brand logos all up to date!', 5);
    };

    const handleGenerateApiKey = async(brandCard: BrandCardType) => {
        const response = await getApiKeyForBrandCard(storeId, brandCard.id);
        await new Prompt().notice(
            {
                body: (
                    <div>
                        <Input
                            className='copy-cursor'
                            onClick={() => apiKeyRef?.current?.select()}
                            label='Use this key in your application to send files to BrandKeep!'
                            readOnly
                            ref={apiKeyRef}
                            value={response.key} />
                    </div>
                ),
                title: 'BrandCard API Token',
                displayType: 'notice',
                props: {confirmText: 'Close'},
            }
        );
    };

    const handleMultiSelect = (ids: number[], selectedBrands: BrandCardType[], {type}: Record<'type', string>) => {
        if (type === 'all') {
            const filteredBrands = _.filter(brandCards, (brandCard) => !brandCard.isLimitedByPlan);
            if (multiSelectItems.length && (multiSelectItems.length === filteredBrands.length)) {
                setMultiSelectItems([]);
                setSelectedRowKeys([]);
            } else {
                setMultiSelectItems(filteredBrands);
                setSelectedRowKeys(_.map(filteredBrands, 'id'));
            }
        } else {
            const filteredBrands = _.filter(selectedBrands, (brandCard) => !brandCard.isLimitedByPlan);
            setMultiSelectItems(filteredBrands);
            setSelectedRowKeys(_.map(filteredBrands, 'id'));
        }
    };

    const hasAnyBrandCards = Boolean(allBrandCards && allBrandCards.length > 0);
    const hasBrandCards = Boolean(brandCards && brandCards.length > 0);

    const brandCardsAreLimitedByPlan = _.get(currentStoreStatus, 'areBrandCardsAtLimit') || _.some(allBrandCards, 'isLimitedByPlan');

    const brandImportProps: DropdownButtonProps = {
        hasMainAction: true,
        menuId: 'brand-import-menu',
        items: [
            {
                key: 'import',
                onClick: openImportModalHandler,
                text: 'Import Brand Cards',
                disabled: readOnly || brandCardsAreLimitedByPlan,
            },
            {
                key: 'export',
                onClick: exportBrands,
                text: 'Export Brand Cards',
            },
            {
                key: 'populateMarketingLogos',
                onClick: handlePopulateMarketingLogos,
                text: 'Get Brand Logos',
                visible: false, //feature disabled for now, likely to come back
            },
        ],
    };

    if (forceImportDropdownVisible) {
        brandImportProps.open = true;
    }

    const locale = () => {
        if (hasAnyBrandCards) {
            return {emptyText: (<NoResults/>)};
        } else if (readOnly) {
            return {emptyText: (<EmptyState objectName='Brand Card' readOnly={readOnly}/>)};
        } else {
            return {emptyText: (
                <div
                    className={classnames(
                        'no-content',
                        {'no-content--is-small': windowIsSmall},
                        {'no-content--is-tiny': windowIsTiny}
                    )}>
                    <div className='no-content-header'>
                        {'Brand Cards keep your team organized. Use Brand Cards to store each brand’s workbooks, \
                        key assets, contacts, important links, terms, MAP policies, and more.'}
                    </div>
                    <div className='no-content-body'>
                        Create workspaces within cards to organize by season, or any way that works for your team.
                        <div className='no-content--brand-card-btn'>
                            <img className='no-content-background-image center' src={CenterConfetti} />
                            <Button className='btn-action' onClick={handleCreateBrandCard}>Create Your First Brand Card</Button>
                        </div>
                    </div>
                </div>)};
        }
    };

    return (
        <div className='brand-dashboard' onClick={handleBodyClick}>
            {!readOnly && (
                <Onboarding onboardType='brands' onShowOnboarding={handleShowOnboarding} onHideOnboarding={handleHideOnboarding} />
            )}
            <div
                className={classnames(
                    'dashboard-container brand-dashboard-container',
                    {'dashboard-container--no-content': !hasAnyBrandCards}
                )}>
                <div className='brand-dashboard-header'>
                    <ControlHeader
                        setSearch={setSearch}
                        search={search}
                        disableSearch={!brandCards || isLoading}
                        multiSelectActions={[
                            {
                                key: 'delete',
                                onClick: handleDeleteBrandCards,
                                text: 'Delete',
                                disabled: readOnly || !multiSelectItems.length,
                            },
                        ]}
                        placeholder='Search by brand name'
                        filterButtonId='filter-brand-button'
                        handleSetFilter={handleSetFilter}
                        handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                        filterValues={filterValues}
                        filterData={filterData}
                        filters={filters}
                        showSort={hasBrandCards}
                        sortOptions={[
                            {
                                key: 'accountNumber',
                                title: 'Account #',
                            },
                            {
                                key: 'createdAt',
                                title: 'Date Created',
                                isReversed: true,
                            },
                            {
                                key: 'name',
                                title: 'Name',
                            },
                        ]}
                        setSortOption={setSortBy}
                        activeSort={sortBy} />

                    <div className='controls'>
                        <Radio.Group
                            className='brandcard-view-toggle'
                            value={hasAnyBrandCards ? viewType : 'table'}
                            onChange={handleSetView}
                            buttonStyle='solid'>
                            <Radio.Button value='grid' disabled={hasAnyBrandCards === false}>Grid</Radio.Button>
                            <Radio.Button value='table' disabled={hasAnyBrandCards === false}>List</Radio.Button>
                        </Radio.Group>
                        <div className='import-brand-button-wrapper' id='create-brand-button'>
                            <DropdownButton {...brandImportProps}>
                                <Button
                                    onClick={handleCreateBrandCard}
                                    className='create-brand-button btn-action dropdown-action-button'
                                    disabled={readOnly || brandCardsAreLimitedByPlan}>
                                    <PlusOutlined className='icon left-icon' />Brand Card
                                </Button>
                            </DropdownButton>
                        </div>
                    </div>
                    <ImportExportBrandModal
                        open={isImportExportModalOpen}
                        onCancel={closeImportModalHandler}
                        refetch={refetchBrands} />
                </div>
                {!isLoading && (
                    <>
                        {viewType === 'table' && (
                            <Table
                                locale={locale()}
                                className='brand-dashboard-table'
                                columns={[
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                        key: 'name',
                                        ellipsis: true,
                                        render: (UNUSED: any, brandCard: BrandCardType) => {
                                            return <BrandRenderer
                                                brandCard={brandCard}
                                                className={_.some(newContent, (asset) => Number(asset.brandCardId || asset.brand?.id) === Number(brandCard.id))
                                                    ? 'new-content-bubble'
                                                    : undefined
                                                } />;
                                        },
                                    },
                                    {
                                        title: 'Account no.',
                                        dataIndex: 'accountNumber',
                                        key: 'accountNumber',
                                        filtered: windowIsTiny,
                                        width: '10%',
                                        ellipsis: true,
                                    },
                                    {
                                        title: 'Tags',
                                        dataIndex: 'tags',
                                        key: 'tag',
                                        width: windowIsTiny ? '20%' : '12%',
                                        ellipsis: true,
                                        render: (tags) => <TagsRenderer tags={tags} tagClassName='asset-tag' />,
                                    },
                                    {
                                        title: 'Workspaces',
                                        dataIndex: 'workspaces',
                                        key: 'workspaces',
                                        filtered: windowIsTiny,
                                        width: '14%',
                                        ellipsis: true,
                                        render: (workspaces) => <TagsRenderer tags={workspaces} tagClassName='workspace-tag' title='Workspaces' />,
                                    },
                                    {
                                        title: 'Date Created',
                                        dataIndex: 'createdAt',
                                        key: 'createdAt',
                                        filtered: windowIsTiny,
                                        width: 105,
                                        ellipsis: true,
                                        render: (createdAt) => {
                                            const createDate = new Date(createdAt);
                                            return `${(createDate.getMonth() + 1)}/${createDate.getDate()}/${createDate.getFullYear()}`;
                                        },
                                    },
                                    {
                                        title: 'Quick Links',
                                        dataIndex: 'links',
                                        key: 'links',
                                        width: windowIsTiny ? '32%' : '25%',
                                        ellipsis: true,
                                        render: (links) => {
                                            const quickLinks = links && links.length
                                                ? _.filter(links, {refType: LinkRefTypes.QuickLink})
                                                : [];
                                            return Boolean(quickLinks.length) && (
                                                <span className='quick-links'>
                                                    <LinkOutlined />
                                                    {quickLinks.map((link) => (
                                                        <a className='quick-link single-line-ellipsis'
                                                            key={link.id}
                                                            href={buildUrlFromPartial(link.url)}
                                                            target='_blank'
                                                            rel='noreferrer'>
                                                            {_.upperFirst(link.name)}
                                                        </a>
                                                    ))}
                                                </span>
                                            );
                                        },
                                    },
                                    {
                                        key: 'actions',
                                        className: 'actions-td',
                                        title: '',
                                        width: 55,
                                        // eslint-disable-next-line react/display-name
                                        render: (UNUSED: any, brandCard: BrandCardType) => (
                                            <DropdownButton
                                                className='action-button primary-color'
                                                placement='bottomRight'
                                                items={[
                                                    {
                                                        key: 'get_api',
                                                        onClick: () => handleGenerateApiKey(brandCard),
                                                        text: 'Get API Key',
                                                    },
                                                ]}>
                                                <Kabob />
                                            </DropdownButton>
                                        ),
                                    },
                                ]}
                                id='BRAND_DASHBOARD_TABLE'
                                scrollParentSelector='.brand-dashboard-table .ant-table-body'
                                rowClassName={(brandCard) => brandCard.isLimitedByPlan ? 'disabled-table-row' : undefined}
                                data={brandCards}
                                shouldScroll={true}
                                useCheckboxes={true}
                                onCheckboxClick={handleMultiSelect}
                                selectedRowKeys={selectedRowKeys}
                                sortBy={sortBy} />
                        )}
                        {viewType === 'grid' && (
                            <div className='cards'>
                                {brandCards && brandCards.map((brandCard: BrandCardType) => {
                                    return (
                                        <BrandTile
                                            className={_.some(newContent, (asset) => Number(asset.brandCardId || asset.brand?.id) === Number(brandCard.id))
                                                ? 'new-content-bubble'
                                                : undefined
                                            }
                                            id={brandCard.id}
                                            key={brandCard.id}
                                            brandName={brandCard.name || ''}
                                            editBrand={() => storeId && navigate(`${AuthRoutes.storeBrands(storeId)}/${brandCard.id}`)} />
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
            {isLoading && (
                <Loading fill size={56} />
            )}
            <Modal
                className='create-brandcard-modal'
                width='1100px'
                open={Boolean(brandIdForEdit)}
                title={<div className='edit-brand-card-title'>Edit Brand Card</div>}
                footer={false}
                onCancel={handleCloseEditModal}
                maskClosable={false}
                destroyOnClose>
                {brandIdForEdit && (
                    <BrandCardForm
                        storeId={storeId}
                        brandId={brandIdForEdit}
                        refetchBrands={refetchBrands}
                        onDelete={() => {
                            setShowMultiSelectDeleteModal(true);
                        }}
                        onCancel={handleCloseEditModal} />
                )}
            </Modal>
            <ModalPrompt
                body={
                    <span className='delete-brands-prompt'>
                        {(!brandIdForEdit && multiSelectItems.length > 1)
                            ? 'Are you sure you want to delete these brand cards? '
                            : 'Are you sure you want to delete this brand card? '
                        }
                        All associated contacts and tasks will also be deleted.
                        <Callout type='danger'>
                            <Checkbox
                                value='delete'
                                className='single-line-ellipsis'
                                onChange={() => setShouldDeleteBrandAssets((shouldDeleteBrandAssets) => !shouldDeleteBrandAssets)}
                                checked={shouldDeleteBrandAssets}>
                                Delete associated assets.
                            </Checkbox>
                            <PopIcon
                                type='info'
                                placement='bottomRight'
                                content={
                                    <>
                                        <div>
                                        By default, BrandKeep will move assets from a deleted workspace to the ‘All’ tab so they are still accessible.
                                        Checking the box to ‘Delete associated assets’ will delete them along with the workspace and they will no longer be available.
                                        </div>
                                    </>
                                }
                                origin={<div className='btn btn-circle btn-white btn-icon-small'><img className='svg-icon' src={infoIcon} /></div>} />
                        </Callout>
                    </span>
                }
                title={(!brandIdForEdit && multiSelectItems.length > 1) ? 'Delete Brand Cards' : 'Delete Brand Card'}
                show={showMultiSelectDeleteModal}
                displayType='delete'
                onNo={() => {
                    setShouldDeleteBrandAssets(false);
                    setShowMultiSelectDeleteModal(false);
                }}
                onYes={async() => {
                    const brandCardIds: ID[] = brandIdForEdit
                        ? [brandIdForEdit]
                        : _.map(multiSelectItems, 'id');
                    if (brandIdForEdit) {
                        handleCloseEditModal();
                    }
                    if (!shouldDeleteBrandAssets) {
                        //DONT delete assets, disassociate them from brandcard
                        await removeAssetAssociations(storeId, brandCardIds);
                    }
                    await deleteBrandCards(brandCardIds);
                    setMultiSelectItems([]);
                    setSelectedRowKeys([]);
                    setShowMultiSelectDeleteModal(false);
                    setShouldDeleteBrandAssets(false);
                }} />
            {BrandModalComponent}
        </div>
    );
};

export default Brands;
