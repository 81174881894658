import {useFormik} from 'formik';
import React, {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import logo from 'src/assets/images/logo-stacked.svg';
import mark from 'src/assets/images/mark.png';
import Button from 'src/Components/Button';
import Input from 'src/Components/Input';
import useSubmit from 'src/Views/Login/Forms/SignUpForm/useSubmit';
import useValidationSchema from 'src/Views/Login/Forms/SignUpForm/useValidationSchema';
import {getQueryParams} from 'src/lib/url';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import './styles.sass';
import Loading from 'src/Components/Loading';
import _ from 'lodash';

const SignUpForm: React.FC = () => {
    const {submitHandler, message, isSuccess, resendInviteHandler, isResent} = useSubmit();
    const navigate = useNavigate();
    const queryParams = getQueryParams(useLocation());
    const initialValues = {email: queryParams.email, name: queryParams.firstName, secondName: queryParams.lastName, password: ''};

    useDocumentTitle('Sign Up');

    const signIn = () => {
        navigate('/signIn');
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto',
            });
        }, 100);
    }, []);

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues,
        validationSchema: useValidationSchema,
        onSubmit: submitHandler,
    });

    if (message && isSuccess) {
        window.scrollTo(0, 0);
        return (
            <>
                <div className='formBackground' />
                <div className='formWrapper'>
                    <div className='formBody'>
                        <a href='https://www.brandkeep.com'>
                            <img src={logo} className='logo' />
                        </a>
                        <div className='formHeader-center'>Please verify your email address</div>
                        <div className='message-verify'>
                            {message}
                        </div>
                        <div className='formFooter'>
                            {isResent
                                ? (<div>Sent!</div>)
                                : (<span className='link' onClick={resendInviteHandler}>
                                    Resend Email
                                </span>)
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className='signUpBackground' />
            <a href='https://www.brandkeep.com'>
                <img src={mark} className='homeMark' />
            </a>
            <div className='signUpWrapper'>
                <div className='header'>Get started for free.</div>
                <div className='subTitle'>BrandKeep is a retailer&apos;s home for everything. No credit card required.</div>
                <div className='container'>
                    <div className='formBody'>
                        <div className='form'>
                            <form onSubmit={handleSubmit} id='sign-up-form'>
                                <div>
                                    <div className='inputWrapper'>
                                        <Input
                                            errors={errors.name && touched.name && errors.name}
                                            autoFocus
                                            required
                                            placeholder='Jill'
                                            label='First'
                                            {...getFieldProps('name')} />
                                    </div>
                                    <div className='inputWrapper'>
                                        <Input
                                            errors={errors.secondName && touched.secondName && errors.secondName}
                                            required
                                            placeholder='Smith'
                                            label='Last'
                                            {...getFieldProps('secondName')} />
                                    </div>
                                    <div className='inputWrapper'>
                                        <Input
                                            errors={errors.email && touched.email && errors.email}
                                            required
                                            placeholder='youremail@example.com'
                                            label='Email'
                                            {...getFieldProps('email')} />
                                    </div>
                                    <div className='inputWrapper'>
                                        <Input
                                            errors={errors.password && touched.password && errors.password}
                                            required
                                            placeholder='*******'
                                            type='password'
                                            label='Password'
                                            {...getFieldProps('password')} />
                                    </div>
                                </div>
                                <div className='error'>{message}</div>
                                <Button
                                    type='submit'
                                    className='btn-secondary submit-button'
                                    form='sign-up-form'
                                    disabled={isSubmitting || !_.isEmpty(errors)}>
                                    {isSubmitting && (
                                        <Loading inline />
                                    )}
                                    Create Account
                                </Button>
                            </form>
                        </div>
                        <div className='formFooter'>
                            Already have an account?{' '}
                            <span className='link' onClick={signIn}>
                                Sign in
                            </span>
                        </div>
                    </div>
                    <div className='marketingBody'>
                        <div className='quote'>
                            &quot;With BrandKeep, the buyers in our shop have an efficient place to organize and quickly access brand assets in a consistent way.&quot;
                        </div>
                        <div className='quoteName'>
                            Charlie Wise
                        </div>
                        <div className='quoteTitle'>
                            Owner | The Mountaineer
                        </div>
                        <div className='quoteTitle'>
                            Keene Valley, New York
                        </div>
                    </div>
                </div>

                <div className='agreement'>
                    We’ll send you emails with product tips and best practices to help get you started.
                    You can opt out from those emails at any time. Please read our <a href='https://www.brandkeep.com/privacy-policy' target='_blank' rel='noreferrer'>Privacy Policy </a>
                    to learn more. By signing up for BrandKeep, you agree to our <a href='https://www.brandkeep.com/terms-of-use' target='_blank' rel='noreferrer'>Terms of Service</a>.
                </div>
            </div>
        </>
    );
};

export default SignUpForm;
