import React from 'react';
import './styles.sass';
import {ID} from 'src/Types/CommonTypes';
//libs
import {classnames} from 'src/utils/general';

interface Props {
    className?: string
    id: ID
    brandName: string
    editBrand: (id: ID, brandName: string) => void
}

const BrandTile: React.FC<Props> = ({
    id,
    className = '',
    brandName,
    editBrand,
}) => {

    const computedClassName = classnames(
        'brand-tile',
        {
            [className]: className,
        }
    );

    return (
        <div className={computedClassName} onClick={() => editBrand(id, brandName)}>
            <p className='brandName'>{brandName}</p>
        </div>
    );
};

export default BrandTile;
