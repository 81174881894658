export const welcomeOnboarding = {
    CHANGEME: {
        header: '',
        text: '',
        knowledgebaseLink: '',
        containerElement: undefined,
        originElement: undefined,
        targetId: '',
    },
};
