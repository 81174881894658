export enum TokenActionTypes {
    GET_TOKEN = 'GET_TOKEN',
    GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS',
    CLEAR_TOKEN = 'CLEAR_TOKEN'
}

export interface Token {
    accessToken?: string | null
}
export interface GetToken {
    type: TokenActionTypes.GET_TOKEN
}

export interface ClearToken {
    type: TokenActionTypes.CLEAR_TOKEN
    payload: {
        token: undefined
    }
}

export interface GetTokenSuccess {
    type: TokenActionTypes.GET_TOKEN_SUCCESS
    payload: {
        token?: Token
    }
}

export interface TokenState {
    token?: Token
}

export type TokenActions = GetToken | GetTokenSuccess | ClearToken;
