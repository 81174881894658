import {GlobalActionTypes, GlobalActions, GlobalState} from './actionTypes';
import _ from 'lodash';
import {serverErrorMatchesKey} from 'src/utils/general';


const initialState: GlobalState = {
    serverErrors: [],
    promptModalOptions: {body: '', title: ''},
};

const GlobalReducer = (state = initialState, action: GlobalActions): GlobalState => {
    switch (action.type) {
        case GlobalActionTypes.SERVER_ERRORS: {
            const serverErrorsFinal = _.cloneDeep(state.serverErrors);
            serverErrorsFinal.push({
                error: action.payload.error,
                config: action.payload.config,
            });

            return {
                ...state,
                serverErrors: serverErrorsFinal,
            };
        }

        case GlobalActionTypes.CLEAR_SERVER_ERRORS: {
            const serverErrorsFinal = _.cloneDeep(state.serverErrors);

            return {
                ...state,
                serverErrors: action.payload.key
                    ? _.filter(serverErrorsFinal, (serverError) => {
                        return !serverErrorMatchesKey(action.payload.key || '', serverError);
                    })
                    : [],
            };
        }

        case GlobalActionTypes.PROMPT_MODAL_OPTIONS: {
            return {
                ...state,
                promptModalOptions: action.payload.promptModalOptions,
            };
        }

        default:
            return state;
    }
};

export default GlobalReducer;
