import React, {useState} from 'react';
import {UploadableFile} from 'src/Components/DropZone';
import EntityImport from 'src/Components/EntityImport';
import ImportResult from 'src/Components/EntityImport/ImportResult';
import PreviewStep from 'src/Components/EntityImport/PreviewStep';
import UploadFileStep from 'src/Components/EntityImport/UploadFileStep';
import useGetFilesHrefs from 'src/Views/Store/Teams/ImportExportTeamModal/useGetFilesHrefs';
import useSubmit from 'src/Views/Store/Teams/ImportExportTeamModal/useSubmit';
import useValidation from 'src/Views/Store/Teams/ImportExportTeamModal/useValidation';
import Pings from 'src/lib/pings';

interface Props {
    open: boolean

    onCancel: () => void
    refetch?: () => void
}

enum StepsEnum {
    Step1 = 'Upload',
    Step2 = 'Preview',
    Step3 = 'Results',
}

const ImportExportTeamModal: React.FC<Props> = ({open, onCancel, refetch}) => {
    const stepsNames = Object.values(StepsEnum);
    const title: JSX.Element = <p className='header'> Import Contacts</p>;
    const [files, setFiles] = useState<Array<UploadableFile>>([]);
    const {headers, data, validateHandler, requiredColumns, errors, setErrors} = useValidation();
    const {
        submitFileHandler,
        // importInfo,
        importFileHandler,
        createdCount,
        updatedCount,
        errors: importErrors,
        isProcessing,
    } = useSubmit();
    const [currentStep, setCurrentStep] = useState<StepsEnum>(StepsEnum.Step1);
    const {template} = useGetFilesHrefs();

    const cancelButtonHandler = () => {
        setFiles([]);
        setCurrentStep(StepsEnum.Step1);
        setErrors([]);
        onCancel();
        Pings.removePing('importContactsPing');
    };

    return (
        <EntityImport open={open} title={title} cancelButtonHandler={cancelButtonHandler}>
            {currentStep === StepsEnum.Step1 ? (
                <UploadFileStep
                    importType='contact'
                    stepsNames={stepsNames}
                    requiredColumns={requiredColumns}
                    currentStep={1}
                    isNextStepDisabled={Boolean(errors.length) || !files.length}
                    files={files}
                    errors={errors}
                    validateHandler={validateHandler}
                    nextStepHandler={() => setCurrentStep(StepsEnum.Step2)}
                    submitFileHandler={() => submitFileHandler(files[0].file)}
                    downloadSampleHref={template}
                    setFiles={setFiles} />
            ) : null}
            {currentStep === StepsEnum.Step2 ? (
                <PreviewStep
                    importType='contact'
                    stepsNames={stepsNames}
                    headers={headers}
                    currentStep={2}
                    tableData={data}
                    disableImport={isProcessing}
                    nextStepHandler={() => setCurrentStep(StepsEnum.Step3)}
                    prevStepHandler={() => {
                        setFiles([]);
                        setCurrentStep(StepsEnum.Step1);
                        setErrors([]);
                        Pings.removePing('importContactsPing');
                    }}
                    importFileHandler={importFileHandler}
                    newFilesValue={createdCount}
                    updatedFilesValue={updatedCount}
                />
            ) : null}
            {currentStep === StepsEnum.Step3 ? (
                <ImportResult
                    importType='contact'
                    stepsNames={stepsNames}
                    errors={importErrors}
                    currentStep={3}
                    totalImported={data.length}
                    createdSuccess={createdCount}
                    updatedSuccess={updatedCount}
                    buttonHandler={() => {
                        if (refetch) {
                            refetch();
                        }
                        cancelButtonHandler();
                    }}
                />
            ) : null}
        </EntityImport>
    );
};

export default ImportExportTeamModal;
