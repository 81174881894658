import {User, UserDTO, UserDTOForApi} from './types';

export const userNormalizerFromApi = (user: UserDTO): User => {
    return {
        createdAt: user.createdAt,
        email: user.email,
        firstName: user.firstName,
        id: user.id,
        isActive: user.isActive,
        lastName: user.lastName,
        updatedAt: user.updatedAt,
        role: user.storeRoles[0].role,
        roleId: user.storeRoles[0].id,
    };
};
export const userNormalizerToApi = (user: User): UserDTOForApi => {
    return {
        email: user.email,
        firstName: user.firstName || '',
        isActive: user.isActive,
        lastName: user.lastName || '',
        role: user.role,
    };
};
