//filter common
import _ from 'lodash';
import {
    buildFilter,
    handleOnChangeCheckboxFactory,
    handleOnChangeFactory,
} from 'src/lib/filter-helper';

//specific
import {ApiAsset} from 'src/api/Assets/api-asset';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';

interface DataBuilder {
    allAssets?: ApiAsset[]
    allBrands?: BrandCard[]
}

interface ContentOption {
    id: 'new' | 'updated'
    title: 'New' | 'Updated'
}

const contentOptions: ContentOption[] = [
    {id: 'new', title: 'New'},
    {id: 'updated', title: 'Updated'},
];

const contentOptionsById = _.keyBy(contentOptions, 'id');

export default buildFilter({
    key: 'newContent',
    name: 'Content',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters) => appliedFilters,
    dataBuilder: (): ContentOption[] => {
        return contentOptions;
    },
    detailRenderer: (content: ContentOption) => content.title,
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {newContent}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='New Content'
                labelKey='title'
                labelTextRenderer={(newContent) => newContent.title}
                onChange={handleOnChangeFactory('newContent', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('newContent', onChange, values)}
                options={newContent}
                value={(values && values.newContent) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (newContent: ContentOption[], searchObject, key: string) => {
        searchObject[key] = _.map(newContent, 'id');
        return searchObject;
    },
    buildValuesFromQueryString: (UNUSED: any, {newContent}) => {
        return _.map(newContent, (content) => contentOptionsById[content]);
    },
    enabled: ({allAssets, allBrands}: DataBuilder) => {
        return Boolean(allAssets && allAssets.length) || Boolean(allBrands && allBrands.length);
    },
});
