import {TeamsActions, TeamsActionTypes, TeamsState} from './actionTypes';

const initialState: TeamsState = {
    loading: false,
    error: '',
    teams: [],
};

const TeamsReducer = (state = initialState, action: TeamsActions): TeamsState => {
    switch (action.type) {
        case TeamsActionTypes.GET_TEAMS: {
            return {...state, loading: true};
        }

        case TeamsActionTypes.GET_TEAMS_SUCCESS: {
            return {
                ...state,
                loading: false,
                teams: action.payload.teams,
            };
        }

        case TeamsActionTypes.GET_TEAMS_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }

        default:
            return state;
    }
};

export default TeamsReducer;
