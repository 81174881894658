import {useEffect, useState} from 'react';

interface WindowResult {
    windowHeight: number
    windowWidth: number
    smallWindowWidth: number
    windowIsSmall: boolean
    tinyWindowWidth: number
    windowIsTiny: boolean
    phoneWindowWidth: number
    windowIsPhone: boolean
    modalSize: number
}

const smallWindowWidth = 1100; //scaled to horizontal/landscape tablet
const tinyWindowWidth = 800; //scaled to vertical/portrait tablet
const phoneWindowWidth = 480;

const useWindow = (): WindowResult => {
    const getWidth = () => window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    const getHeight = () => window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    const [windowWidth, setWindowWidth] = useState(getWidth() || 0);
    const [windowHeight, setWindowHeight] = useState(getHeight() || 0);

    // const modalSizeBuilder = (windowWidth: number) => {
    //     return () => (large: number) => {
    //         console.log('🚀 --- file: useWindow.tsx --- line 39 --- modalSizeBuilder --- windowWidth', windowWidth);
    //         if (windowWidth < tinyWindowWidth) {
    //             return 725;
    //         } else if (windowWidth < smallWindowWidth) {
    //             return 950;
    //         } else {
    //             return large || 1255;
    //         }
    //     };
    // };
    // const [getModalSize, setGetModalSize] = useState<genericFn>(modalSizeBuilder(windowWidth));
    // console.log('🚀 --- file: useWindow.tsx --- line 40 --- useWindow --- getModalSize', getModalSize);
    const getModalSize = (width: number) => {
        if (width < tinyWindowWidth) {
            return 725;
        } else if (width < smallWindowWidth) {
            return 950;
        } else {
            return 1255;
        }
    };

    const [modalSize, setModalSize] = useState(getModalSize(getWidth()) || 0);

    useEffect(() => {
        let timeoutId: any = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                const width = getWidth();
                const height = getHeight();
                setWindowWidth(width);
                setWindowHeight(height);
                setModalSize(getModalSize(width));
            });
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    });

    return {
        windowHeight,
        windowWidth,
        smallWindowWidth,
        windowIsSmall: windowWidth < smallWindowWidth,
        tinyWindowWidth,
        windowIsTiny: windowWidth < tinyWindowWidth,
        phoneWindowWidth,
        windowIsPhone: windowWidth < phoneWindowWidth,
        modalSize,
    };
};

export default useWindow;
