import {StoresActions, StoresActionTypes, StoresState} from './actionTypes';

const initialState: StoresState = {
    loading: null,
    error: '',
    stores: [],
};

const StoresReducer = (state = initialState, action: StoresActions): StoresState => {
    switch (action.type) {
        case StoresActionTypes.GET_STORES: {
            return {...state, loading: true};
        }

        case StoresActionTypes.GET_STORES_SUCCESS: {
            return {
                ...state,
                loading: false,
                stores: action.payload.stores,
            };
        }

        case StoresActionTypes.GET_STORES_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }

        case StoresActionTypes.CLEAR_STORES: {
            return {
                ...state,
                loading: false,
                stores: undefined,
            };
        }

        default:
            return state;
    }
};

export default StoresReducer;
