import {Token, TokenActions, TokenActionTypes} from './actionTypes';

export const getToken = (): TokenActions => ({
    type: TokenActionTypes.GET_TOKEN,
});

export const getTokenSuccess = (token: Token): TokenActions => ({
    type: TokenActionTypes.GET_TOKEN_SUCCESS,
    payload: {
        token,
    },
});

export const clearToken = (): TokenActions => ({
    type: TokenActionTypes.CLEAR_TOKEN,
    payload: {
        token: undefined,
    },
});
