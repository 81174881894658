import React from 'react';
import {useNavigate} from 'react-router-dom';
import HeaderBar from '../../../Components/HeaderBar';
import {AuthRoutes} from '../../../constants/userRoles';
import './styles.sass';

const MobileMessage: React.FC = () => {
    const navigate = useNavigate();

    const goToHome = () => {
        navigate(AuthRoutes.home);
    };

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    return (
        <>
            <HeaderBar className='scene-onboarding-invite' hideMenu={true} />
            <div className='mobileMessage'>
                <h1 className='header'>
                    This site is currently best viewed on your desktop.
                </h1>
                <div className='btn-submit' onClick={goToHome}>
                    <a>Continue to Desktop Site</a>
                </div>
            </div>
        </>
    );
};

export default MobileMessage;
