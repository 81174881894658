import {User} from '../../Types/User/types';
import {ID} from 'src/Types/CommonTypes';

export enum TeamsActionTypes {
    GET_TEAMS = 'GET_TEAMS',
    GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS',
    GET_TEAMS_FAILURE = 'GET_TEAMS_FAILURE',
}

export interface Team {
    createdAt: string,
    description: string | null,
    id: ID,
    title: string,
    updatedAt: string,
}

export interface IGetTeams {
    type: TeamsActionTypes.GET_TEAMS,
    payload: {
        storeId: ID,
    }
}

export interface IGetTeamsSuccess {
    type: TeamsActionTypes.GET_TEAMS_SUCCESS,
    payload: {
        teams: Array<User>,
    }
}

export interface IGetTeamsFailure {
    type: TeamsActionTypes.GET_TEAMS_FAILURE,
    payload: {
        error: string,
    }
}

export interface TeamsState {
    error: string,
    loading: boolean,
    teams?: Array<User>,
}

export type TeamsActions = IGetTeams | IGetTeamsSuccess | IGetTeamsFailure;
