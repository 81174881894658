//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    validateFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';

//specific
import {BrandCard} from 'src/api/BrandCard/api-brand-card';

interface DataBuilder {
    allBrands: BrandCard[]
    brandFilterHasEmptyFilterOption: boolean
}

export default buildFilter({
    key: 'brand',
    name: 'Brand Card',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters, {brand: brands}: Record<'brand', BrandCard[]>) => validateFilter(appliedFilters, brands),
    // dataBuilder: ({allBrands}: DataBuilder) => _.sortBy(allBrands, (brand) => _.toLower(brand.name)),
    dataBuilder: ({allBrands, brandFilterHasEmptyFilterOption}: DataBuilder) => brandFilterHasEmptyFilterOption
        ? [{id: 0, name: '(No Brand)'}, ..._.sortBy(allBrands, (brand) => _.toLower(brand.name))]
        : _.sortBy(allBrands, (brand) => _.toLower(brand.name)),
    detailRenderer: (brand: BrandCard) => brand?.name || '',
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {brand: brands}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Brand Cards'
                disabled={!(brands && brands.length)}
                labelKey='name'
                onChange={handleOnChangeFactory('brand', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('brand', onChange, values)}
                options={brands}
                placeholder='Type to Search Brand Cards'
                value={(values && values.brand) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (brands: BrandCard[], searchObject, key: string) => {
        searchObject[key] = _.map(brands, 'id');
        return searchObject;
    },
    buildValuesFromQueryString: ({allBrands}, {brand: selectedBrandIds}) => {
        const valueIdSet = new Set(selectedBrandIds);
        const availableBrands = _.filter(allBrands, (brand) => valueIdSet.has(brand.id));
        const allAvailableBrands = valueIdSet.has(0) ? [{id: 0, name: '(No Brand)'}, ...availableBrands] : availableBrands;
        return allAvailableBrands;
    },
    enabled: ({allBrands}: Record<'allBrands', BrandCard[]>) => Boolean(allBrands && allBrands.length),
});
