import {PlusCircleOutlined} from '@ant-design/icons';
import Modal from 'src/Components/Modal';
import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import {useDispatch} from 'react-redux';
import {inviteUsers} from 'src/api/User/user-api';
import {inviteUsers as inviteUsersAdmin} from 'src/api/Admin/User/api-admin-user';
import Button from 'src/Components/Button';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import PopIcon from 'src/Components/PopIcon';
import Select from 'src/Components/Select';
import {getTeams} from 'src/redux/teams/actions';
import getValidationSchema from 'src/Views/Onboarding/InviteUsers/getValidationSchema';
import {ID, genericFn} from 'src/Types/CommonTypes';
import './styles.sass';
import tipIcon from 'src/assets/icons/tip-hex.svg';
import _ from 'lodash';
import {filterMap} from 'src/utils/general';

interface Props {
    isModalOpen: boolean
    onModalClose: genericFn
    storeId: ID
    isAdmin?: boolean
    isOwner?: boolean
}

interface Invites {
    email: string
    role: string
}

const TeamCreate: React.FC<Props> = ({isModalOpen, onModalClose, storeId, isAdmin = false, isOwner = false}) => {
    const dispatch = useDispatch();

    const {
        handleBlur,
        handleChange,
        handleSubmit,
        errors,
        touched,
        values,
        isSubmitting,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: {
            invites: [
                {email: '', role: {}},
                {email: '', role: {}},
                {email: '', role: {}},
            ],
        },
        validationSchema: getValidationSchema,
        onSubmit: async(values, actions: FormikHelpers<any>) => {
            actions.setSubmitting(true);
            const requestPayload: Invites[] = filterMap(values.invites, ({email}: Record<'email', string>) => email, ({email, role}) => {
                return {
                    email,
                    role: role.id,
                };
            });
            if (isAdmin) {
                await inviteUsersAdmin(storeId, {invites: requestPayload});
            } else {
                if (requestPayload.length) {
                    await inviteUsers(storeId, {invites: requestPayload});
                }
                dispatch(getTeams(storeId));
            }
            actions.setSubmitting(false);
            actions.resetForm();
            onModalClose(true);
        },
        validateOnMount: true,
        initialErrors: {invites: 'Error'},
        isInitialValid: false,
        enableReinitialize: true,
    });

    const options = [
        {
            label: 'Viewer',
            id: 'retail_read_only',
        },
        {
            label: 'Editor',
            id: 'retail_user',
        },
    ];

    if (isAdmin || isOwner) {
        options.push(
            {
                label: 'Owner',
                id: 'retail_owner',
            }
        );
    }

    return (
        <Modal
            className='invite-users-modal'
            width={600}
            title='Add a Team Member'
            open={isModalOpen}
            onCancel={() => onModalClose()}
            footer={false}>
            <form id='invite-users' onSubmit={handleSubmit}>
                <div className='modal-body'>
                    <div className='row'>
                        <label className='form-label user-email'>Email</label>
                        <div className='pop-label user-role'>
                            <label className='form-label'>
                                Role
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content='Users invited as owners have access to view and edit all aspects of the platform while Editors have access to view everything, but limited edit access to overall store and account information.'
                                origin={<img src={tipIcon} />} />
                        </div>
                    </div>
                    {values.invites && values.invites.map(
                        (
                            //eslint-disable-next-line @typescript-eslint/no-unused-vars
                            UNUSED: any,
                            index: number
                        ) => (
                            <div className='row' key={index}>
                                <div className='user-email'>
                                    <Input
                                        autoFocus
                                        className='input'
                                        errors={_.get(touched, ['invites', index, 'email']) && _.get(errors, ['invites', index, 'email'])}
                                        name={`invites.${index}.email`}
                                        placeholder='name@example.com'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.invites[index].email} />
                                </div>
                                <div className='user-role'>
                                    <Select
                                        placeholder='Role'
                                        onChange={(UNUSED: any, value) => {
                                            setFieldValue(`invites.${index}.role`, value);
                                        }}
                                        className='selectInput'
                                        labelKey='label'
                                        valueKey='id'
                                        id='role'
                                        expectScroll={false}
                                        options={options}
                                        value={values.invites[index].role} />
                                </div>
                            </div>
                        )
                    )}
                    <div className='add-row-button'
                        onClick={() => {
                            setFieldValue('invites', [...values.invites, {email: '', role: {}}]);
                        }}>
                        <div className='spar' />
                        <div className='add-row-button-text'>Add More <PlusCircleOutlined /></div>
                        <div className='spar' />
                    </div>
                </div>
                <div className='modal-footer'>
                    <div
                        className='cancel-button'
                        onClick={() => {
                            onModalClose();
                            resetForm();
                        }}>
                        Cancel
                    </div>
                    <Button
                        type='submit'
                        className='btn-secondary submit-button'
                        form='invite-users'
                        disabled={isSubmitting || !_.isEmpty(errors)}>
                        {isSubmitting && (
                            <Loading inline />
                        )}
                        Invite
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default TeamCreate;
