//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';

//specific
import {User} from 'src/Types/User/types';

interface DataBuilder {
    allUsers: User[]
}

interface StatusOption {
    id: 'inactive' | 'active'
    title: 'Inactive' | 'Active'
}

export default buildFilter({
    key: 'userStatus',
    name: 'Status',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters) => appliedFilters,
    dataBuilder: () => {
        return [
            {id: 'active', title: 'Active'},
            {id: 'inactive', title: 'Inactive'},
        ];
    },
    detailRenderer: (userStatus: StatusOption) => userStatus.title,
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {userStatus}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Status'
                labelKey='title'
                labelTextRenderer={(userStatus) => userStatus.title}
                onChange={handleOnChangeFactory('userStatus', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('userStatus', onChange, values)}
                options={userStatus}
                value={(values && values.userStatus) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (userStatus: StatusOption[], searchObject, key: string) => {
        searchObject[key] = _.map(userStatus, (status) => status.id === 'active');
        return searchObject;
    },
    buildValuesFromQueryString: (UNUSED: any, {userStatus}) => {
        const values = [];
        if (_.some(userStatus, (status) => status === true)) {
            values.push({id: 'active', title: 'Active'});
        }
        if (_.some(userStatus, (status) => status === false)) {
            values.push({id: 'inactive', title: 'Inactive'});
        }
        return values;
    },
    enabled: ({allUsers}: DataBuilder) => {
        return Boolean(allUsers && allUsers.length);
    },
});
