//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';

//specific
import {User} from 'src/Types/User/types';

interface DataBuilder {
    allUsers: User[]
}

interface RoleOption {
    id: 'viewer' | 'editor' | 'owner'
    title: 'Viewer' | 'Editor' | 'Owner'
}

const displayRoleByStoreRole = {
    retail_read_only: 'viewer',
    retail_user: 'editor',
    retail_owner: 'owner',
};

//roles supported by the system, in display format
const possibleRoles = [
    {id: 'viewer', title: 'Viewer'},
    {id: 'editor', title: 'Editor'},
    {id: 'owner', title: 'Owner'},
];

export default buildFilter({
    key: 'userRole',
    name: 'Permissions',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters) => appliedFilters,
    dataBuilder: ({allUsers}: DataBuilder) => {
        const availableRoles = new Set(_(allUsers)
            .map('storeRoles')
            .flatten()
            .compact()
            .map((storeRole) => displayRoleByStoreRole[storeRole.role])
            .value()
        );

        return _.filter(
            possibleRoles,
            (displayRole) => availableRoles.has(displayRole.id)
        );
    },
    detailRenderer: (userRole: RoleOption) => userRole.title,
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {userRole}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Role'
                labelKey='title'
                labelTextRenderer={(userRole) => userRole.title}
                onChange={handleOnChangeFactory('userRole', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('userRole', onChange, values)}
                options={userRole}
                value={(values && values.userRole) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (userRole: RoleOption[], searchObject, key: string) => {
        searchObject[key] = _.map(userRole, 'id');
        return searchObject;
    },
    buildValuesFromQueryString: ({allUsers}, {userRole}) => {
        const valueIdSet = new Set(userRole);

        //roles actually in use
        const availableRoles = new Set(_(allUsers)
            .map('storeRoles')
            .flatten()
            .compact()
            .map((storeRole) => displayRoleByStoreRole[storeRole.role])
            .value()
        );

        const allowedAndInUseRoles = _.filter(
            possibleRoles,
            (displayRole) => availableRoles.has(displayRole.id)
        );

        //return only selected filter values
        return _.filter(allowedAndInUseRoles, (role) => valueIdSet.has(role.id));
    },
    enabled: ({allUsers}: DataBuilder) => {
        return Boolean(allUsers && allUsers.length);
    },
});
