import React from 'react';
import './styles.sass';
import circleBackground from '/src/assets/images/pink-orange-circle.svg';
import photoImage from '/src/assets/images/photo.png';
import websiteImage from '/src/assets/images/website.png';
import xsheetImage from '/src/assets/images/xsheet.png';


//components
import Button from 'src/Components/Button';

//types
import {genericFn} from 'src/Types/CommonTypes/index';
import {PlanGroup} from 'src/constants/subscriptionPlans';

//libs
import {classnames} from 'src/utils/general';

interface Props {
    className?: string
    newPlan: PlanGroup
    onCancel: genericFn
    onContinue: genericFn
    costDiff: string
    isSubmitting: boolean
    isTrial?: boolean
    trialEnds?: string
}

const Upgrade: React.FC<Props> = ({
    className = '',
    newPlan,
    onCancel,
    onContinue,
    costDiff,
    isSubmitting = false,
    isTrial = false,
    trialEnds = '',
}): JSX.Element => {

    const computedClassName = classnames(
        'Upgrade',
        {
            [className]: className,
        }
    );

    const handleConfirm = () => {
        onContinue(newPlan);
    };

    return (
        <div className={computedClassName}>
            <div className='modal-background-image-container'>
                <img className='background-image' src={circleBackground} />
                <img className='accent-image top-image' src={websiteImage} />
                <img className='accent-image middle-image' src={photoImage} />
                <img className='accent-image bottom-image' src={xsheetImage} />
            </div>
            <div className='modal-body'>
                <div className='Upgrade-body'>
                    <div className='Upgrade-blurb large-font-size'>
                        {isTrial && (
                            <span>
                                {`By choosing the ${newPlan.name} plan, you’ll be charged `}<span className='primary-color'>{costDiff}</span>{` when your free trial expires on ${trialEnds}.`}
                            </span>
                        )}
                        {!isTrial && (
                            <span>
                                {'By upgrading, you’ll be immediately charged '}<span className='primary-color'>{costDiff}</span>{' for the prorated difference between your current plan and selected plan.'}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <div
                    className='cancel-button'
                    onClick={onCancel}>
                    Cancel
                </div>
                <Button className='btn-secondary' onClick={handleConfirm} disabled={isSubmitting}>
                    {isTrial && 'Confirm'}
                    {!isTrial && `Confirm and pay ${costDiff} now`}
                </Button>
            </div>
        </div>
    );
};

export default Upgrade;
