import {ApiTag, NormalizedTag} from '../../Types/Tags/types';
import apiHelper from '../api-helper';
import {ID, Suggested} from 'src/Types/CommonTypes';

//todo harden this
export interface Tag {
    id?: ID
    refType: string
    scope: string
    scopeId: ID
    title: string
}

// export interface DataMetaReturn {
//     data: Tag[],
//     meta: ApiMeta
// }


export const getTags = (refType: string, scope: string, scopeId?: ID): Promise<ApiTag[]> => {
    return apiHelper.get('/tags', {refType, scope, scopeId});
};

export const createTag = (tag: Tag): Promise<ApiTag> => {
    return apiHelper.post('/tags', tag);
};

export const getTagById = (tagId: string | number): Promise<ApiTag> => {
    return apiHelper.get(`/tags/${tagId}`);
};

export const updateTag = (tagId: ID, tag: Tag | ApiTag | NormalizedTag): Promise<ApiTag> => {
    return apiHelper.put(`/tags/${tagId}`, tag);
};

export const deleteTag = (tagId: ID): Promise<void> => {
    return apiHelper.delete(`/tags/${tagId}`);
};

export const getSuggestTags = async(
    scopeId: ID,
    count: number,
    refType: 'brand_cards' | 'contacts' | 'assets',
    scope: 'store' | 'brand',
    search: string
): Promise<Suggested[]> => {
    return apiHelper.get('tags/suggest', {refType, scopeId, scope, search, count});
};
