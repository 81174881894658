import _ from 'lodash';
import {Location} from 'history';
import {NavigateFunction, useLocation} from 'react-router-dom';

export interface QueryParams {
    [key: string]: string | string[]
}

export const getQueryParams = (location: Location): QueryParams => {
    const params: QueryParams = {};
    for (const [k, v] of new URLSearchParams(location.search).entries()) {
        if (v) {
            if (_.endsWith(k, '[]')) {
                const newKey = k.replace('[]', '');
                if (Array.isArray(params[newKey])) {
                    (params[newKey] as string[]).push(JSON.parse(v));
                } else {
                    params[newKey] = [JSON.parse(v)];
                }
            } else {
                try {
                    params[k] = JSON.parse(v);
                } catch (e) {
                    params[k] = JSON.stringify(v);
                }
            }
        }
    }
    return params;
};

// 

export const buildQueryParams = (queryParts: Record<string, any>, isNewUrl = true): string => {
    const queryParams = [];
    for (const [key, param] of Object.entries(queryParts)) {
        if (Array.isArray(param)) {
            param.forEach((value) => {
                queryParams.push(`${key}[]=${JSON.stringify(value)}`);
            });
        } else {
            queryParams.push(`${key}=${JSON.stringify(param)}`);
        }
    }

    if (queryParams.length) {
        return isNewUrl ? `?${queryParams.join('&')}` : `&${queryParams.join('&')}`;
    }

    return '';
};

/*
    history is pretty limited in what it can do.
    Worthy of note is that you can save state, but it obviously won't go to the URL, nor survive a refresh.
*/
export const HistoryHelperFactory = (navigate: NavigateFunction): any => {
    const location = useLocation();
    return {
        updateQueryParam: (paramName: string, paramValue: string | any[], replace = false) => {
            //preserve previous params
            const queryParts = getQueryParams(location);
            queryParts[paramName] = paramValue;
            const queryString = buildQueryParams(queryParts);

            if (replace) {
                navigate(queryString, {replace: true});
            } else {
                navigate(queryString);
            }
        },
        updateQueryParams: (searchObject, replace = false) => {
            const queryParams = [];
            for (const [paramName, paramValue] of Object.entries(searchObject)) {
                if (paramValue) {
                    if (Array.isArray(paramValue) && paramValue.length) {
                        paramValue.forEach((value) => {
                            queryParams.push(`${paramName}[]=${JSON.stringify(value)}`);
                        });
                    } else if (!Array.isArray(paramValue) && !_.isEmpty(paramValue)) {
                        queryParams.push(`${paramName}=${JSON.stringify(paramValue)}`);
                    }
                }
            }

            const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
            if (queryString) {
                if (replace) {
                    navigate(queryString, {replace: true});
                } else {
                    navigate(queryString);
                }
            } else if (!_.isEmpty(getQueryParams(location))) {
                //queryparams are not empty, but querystring is. Wipe the querystring.
                if (replace) {
                    navigate(queryString, {replace: true, state: {search: ''}});
                } else {
                    navigate(queryString, {state: {search: ''}});
                }
            }
        },
        clearStateHistory: () => {
            window.history.replaceState({}, document.title);
        },
        queryParams: () => getQueryParams(location),
    };
};

export const buildUrl = (url: string, urlParts: {[s: string]: any} = {}, relative = false, api = true): string => {
    for (const [urlPartKey, urlPartValue] of Object.entries(urlParts)) {
        url = url.replace(`{{${urlPartKey}}}`, urlPartValue);
    }
    if (relative) {
        return url;
    }
    return api ? MACRO_BASE_URL + url : BASE_URL + url;
};
