import {useFormik} from 'formik';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Button from 'src/Components/Button';
import Input from 'src/Components/Input';
import {AuthRoutes} from 'src/constants/userRoles';
import {createStore} from 'src/redux/createStore/actions';
import {RootState} from 'src/redux/rootReducer';
import useValidationSchema from './useValidationSchema';
import {getStores} from 'src/redux/stores/actions';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import Loading from 'src/Components/Loading';
import _ from 'lodash';

const CreateStoreForm: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector((state: RootState) => state.store);
    const stores = useSelector((state: RootState) => state.stores.stores);

    useDocumentTitle('Create Store');

    useEffect(() => {
        dispatch(getStores());
        dispatch(getStoreStatus());
    }, [dispatch]);

    useEffect(() => {
        if (stores?.length) {
            navigate(AuthRoutes.onboardingInviteTeam);
        }
    }, [stores]);

    useEffect(() => {
        if (store.createdStore) {
            navigate(AuthRoutes.onboardingInviteTeam);
        }
    }, [store]);

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues: {retailerInc: ''},
        validationSchema: useValidationSchema,
        onSubmit: (values, actions) => {
            dispatch(createStore(values.retailerInc));
            actions.setSubmitting(false);
        },
    });

    return (
        <form onSubmit={handleSubmit} id='set-company-name'>
            <Input
                errors={errors.retailerInc && touched.retailerInc && errors.retailerInc}
                autoFocus
                placeholder='Company Name'
                label='What is the name of your company?'
                {...getFieldProps('retailerInc')} />
            <Button
                type='submit'
                className='btn-secondary submit-button'
                form='set-company-name'
                disabled={isSubmitting || !_.isEmpty(errors)}>
                {isSubmitting && (
                    <Loading inline />
                )}
                Next
            </Button>
        </form>
    );
};

export default CreateStoreForm;
