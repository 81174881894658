import React from 'react';
import {genericFn, ID} from 'src/Types/CommonTypes';
import Button from 'src/Components/Button';
import DropdownBtnCreateAsset from 'src/Components/DropdownBtnCreateAsset';
import './styles.sass';

interface Props {
    objectName?: string
    isAsset?: boolean
    handleCreate?: genericFn
    brandCardId?: ID
    workspaceId?: ID
    readOnly?: boolean
}

const EmptyState: React.FC<Props> = ({objectName, isAsset, handleCreate, brandCardId, workspaceId, readOnly}) => {
    const buttonTitle = `Create Your First ${objectName}`;

    return (
        <div className='empty-state'>
            <div className='empty-state-header'>No {objectName}s Yet</div>
            {!readOnly && (isAsset
                ? (<DropdownBtnCreateAsset actionText={buttonTitle} onCreate={handleCreate} brandCardId={brandCardId} workspaceId={workspaceId} />)
                : (<Button className='btn-action' onClick={handleCreate}>{buttonTitle}</Button>))}
        </div>
    );
};

export default EmptyState;
