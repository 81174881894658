import React from 'react';
import './styles.sass';

interface Props {
    message: string
}

const ErrorMessage: React.FC<Props> = ({message}) => {
    return <div className='errorMessage'>{ message}</div>;
};

export default ErrorMessage;
