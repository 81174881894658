import * as Yup from 'yup';

const validationSchema = (): any => {
    return Yup.object().shape({
        retailerInc: Yup.string().required('Please enter a company name to continue'),
    });
};

export const defaultSchema = validationSchema();

const useValidationSchema = validationSchema;

export type FormValues = Yup.InferType<typeof defaultSchema>;
export default useValidationSchema;
