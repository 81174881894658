import {BrandCard} from '../../api/BrandCard/api-brand-card';
import {ID} from 'src/Types/CommonTypes';

export interface Brand {
    id?: ID
    name: string
    owner: string
    ownerPhone: string
    ownerMail: string
    tags: Array<string>
}

export enum BrandsActionTypes {
    GET_BRANDS = 'GET_BRANDS',
    GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS',
    GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE',
    GET_BRAND = 'GET_BRAND',
    GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS',
    GET_BRAND_FAILURE = 'GET_BRAND_FAILURE',
    CRATE_BRAND_CARD = 'CRATE_BRAND_CARD',
    CRATE_BRAND_CARD_SUCCESS = 'CRATE_BRAND_CARD_SUCCESS',
    CRATE_BRAND_CARD_FAILURE = 'CRATE_BRAND_CARD_FAILURE',
    UPDATE_BRAND_CARD = 'UPDATE_BRAND_CARD',
    UPDATE_BRAND_CARD_SUCCESS = 'UPDATE_BRAND_CARD_SUCCESS',
    UPDATE_BRAND_CARD_FAILURE = 'UPDATE_BRAND_CARD_FAILURE',
}

export interface IGetBrands {
    type: BrandsActionTypes.GET_BRANDS
    payload: {storeId: ID}
}

export interface IGetBrandsSuccess {
    type: BrandsActionTypes.GET_BRANDS_SUCCESS
    payload: {
        brands: Array<Brand>
    }
}

export interface IGetBrandsFailure {
    type: BrandsActionTypes.GET_BRANDS_FAILURE
    payload: {
        error: string
    }
}
export interface IGetBrand {
    type: BrandsActionTypes.GET_BRAND
    payload: {brandId: ID}
}

export interface IGetBrandSuccess {
    type: BrandsActionTypes.GET_BRAND_SUCCESS
    payload: {
        brand: Brand
    }
}

export interface IGetBrandFailure {
    type: BrandsActionTypes.GET_BRAND_FAILURE
    payload: {
        error: string
    }
}

export interface ICreateBrandCard {
    type: BrandsActionTypes.CRATE_BRAND_CARD
    payload: {storeId: ID; brandCard: BrandCard}
}

export interface ICreateBrandCardSuccess {
    type: BrandsActionTypes.CRATE_BRAND_CARD_SUCCESS
    payload: {
        brandCard: Brand
    }
}

export interface ICreateBrandCardFailure {
    type: BrandsActionTypes.CRATE_BRAND_CARD_FAILURE
    payload: {
        error: string
    }
}

export interface IUpdateBrandCard {
    type: BrandsActionTypes.UPDATE_BRAND_CARD
    payload: {
        brandId: ID
        brandCard: BrandCard
    }
}

export interface IUpdateBrandCardSuccess {
    type: BrandsActionTypes.UPDATE_BRAND_CARD_SUCCESS
    payload: {
        brandCard: Brand
    }
}

export interface IUpdateBrandCardFailure {
    type: BrandsActionTypes.UPDATE_BRAND_CARD_FAILURE
    payload: {
        error: string
    }
}

export interface BrandsState {
    error: string
    loading: boolean
    brands: Array<Brand>
    currentBrand: Brand | null
}

export type BrandsActions =
  | IGetBrandsFailure
  | IGetBrandsSuccess
  | IGetBrands
  | ICreateBrandCard
  | ICreateBrandCardSuccess
  | ICreateBrandCardFailure
  | IGetBrand
  | IGetBrandSuccess
  | IGetBrandFailure
  | IUpdateBrandCard
  | IUpdateBrandCardSuccess
  | IUpdateBrandCardFailure;
