import {ID} from 'src/Types/CommonTypes';
import {OnboardStatus} from 'src/hooks/useOnboarding';
export enum AuthActionTypes {
    GET_USER_STATUS = 'GET_USER_STATUS',
    AUTH_GET_INFO_BY_CODE = 'AUTH_GET_INFO_BY_CODE',
    GET_USER_STATUS_SUCCESS = 'GET_USER_STATUS_SUCCESS',
    GET_USER_STATUS_FAILURE = 'GET_USER_STATUS_FAILURE',
    FORCE_REFRESH = 'FORCE_REFRESH',
    LOGOUT = 'LOGOUT',
}

export interface IGetUserStatus {
    type: AuthActionTypes.GET_USER_STATUS
}

export interface IForceRefresh {
    type: AuthActionTypes.FORCE_REFRESH
}

export interface IGetAuthInfoByCode {
    type: AuthActionTypes.AUTH_GET_INFO_BY_CODE
    payload: { code: string | null }
}

export interface IGetUserStatusSuccess {
    type: AuthActionTypes.GET_USER_STATUS_SUCCESS
    payload: {
        isSignedIn: boolean
        name: string
        familyName: string
        id: ID
        email: string
        hasStores?: boolean
        canUseAdminDashboard?: boolean
        isMainMenuPinned?: boolean
        intercomUserId?: string
        onboardStatus?: OnboardStatus
    }
}

export interface IGetUserStatusFailure {
    type: AuthActionTypes.GET_USER_STATUS_FAILURE
    payload: {
        isSignedIn: boolean
        signInUrl: string
        signUpUrl: string
    }
}

export interface ILogout {
    type: AuthActionTypes.LOGOUT
}

export interface AuthState {
    isLoading: boolean | null
    isSignedIn: boolean
    name: string
    familyName: string
    signInUrl: string
    signUpUrl: string
    email: string
    id: ID
    hasStores?: boolean
    canUseAdminDashboard?: boolean
    isMainMenuPinned?: boolean
    intercomUserId?: string
    onboardStatus?: OnboardStatus
}

export type AuthActions = IGetUserStatus | IGetAuthInfoByCode | IGetUserStatusSuccess | IGetUserStatusFailure | ILogout | IForceRefresh;
