import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {RootState} from 'src/redux/rootReducer';
import './styles.sass';
import _ from 'lodash';


//components
import {message} from 'antd';
import AssetPreview from 'src/Components/AssetPreview';
import Loading from 'src/Components/Loading';
import {Prompt} from 'src/Components/ModalPrompt';

//apis
import useAssetsApi from 'src/Views/Store/Assets/useAssetsApi';
import {StoreInfoApi, getStoreRolesInfo} from 'src/api/StoreRole/api-store-role';

//types
import {ApiAsset, getAsset, getLockedAssetsStatus} from 'src/api/Assets/api-asset';

//libs
import {_get} from 'src/utils/general';
import {AuthRoutes} from 'src/constants/userRoles';
import {buildQueryParams} from 'src/lib/url';
import {ID} from 'src/Types/CommonTypes';


const AssetViewer = (): JSX.Element => {
    const {storeId} = useParams<{storeId: any}>();
    const {assetId} = useParams<{assetId: any}>();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        downloadAll,
        lockedAssets,
        handleLockedAssets,
    } = useAssetsApi(storeId, undefined, undefined, true);
    const searchObject = _.get(location, 'state.queryParams', {});
    const from = _.get(location, 'state.from', AuthRoutes.storeAssets(storeId));
    const [assetToPreview, setAssetToPreview] = useState<ApiAsset>();
    const [userStoreInfo, setUserStoreInfo] = useState<StoreInfoApi>();
    const [isLoading, setIsLoading] = useState(true);
    const readOnly = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_read_only';
    const userId = useSelector((state: RootState) => state.auth.id);


    const previewAssetIfNotLocked = async(asset: ApiAsset) => {
        const assetId = _.toNumber(asset.id);
        const lockStatuses = await getLockedAssetsStatus(storeId, [assetId]);
        const lockStatus = _.find(lockStatuses, {id: assetId});
        if (lockStatus) {
            if (!lockStatus.locked && !lockStatus.editLocked) {
                setAssetToPreview(asset);
            } else {
                navigate(`${AuthRoutes.storeAssets(storeId)}`);
                await new Prompt().notice(
                    {
                        body: (
                            <>
                                {lockStatus.locked && (
                                    <div>This file is currently being saved by another user. Please wait until saving is complete.</div>
                                )}
                                {lockStatus.editLocked && (
                                    <div>This file is currently being edited by another user. Please wait until edit is complete.</div>
                                )}
                            </>
                        ),
                        title: 'File in use',
                        displayType: 'notice',
                        props: {confirmText: 'Close'},
                    }
                );
            }
        }
        await handleLockedAssets(true);
    };

    const handleEditPdf = async() => {
        await handleLockedAssets(true);
    };

    const handleGetAsset = async(assetId: ID) => {
        const asset = await getAsset(storeId, assetId);
        if (asset) {
            previewAssetIfNotLocked(asset);
            setIsLoading(false);
        } else {
            message.error('Asset not found, or it was deleted.');
        }
    };

    useEffect(() => {
        setIsLoading(true);
        handleGetAsset(assetId);
    }, [assetId]);

    useEffect(() => {
        const fetchUserStoreInfoTags = async() => {
            const userInfo = await getStoreRolesInfo(storeId, userId);
            setUserStoreInfo(userInfo[0]);
        };
        fetchUserStoreInfoTags();
    }, [storeId, userId]);



    const handleCloseAssetPreview = async() => {
        setAssetToPreview(undefined);
        // const searchObject: Record<string, any> = {};
        // for (const [filterKey, filterValue] of Object.entries(filterValues)) {
        //     if (_.get(filters, [filterKey, 'transformForAPI'])) {
        //         filters[filterKey].transformForAPI(
        //             filterValue,
        //             searchObject,
        //             filterKey,
        //             filterDependencies
        //         );
        //     } else {
        //         searchObject[filterKey] = undefined;
        //     }
        // }
        // const finalSearchObject: Record<string, any> = {};
        // for (const [searchKey, searchValue] of Object.entries(searchObject)) {
        //     if (!_.isEmpty(searchValue)) {
        //         finalSearchObject[searchKey] = searchValue;
        //     }
        // }
        const queryParams = buildQueryParams(searchObject);
        // navigate(from + queryParams, {replace: false});
        window.location.href = from + queryParams;
        //CLEAR OUT FILTERS FROM LOCATION HISTORY
        // await refetchAssets(finalSearchObject);
    };




    return (
        <div className='asset-viewer'>
            {isLoading && (
                <Loading fill size={56} />
            )}
            <AssetPreview
                asset={assetToPreview}
                readOnly={readOnly}
                isLockedForEditing={assetToPreview && lockedAssets.has(_.toNumber(assetToPreview.id))}
                onAssetChange={(asset) => setAssetToPreview(asset)}
                onDownload={() => assetToPreview && downloadAll(storeId, [assetToPreview.id])}
                onEditPdf={handleEditPdf}
                copyUrl='/store/{{storeId}}/asset-viewer/{{assetId}}'
                portal={false}
                showTopbar={false}
                show={Boolean(_.get(assetToPreview, 'id'))}
                onClose={handleCloseAssetPreview} />
        </div>
    );
};

export default AssetViewer;
