import {all, put, takeEvery} from 'redux-saga/effects';
import {getTokenSuccess} from './actions';
import {TokenActionTypes} from './actionTypes';


function* getToken() {
    const accessToken = window.localStorage.getItem('ACCESS_TOKEN');
    yield put(getTokenSuccess({accessToken}));
}

function* watchGetToken() {
    yield takeEvery(TokenActionTypes.GET_TOKEN, getToken);
}

export default function* tokenSagas() {
    yield all([watchGetToken()]);
}
