import apiHelper from 'src/api/api-helper';
import {API_ID, ID} from 'src/Types/CommonTypes';

export type Plan = {
    group: string
    id: API_ID
    isTrial: boolean
    price: number
    name: string
};

export interface Store {
    id: ID
    title: string
    description?: string
    createdAt: string
    updatedAt: string
    lastActive?: string
    useKeepMail?: boolean
    assetMailbox?: string
    assetMailboxSuffix?: string
    logoUrl?: string
    isTrial: boolean
    plan: Plan
    planEnd: string
    planStart: string
    planCharge: number
    nextBill: number
    nextPlan?: Plan
    planName: string
    hasNoPlan?: boolean
    assetUsage?: number
}

export interface UserInvite {
    email: string
    role: string
}

interface ParamsForStores {
    textSearch?: string
    plan?: ID[]
}

const adminPrefix = '/admin';

export const getStores = (params?: ParamsForStores): Promise<Store[]> => {
    return apiHelper.post(`${adminPrefix}/stores`, params);
};

export const getStoreById = (storeId: ID): Promise<Store> => {
    return apiHelper.get(`${adminPrefix}/stores/${storeId}`);
};

export const upsertStoreLogo = (storeId: ID, formData?: FormData): Promise<Store> => {
    return apiHelper.put(`${adminPrefix}/stores/${storeId}/logo`, formData, {'Content-Type': 'multipart/form-data'});
};

export const updateStore = (storeId: ID, store: Store) => {
    return apiHelper.put(`${adminPrefix}/stores/${storeId}`, store);
};

export const forceRecalulationOfPlanLimitationsForStore = (storeId: ID) => {
    return apiHelper.post(`${adminPrefix}/stores/${storeId}/recalcPlan`, {});
};

