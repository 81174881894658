import {Select as AntSelect, SelectProps} from 'antd';
import {WarningFilled} from '@ant-design/icons';
import {
    OptionData,
    OptionGroupData,
} from 'rc-select/lib/interface';
import React, {useState} from 'react';
import './styles.sass';
import {classnames} from 'src/utils/general';
import _ from 'lodash';

interface Props<T> extends SelectProps<T> {
    className?: string
    labelKey: string
    valueKey: string
    valueTagRenderer?: (tagValue, isInputFocused: boolean) => JSX.Element | null
    message?: string
    expectScroll: boolean
}

type RawValue = string | number;

interface LabeledValue {
    key?: string;
    value: RawValue;
    label: React.ReactNode;
}

type SelectValue = RawValue | RawValue[] | LabeledValue | LabeledValue[] | undefined;

//ALIGNMENT DOCUMENTATION - you won't find this in ant's docs... don't know why.
// const dropdownAlign = {
//     points: ['tl', 'tr'],        // align top left point of sourceNode with top right point of targetNode
//     offset: [10, 20],            // the offset sourceNode by 10px in x and 20px in y,
//     targetOffset: ['30%','40%'], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
//     overflow: {adjustX: true, adjustY: true}, // auto adjust position when sourceNode is overflowed
// };

//https://ant.design/components/select/
export const Select = <T extends SelectValue = SelectValue>({
    className = '',
    options,
    labelKey,
    valueKey,
    message,
    onChange,
    showSearch = true,
    allowClear = true,
    valueTagRenderer,
    value,
    //Make this FALSE especially when <Select> is on a short modal and the dropdown is expected to overflow the content.
    //If you pop a select and the dropdown causes the modal to have a scrollbar where there once was not one, set this prop to false!
    expectScroll = true,
    ...props
}: Props<T>): JSX.Element => {
    const optionsByKey = _.keyBy(options, valueKey);
    const [isFocused, setIsFocused] = useState(false);

    const handleOnChange = (rawValue: RawValue[], option: Record<any, any>) => {
        // if (rawValue && option && optionsByKey && !_.isEmpty(optionsByKey)) {
        if (_.isArray(rawValue)) {
            const optionObjects = _.map(rawValue, (key) => {
                if (optionsByKey[key]) {
                    return optionsByKey[key];
                } else {
                    return {
                        [labelKey]: key,
                        [valueKey]: key,
                    };
                }
            });
            onChange(optionObjects, option);
        } else {
            onChange(rawValue, optionsByKey[rawValue]);
        }
        // }
    };

    const buildData = (data) => {
        return data && data.length
            ? _(data)
                .compact()
                .map((option) => {
                    return {
                        label: option[labelKey],
                        value: option[valueKey],
                    };
                })
                .value()
            : [];
    };

    const buildValue = (value: any) => {
        if (props.multiple) {
            return value && value.length
                ? _(value)
                    .compact()
                    .map((option) => {
                        return option[valueKey];
                    })
                    .value()
                : [];
        } else {
            return value && value[valueKey];
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    const handleTagRenderer = (tagValue) => {
        return valueTagRenderer(tagValue, isFocused);
    };
    const handleFilterOption = (searchText: string, option: OptionData | OptionGroupData) => {
        return option.label && (option.label).toString().toLowerCase().includes(_.toLower(searchText));
    };

    return (
        <>
            <AntSelect
                className={classnames(
                    'select',
                    {[className]: className}
                )}
                /****************
                 * IMPLEMENT labelInValue SOMEDAY! https://ant.design/components/select/#API
                 * consider maxTagPlaceholder?
                 ******************/
                popupClassName='select-dropdown'
                dropdownRender={(originNode: React.ReactNode) => originNode}
                //select attaches itself to parent node somehow. useful when select changes position, the dropdown will follow it.
                getPopupContainer={expectScroll ? (trigger) => trigger.parentNode : undefined}
                onChange={handleOnChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                options={buildData(options)}
                filterOption={handleFilterOption}
                tagRender={valueTagRenderer ? handleTagRenderer : undefined}
                maxTagCount='responsive'
                showSearch={showSearch}
                allowClear={allowClear}
                value={buildValue(value)}
                {...props} />
            {message && (
                <div className='select-message'>
                    <WarningFilled /> {_.upperFirst(message)}
                </div>
            )}
        </>
    );
};

export default Select;

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;
