import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {nanoid} from 'nanoid';
import './styles.sass';
import _ from 'lodash';

//icons

//components
import ControlHeader from 'src/Components/ControlHeader';
import Loading from 'src/Components/Loading';
import Table, {SortBy} from 'src/Components/Table';

//apis
import {
    getStores,
    Store,
} from 'src/api/Admin/Store/api-admin-store';

//types

//libs
import {Filters, Filter, handleFilterValidations, removeFilterFactory, GLOBAL_FILTER_DEBOUNCE_RATE} from 'src/lib/filter-helper';
import {displayFileSize, debouncePromise} from 'src/utils/general';
import {formatDate} from 'src/lib/date-helper';
import {getQueryParams, HistoryHelperFactory, QueryParams} from 'src/lib/url';
import planFilter from 'src/lib/filters/plan';

interface FilterDependencies {
    stores?: Store[]
    allStores?: Store[]
}

const StoreDashboard = (): JSX.Element => {
    const navigate = useNavigate();
    const historyHelper = HistoryHelperFactory(useNavigate());
    const queryParams = getQueryParams(useLocation());

    const [stores, setStores] = useState<Store[]>();
    const [allStores, setAllStores] = useState<Store[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [textSearchValue, setTextSearchValue] = useState(queryParams.textSearch || '');
    const [filterValues, setFilterValues] = useState<any>({});
    const [filterData, setFilterData] = useState<any>();
    const [areFilterValuesDefault, setAreFilterValuesDefault] = useState(true);
    const hasStores = Boolean(stores && stores.length);
    const [sortBy, setSortBy] = useState<SortBy>({
        key: 'updatedAt',
        title: 'Modified',
        isReversed: true,
    });

    const initialFilters = [planFilter];
    const rawFilters = _.keyBy(initialFilters, 'key');
    const filterDependencies: FilterDependencies = {
        stores,
    };

    const [filters, setFilters] = useState<Record<string, Filter>>(
        _.omitBy(rawFilters, (filter) => {
            return !filter.enabled(filterDependencies) && !filter.show(filterDependencies);
        })
    );
    const initFilters = (filterDependenciesInit = filterDependencies, filtersInit = filters) => {
        if (!hasStores) {
            return;
        }

        const filterData: Filters = {};
        for (const [filterKey, filter] of Object.entries(filtersInit)) {
            if (!filter.loadAfter && filter.show(filterDependenciesInit) && filter.enabled(filterDependenciesInit)) {
                filterData[filterKey] = filter.dataBuilder(filterDependenciesInit);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    const getFilters = (filterDependencies: FilterDependencies) => {
        const initialFilters = [planFilter];

        //filters here must still be key'ed to their respective 'key'
        const rawFilters = _.keyBy(initialFilters, 'key');
        const filters = _.omitBy(
            rawFilters,
            (filter) => !filter.enabled(filterDependencies) && !filter.show(filterDependencies)
        );
        setFilters(filters);
        initFilters(filterDependencies, filters);
        return filters;
    };

    if (!isLoading && filters && !filterData && _.every(Object.values(filterDependencies))) {
        initFilters(filterDependencies, filters);
    }

    const callId = useRef<string | null>(null);

    const getStoresHandler = useCallback(async(
        currentCallId: string,
        queryParams: QueryParams,
        {
            init = false,
            textSearchValue = undefined,
            filterValuesFromState = filterValues,
        }
    ) => {
        if (callId.current === currentCallId) {
            setIsLoading(true);
            const searchObject = {};
            if (textSearchValue) {
                searchObject.textSearch = textSearchValue;
            }
            const filters = getFilters(filterDependencies);
            for (const [filterKey, filterValue] of Object.entries(filterValuesFromState)) {
                if (_.get(filters, [filterKey, 'transformForAPI'])) {
                    filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies);
                }
            }
            const finalSearchObject = init ? queryParams : searchObject;

            const filteredStores = await getStores(finalSearchObject);

            if (init) {
                const [
                    allStores,
                ] = await Promise.all([
                    getStores(),
                ]);
                setAllStores(allStores);

                filterDependencies.allStores = allStores;

                const filters = getFilters(filterDependencies);

                const {
                    updatedQueryParamObject,
                    urlFilterValues,
                    didFiltersObviouslyChange,
                } = handleFilterValidations(
                    filters,
                    filterDependencies,
                    queryParams
                );

                setFilterValues(urlFilterValues);
                historyHelper.updateQueryParams(updatedQueryParamObject, false);

                if (didFiltersObviouslyChange) {
                    //filters changed, short-circuit this request and fire new query
                    const currentCallId = nanoid();
                    callId.current = currentCallId;
                    getStoresHandler(
                        currentCallId,
                        queryParams,
                        {filterValuesFromState: urlFilterValues}
                    );
                    return;
                } else {
                    setStores(filteredStores);
                }
            } else {
                setStores(filteredStores);
                historyHelper.updateQueryParams(searchObject);
            }
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        getStoresHandler(currentCallId, queryParams, {init: true, textSearchValue, filterValuesFromState: filterValues});
    }, []);

    useEffect(() => {
        if (!isLoading && allStores && allStores.length && !areFilterValuesDefault) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => getStoresHandler(currentCallId, queryParams, {textSearchValue, filterValuesFromState: filterValues})
            )();
        }
        if (!isLoading && !_.isEmpty(filterValues)) {
            setAreFilterValuesDefault(false);
        }
    }, [filterValues, textSearchValue]);


    useEffect(() => {
        if (filters && allStores) {
            initFilters({allStores, stores}, filters);
        }
    }, [filters, allStores]);

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    return (
        <div className='store-dashboard'>
            <div className='store-dashboard-header'>
                <ControlHeader
                    setSearch={setTextSearchValue}
                    search={textSearchValue}
                    disableSearch={isLoading}
                    placeholder='Search by store name'
                    handleSetFilter={handleSetFilter}
                    handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                    filterValues={filterValues}
                    filterData={filterData}
                    filters={filters}
                    showSort={hasStores}
                    sortOptions={[
                        {
                            key: 'brandCardCount',
                            title: 'Brand Cards',
                            isReversed: true,
                        },
                        {
                            key: 'createdAt',
                            title: 'Created',
                        },
                        {
                            key: 'title',
                            title: 'Name',
                        },
                        {
                            key: 'lastActive',
                            title: 'Last Login',
                            isReversed: true,
                        },
                        {
                            key: 'userCount',
                            title: 'Users',
                            isReversed: true,
                        },
                        {
                            key: 'assetUsage',
                            title: 'Storage',
                            isReversed: true,
                            shouldSortEmpty: true,
                        },
                    ]}
                    setSortOption={setSortBy}
                    activeSort={sortBy} />
            </div>
            {isLoading && (
                <Loading fill size={56} />
            )}
            {!isLoading && (
                <Table
                    columns={[
                        {
                            title: 'Store Name',
                            dataIndex: 'title',
                            key: 'title',
                            ellipsis: true,
                            cellTitle: (store: Store) => store.title,
                            render: (UNUSED: any, store: Store) =>
                                <div
                                    className='primary-color pointer single-line-ellipsis'
                                    onClick={() => {
                                        navigate(`/admin/stores/${store.id}`);
                                    }}>
                                    {store.title}
                                </div>,
                        },
                        {
                            title: 'Current Plan',
                            dataIndex: 'currentPlan',
                            key: 'currentPlan',
                            width: '8%',
                            ellipsis: true,
                            cellTitle: (store: Store) =>
                                store.plan.key.includes('_legacy')
                                    ? _.capitalize(`${store.plan.name} (Free)`)
                                    : _.capitalize(store.plan.name),
                            render: (UNUSED: any, store: Store) =>
                                store.plan.key.includes('_legacy')
                                    ? _.capitalize(`${store.plan.name} (Free)`)
                                    : _.capitalize(store.plan.name),
                        },
                        {
                            title: 'Next Plan',
                            dataIndex: 'nextPlan',
                            key: 'nextPlan',
                            width: '8%',
                            ellipsis: true,
                            cellTitle: (store: Store) => _.capitalize(store.nextPlan?.name),
                            render: (UNUSED: any, store: Store) => _.capitalize(store.nextPlan?.name),
                        },
                        {
                            title: 'Account #',
                            dataIndex: 'accountNumber',
                            key: 'accountNumber',
                            width: '8%',
                            ellipsis: true,
                        },
                        {
                            title: 'Date Created',
                            dataIndex: 'createdAt',
                            key: 'createdAt',
                            width: '8%',
                            ellipsis: true,
                            cellTitle: (store: Store) => store.createdAt && formatDate(store.createdAt),
                            render: (UNUSED: any, store: Store) => store.createdAt && formatDate(store.createdAt),
                        },
                        {
                            title: 'Last Login',
                            dataIndex: 'lastActive',
                            key: 'lastActive',
                            width: '8%',
                            ellipsis: true,
                            cellTitle: (store: Store) => store.lastActive && formatDate(store.lastActive),
                            render: (UNUSED: any, store: Store) => store.lastActive && formatDate(store.lastActive),
                        },
                        {
                            title: 'Users',
                            dataIndex: 'userCount',
                            key: 'userCount',
                            ellipsis: true,
                            width: '8%',
                        },
                        {
                            title: 'Brand Cards',
                            dataIndex: 'brandCardCount',
                            key: 'brandCardCount',
                            width: '8%',
                            ellipsis: true,
                        },
                        {
                            title: 'Storage',
                            dataIndex: 'assetUsage',
                            key: 'assetUsage',
                            width: '8%',
                            ellipsis: true,
                            cellTitle: (store: Store) => store.assetUsage ? displayFileSize(store.assetUsage) : '',
                            render: (UNUSED: any, store: Store) => store.assetUsage ? displayFileSize(store.assetUsage) : '',
                        },
                    ]}
                    shouldScroll={true}
                    data={stores}
                    sortBy={sortBy}
                    id='STORE_TABLE'
                    scrollParentSelector='.store-dashboard-table .ant-table-body'
                    className='store-dashboard-table' />
            )}
        </div>
    );
};

export default StoreDashboard;
