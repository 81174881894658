import React from 'react';
import './styles.sass';
import _ from 'lodash';
import {genericFn} from 'src/Types/CommonTypes';
import {classnames} from 'src/utils/general';

interface Props {
    className?: string
    name: string
    onLoad?: genericFn
    video?: any
    videoType?: string
}

const AssetVideo: React.FC<Props> = ({
    className = '',
    onLoad = _.noop,
    video,
    videoType,
}) => {
    const classes = classnames(
        'AssetVideo',
        {[className]: className}
    );

    const handleOnLoad = () => {
        onLoad();
    };


    return (
        <div className={classes}>
            {video && (
                <video
                    className='video-container'
                    data-reactid='.0.1.0.0'
                    controls
                    onCanPlay={handleOnLoad}>
                    <source type={videoType} data-reactid='.0.1.0.0.0' src={video} />
                </video>
            )}
        </div>
    );
};

export default AssetVideo;
