import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import './styles.sass';
import _ from 'lodash';

//icons
import {PlusOutlined} from '@ant-design/icons';
import {Kabob, Note} from 'src/lib/svg-icon-helper';
import tipIcon from 'src/assets/icons/tip-hex.svg';

//components
import {Divider, Space, Typography, message} from 'antd';
import AssetPreview from 'src/Components/AssetPreview';
import Button from 'src/Components/Button';
import ControlHeader from 'src/Components/ControlHeader';
import DropdownButton from 'src/Components/DropdownButton';
import DropdownBtnCreateAsset from 'src/Components/DropdownBtnCreateAsset';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import Onboarding from 'src/Components/Onboarding';
import PopIcon from 'src/Components/PopIcon';
import {Prompt} from 'src/Components/ModalPrompt';
import Select from 'src/Components/Select';
import Table, {SortBy} from 'src/Components/Table';
import TagsEditable from 'src/Components/TagsEditable';
import TagsRenderer from 'src/Components/TagsRenderer';
import NoResults from 'src/Components/NoResults';
import EmptyState from 'src/Components/EmptyState';

//apis
import useAssetsApi, {MultiEditAsset} from 'src/Views/Store/Assets/useAssetsApi';

//types
import {NormalizedTag, ApiTag} from 'src/Types/Tags/types';
import {ApiAsset} from 'src/api/Assets/api-asset';
import {AlignType, ID, genericFn, ReactEvent, ReactTextareaEvent, ReactAnchorEvent} from 'src/Types/CommonTypes';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';
import {ApiWorkspace} from 'src/api/Workspace/api-workspace';

//libs
import {Filters, removeFilterFactory} from 'src/lib/filter-helper';
import {displayFileSize, _get, buildUrlFromPartial, textToClipboard, invisibleCharacter, classnames} from 'src/utils/general';
import {getContactDisplayBySource} from 'src/lib/asset-helper';
import useWindow from 'src/hooks/useWindow';
import {NewContentContext} from 'src/hooks/useNewContent';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';
import {RootState} from 'src/redux/rootReducer';
import {AuthRoutes} from 'src/constants/userRoles';
import {buildUrl} from 'src/lib/url';
import getFileType, {SUPPORTED_FILE_TYPES} from 'src/lib/file-type-helper';

interface Props {
    workspaceId?: ID
    brandCardId?: ID
    reloadWorkspaces?: genericFn
    readOnly?: boolean
    workspacesUpdated: boolean
}

const unchangedMultiSelectId = `(Several)${invisibleCharacter}`;

/*********************************************

 ***** THIS COMPONENT IS MIRRORED SOMEWHAT CLOSELY BY /Views/Store/Assets/index.tsx *****
 ***** ANY UPDATE MAY NEED TO BE IMPLEMENTED THERE ALSO! *****

*********************************************/
const WorkspaceAssets = ({workspaceId, brandCardId, reloadWorkspaces, readOnly, workspacesUpdated}: Props): JSX.Element => {
    const navigate = useNavigate();
    const {storeId} = useParams<{storeId: any}>();
    const {brandCardItemId} = useParams<{brandCardItemId: any}>();
    const {
        assets,
        allAssets,
        refetchAssets,
        allBrands,
        isLoading,
        deleteAssets,
        textSearchValue,
        setTextSearchValue,
        tags,
        workspaces,
        saveAssetsApi,
        filters,
        filterData,
        setFilterData,
        filterValues,
        setFilterValues,
        downloadAll,
        silentlyRefreshAssets,
    } = useAssetsApi(storeId, brandCardId, workspaceId);
    const {windowIsSmall, windowIsTiny} = useWindow();

    const [multiSelectItems, setMultiSelectItems] = useState<any[]>([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [assetName, setAssetName] = useState<string>('');
    const [assetLink, setAssetLink] = useState<string>();
    const [assetBrand, setSelectedBrand] = useState<BrandCard>();
    const [initialBrand, setInititalBrand] = useState<BrandCard>();
    const [assetWorkspace, setSelectedWorkspace] = useState<Record<'title', string>>();
    const [assetTags, setSelectedAssetTags] = useState<NormalizedTag[]>();
    const [assetNotes, setAssetNotes] = useState<string>('');
    const [assetToEdit, setAssetToEdit] = useState<ApiAsset>();
    const [assetToPreview, setAssetToPreview] = useState<ApiAsset>();
    const [assetPreviewErrorMessageShowing, setAssetPreviewErrorMessageShowing] = useState<boolean>(false);
    const [newWorkspace, setNewWorkspace] = useState<string>('');
    const [isSubmittingEditAssets, setIsSubmittingEditAssets] = useState<boolean>(false);
    const [isDeletingAsset, setIsDeletingAsset] = useState<boolean>(false);
    const shouldMultiEdit = multiSelectItems.length > 1 && !assetToEdit;
    const storeStatuses = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const currentStoreStatus = storeStatuses?.find((status: StoreStatus) => `${status.id}` === storeId);
    const [workspacesCompact, setWorkspacesCompact] = useState<Record<'title', string>[]>([]);
    const [sortBy, setSortBy] = useState<SortBy>({
        key: 'createdAt',
        title: 'Date Added',
        isReversed: true,
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<ID[]>([]);
    const {newContent} = useContext(NewContentContext);

    useEffect(() => {
        if (allAssets && allAssets.length && brandCardItemId && !assetToPreview) {
            const asset = _.find(allAssets, (asset) => Number(asset.id) === Number(brandCardItemId));
            if (asset) {
                setAssetToPreview(asset);
            } else {
                setAssetToPreview(undefined);
                if (!assetPreviewErrorMessageShowing) {
                    setAssetPreviewErrorMessageShowing(true);
                    message.error('Asset not found, or it was deleted.', 4, () => setAssetPreviewErrorMessageShowing(false));
                }
            }
        }
        if (!brandCardItemId && assetToPreview) {
            setAssetToPreview(undefined);
        }
    }, [brandCardItemId, allAssets, assetToPreview]);

    const handleWorkspacesForDropdown = () => {
        const workspacesByTitle = _.groupBy(workspaces, 'title');
        const combinedWorkspaces = _.map(workspacesByTitle, (workspaces: ApiWorkspace[]) => {
            return {
                title: workspaces[0].title,
            };
        });
        setWorkspacesCompact(_.sortBy(combinedWorkspaces, (workspace) => _.toLower(workspace.title)));
    };

    useEffect(() => {
        handleWorkspacesForDropdown();
    }, [workspaces]);

    useEffect(() => {
        refetchAssets();
    }, [workspacesUpdated]);

    useEffect(() => {
        let promptReload = false;
        if (storeId && brandCardId && allAssets && allAssets.length && !brandCardItemId && newContent.length) {
            const handleRefresh = async() => {
                await silentlyRefreshAssets();
            };
            for (const asset of newContent) {
                if (asset.promptLoad) {
                    promptReload = true;
                    asset.promptLoad = false;
                }
            }
            if (promptReload) {
                //silently refresh assets, new content has been added
                handleRefresh();
            }
        }
    }, [newContent, storeId, brandCardId]);

    //filter options is raw input data
    const filterDependencies = {
        allBrands,
        tags,
        workspaces,
        allAssets,
    };

    const initFilters = (filterDependenciesInit = filterDependencies) => {

        const filterData: Filters = {};
        for (const [filterKey, filter] of Object.entries(filters)) {
            if (!filter.loadAfter && filter.show(filterDependenciesInit) && filter.enabled(filterDependenciesInit)) {
                filterData[filterKey] = filter.dataBuilder(filterDependenciesInit);
            }
        }
        //filter data is the processed and assigned data from `dataBuilder`
        setFilterData(filterData);
    };

    const handleSetFilter = (filterValues: Record<any, any>[]) => {
        setFilterValues(filterValues);
    };

    useEffect(() => {
        if (filters && tags && allBrands && workspaces && allAssets) {
            initFilters({
                tags,
                allBrands,
                workspaces,
                allAssets,
            });
        }
    }, [tags, allBrands, workspaces, allAssets]);

    const createAssetCallback = async() => {
        await refetchAssets();
    };

    const handlePreviewAsset = (asset: ApiAsset) => {
        const assetType = getFileType(asset.originalFilename);
        if (assetType === SUPPORTED_FILE_TYPES.PDF) {
            navigate(`${AuthRoutes.storeAssetViewer(storeId)}/${asset.id}`, {state: {from: `${AuthRoutes.storeBrands(storeId)}/${brandCardId}/assets`}});
        } else {
            navigate(`${AuthRoutes.storeBrands(storeId)}/${brandCardId}/assets/${asset.id}`);
        }
    };

    const handleCloseAssetPreview = () => {
        navigate(`${AuthRoutes.storeBrands(storeId)}/${brandCardId}/assets`);
    };

    const handleEditAssets = () => {
        const testBrandCard = _.get(multiSelectItems, [0, 'workspace', 'brandCard']) || multiSelectItems[0].brand;
        if (_.every(multiSelectItems, (asset) => _.get(asset, 'workspace.brandCard.id') === _.get(testBrandCard, 'id') || _.get(asset.brand, 'id') === _.get(testBrandCard, 'id'))) {
            setSelectedBrand(testBrandCard);
            setInititalBrand(testBrandCard);
        }
        const testWorkSpaceTitle = _.get(multiSelectItems, [0, 'workspace', 'title']);
        if (_.every(multiSelectItems, (asset) => _.get(asset, 'workspace.title') === testWorkSpaceTitle)) {
            setSelectedWorkspace({title: testWorkSpaceTitle});
        } else {
            setSelectedWorkspace({title: unchangedMultiSelectId});
        }

        const testTags = _.get(multiSelectItems, [0, 'tags']);
        const selectedAssetTags = [];
        for (const tag of testTags) {
            if (_.every(multiSelectItems, (asset) => {
                const tagSet = new Set(_.map(asset.tags, 'id'));
                return tagSet.has(tag.id);
            })) {
                selectedAssetTags.push(tag);
            }
        }
        setSelectedAssetTags(selectedAssetTags);

        if (multiSelectItems.length === 1) {
            setAssetName(multiSelectItems[0].name);
            if (multiSelectItems[0].isLink) {
                setAssetLink(multiSelectItems[0].url);
            }
            setAssetNotes(multiSelectItems[0].notes);
        }

        setShowEditModal(true);
    };

    const hideEditModal = () => {
        setIsSubmittingEditAssets(false);
        setShowEditModal(false);
        setAssetToEdit(undefined);
        setAssetName('');
        setAssetLink(undefined);
        setSelectedBrand(undefined);
        setInititalBrand(undefined);
        setSelectedWorkspace(undefined);
        setSelectedAssetTags(undefined);
        setAssetNotes('');
    };

    const handleDownloadAssets = () => {
        downloadAll(storeId, _.map(multiSelectItems, 'id'));
    };

    const handleDeleteAssets = async() => {
        await new Prompt().yesNoDanger(
            {
                body: multiSelectItems.length > 1 ? 'Are you sure you want to delete these assets?' : 'Are you sure you want to delete this asset?',
                title: multiSelectItems.length > 1 ? 'Delete Assets' : 'Delete Asset',
                displayType: 'delete',
                onYes: async() => {
                    await deleteAssets(_.map(multiSelectItems, 'id'));
                    setMultiSelectItems([]);
                },
            }
        );
    };

    const handleDeleteAsset = async(assetId: ID) => {
        await new Prompt().yesNoDanger(
            {
                body: 'Are you sure you want to delete this asset?',
                title: 'Delete Asset',
                displayType: 'delete',
                onYes: async() => {
                    setIsDeletingAsset(true);
                    await deleteAssets(assetId ? [assetId] : [_.get(assetToEdit, 'id', '')]);
                    setIsDeletingAsset(false);
                    hideEditModal();
                },
            }
        );
    };

    const handleCopyUrlToClipboard = (asset: ApiAsset) => {
        if (asset.isLink && asset.url) {
            textToClipboard(asset.url);
        } else {
            textToClipboard(buildUrl(
                '/store/{{storeId}}/brands/{{brandCardId}}/assets/{{assetId}}',
                {
                    storeId,
                    assetId: asset.id,
                    brandCardId,
                },
                false,
                false
            ));
        }
    };
    const buildAssetNameClasses = (asset: ApiAsset) => classnames(
        'asset-link',
        {
            'new-content-bubble': _.some(newContent, (newContentAsset) => newContentAsset.id === asset.id),
        }
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            cellTitle: (asset: ApiAsset) => asset.name,
            render: (UNUSED: any, asset: ApiAsset) => {
                return asset.isLink && asset.url
                    ? <a target='_blank' rel='noreferrer' href={buildUrlFromPartial(asset.url)} className={buildAssetNameClasses(asset)}>{asset.name}</a>
                    : <a className={buildAssetNameClasses(asset)} onClick={() => handlePreviewAsset(asset)}>{asset.name}</a>;
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            ellipsis: true,
            filtered: windowIsTiny,
            width: 95,
            cellTitle: (asset: ApiAsset) => asset.size ? displayFileSize(asset.size) : '',
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) => {
                return asset.size ? displayFileSize(asset.size) : '';
            },
        },
        {
            title: 'Source',
            dataIndex: 'creator.name',
            key: 'creator',
            filtered: windowIsSmall,
            ellipsis: true,
            width: workspaceId === 'ALL' ? '11%' : '15%',
            cellTitle: (asset: ApiAsset) => getContactDisplayBySource(asset),
            render: (UNUSED: any, asset: ApiAsset) => getContactDisplayBySource(asset),
        },
        {
            title: 'Category',
            dataIndex: 'tags',
            key: 'tag',
            filtered: windowIsTiny,
            width: workspaceId === 'ALL' ? '16%' : '22%',
            ellipsis: true,
            cellTitle: _.noop,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) =>
                <TagsRenderer
                    tags={_.get(asset, 'tags')}
                    tagClassName='asset-tag' />,
        },
    ];
    if (workspaceId === 'ALL') {
        columns.push({
            title: 'Workspace',
            width: '16%',
            ellipsis: true,
            filtered: windowIsSmall,
            dataIndex: ['workspace', 'title'],
            key: 'Workspace-title',
            // eslint-disable-next-line react/display-name
            render: (workspaceName: string) => {
                return workspaceName
                    ? (
                        <div className='tag workspace-tag single-line-ellipsis'>{workspaceName}</div>
                    )
                    : <span />;
            },
        });
    }
    if (_.some(assets, (asset) => asset.notes)) {
        columns.push({
            title: 'Notes',
            key: 'notes',
            className: 'notes-td',
            align: AlignType.center,
            width: 60,
            // eslint-disable-next-line react/display-name
            render: (UNUSED: any, asset: ApiAsset) => {
                return asset && asset.notes
                    ? (
                        <PopIcon
                            className='action-button primary-color'
                            popClassName='asset-dashboard-notes'
                            placement='bottomRight'
                            content={asset.notes}
                            origin={<span><Note /></span>} />
                    )
                    : <span />;
            },
        });
    }
    columns.push({
        key: 'actions',
        className: 'actions-td',
        width: 55,
        // eslint-disable-next-line react/display-name
        render: (UNUSED: any, asset: ApiAsset) => (
            <DropdownButton
                className='action-button primary-color'
                placement='bottomRight'
                items={[
                    {
                        key: 'edit',
                        visible: !readOnly && !asset.limitedByPlan,
                        onClick: () => {
                            setShowEditModal(true);
                            setAssetToEdit(asset);
                            setAssetName(asset.name);
                            if (asset.isLink) {
                                setAssetLink(asset.url);
                            }
                            const brandCard = _get(asset, 'workspace.brandCard') || _get(asset, 'brand');
                            setSelectedBrand(brandCard);
                            setInititalBrand(brandCard);
                            setSelectedWorkspace({title: _get(asset, 'workspace.title')});
                            setSelectedAssetTags(asset.tags);
                            setAssetNotes(_get(asset, 'notes', ''));
                        },
                        text: 'Edit',
                    },
                    {
                        key: 'copy_url',
                        onClick: () => handleCopyUrlToClipboard(asset),
                        text: 'Get Url',
                        visible: !asset.limitedByPlan,
                    },
                    {
                        key: 'download',
                        onClick: () => downloadAll(storeId, [asset.id]),
                        text: 'Download',
                        visible: !asset.limitedByPlan,
                    },
                    {
                        key: 'delete',
                        visible: !readOnly,
                        onClick: () => handleDeleteAsset(asset.id),
                        text: 'Delete',
                    },
                ]}>
                <Kabob />
            </DropdownButton>
        ),
    });

    const mergedColumns = columns.map((col) => {
        return {
            ...col,
            onCell: (record: ApiAsset) => ({
                record,
                key: col.key || col.dataIndex,
                title: col.dataIndex && (
                    col.cellTitle ? col.cellTitle(record) : _.get(record, col.dataIndex)
                ),
            }),
        };
    });

    const handleSetAssetName = (event: ReactEvent) => {
        setAssetName(event.target.value);
    };

    const handleSetAssetLink = (event: ReactEvent) => {
        setAssetLink(event.target.value);
    };

    const handleSetAssetBrand = (id: ID, selectedBrand: BrandCard) => {
        setSelectedBrand(selectedBrand);
    };

    const handleSetAssetWorkspace = (id: ID, selectedWorkspace: Record<'title', string>) => {
        setSelectedWorkspace(selectedWorkspace);
    };

    const handleWorkspaceSearchTextChange = (searchText: string) => {
        setNewWorkspace(searchText);
    };

    const handleOnKeyDownWorkspace = (e: any) => {
        if (e.key === 'Enter') {
            const values = _.filter(workspacesCompact, (workspace) => {
                return workspace.title.toLowerCase().includes(_.toLower(newWorkspace));
            });
            if (values.length === 0) {
                const newWorkspaceObject = {title: newWorkspace};

                setSelectedWorkspace(newWorkspaceObject);
                const workspacesCompactUpdated = [newWorkspaceObject, ..._.cloneDeep(workspacesCompact)];

                setWorkspacesCompact(workspacesCompactUpdated);
                setNewWorkspace('');
            }
        }
    };

    const handleSelectTags = (UNUSED: any, selectedTags: ApiTag[]) => {
        setSelectedAssetTags(selectedTags);
    };

    const handleSetAssetNotes = (event: ReactTextareaEvent) => {
        setAssetNotes(event.target.value);
    };

    const handleAddWorkspace = (event: ReactAnchorEvent) => {
        event.preventDefault();
        const newWorkspaceObject = {title: newWorkspace};

        setSelectedWorkspace(newWorkspaceObject);
        const workspacesCompactUpdated = [newWorkspaceObject, ..._.cloneDeep(workspacesCompact)];

        setWorkspacesCompact(workspacesCompactUpdated);
        setNewWorkspace('');
    };

    const handleSubmitAssetEdit = async() => {
        setIsSubmittingEditAssets(true);
        //these ignores cover when multiSelectItems have several workspaces, but the user does not wish to remove them
        const ignoreWorkspaceUpdate = Boolean(assetWorkspace && assetWorkspace.title === unchangedMultiSelectId);
        const masterAsset: MultiEditAsset = {};

        const isWorkspaceAsset = assetBrand && assetWorkspace && assetWorkspace.title && !ignoreWorkspaceUpdate;

        if (isWorkspaceAsset) {
            //workspace-level asset
            masterAsset.storeId = null;
            masterAsset.workspaceTitle = assetWorkspace.title;
            masterAsset.brandCardId = _get(assetBrand, 'id');
        } else if (assetBrand) {
            //brand-level asset
            masterAsset.brandCardId = _get(assetBrand, 'id');
            masterAsset.storeId = null;
            if (!ignoreWorkspaceUpdate) {
                masterAsset.workspaceTitle = null;
                masterAsset.workspaceId = null;
            }
        } else if (!assetBrand) {
            //store-level asset
            masterAsset.storeId = storeId;
            masterAsset.brandCardId = null;
            masterAsset.workspaceTitle = null;
            masterAsset.workspaceId = null;
        }

        if (assetName) {
            masterAsset.name = assetName;
        }

        if (assetNotes) {
            masterAsset.notes = assetNotes;
        }

        if (assetLink) {
            masterAsset.url = assetLink;
        }

        const tagsAbleToBeRemoved = assetToEdit ? assetToEdit.tags : [];
        if (!assetToEdit) {
            const testTags = _.get(multiSelectItems, [0, 'tags']);
            for (const tag of (testTags || [])) {
                if (_.every(multiSelectItems, (asset) => {
                    const tagSet = new Set(_.map(asset.tags, 'id'));
                    return tagSet.has(tag.id);
                })) {
                    tagsAbleToBeRemoved.push(tag);
                }
            }
        }

        await saveAssetsApi(
            masterAsset,
            assetTags,
            tagsAbleToBeRemoved,
            assetToEdit ? [assetToEdit] : multiSelectItems,
            false,
            ignoreWorkspaceUpdate,
            isWorkspaceAsset,
            assetWorkspace && assetWorkspace.title
        );
        if (masterAsset.workspaceTitle && reloadWorkspaces) {
            await reloadWorkspaces();
        }
        hideEditModal();
        setMultiSelectItems([]);
    };

    const editAssetsModalTitle = (
        <div className='edit-assets-modal-title'>
            {
                shouldMultiEdit
                    ? `Edit Assets (${multiSelectItems.length})`
                    : 'Edit Asset'
            }
        </div>
    );
    const hasAnyAssets = Boolean(allAssets && allAssets.length > 0);
    const limitedBrands = _.filter(allBrands, (brandCard) => !brandCard.isLimitedByPlan);
    const storageIsOverPlanCapacity = _.get(currentStoreStatus, 'areAssetsAtLimit');

    const handleMultiSelectItems = (UNUSED: any, selectedAssets: ApiAsset[], {type}: Record<'type', string>) => {
        if (type === 'all') {
            const filteredAssets = _.filter(assets, (asset) => !asset.limitedByPlan);
            if (multiSelectItems.length && (multiSelectItems.length === filteredAssets.length)) {
                setMultiSelectItems([]);
                setSelectedRowKeys([]);
            } else {
                setMultiSelectItems(filteredAssets);
                setSelectedRowKeys(_.map(filteredAssets, 'id'));
            }
        } else {
            const filteredAssets = _.filter(selectedAssets, (asset) => !asset.limitedByPlan);
            setMultiSelectItems(filteredAssets);
            setSelectedRowKeys(_.map(filteredAssets, 'id'));
        }
    };

    return (
        <div className='workspace-assets'>
            {!readOnly && (
                <Onboarding onboardType='brandCardAssets' />
            )}
            <div className='workspace-assets-container'>
                <ControlHeader
                    setSearch={setTextSearchValue}
                    search={textSearchValue}
                    handleSetFilter={handleSetFilter}
                    handleRemoveFilter={removeFilterFactory(filterValues, handleSetFilter)}
                    multiSelectActions={[
                        {
                            key: 'edit',
                            onClick: handleEditAssets,
                            text: 'Edit',
                            disabled: readOnly || !multiSelectItems.length,
                        },
                        {
                            key: 'download',
                            onClick: handleDownloadAssets,
                            text: 'Download',
                            disabled: _.every(multiSelectItems, {isLink: true}),
                        },
                        {
                            key: 'delete',
                            onClick: handleDeleteAssets,
                            text: 'Delete',
                            disabled: readOnly || !multiSelectItems.length,
                        },
                    ]}
                    disableSearch={isLoading}
                    filterValues={filterValues}
                    placeholder='Search description'
                    filterData={filterData}
                    filters={filters}
                    showSort={!isLoading && Boolean(allAssets && allAssets.length)}
                    sortOptions={[
                        {
                            key: 'createdAt',
                            title: 'Date Added',
                            isReversed: true,
                        },
                        {
                            key: 'name',
                            title: 'Name',
                        },
                        {
                            key: 'size',
                            title: 'Size',
                            isReversed: true,
                            shouldSortEmpty: true,
                        },
                        {
                            key: 'source',
                            title: 'Source',
                            func: (asset) => getContactDisplayBySource(asset),
                        },
                    ]}
                    setSortOption={setSortBy}
                    activeSort={sortBy} />

                <DropdownBtnCreateAsset onCreate={createAssetCallback} brandCardId={brandCardId} workspaceId={workspaceId} disabled={readOnly || storageIsOverPlanCapacity} />
            </div>
            {isLoading && (
                <Loading fill size={56} />
            )}
            {!isLoading && (
                <Table
                    locale={hasAnyAssets
                        ? {emptyText: (<NoResults />)}
                        : {emptyText: (<EmptyState objectName='Asset' isAsset={true} brandCardId={brandCardId} workspaceId={workspaceId} handleCreate={createAssetCallback} readOnly={readOnly} />)}}
                    scrollParentSelector='.brandcard-assets-table .ant-table-body'
                    columns={mergedColumns}
                    id='WORKSPACE_ASSET_TABLE'
                    shouldScroll={true}
                    data={assets}
                    rowClassName={(asset) => asset.limitedByPlan ? 'disabled-table-row--allow-actions' : undefined}
                    useCheckboxes={true}
                    sortBy={sortBy}
                    onCheckboxClick={handleMultiSelectItems}
                    selectedRowKeys={selectedRowKeys}
                    className='brandcard-assets-table' />
            )}
            <Modal
                className='edit-asset-modal'
                open={showEditModal}
                title={editAssetsModalTitle}
                footer={false}
                onCancel={hideEditModal}
                destroyOnClose>
                <div className='modal-body'>
                    {!shouldMultiEdit && (
                        <Input
                            name='asset-name'
                            required
                            label='Name'
                            onChange={handleSetAssetName}
                            value={assetName} />
                    )}
                    {!shouldMultiEdit && assetToEdit?.isLink && (
                        <Input
                            name='asset-link'
                            required
                            label='Link'
                            onChange={handleSetAssetLink}
                            value={assetLink} />
                    )}
                    <div>
                        <div className='pop-label'>
                            <label className='form-label' htmlFor='asset-brand'>
                                Brand Card
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content={shouldMultiEdit
                                    ? 'The Brand Card field is a dropdown menu with all of your created brand cards. Choosing a brand will associate all of your selected assets to that particular brand and make it viewable in the Brand Card. If this field says ‘several’ it means your selected assets are associated with different brands and any changes will uniformly be applied to all.'
                                    : 'The Brand Card field is a dropdown menu with all of your created brand cards. Choosing a brand will associate this asset to that particular brand and make it viewable in the Brand Card.'
                                }
                                origin={<img src={tipIcon} />} />
                        </div>
                        <Select
                            notFoundContent={allBrands?.length
                                ? (<div>No results found</div>)
                                : (<div>No Brands added yet</div>)}
                            className='asset-brand-select'
                            message={(_.get(initialBrand, 'id') === _.get(assetBrand, 'id'))
                                ? undefined
                                : `Once this action is confirmed, ${shouldMultiEdit ? 'these assets' : 'this asset'} will no longer be viewable in the ${_.get(initialBrand, 'name')} Brand Card.`}
                            id='asset-brand'
                            labelKey='name'
                            onChange={handleSetAssetBrand}
                            options={limitedBrands}
                            valueKey='id'
                            value={assetBrand} />
                    </div>
                    <div>
                        <div className='pop-label'>
                            <label className='form-label' htmlFor='asset-workspace'>
                                Workspace
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content={shouldMultiEdit
                                    ? 'The workspace field is a dropdown menu with all of your created workspaces. You can use it to select an existing workspace or create a new one! Choosing a workspace will associate all of your selected assets to a workspace within Brand Card and make them viewable there. If this field says ‘several’ it means your selected assets are associated with different workspaces and any changes will uniformly be applied to all.'
                                    : 'The workspace field is a dropdown menu with all of your created workspaces. You can use it to select an existing workspace or create a new one! Choosing a workspace will associate this asset to a workspace within Brand Card and make it viewable there.'
                                }
                                origin={<img src={tipIcon} />} />
                        </div>
                        <Select
                            notFoundContent={workspaces?.length || newWorkspace.length > 0
                                ? (<div/>)
                                : (<div>No Workspaces added yet</div>)}
                            className='asset-workspace-select'
                            disabled={!assetBrand}
                            id='asset-workspace'
                            labelKey='title'
                            onChange={handleSetAssetWorkspace}
                            onSearch={handleWorkspaceSearchTextChange}
                            options={workspacesCompact}
                            valueKey='title'
                            onKeyDown={handleOnKeyDownWorkspace}
                            dropdownRender={(menu) => (
                                <>
                                    {newWorkspace.length > 0 && (
                                        <Space align='center' style={{padding: '0 8px 4px'}}>
                                            <Typography.Link onClick={handleAddWorkspace} style={{whiteSpace: 'nowrap'}}>
                                                <PlusOutlined /> Add new <b>{newWorkspace}</b>
                                            </Typography.Link>
                                        </Space>
                                    )}
                                    {menu}
                                    <Divider style={{margin: '8px 0'}} />
                                </>
                            )}
                            value={assetWorkspace} />
                    </div>
                    <div>
                        <div className='pop-label'>
                            <label className='form-label' htmlFor='tags'>
                                Category
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content={shouldMultiEdit
                                    ? 'Asset Category tags allow you to organize your assets to ensure you can find them quickly. Some common asset categories are: Workbook, Annotated Workbook, Order Form, Completed Order Form, Order Confirmation, UPC list, MAP Policy, Terms Sheet, Product Imagery. If a tag is showing up here, it means it is applied to ALL of your selected assets. Adding tags to multiple assets at once will not remove existing tags that may only be applied to a few.'
                                    : 'Asset Category tags allow you to organize your assets to ensure you can find them quickly. Some common asset categories are: Workbook, Annotated Workbook, Order Form, Completed Order Form, Order Confirmation, UPC list, MAP Policy, Terms Sheet, Product Imagery.'
                                }
                                origin={<img src={tipIcon} />} />
                        </div>
                        <TagsEditable
                            notFoundContent={(
                                <div>No Categories added yet</div>
                            )}
                            tags={tags}
                            placeholder='Asset Category'
                            id='tags'
                            isEdit={true}
                            onChange={handleSelectTags}
                            selectedTags={assetTags} />
                    </div>
                    {!shouldMultiEdit && (
                        <div>
                            <div className='pop-label'>
                                <label className='form-label' htmlFor='notes'>
                                    Notes
                                </label>
                                <PopIcon
                                    className='btn-tip'
                                    type='tip'
                                    content='Use notes to keep track of useful information that can be viewed by you and the rest of your team.'
                                    origin={<img src={tipIcon} />} />
                            </div>
                            <textarea
                                id='notes'
                                className='form-textarea'
                                maxLength={250}
                                value={assetNotes}
                                placeholder='Add Notes (250 characters max)'
                                onChange={handleSetAssetNotes} />
                        </div>
                    )}
                </div>
                <div className='modal-footer'>
                    {!shouldMultiEdit && (
                        <Button
                            onClick={handleDeleteAsset}
                            className='btn-delete'>
                            {isDeletingAsset && (
                                <Loading inline />
                            )}
                            Delete Asset
                        </Button>
                    )}
                    <div
                        className='cancel-button'
                        onClick={hideEditModal}>
                        Cancel
                    </div>
                    <Button
                        disabled={isSubmittingEditAssets || (!shouldMultiEdit && !assetName)}
                        className='btn-secondary'
                        onClick={handleSubmitAssetEdit}>
                        {isSubmittingEditAssets && (
                            <Loading inline />
                        )}
                        Save
                    </Button>
                </div>
            </Modal>
            <AssetPreview
                asset={assetToPreview}
                showTopbar={false}
                readOnly={readOnly}
                copyUrl='/store/{{storeId}}/brands/{{brandCardId}}/assets/{{assetId}}'
                urlData={{brandCardId}}
                onDownload={() => downloadAll(storeId, [assetToPreview.id])}
                show={Boolean(_.get(assetToPreview, 'id'))}
                onClose={handleCloseAssetPreview} />
        </div>
    );
};

export default WorkspaceAssets;
