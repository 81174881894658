import {ID} from '../CommonTypes';

export interface TagToApiSave {
    id?: ID
    refType: TagRefType
    scope: TagsScope
    scopeId: ID
    title: string
}

export interface ApiTag extends TagToApiSave {
    id: ID
    createdAt: string
    updatedAt: string
}

export interface NormalizedTag {
    id?: ID
    refType?: TagRefType
    scope?: TagsScope
    scopeId?: ID
    title: string
    createdAt?: string
    updatedAt?: string
}

export enum TagRefType {
    BrandCards = 'brand_cards',
    Contacts = 'contacts',
    Assets = 'assets',
}

export enum TagsScope {
    Store = 'store',
    Brand = 'brand',
}

export interface BuiltTag {
    id: string
    originKeys: string[]
}
