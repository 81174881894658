import React from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import HeaderBar from 'src/Components/HeaderBar';
import Loading from 'src/Components/Loading';
import {Collapse, Typography} from 'antd';
const {Panel} = Collapse;
import StoreDashboard from 'src/Views/Admin/StoreDashboard';
import StoreDetail from './StoreDashboard/StoreDetail';


const Admin = (): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const loading = false;

    const subMenu = [
        {
            name: 'Stores',
            type: 'stores',
            redirectHandler: () => {
                navigate('/admin/stores');
            },
        },
    ];

    if (loading) {
        return (<Loading fill size={56} />);
    }

    return (
        <>
            <HeaderBar className='scene-admin' isAdmin={true} />
            <div className='pageWrapper'>
                <Collapse
                    bordered={false}
                    expandIconPosition='end'
                    defaultActiveKey={[1]}
                    className='sidebarMenuWrapper'>
                    <Panel
                        key={1}
                        header={<div className='title'>ADMIN</div>}
                        className='accordionItem'>
                        {subMenu.map((element) => (
                            <Typography.Link
                                onClick={() => element.redirectHandler()}
                                key={`${element.type}`}
                                className={[
                                    'subMenu',
                                    location.pathname.includes(element.type) && 'subMenuSelected',
                                ].join(' ')}>
                                {element.name}
                            </Typography.Link>
                        ))}
                    </Panel>
                </Collapse>
                <div className='internalPage' id='internalPage'>
                    <Routes>
                        <Route path='stores'
                            element={<StoreDashboard />} />
                        <Route path='stores/:adminStoreId'
                            element={<StoreDetail />} />
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default Admin;
