import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    useNavigate,
} from 'react-router-dom';
import {checkIsUserAuthByCode, getUserStatus} from 'src/redux/auth/actions';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import {RootState} from '../../redux/rootReducer';
import './styles.sass';

const LoginPage: React.FC = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const {isSignedIn, isLoading, signInUrl} = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getUserStatus());
    }, [dispatch]);

    useEffect(() => {
        if (isSignedIn && !isLoading) {
            dispatch(getStoreStatus());
            navigate('/store');
            return;
        }

        if (code && !isSignedIn && !isLoading) {
            dispatch(checkIsUserAuthByCode(code));
            return;
        }
        if (isLoading === false && isSignedIn === false && signInUrl) {
            navigate('/signIn');
        } else {
            dispatch(getUserStatus());
            return;
        }
    }, [isSignedIn, isLoading, signInUrl, dispatch, navigate, code]);

    return <></>;
};

export default LoginPage;
