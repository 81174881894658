import _ from 'lodash';
import * as Yup from 'yup';

const useValidationSchema = () => {
    return Yup.object().shape({
        invites: Yup.array().of(
            Yup.object().shape({
                email: Yup.string().email('Invalid email format').when(['role'], {
                    is: (role) => {
                        return !_.isEmpty(role);
                    },
                    then: Yup.string().required('Required'),
                }),
                role: Yup.object().when(['email'], {
                    is: (email) => {
                        return email && email !== '';
                    },
                    then: Yup.object().required(),
                }),
            },
            [
                ['email', 'role'],
            ])
        ),
    });
};

export default useValidationSchema;
