import apiHelper from 'src/api/api-helper';

interface Auth {
    username: string
    password: string
}

interface SignUpPayload {
    username: string
    newPassword: string
    firstName: string
    lastName: string
}

interface ResetPassword {
    username: string
}

interface ResendVerificationEmail {
    username: string
}

interface NewPasswordChallengePayload {
    code: string,
    name: string,
    familyName: string,
    password: string
}

export const signInApi = (auth: Auth) => {
    return apiHelper.post('/auth/signin', auth);
};

export const invitedUserSignUp = (payload: NewPasswordChallengePayload) => {
    return apiHelper.post('/auth/invited_user_sign_up', payload);
};

export const signUpApi = (signUp: SignUpPayload) => {
    return apiHelper.post('/auth/signup', signUp);
};

export const resendVerificationEmail = (payload: ResendVerificationEmail): Promise<boolean> => {
    return apiHelper.post('/auth/resendVerificationEmail', payload);
};

export const forgotPassword = (payload: ResetPassword) => {
    return apiHelper.post('/auth/forgot_password', payload);
};

export const confirmSignUpApi = (code: string) => {
    return apiHelper.post(`auth/confirm_email/${code}`, {});
};

export const confirmResetPassApi = (code: string, newPassword: string) => {
    return apiHelper.post(`auth/forgot_password/confirm/${code}`, {newPassword});
};
