import React, {useState} from 'react';
import './styles.sass';
import _ from 'lodash';
import {formatDate} from 'src/lib/date-helper';

import circleBackground from '/src/assets/images/pink-orange-circle.svg';
import contactImage from '/src/assets/images/contact.png';
import linkImage from '/src/assets/images/link.png';
import photoImage from '/src/assets/images/photo.png';


//components
import {Checkbox} from 'antd';
import Button from 'src/Components/Button';

//types
import {genericFn} from 'src/Types/CommonTypes/index';
import {PlanGroup} from 'src/constants/subscriptionPlans';

//libs
import {classnames} from 'src/utils/general';

interface Props {
    className?: string
    newPlan: PlanGroup
    onCancel: genericFn
    onContinue: genericFn
    planEnd: string
    planName?: string
}

const Downgrade: React.FC<Props> = ({
    className = '',
    onCancel,
    onContinue,
    newPlan,
    planEnd,
    planName = '',
}): JSX.Element => {
    const [confirmDowngrade, setConfirmDowngrade] = useState(false);

    const computedClassName = classnames(
        'Downgrade',
        {
            [className]: className,
        }
    );

    const handleConfirm = () => {
        onContinue(newPlan);
    };

    return (
        <div className={computedClassName}>
            <div className='modal-background-image-container'>
                <img className='background-image' src={circleBackground} />
                <img className='accent-image top-image' src={contactImage} />
                <img className='accent-image middle-image' src={linkImage} />
                <img className='accent-image bottom-image' src={photoImage} />
            </div>
            <div className='modal-body'>
                <div className='Downgrade-body'>
                    <div className='Downgrade-blurb large-font-size'>
                        {'By downgrading, you\'ll lose access to some BrandKeep features and data at the end of the current billing period.'}
                    </div>
                    <div className='Downgrade-blurb-small medium-font-size'>
                        Upgrade at anytime to regain access.
                    </div>
                    <Button className='keep-plan-button theme-purple theme-background' onClick={onCancel}>Keep BrandKeep {_.capitalize(planName)}</Button>
                    <div className='confirm-checkbox'>
                        <Checkbox
                            onChange={() => setConfirmDowngrade((confirmDowngrade) => !confirmDowngrade)}
                            checked={confirmDowngrade}>
                            I acknowledge that my store will lose access to all BrandKeep {_.capitalize(planName)} features on {formatDate(planEnd)}
                        </Checkbox>
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <Button className='btn-secondary' onClick={handleConfirm} disabled={!confirmDowngrade}>
                    Continue with Downgrading
                </Button>
            </div>
        </div>
    );
};

export default Downgrade;
