import {StoreActions, StoreActionTypes, StoreState} from './actionTypes';

const initialState: StoreState = {
    loading: false,
    createdStore: undefined,
    error: '',
};

const StoreReducer = (state = initialState, action: StoreActions): StoreState => {
    switch (action.type) {
        case StoreActionTypes.CREATE_STORE: {
            return {...state, loading: true};
        }

        case StoreActionTypes.CREATE_STORE_SUCCESS: {
            return {
                ...state,
                loading: false,
                createdStore: action.payload.store,
            };
        }

        case StoreActionTypes.CREATE_STORE_FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }

        case StoreActionTypes.CLEAR_STORE: {
            return {
                ...state,
                loading: false,
                createdStore: undefined,
            };
        }

        default:
            return state;
    }
};

export default StoreReducer;
