import React from 'react';
import './styles.sass';

interface Props {
    steps: Array<string>
    currentStep: number
    leftElement?: React.ReactNode
    rightElement?: React.ReactNode
}

const Steps: React.FC<Props> = ({leftElement, steps, currentStep, rightElement}) => {
    return (
        <div className='step-wrapper'>
            <div>{leftElement}</div>
            <div className='steps'>
                {steps.map((step, stepIndex) => {
                    const classNames = ['step'];
                    if (currentStep === (stepIndex + 1)) {
                        classNames.push('activeStep');
                    }
                    if (currentStep > (stepIndex + 1)) {
                        classNames.push('finishedStep');
                    }
                    return (
                        <div key={step} className={classNames.join(' ')}>
                            <div className='stepIndex'>{stepIndex + 1}</div>
                            <div className='stepName'>{step}</div>
                        </div>
                    );
                })}
            </div>
            <div>{rightElement}</div>
        </div>
    );
};

export default Steps;
