import Loading from 'src/Components/Loading';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import {NonAuthRoutes} from 'src/constants/userRoles';
import {RootState} from '../redux/rootReducer';

interface Props {
    children: JSX.Element
}

const AdminAuthRoute = ({children}: Props): JSX.Element => {
    const location = useLocation();
    const auth = useSelector((state: RootState) => state.auth);
    const message = 'Please log in to view this page';

    if (auth.isLoading !== false) {
        return (<Loading fill size={56} />);
    }

    return auth.isSignedIn && auth.canUseAdminDashboard
        ? children
        : <Navigate
            to={NonAuthRoutes.signin}
            state={{
                message,
                requestedPath: location && location.pathname,
            }} />;
};

export default AdminAuthRoute;
