export const contactsOnboarding = {
    addContact: {
        header: 'Use the ‘+ Contact’ button to add new contacts to your store.',
        text: 'Associate them with a brand card and add notes to help remember important things.',
        knowledgebaseLink: '',
        targetId: 'create-contact-button',
        targetClassesToApply: ['pulse'],
        //stop animating the dropdown menu
        onShowOnboarding: (): void => {
            setTimeout(() => {
                const targetedElementForHighlight = document.querySelector('#contact-import-menu');
                if (targetedElementForHighlight) {
                    targetedElementForHighlight.classList.remove('pulse');
                }
            });
        },
    },
    importContact: {
        header: 'Looking for a faster way to get your shop built out in BrandKeep?',
        text: 'Utilize contact imports to create several contacts at once or make bulk updates to those that already exist.',
        knowledgebaseLink: '',
        targetId: 'create-contact-button',
        targetClassesToApply: ['pulse', 'origin-bottom'],
        onShowOnboarding: (): void => {
            setTimeout(() => {
                const targetedElementForHighlight = document.querySelector('#contact-import-menu');
                if (targetedElementForHighlight) {
                    targetedElementForHighlight.classList.add('pulse');
                    targetedElementForHighlight.classList.add('origin-top');
                }
            });
        },
    },
    multiSelectContact: {
        header: 'Utilize the multi-select Actions menu.',
        text: 'Here you can make changes or remove multiple contacts easily.',
        knowledgebaseLink: '',
        targetId: 'multi-select-contact-button',
        targetClassesToApply: ['pulse-large'],
        //stop animating the dropdown menu
        onShowOnboarding: (): void => {
            setTimeout(() => {
                const targetedElementForHighlight = document.querySelector('#contact-import-menu');
                if (targetedElementForHighlight) {
                    targetedElementForHighlight.classList.remove('pulse');
                }
            });
        },
    },
};
