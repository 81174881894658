import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import './styles.sass';

//components
import {Table as AntTable} from 'antd';
import InfiniteScroll from 'src/Components/InfiniteScroll';

//types
import {genericFn} from 'src/Types/CommonTypes';
import {ColumnType} from 'antd/lib/table';

//libs
import {classnames} from 'src/utils/general';
import useWindow from 'src/hooks/useWindow';
import {TableLocale} from 'antd/lib/table/interface';

interface RowData {
    [key: string]: any
}

export interface SortBy {
    key: string
    title: string
    func?: genericFn
    isReversed?: boolean
    useNaturalSort?: boolean
    shouldSortEmpty?: boolean
}

interface Props {
    columns: Array<ColumnType<RowData>>
    data: RowData[]
    className?: string
    // scroll?: Record<'x' | 'y', any>
    shouldScroll?: boolean
    rowKey?: string
    rowSelection?: any
    useCheckboxes: boolean
    onCheckboxClick?: genericFn
    isRowDisabled?: genericFn
    scrollParentSelector?: string
    rowClassName?: string | genericFn
    id: string //needed for dynamic scrolling!
    sortBy?: SortBy
    locale?: TableLocale
    selectedRowKeys?: any[]
}


const Table = React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLFormElement>): JSX.Element => {
    const {
        data,
        columns,
        className = '',
        rowClassName,
        rowKey,
        id,
        shouldScroll = false,
        useCheckboxes = false,
        onCheckboxClick,
        isRowDisabled = _.noop,
        scrollParentSelector = '#internalPage',
        sortBy = {},
        locale,
        selectedRowKeys,
    } = props;
    const [scrollerEnd, setScrollerEnd] = useState(50);
    const [scrollY, setScrollY] = useState<number | string>('calc(100vh - 254px)');

    const sortData = (data: any): RowData[] => {
        const sortByField = _.get(sortBy, 'key');
        if (sortByField && data && data.length) {
            const sortByFunc = sortBy && sortBy.func
                ? _.get(sortBy, 'func')
                : (d: any) => _.toLower(_.get(d, sortByField));

            let sortedArray;
            if (_.get(sortBy, 'useNaturalSort')) {
                sortedArray = _.sortBy(
                    data,
                    (d) => typeof sortByFunc(d) === 'string'
                        ? sortByFunc(d).toLowerCase()
                        : (sortByFunc(d) || undefined)
                );
            } else {
                sortedArray = _.cloneDeep(data).sort((a: any, b: any) => {
                    const sortA = sortByFunc(a);
                    const sortB = sortByFunc(b);
                    const shouldSortEmpty = _.get(sortBy, 'shouldSortEmpty');
                    if (!shouldSortEmpty) {
                        if (!sortA) {
                            return 1;
                        }
                        if (!sortB) {
                            return -1;
                        }
                    }
                    return (sortA || '').toString().localeCompare((sortB || '').toString(), navigator.languages[0] || navigator.language, {ignorePunctuation: true, numeric: true, sensitivity: 'base'});
                });
            }

            if (_.get(sortBy, 'isReversed')) {
                sortedArray.reverse();
            }
            return sortedArray;
        } else {
            return data;
        }
    };

    const sortedData = sortData(data);
    const limitedData = _.slice(sortedData, 0, scrollerEnd);

    const {windowHeight} = useWindow();


    useEffect(() => {
        const targetBoundingClientDefault = document.querySelector(`#${id}`) && document.querySelector(`#${id}`).getBoundingClientRect();
        if (targetBoundingClientDefault) {
            setScrollY(windowHeight - targetBoundingClientDefault.top - 78);
        }
    }, [id, windowHeight]);

    const classes = classnames(
        'Table',
        {[className]: className},
        {'Table-scrolls': shouldScroll}
    );

    const handleRowClasses = (record: any): string => {
        const rowClasses: string[] = [];
        if (_.isFunction(rowClassName)) {
            rowClasses.push(rowClassName(record));
        } else if (rowClassName) {
            rowClasses.push(rowClassName);
        } else {
            rowClasses.push('Table-row');
        }
        if (isRowDisabled(record)) {
            rowClasses.push('disabled');
        }
        return rowClasses.join(' ');
    };

    let rowSelection = undefined;
    if (props.rowSelection) {
        rowSelection = props.rowSelection;
    } else if (useCheckboxes) {
        rowSelection = {
            type: 'checkbox',
            onChange: onCheckboxClick,
            getCheckboxProps: (record: any) => ({
                disabled: isRowDisabled(record) || false,
                name: record.name,
            }),
        };
        if (selectedRowKeys) {
            rowSelection.selectedRowKeys = selectedRowKeys;
        }
    }

    return (
        <InfiniteScroll
            incrementAmount={10}
            scrollParentSelector={scrollParentSelector}
            scrollerEnd={scrollerEnd}
            totalItemCount={_.get(data, 'length', 0)}
            onChange={setScrollerEnd}
            pixelLead={150}>
            <AntTable
                className={classes}
                id={id}
                rowKey={(record) => {
                    return record && ((rowKey && record[rowKey]) || record.key || record.id);
                }}
                rowSelection={rowSelection}
                dataSource={limitedData}
                scroll={shouldScroll ? {y: scrollY} : undefined}
                columns={_.filter(columns, (column) => !column.filtered)}
                rowClassName={handleRowClasses}
                locale={locale}
                pagination={false} />
        </InfiniteScroll>
    );
});

export default Table;
