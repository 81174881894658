import {useNavigate} from 'react-router-dom';
import {confirmResetPassApi} from 'src/api/Auth';
import {setTokens} from 'src/utils/token.utils';

const useSubmit = () => {
    const navigate = useNavigate();
    const submitHandler = async(values) => {
        try {
            const response = await confirmResetPassApi(
                values.code,
                values.password
            );
            if (response.challenge) {
                navigate('/signIn');
            } else {
                setTokens(response.tokens!.accessToken, response.tokens!.refreshToken, response.username);
                navigate('/');
            }
        // todo fix
        // eslint-disable-next-line no-empty
        } catch (e) {}
    };
    return {submitHandler};
};

export default useSubmit;
