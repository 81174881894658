//deprecate/change the superAdmin elements of this

import {NavigateFunction, Location} from 'react-router-dom';
import {genericFn} from 'src/Types/CommonTypes';

export enum UserRoles {
    anonym = 'anonym',
    superAdmin = 'superAdmin',
    admin = 'admin',
    nonAdmin = 'nonAdmin',
}

export const userRoles = {
    admins: [String(UserRoles.superAdmin), String(UserRoles.admin)],
    users: [String(UserRoles.nonAdmin)],
    all: [String(UserRoles.superAdmin), String(UserRoles.admin), String(UserRoles.nonAdmin)],
};

export enum NonAuthRoutes {
    signin = '/signin',
    home = '/',
}

//lets more all this to a real helper someday
export const AuthRoutes = {
    home: '/store',
    onboardingInviteTeam: '/onboarding/invite-team',
    onboardingCreateStore: '/onboarding/create-store',
    onboardingMobileMessage: '/onboarding/mobile-message',
    storeBrands: (storeId: number | string): string => `/store/${storeId}/brands`,
    storeAssets: (storeId: number | string): string => `/store/${storeId}/assets`,
    storeContacts: (storeId: number | string): string => `/store/${storeId}/contacts`,
    storeTasks: (storeId: number | string): string => `/store/${storeId}/tasks`,
    storeMyStore: (storeId: number | string): string => `/store/${storeId}/my-store`,
    storeAssetViewer: (storeId: number | string): string => `/store/${storeId}/asset-viewer`,
};

type routeByStorePathnameType = {
    [key: string]: genericFn
};

const routeByStorePathname: routeByStorePathnameType = {
    brands: AuthRoutes.storeBrands,
    assets: AuthRoutes.storeAssets,
    contacts: AuthRoutes.storeContacts,
    tasks: AuthRoutes.storeTasks,
    'my-store': AuthRoutes.storeMyStore,
    'asset-viewer': AuthRoutes.storeAssetViewer,
};

export const navigateToSameRouteInStore = (navigate: NavigateFunction, location: Location, storeId: number | string): void => {
    const urlElements = location.pathname.split('/');
    navigate(urlElements?.length && urlElements[3]
        ? routeByStorePathname[urlElements[3]](storeId)
        : AuthRoutes.storeBrands(storeId)
    );
};
