import {Dropdown} from 'antd';
import Button from 'src/Components/Button';
import './styles.sass';
import _ from 'lodash';
import {genericFn, ID} from 'src/Types/CommonTypes';
import {classnames, filterMap} from 'src/utils/general';
import {Kabob} from 'src/lib/svg-icon-helper';

export interface Props {
    className?: string
    id?: string
    menuId?: string
    disabled?: boolean
    hasMainAction?: boolean
    items?: menuItem[]
    title?: string | JSX.Element
    primaryIcon?: string | JSX.Element
    children?: React.ReactNode
    placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'topCenter' | 'bottomCenter'
    open?: boolean
}

interface menuItem {
    key: ID
    href?: string
    onClick?: genericFn
    className?: string
    optionRenderer?: genericFn
    text?: string
    disabled?: boolean | genericFn
    visible?: boolean
    download?: string
}

const DropdownButton: React.FC<Props> = ({
    className = '',
    id = '',
    children,
    disabled,
    hasMainAction,
    items,
    title,
    primaryIcon,
    placement = 'bottomLeft',
    menuId = '',
    ...props
}) => {
    const classes = classnames(
        'DropdownButton',
        {[className]: className},
        {'btn': !children}
    );

    const menu = items && filterMap(
        items,
        (item) => typeof item.visible === 'undefined' ? true : item.visible,
        (props) => {
            const {optionRenderer, text, className, key, onClick, disabled, href} = props;
            const classes = _.compact(['dropdown-menu-button', className]);
            return {
                key,
                disabled: _.isFunction(disabled) ? disabled() : disabled,
                label: optionRenderer
                    ? optionRenderer(props)
                    : <a
                        className={classes.join(' ')}
                        {...{
                            ...(onClick) && {onClick},
                            ...(href) && {href},
                        }}>
                        {text}
                    </a>,
            };
        }
    );

    let body = <Button id={id}>{primaryIcon}{title}</Button>;
    if (children) {
        if (hasMainAction) {
            body = <a className='btn btn-white dropdown-trigger' id={id}><Kabob className='dropdown-kabob' /></a>;
        } else {
            body = <a className='dropdown-trigger' id={id}>{children}</a>;
        }
    }

    return (
        <>
            {hasMainAction && children}
            <Dropdown
                {...props}
                className={classes}
                overlayClassName='dropdown-menu'
                menu={{
                    id: menuId,
                    items: menu,
                }}
                trigger={['click']}
                placement={placement}
                disabled={disabled}>
                {body}
            </Dropdown>
        </>
    );
};

export default DropdownButton;
