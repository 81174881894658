import React, {useEffect, useState, useCallback, useContext} from 'react';
import {RootState} from 'src/redux/rootReducer';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import _ from 'lodash';
import './styles.sass';

//icons
import {LinkOutlined, DeleteFilled} from '@ant-design/icons';
import pen from 'src/assets/icons/pen.svg';
import disabledLogo from 'src/assets/images/bk-disabled-2.svg';
import infoIcon from 'src/assets/icons/info.svg';

//components
import {Checkbox, Upload, message} from 'antd';
import Assets from 'src/Components/WorkspaceAssets';
import BrandCardForm from 'src/Components/BrandModal/BrandCardForm';
import BrandInfo from './BrandInfo';
import Button from 'src/Components/Button';
import Callout from 'src/Components/Callout';
import Contacts from './Contacts';
import Tasks from 'src/Components/TaskManager';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import ModalPrompt from 'src/Components/ModalPrompt';
import PopIcon from 'src/Components/PopIcon';
import TagsRenderer from 'src/Components/TagsRenderer';
import WorkspaceSidebar from 'src/Components/WorkspaceSidebar';

//apis
import {LinkRefTypes, ApiLink} from 'src/api/Link/api-link';
import {
    getBrandCardById,
    BrandCard as BrandCardType,
    upsertBrandCardLogo,
    updateBrandCardNotes,
    deleteBrandCard,
} from 'src/api/BrandCard/api-brand-card';
import {getTags, Tag} from 'src/api/Tags/api-tags';
import {getStoreRolesInfo, StoreInfoApi} from 'src/api/StoreRole/api-store-role';
import {removeAssetAssociations} from 'src/api/Assets/api-asset';

//types
import {fileTypes} from 'src/constants/fileTypes';
import {UploadProps} from 'src/Types/CommonTypes';

//libs
import {classnames, _get, buildUrlFromPartial, getErrorMessage, filterMap} from 'src/utils/general';
import {clearServerErrors} from 'src/redux/global/actions';
import useWindow from 'src/hooks/useWindow';
import {AuthRoutes} from 'src/constants/userRoles';
import {NewContentContext} from 'src/hooks/useNewContent';
import {StoreStatus} from 'src/redux/storeStatus/actionTypes';
import {logWorkspaceViewed} from 'src/api/Workspace/api-workspace';

export const parents = {
    assets: AuthRoutes.storeAssets,
    brands: AuthRoutes.storeBrands,
    contacts: AuthRoutes.storeContacts,
    tasks: AuthRoutes.storeTasks,
};

const BrandCard: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {storeId} = useParams<{storeId: any}>();
    const origin = _.get(location, 'state.from') || 'brands';
    const {brandCardId} = useParams<{brandCardId: any}>();
    const {brandCardTabId} = useParams<{brandCardTabId: any}>();
    const [tags, setTags] = useState<Tag[]>();
    const [userStoreInfo, setUserStoreInfo] = useState<StoreInfoApi>();
    const userId = useSelector((state: RootState) => state.auth.id);
    const [shouldLoad, setShouldLoad] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingBrandInfo, setIsEditingBrandInfo] = useState(false);
    const [isHoveringBrandImage, setIsHoveringBrandImage] = useState(false);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [brandInfo, setBrandInfo] = useState<BrandCardType>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [shouldDeleteBrandAssets, setShouldDeleteBrandAssets] = useState(false);
    const readOnly = !_get(userStoreInfo, 'role') || _get(userStoreInfo, 'role') === 'retail_read_only';
    const hasBrandInfo = brandInfo && brandInfo.id;
    const {windowIsSmall, windowIsTiny} = useWindow();
    const serverErrors = useSelector((state: RootState) => state.global.serverErrors);
    const dispatch = useDispatch();
    const storeStatus = useSelector((state: RootState) => state.storeStatus.storeStatus);
    const currentStoreStatus = storeStatus?.find((currentStore: StoreStatus) => `${currentStore.id}` === storeId);
    const {newContent, setNewContent} = useContext(NewContentContext);

    //use payload to only update specific parts
    const handleSaveBrandCard = async(payload: any) => {
        const finalPayload = payload || brandInfo;
        if (finalPayload && hasBrandInfo) {
            await updateBrandCardNotes(brandInfo.id, finalPayload);
        }
    };

    const brandCardTabs: Record<string, any> = {
        info: {
            title: 'Brand Information',
            component: <BrandInfo
                readOnly={readOnly}
                brandInfo={brandInfo}
                onChange={setBrandInfo}
                onSaveBrandCard={handleSaveBrandCard}
                setIsEditing={setIsEditingBrandInfo}
                isEditing={isEditingBrandInfo} />,
        },
        assets: {
            title: 'Brand Assets',
            component: hasBrandInfo && <WorkspaceSidebar brandCardId={brandInfo.id} WorkspaceChild={Assets} childType='assets' readOnly={readOnly} />,
        },
        contacts: {
            title: 'Contacts',
            component: hasBrandInfo && <Contacts brandId={brandInfo.id} readOnly={readOnly} />,
        },
        tasks: {
            title: 'Tasks',
            component: hasBrandInfo && <Tasks brandCardId={brandInfo.id} readOnly={readOnly} />,
            visible: _.get(currentStoreStatus, 'hasTasks'),
        },
    };

    const [activeTab, setActiveTab] = useState<string>((brandCardTabs[brandCardTabId] && brandCardTabId) || Object.keys(brandCardTabs)[0]);

    const handleViewedContent = () => {
        //if viewed asset tab!
        setNewContent(_.filter(
            newContent,
            (asset) => Number(asset.brandCardId || asset.brand?.id) !== Number(brandCardId)
        ));
        logWorkspaceViewed(storeId, brandCardId);
    };

    const handleCloseBrandCardModal = () => {
        if (activeTab === 'assets') {
            handleViewedContent();
        }
        const queryString = _.get(location, 'state.search');

        navigate(
            queryString
                ? parents[origin](storeId) + queryString
                : parents[origin](storeId),
            {state: {from: 'brandCard'}}
        );
    };

    const fetchBrand = async() => {
        try {
            const fetchedBrand = await getBrandCardById(brandCardId);
            setBrandInfo(fetchedBrand);
        } catch (error) {
            handleCloseBrandCardModal();
            message.error('Brand Card not found, or it was deleted.');
        }
    };

    useEffect(() => {
        if (brandCardId) {
            fetchBrand();
        }
    }, [brandCardId]);


    const fetchTags = useCallback(async(force = false) => {
        if (!tags || !tags.length || force) {
            const newTags = await getTags('brand_cards', 'store', storeId);
            setTags(newTags);
        }
    }, [storeId, tags]);

    const fetchUserStoreInfoTags = useCallback(async(force = false) => {
        if (!userStoreInfo || force) {
            const userInfo = await getStoreRolesInfo(storeId, userId);
            setUserStoreInfo(userInfo[0]);
        }
    }, [storeId, userId, userStoreInfo]);

    useEffect(() => {
        if (shouldLoad) {
            fetchTags();
            fetchUserStoreInfoTags();
            setShouldLoad(false);
        }
    }, [storeId, fetchUserStoreInfoTags, fetchTags, shouldLoad]);

    useEffect(() => {
        const serverError = serverErrors
            && (getErrorMessage('storesBrandCards', serverErrors));

        //this error is already handled
        if (serverError && serverError !== 'E_ROW_NOT_FOUND: Row not found') {
            message.error(serverError, 4, () => {
                dispatch(clearServerErrors());
            });
        }
    }, [serverErrors]);

    useEffect(() => {
        if (brandCardId) {
            setActiveTab((brandCardTabs[brandCardTabId] && brandCardTabId) || Object.keys(brandCardTabs)[0]);
        }
    }, [brandCardTabId, brandCardId]);

    const completeReload = async() => {
        fetchBrand();
        fetchTags(true);
        fetchUserStoreInfoTags(true);
    };

    const handleTabClick = (key: string) => {
        const search = _.get(location, 'state.search');
        return () => {
            if (activeTab === 'assets' && key !== 'assets') {
                handleViewedContent();
            }
            navigate(`${AuthRoutes.storeBrands(storeId)}/${brandCardId}/${key}`, {state: {from: origin, search}});
        };
    };

    const handleHover = () => {
        setIsHoveringBrandImage(true);
    };

    const handleHoverOff = () => {
        setIsHoveringBrandImage(false);
    };

    const handleDeleteImage = async() => {
        if (brandInfo) {
            setBrandInfo(Object.assign({}, brandInfo, {logoUrl: undefined}));
            await upsertBrandCardLogo(brandInfo.id);
        }
    };

    const hasBrandTags = Boolean(brandInfo && brandInfo.tags && brandInfo.tags.length);
    const hasBrandImage = Boolean(brandInfo && brandInfo.logoUrl);

    const addBrandLogo = async(file: UploadProps) => {
        if (brandInfo) {
            setIsUploadingLogo(true);
            const formData = new FormData();
            if (file) {
                formData.append('file', file.file);
            }
            formData.append('name', file.file.name);
            const brandCardReturn = await upsertBrandCardLogo(brandInfo.id, formData);
            setBrandInfo(Object.assign({}, brandInfo, {logoUrl: brandCardReturn.logo}));
        }
    };

    const imageIsLoaded = () => {
        setIsUploadingLogo(false);
    };

    const uploadProps = {
        name: 'file',
        showUploadList: false,
        beforeUpload: () => false,
        onChange(info: UploadProps) {
            addBrandLogo(info);
        },
    };

    const onHideEditModal = () => {
        completeReload();
        setIsEditing(false);
    };
    const onShowEditModal = () => {
        setIsEditing(true);
    };

    const quickLinks = brandInfo && brandInfo.links
        ? _.filter(brandInfo.links, {refType: LinkRefTypes.QuickLink})
        : [];
    const marketingLink = brandInfo && brandInfo.links
        ? _.find(brandInfo.links, {refType: LinkRefTypes.MarketingLink})
        : undefined;

    return (
        <>
            <Modal
                className='brand-card-modal'
                width='auto'
                open={Boolean(brandCardId)}
                title={false}
                footer={false}
                onCancel={handleCloseBrandCardModal}
                destroyOnClose>
                <div className='brand-card'>
                    <div className='brand-card-header'>
                        {brandInfo && (
                            <>
                                <div
                                    className='brand-card-main-image-container'
                                    onMouseOver={handleHover}
                                    onMouseLeave={handleHoverOff}>
                                    {isUploadingLogo && (
                                        <Loading fill size={56} />
                                    )}
                                    <img
                                        className='brand-card-main-image'
                                        src={hasBrandImage
                                            ? `${BASE_API || BASE_URL}${brandInfo.logoUrl}`
                                            : disabledLogo}
                                        onLoad={imageIsLoaded} />
                                    {brandInfo && !isUploadingLogo && (
                                        <div className={classnames(
                                            'main-image-controls',
                                            {'is-hovered': isHoveringBrandImage}
                                        )}>
                                            <Upload accept={fileTypes.image.join(', ')} className='brand-image-upload' {...uploadProps}>
                                                <Button
                                                    className='btn-white btn-circle btn-icon'>
                                                    <img className='svg-icon' src={pen} />
                                                </Button>
                                            </Upload>
                                            {hasBrandImage && (
                                                <Button
                                                    className='btn-white btn-circle btn-icon'
                                                    onClick={handleDeleteImage}>
                                                    <DeleteFilled />
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className={classnames(
                                    'title-block',
                                    {'title-block--small-screen': windowIsSmall},
                                    {'title-block--tiny-screen': windowIsTiny}
                                )}>
                                    <div className='brand-card-name  single-line-ellipsis'>
                                        {_get(brandInfo, 'name', '-')}
                                    </div>
                                    <div className='brand-card-info'>
                                        <div>
                                            <span className='brand-card-info-title'>Account Number:</span> {_get(brandInfo, 'accountNumber', '-')}
                                        </div>
                                        <div>
                                            <span className='brand-card-info-title'>Credit Limit:</span> {_get(brandInfo, 'creditLimit', '-')}
                                        </div>
                                        {marketingLink && (
                                            <div>
                                                <a
                                                    href={buildUrlFromPartial(marketingLink.url)}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    className='btn btn-primary url-button single-line-ellipsis'>
                                                    {marketingLink.url}
                                                </a>
                                            </div>
                                        )}
                                        {windowIsSmall && (
                                            <div className='url-block url-block--landscape'>
                                                {quickLinks.map((link: ApiLink) => (
                                                    <a className='brand-url single-line-ellipsis' key={link.id} href={buildUrlFromPartial(link.url)} target='_blank' rel='noreferrer'>
                                                        <LinkOutlined />{_.upperFirst(link.name)}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!windowIsSmall && (
                                    <div className='url-block'>
                                        {quickLinks.map((link: ApiLink) => (
                                            <a className='brand-url single-line-ellipsis' key={link.id} href={buildUrlFromPartial(link.url)} target='_blank' rel='noreferrer'>
                                                <LinkOutlined />{_.upperFirst(link.name)}
                                            </a>
                                        ))}
                                    </div>
                                )}
                                <div className='tag-block'>
                                    {hasBrandTags && (
                                        <TagsRenderer tags={(brandInfo && brandInfo.tags) || []} tagClassName='brand-tag' display={5} />
                                    )}
                                    {!hasBrandTags && (
                                        <span>-</span>
                                    )}
                                </div>
                                <div className='controls-block'>
                                    {!readOnly && (
                                        <Button
                                            className='btn-white btn-circle btn-icon'
                                            onClick={onShowEditModal}>
                                            <img className='svg-icon' src={pen} />
                                        </Button>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className='brand-card-body'>
                        <div className='brand-card-tabs'>
                            {filterMap(
                                Object.entries(brandCardTabs),
                                (entries) => !(entries[1].visible === false),
                                ([key, tab]) => (
                                    <button
                                        key={key}
                                        onClick={handleTabClick(key)}
                                        className={classnames(
                                            'brand-card-tab',
                                            {'active': activeTab === key}
                                        )}>
                                        {tab.title}
                                    </button>
                                )
                            )}
                        </div>
                        <div className='brand-card-tab-body'>
                            {hasBrandInfo && brandCardTabs[activeTab].component}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                className='create-brandcard-modal'
                width='1100px'
                open={isEditing}
                title={<div className='edit-brand-card-title'>Edit Brand Card</div>}
                footer={false}
                onCancel={onHideEditModal}
                maskClosable={false}
                destroyOnClose>
                {isEditing && (
                    <BrandCardForm
                        storeId={storeId}
                        brandId={brandCardId}
                        refetchBrands={_.noop}
                        onDelete={() => {
                            setShowDeleteModal(true);
                        }}
                        onCancel={onHideEditModal} />
                )}
            </Modal>
            <ModalPrompt
                body={
                    <span className='delete-brands-prompt'>
                        Are you sure you want to delete this brand card? All associated contacts and tasks will also be deleted.
                        <Callout type='danger'>
                            <Checkbox
                                value='delete'
                                className='single-line-ellipsis'
                                onChange={() => setShouldDeleteBrandAssets((shouldDeleteBrandAssets) => !shouldDeleteBrandAssets)}
                                checked={shouldDeleteBrandAssets}>
                                Delete associated assets.
                            </Checkbox>
                            <PopIcon
                                type='info'
                                placement='bottomRight'
                                content={
                                    <>
                                        <div>
                                        By default, BrandKeep will move assets from a deleted workspace to the ‘All’ tab so they are still accessible.
                                        Checking the box to ‘Delete associated assets’ will delete them along with the workspace and they will no longer be available.
                                        </div>
                                    </>
                                }
                                origin={<div className='btn btn-circle btn-white btn-icon-small'><img className='svg-icon' src={infoIcon} /></div>} />
                        </Callout>
                    </span>
                }
                title='Delete Brand Card'
                show={showDeleteModal}
                displayType='delete'
                onNo={() => {
                    setShouldDeleteBrandAssets(false);
                    setShowDeleteModal(false);
                }}
                onYes={async() => {
                    if (!shouldDeleteBrandAssets) {
                        //disassociate assets from brandcard
                        await removeAssetAssociations(storeId, [brandCardId]);
                    }
                    await deleteBrandCard(brandCardId);
                    setShowDeleteModal(false);
                    setShouldDeleteBrandAssets(false);
                    handleCloseBrandCardModal();
                }} />
        </>
    );
};

export default BrandCard;
