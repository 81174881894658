import {useFormik} from 'formik';
import React from 'react';
import logo from 'src/assets/images/logo-stacked.svg';
import Button from 'src/Components/Button';
import Input from 'src/Components/Input';
import useFormValues from 'src/Views/Login/Forms/ForgotPassForm/useFormValues';
import useSubmit from 'src/Views/Login/Forms/ForgotPassForm/useSubmit';
import useValidationSchema from 'src/Views/Login/Forms/ForgotPassForm/useValidationSchema';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import _ from 'lodash';
import Loading from 'src/Components/Loading';

const ForgotPassForm: React.FC = () => {
    const validationSchema = useValidationSchema();

    const initialValues = useFormValues();
    const {submitHandler, message, isSuccess} = useSubmit();

    useDocumentTitle('Forgot Password');

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');


    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: submitHandler,
    });

    if (message && isSuccess) {
        return (
            <>
                <div className='formBackground' />
                <div className='formWrapper'>
                    <div className='formBody'>
                        <img src={logo} className='logo' />
                        <div className='formHeader'>{message}</div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div className='formBackground' />
            <div className='formWrapper'>
                <div className='formBody'>
                    <a href='https://www.brandkeep.com'>
                        <img src={logo} className='logo' />
                    </a>
                    <div className='formHeader'>Forgot your password?</div>
                    <div className='message'>
                        Enter your email below and we will send a message to reset your password.
                    </div>
                    <div className='form'>
                        <form onSubmit={handleSubmit} id='forgot-pass-form'>
                            <div>
                                <div className='inputWrapper'>
                                    <Input
                                        errors={errors.email && touched.email && errors.email}
                                        required
                                        autoFocus
                                        placeholder='youremail@example.com'
                                        label='Email'
                                        {...getFieldProps('email')} />
                                </div>
                            </div>
                            <div className='error'>{message}</div>
                            <Button
                                type='submit'
                                className='btn-secondary submit-button'
                                form='forgot-pass-form'
                                disabled={isSubmitting || !_.isEmpty(errors)}>
                                {isSubmitting && (
                                    <Loading inline />
                                )}
                                Reset my password
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassForm;
