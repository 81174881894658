import {all, call, put, takeEvery} from 'redux-saga/effects';
import {getUsers} from '../../api/User/user-api';
import {userNormalizerFromApi} from '../../Types/User/normiliser';
import {UserDTO} from '../../Types/User/types';
import {getTeamsFailure, getTeamsSuccess} from './actions';
import {IGetTeams, TeamsActionTypes} from './actionTypes';

function* getTeams(action: IGetTeams) {
    try {
        const users: Array<UserDTO> = yield call(getUsers, action.payload.storeId);
        yield put(getTeamsSuccess(users.map((user) => userNormalizerFromApi(user))));
    } catch (error) {
        yield put(getTeamsFailure('Something went wrong. Please Try again'));
    }
}

function* watchGetTeams() {
    yield takeEvery(TeamsActionTypes.GET_TEAMS, getTeams);
}

export default function* usersSagas() {
    yield all([watchGetTeams()]);
}
