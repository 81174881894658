import React, {useState} from 'react';
import './styles.sass';
import _ from 'lodash';

import circleBackground from '/src/assets/images/orange-circle.svg';
import computerImage from '/src/assets/images/computer.png';
import websiteImage from '/src/assets/images/website.png';
import contactImage from '/src/assets/images/contact.png';

//components
import {Checkbox} from 'antd';
import Button from 'src/Components/Button';

//types
import {genericFn} from 'src/Types/CommonTypes/index';

//libs
import {classnames} from 'src/utils/general';

interface Props {
    className?: string
    onCancel: genericFn
    onDelete: genericFn
    plan?: string
}

const Delete: React.FC<Props> = ({
    className = '',
    onCancel,
    onDelete,
    plan = '',
}): JSX.Element => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const computedClassName = classnames(
        'Delete',
        {
            [className]: className,
        }
    );

    return (
        <div className={computedClassName}>
            <div className='modal-background-image-container'>
                <img className='background-image' src={circleBackground} />
                <img className='accent-image top-image' src={websiteImage} />
                <img className='accent-image middle-image' src={computerImage} />
                <img className='accent-image bottom-image' src={contactImage} />
            </div>
            <div className='modal-body'>
                <div className='Delete-body'>
                    <div className='Delete-blurb large-font-size'>
                        {'By deleting your account, all of your BrandKeep data will be immediately deleted and you will be logged out of the platform.'}
                    </div>
                    <div className='Delete-blurb-small medium-font-size'>
                        You cannot undo this action
                    </div>
                    <Button className='keep-plan-button theme-purple theme-background' onClick={onCancel}>Keep BrandKeep {_.capitalize(plan)}</Button>
                    <div className='confirm-checkbox'>
                        <Checkbox
                            onChange={() => setConfirmDelete((confirmDelete) => !confirmDelete)}
                            checked={confirmDelete}>
                            I acknowledge that all of my BrandKeep data will be immediately deleted.
                        </Checkbox>
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <Button className='btn-secondary' onClick={onDelete} disabled={!confirmDelete}>
                    Continue with Deletion
                </Button>
            </div>
        </div>
    );
};

export default Delete;
