import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {invitedUserSignUp} from 'src/api/Auth';
import {setTokens} from 'src/utils/token.utils';

type InvitedUserSubmitProps = {
    code: string
    name: string
    familyName: string
    password: string
};

const useSubmit = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState(false);

    const submitHandler = async({code, name, familyName, password}: InvitedUserSubmitProps) => {
        try {
            const response = await invitedUserSignUp({
                code,
                name,
                familyName,
                password,
            });
            //BK-600 logout current user?
            if (response.tokens) {
                setTokens(response.tokens.accessToken, response.tokens.refreshToken, response.username);
                navigate('/');
            }
        } catch (e: any) {
            setMessage(e.combinedMessage);
        }
    };
    return {submitHandler, message};
};

export default useSubmit;
