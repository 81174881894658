import React from 'react';
import Modal from 'src/Components/Modal';
import _ from 'lodash';

interface Props {
    cancelButtonHandler: () => void
    title: JSX.Element
    open: boolean
    className?: string
}

const EntityImport: React.FC<Props> = ({title, open, cancelButtonHandler, children, className = ''}) => {
    const classNames = _.compact([className, 'EntityImport']).join(' ');
    return (
        <Modal width='1100px' className={classNames} open={open} title={title} footer={false} onCancel={cancelButtonHandler} destroyOnClose>
            {children}
        </Modal>
    );
};

export default EntityImport;
