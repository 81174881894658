import _ from 'lodash';
import React from 'react';
import Select from 'src/Components/Select';
import {Checkbox} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/Checkbox';
import './styles.sass';
import {onChangeCb} from 'src/lib/filter-helper';

interface Props {
    compactLabel: string,
    disabled?: boolean,
    inputId?: string,
    labelKey: string,
    onChange: onChangeCb,
    onCheckboxChange: onChangeCb,
    options?: any[],
    placeholder?: string,
    searchKeys?: string[],
    value?: any,
    valueKey: string,
    labelTextRenderer?: (option: any) => JSX.Element | string | number,
}

interface SelectValue {
    label: any
    value: any
}

const FilterMultiSelect: React.FC<Props> = ({
    compactLabel,
    disabled,
    inputId,
    labelKey,
    onChange,
    onCheckboxChange,
    options,
    placeholder,
    value,
    valueKey,
    labelTextRenderer,
}) => {

    const handleOnChange: onChangeCb = (value) => {
        onChange(value);
    };

    const handleOnCheckboxChange = (newValues: CheckboxChangeEvent) => {
        const value = (newValues && newValues.target) ? newValues.target.value : newValues;
        const option = _.find(options, {[valueKey]: value});
        onCheckboxChange(option);
    };

    if (!options || _.isEmpty(options)) {
        return null;
    }

    const valueKeys = new Set(_.map(value, valueKey));
    const tagRenderer = (tagValue: SelectValue, inputIsFocused: boolean) => {
        if (
            !inputIsFocused
            && value
            && value.length
            && tagValue
            && value[0][valueKey] === tagValue.value
        ) {
            return (
                <span>{`${compactLabel} (${value.length})`}</span>
            );
        }
        return null;
    };

    return (
        <div className='filter-multi-select'>
            {(options.length < 7) && options.map((option) => (
                <div
                    className='checkboxContainer'
                    key={option[valueKey]}>
                    <Checkbox
                        className='single-line-ellipsis'
                        value={option[valueKey]}
                        onChange={handleOnCheckboxChange}
                        checked={option && valueKeys.has(option[valueKey])}>
                        {labelTextRenderer ? labelTextRenderer(option) : _.words(option[labelKey] || '').map(_.capitalize).join(' ')}
                    </Checkbox>
                </div>
            ))}
            {(options.length >= 7) && (
                <Select
                    className='filter-multi-select__select'
                    disabled={disabled}
                    id={inputId}
                    labelKey={labelKey}
                    mode='multiple'
                    multiple={true}
                    onChange={handleOnChange}
                    valueTagRenderer={tagRenderer}
                    options={options}
                    optionLabelProp={valueKey}
                    placeholder={placeholder}
                    valueKey={valueKey}
                    value={value} />
            )}
        </div>
    );
};

export default FilterMultiSelect;
