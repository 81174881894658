import {User} from '../../Types/User/types';
import {TeamsActions, TeamsActionTypes} from './actionTypes';
import {ID} from 'src/Types/CommonTypes';

export const getTeams = (storeId: ID): TeamsActions => ({
    type: TeamsActionTypes.GET_TEAMS,
    payload: {
        storeId,
    },
});

export const getTeamsSuccess = (teams: Array<User>): TeamsActions => ({
    type: TeamsActionTypes.GET_TEAMS_SUCCESS,
    payload: {
        teams,
    },
});

export const getTeamsFailure = (error: string): TeamsActions => ({
    type: TeamsActionTypes.GET_TEAMS_FAILURE,
    payload: {
        error,
    },
});
