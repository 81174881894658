export const brandCardAssetsOnboarding = {
    addAsset: {
        header: 'Create workspaces using the ‘+ Add & Edit Workspaces’ button',
        text: 'Use workspaces to organize by season, collection, or anything else. Workspaces are selectable across all brand cards.',
        knowledgebaseLink: '',
        containerElement: undefined,
        originElement: undefined,
        targetId: '',
    },
};
