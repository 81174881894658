import React, {useState} from 'react';
import {NormalizedTag} from 'src/Types/Tags/types';
import {Tag} from 'src/api/Tags/api-tags';
import _ from 'lodash';
import {classnames} from 'src/utils/general';
import {Popover} from 'antd';
import './styles.sass';
import {CaretDownOutlined} from '@ant-design/icons';

interface Props {
    tags: NormalizedTag[] | Tag[]
    display?: number
    tagClassName?: string
    title?: string
}

const TagsRenderer: React.FC<Props> = ({display = 1, tagClassName = 'brand-tag', title='Tags', ...props}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const tags = _.sortBy(props.tags, 'title');

    const slicedTags = _.take(tags || [], display);
    const extraTags = tags
        ? _.slice(tags, display, tags.length)
        : [];
    const hasExtraTags = Boolean(extraTags.length);
    const tagClassNames = classnames('tag single-line-ellipsis', {[tagClassName]: tagClassName});
    const hasTags = Boolean(tags && tags.length);

    const popover = (
        <div className='popover'>
            <div className='tag-popover-title'>{title} </div>
            <div className='tag-popover-container'>
                {extraTags.map((tag: NormalizedTag) => {
                    return (
                        <span key={`popover-tag-${tag.id}`} className={tagClassNames} title={tag.title}>
                            {tag.title}
                        </span>
                    );
                })}
            </div>
        </div>
    );

    return (
        <div className='tags-renderer'>
            {hasTags && slicedTags.map((tag: Tag, index) => (
                <div className={classnames(
                    'tag-container',
                    {'has-multiple-tags': hasExtraTags}
                )}
                key={`renderer-tag-${tag.id}`}>
                    <span className={tagClassNames} title={tag.title}>
                        {tag.title}
                    </span>
                    {hasExtraTags && (index === (display - 1)) && (
                        <Popover
                            overlayClassName='tags-popover'
                            placement='bottomRight'
                            content={popover}
                            trigger='click'
                            open={isPopoverOpen}
                            onOpenChange={setIsPopoverOpen}>
                            <div className='tag-caret'>
                                <CaretDownOutlined />
                            </div>
                        </Popover>
                    )}
                </div>
            ))}
            {!hasTags && (
                <div />
            )}
        </div>
    );
};

export default TagsRenderer;
