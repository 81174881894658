import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    getBrandCardsForImport,
    getImportInfo,
    moveImportedBrandsToStore,
    postFileForImport,
} from 'src/api/BrandCardImport/api-brand-card-import';
import Pings from 'src/lib/pings';
import _ from 'lodash';

export interface ImportInfo {
    updated: number
    new: number
}

export interface ImportError {
    rowId: number
    name: string
    message: string
}

const useSubmit = (refetch: () => void) => {
    const {storeId} = useParams<{ storeId: string }>();
    const [importId, setImportId] = useState('');
    const [updatedCount, setUpdatedCount] = useState(0);
    const [createdCount, setCreatedCount] = useState(0);
    const [errors, setErrors] = useState<Array<any>>([]);
    const [importInfo, setImportInfo] = useState<ImportInfo>({
        updated: 0,
        new: 0,
    });
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const getUpdateInfo = async(importId: string, importInfo: any): Promise<boolean> => {
        const brandsImportInfo = await getBrandCardsForImport(importId);
        let updatedCount = 0;
        let createdCount = 0;
        let skipCount = 0;
        const errors: ImportError[] = [];
        brandsImportInfo.forEach((brand: any, rowId: number) => {
            if (brand.conflicts) {
                errors.push({
                    rowId: rowId + 1,
                    name: brand.name,
                    message: brand.importMessages,
                });
                skipCount++;
            } else if (brand.isNew) {
                createdCount++;
            } else if (brand.isUpdated) {
                updatedCount++;
            } else {
                skipCount++;
            }
        });
        setUpdatedCount(updatedCount);
        setCreatedCount(createdCount);
        setErrors(errors);
        setImportId(importId);
        return ((updatedCount + createdCount + skipCount) / importInfo.rowCount) === 1;
    };

    const submitFileHandler = async(file: File) => {
        setUpdatedCount(0);
        setCreatedCount(0);
        setIsProcessing(true);
        const {importId} = await postFileForImport(storeId, file);
        const importInfo = await getImportInfo(importId);
        setImportInfo({updated: Number(importInfo.updated), new: Number(importInfo.new)});
        getUpdateInfo(importId, importInfo);
        Pings.addPing({
            id: 'importBrandCardsPing',
            pingOnInit: true,
            frequency: .5,
            unit: 'seconds',
            task: async() => {
                const isComplete = await getUpdateInfo(importId, importInfo);
                if (isComplete) {
                    setIsProcessing(false);
                    Pings.removePing('importBrandCardsPing');
                }
            },
            onError: _.noop,
        });
    };

    const importFileHandler = async() => {
        await moveImportedBrandsToStore(importId);
        Pings.removePing('importPing');
        await refetch();
    };

    return {submitFileHandler, importId, importInfo, importFileHandler, createdCount, updatedCount, errors, isProcessing};
};

export default useSubmit;
