import {nanoid} from 'nanoid';
import {useEffect, useRef, useState, useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
    updateAssets,
    getStoreAssets,
    deleteAssets as deleteAssetsApi,
    downloadAllForStoreLink,
    ApiAsset,
    getLockedAssetsStatus,
} from 'src/api/Assets/api-asset';
import {BrandCard, getBrandCards, ParamsForBrandCard} from 'src/api/BrandCard/api-brand-card';
import {getAllWorkspaces, ApiWorkspace} from 'src/api/Workspace/api-workspace';
import {getTags, createTag} from 'src/api/Tags/api-tags';
import {ApiTag} from 'src/Types/Tags/types';
import {ID} from 'src/Types/CommonTypes';
import {Filter, handleFilterValidations, GLOBAL_FILTER_DEBOUNCE_RATE} from 'src/lib/filter-helper';
import _ from 'lodash';
import assetTagFilter from 'src/lib/filters/asset-tag';
import sourceFilter from 'src/lib/filters/asset-source';
import workspaceFilter from 'src/lib/filters/workspace';
import brandFilter from 'src/lib/filters/brand';
import brandTagFilter from 'src/lib/filters/brand-tag';
import newContentFilter from 'src/lib/filters/new-content';
import {getQueryParams, HistoryHelperFactory, QueryParams} from 'src/lib/url';
import {debouncePromise, filterMap} from 'src/utils/general';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import {useDispatch} from 'react-redux';
import Pings from 'src/lib/pings';

interface FilterDependencies {
    tags: ApiTag[]
    workspaces: ApiWorkspace[]
    allAssets?: ApiAsset[]
    allBrands: BrandCard[]
    brandTags: ApiTag[]
}

export interface MultiEditAsset {
    brandCardId?: ID | null
    storeId?: ID | null
    workspaceTitle?: string | null
    name?: string
    notes?: string
    tags?: number[]
    url?: string
    workspaceId?: ID | null
}

const useAssetsApi = (storeId: ID, brandCardId?: ID, workspaceId?: ID, delayLoad? = false) => {
    //temporary until we allow brandCard asset-list to use filters
    const DISABLE_QUERY_PARAM = Boolean(workspaceId);
    const queryParams = getQueryParams(useLocation());
    const dispatch = useDispatch();

    const historyHelper = HistoryHelperFactory(useNavigate());

    const [tags, setTags] = useState<ApiTag[]>([]);
    const [workspaces, setWorkspaces] = useState<ApiWorkspace[]>();
    const [allBrands, setAllBrands] = useState<BrandCard[]>([]);
    const [brandTags, setBrandTags] = useState<ApiTag[]>([]);
    const [lockedAssets, setLockedAssets] = useState<Set<number>>(new Set());

    const initialFilters = [
        assetTagFilter,
        newContentFilter,
    ];

    if (workspaceId === 'ALL' || !workspaceId) {
        initialFilters.push(workspaceFilter);
    }

    if (!workspaceId) {
        initialFilters.push(brandFilter);
    }

    initialFilters.push(sourceFilter);
    if (!workspaceId) {
        initialFilters.push(brandTagFilter);
    }

    //filters here must still be key'ed to their respective 'key'
    const rawFilters = _.keyBy(initialFilters, 'key');
    const filterDependencies: FilterDependencies = {
        allBrands,
        tags,
        workspaces,
        brandTags,
    };

    const [editingKey, setEditingKey] = useState('');
    const [assets, setAssets] = useState<ApiAsset[]>([]);
    const [allAssets, setAllAssets] = useState<ApiAsset[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [textSearchValue, setTextSearchValue] = useState((!DISABLE_QUERY_PARAM && queryParams.textSearch) || '');
    const [filterData, setFilterData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>({});
    const [areFilterValuesDefault, setAreFilterValuesDefault] = useState(true);
    const [filters, setFilters] = useState<Record<string, Filter>>(
        _.omitBy(rawFilters, (filter) => {
            return !filter.enabled(filterDependencies) && !filter.show(filterDependencies);
        })
    );

    const getFilters = (filterDependencies: FilterDependencies, workspaceId: ID) => {
        const initialFilters = [
            assetTagFilter,
            newContentFilter,
        ];

        if (workspaceId === 'ALL' || !workspaceId) {
            initialFilters.push(workspaceFilter);
        }

        if (!workspaceId) {
            initialFilters.push(brandFilter);
        }
        initialFilters.push(sourceFilter);
        if (!workspaceId) {
            initialFilters.push(brandTagFilter);
        }

        //filters here must still be key'ed to their respective 'key'
        const rawFilters = _.keyBy(initialFilters, 'key');
        const filters = _.omitBy(
            rawFilters,
            (filter) => !filter.enabled(filterDependencies) && !filter.show(filterDependencies)
        );
        setFilters(filters);
    };

    const handleLockedAssets = async(forceCheck = false) => {
        if (_.some(assets, 'locked') || forceCheck) {
            const currentlyLockedAssets = new Set(filterMap(assets, 'locked', 'id'));
            setLockedAssets(currentlyLockedAssets);
            Pings.removePing('assetLocksPing');
            //begin ping'ing for lock updates
            Pings.addPing({
                id: 'assetLocksPing',
                pingOnInit: forceCheck,
                frequency: 2,
                unit: 'seconds',
                task: async() => {
                    const assetStatus = await getLockedAssetsStatus(storeId, Array.from(currentlyLockedAssets));
                    const newlyLockedAssets = new Set<number>([]);
                    for (const status of assetStatus) {
                        if (status.locked) {
                            newlyLockedAssets.add(status.id);
                        } else {
                            newlyLockedAssets.delete(status.id);
                        }
                    }
                    setLockedAssets(newlyLockedAssets);
                    if (!_.some(assetStatus, 'locked')) {
                        Pings.removePing('assetLocksPing');
                    }
                },
                onError: _.noop,
            });
        }
    };

    useEffect(() => {
        handleLockedAssets();
    }, [storeId, assets]);

    const callId = useRef<string | null>(null);

    const getAssets = useCallback(async(
        currentCallId: string,
        workspaceId: ID,
        brandCardId: ID,
        queryParams: QueryParams,
        {
            isInitialLoad = false,
            filterValuesFromState = filterValues,
            isSilent = false,
        } = {}
    ) => {
        if (callId.current === currentCallId) {
            if (!isSilent) {
                setIsLoading(true);
            }
            const searchObject: Record<string, any> = {};
            if (textSearchValue) {
                searchObject.textSearch = textSearchValue;
            }

            for (const [filterKey, filterValue] of Object.entries(filterValuesFromState)) {
                if (_.get(filters, [filterKey, 'transformForAPI'])) {
                    filters[filterKey].transformForAPI(
                        filterValue,
                        searchObject,
                        filterKey,
                        filterDependencies
                    );
                } else {
                    searchObject[filterKey] = undefined;
                }
            }

            const finalSearchObject = (isInitialLoad && !DISABLE_QUERY_PARAM) ? queryParams : searchObject;
            //coming from brandCard
            if (workspaceId && workspaceId !== 'ALL') {
                finalSearchObject.workspace = [workspaceId];
            }
            //coming from "ALL" workspace
            if (brandCardId) {
                finalSearchObject.brand = [brandCardId];
            }

            const filteredAssets = await getStoreAssets(
                storeId,
                finalSearchObject
            );
            const filteredAndSortedAssets = filteredAssets.data;

            if (isInitialLoad) { //  || !allAssets.length BK-499 probalby could do this better :shrug:
                //handle first-time load

                //coming from brandCard
                const specializedSearchObject = {};
                if (workspaceId && workspaceId !== 'ALL') {
                    specializedSearchObject.workspace = [workspaceId];
                }
                //coming from "ALL" workspace
                if (brandCardId) {
                    specializedSearchObject.brand = [brandCardId];
                }
                const [
                    {data: allAssets},
                    workspaces,
                    tags,
                    {data: allBrands},
                    brandTags,
                ] = await Promise.all([
                    getStoreAssets(storeId, specializedSearchObject),
                    getAllWorkspaces(storeId),
                    getTags('assets', 'store', storeId),
                    getBrandCards(storeId, {
                        perpage: 5000,
                        [ParamsForBrandCard.Assets]: false,
                        [ParamsForBrandCard.Contacts]: false,
                        [ParamsForBrandCard.Workspaces]: false,
                    }),
                    getTags('brand_cards', 'store', storeId),
                ]);
                setWorkspaces(workspaces);
                setAllBrands(allBrands);
                setTags(tags);
                setAllAssets(allAssets);
                setBrandTags(brandTags);

                filterDependencies.allAssets = allAssets;
                filterDependencies.workspaces = workspaces;
                filterDependencies.tags = tags;
                filterDependencies.allBrands = allBrands;
                filterDependencies.brandTags = brandTags;
                getFilters(filterDependencies, workspaceId);


                const {
                    updatedQueryParamObject,
                    urlFilterValues,
                    didFiltersObviouslyChange,
                } = handleFilterValidations(
                    filters,
                    filterDependencies,
                    DISABLE_QUERY_PARAM ? {} : queryParams
                );
                setFilterValues((filterValues: Record<'string', any>) => {
                    return _.isEqual(filterValues, urlFilterValues)
                        ? filterValues
                        : urlFilterValues;
                });
                if (!DISABLE_QUERY_PARAM && !_.isEqual(filterValues, urlFilterValues)) {
                    historyHelper.updateQueryParams(updatedQueryParamObject, false);
                }
                if (didFiltersObviouslyChange) {
                    //filters changed, short-circuit this request and fire new query
                    const currentCallId = nanoid();
                    callId.current = currentCallId;
                    getAssets(
                        currentCallId,
                        workspaceId,
                        brandCardId,
                        queryParams,
                        {filterValuesFromState: urlFilterValues, isSilent}
                    );
                    return;
                } else {
                    setAssets(filteredAndSortedAssets);
                }
            } else {
                setAssets(filteredAndSortedAssets);
                if (!DISABLE_QUERY_PARAM) {
                    historyHelper.updateQueryParams(searchObject);
                }
            }
            if (!isSilent) {
                setIsLoading(false);
            }
        }
    }, [storeId, textSearchValue, filterValues, queryParams]);

    const deleteAssets = async(assetIds: ID[]) => {
        setIsLoading(true);
        const currentCallId = nanoid();
        callId.current = currentCallId;
        await deleteAssetsApi(storeId, assetIds);
        dispatch(getStoreStatus());
        await getAssets(currentCallId, workspaceId, brandCardId, queryParams, {isInitialLoad: true});
        setIsLoading(false);
    };

    const downloadAll = async(storeId: ID, assetIds: ID[]) => {
        const {url} = await downloadAllForStoreLink(storeId, assetIds);
        window.open(`${MACRO_BASE_URL}${url.substring(4)}`, '_blank'); // .substring(4) to remove '/api'
    };

    const saveAssetsApi = async(
        record: MultiEditAsset,
        assignedTags: ApiTag[],
        tagsAbleToBeRemoved: ApiTag[],
        assetsToUpdate: Partial<ApiAsset>[],
        ignoreBrandUpdate = false,
        ignoreWorkspaceUpdate = false,
        isWorkspaceAsset = false,
        newWorkspaceTitle?: string
    ) => {
        const tagsAbleToBeRemovedSet = new Set(_.map(tagsAbleToBeRemoved, 'id'));
        const tagsById = _.keyBy(tags, 'id');
        const payloadForSaveTags: ApiTag[] = [];
        for (const recordTag of assignedTags || []) {
            if (recordTag && (!recordTag.id || !tagsById[recordTag.id])) {
                delete recordTag.id;
                payloadForSaveTags.push({
                    refType: 'assets',
                    scope: 'store',
                    scopeId: Number(storeId),
                    title: `${recordTag.title}` || '',
                });
            }
        }

        const tagPromises: Array<Promise<any>> = [];
        payloadForSaveTags?.forEach((tag) => {
            tagPromises.push(createTag(tag));
        });

        const savedTags = await Promise.all(tagPromises);
        const savedTagsByTitle = _.keyBy(savedTags, 'title');

        const aggregatedTagIds = _.map(assignedTags, (recordTag) => {
            if (!recordTag.id && savedTagsByTitle[recordTag.title]) {
                return savedTagsByTitle[recordTag.title].id;
            } else {
                return recordTag.id;
            }
        });

        const updatedAssets = [];
        for (const asset of assetsToUpdate) {
            if (assetsToUpdate.length === 1) {
                delete asset.name;
                delete asset.notes;
            }
            const updatedAsset = Object.assign({}, asset, record);
            if (isWorkspaceAsset && ignoreBrandUpdate && !ignoreWorkspaceUpdate) {
                /*
                    * Brand wasnt updated as part of the multi-select, so here it can read "(Several)".
                    * That would already be blanked above, but we need it to determine which brandcard the 
                    * season belongs to (if any), so send it along here.
                */
                updatedAsset.brandCardId = _.get(asset, 'brand.id') || _.get(asset, 'season.brand_card_id');
                if (asset.workspaceId) {
                    updatedAsset.workspaceTitle = newWorkspaceTitle;
                }
            }
            const assetTags = _.filter(asset.tags, (tag) => !tagsAbleToBeRemovedSet.has(tag.id));
            updatedAsset.tags = [..._.map(assetTags, 'id'), ...aggregatedTagIds];
            updatedAssets.push(updatedAsset);
        }

        await updateAssets(storeId, updatedAssets, ignoreBrandUpdate, ignoreWorkspaceUpdate);

        const currentCallId = nanoid();
        callId.current = currentCallId;
        await getAssets(currentCallId, workspaceId, brandCardId, queryParams, {isInitialLoad: true});
    };

    const refetchAssets = async(newQueryParams = queryParams) => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        dispatch(getStoreStatus());
        await getAssets(currentCallId, workspaceId, brandCardId, newQueryParams, {isInitialLoad: true});
    };

    const silentlyRefreshAssets = async(newQueryParams = queryParams) => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        dispatch(getStoreStatus());
        await getAssets(currentCallId, workspaceId, brandCardId, newQueryParams, {isSilent: true});
    };

    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        if (!delayLoad) {
            getAssets(currentCallId, workspaceId, brandCardId, queryParams, {isInitialLoad: true});
        }
    }, [storeId, workspaceId, brandCardId]);

    //if filterValues change, get new asset list
    useEffect(() => {
        if (!isLoading && allAssets && allAssets.length && !areFilterValuesDefault) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => getAssets(currentCallId, workspaceId, brandCardId, queryParams)
            )();
        }
        if (!isLoading && !_.isEmpty(filterValues)) {
            setAreFilterValuesDefault(false);
        }
    }, [filterValues, textSearchValue]);

    //unmount
    useEffect(() => () => Pings.removePing('assetLocksPing'), []);

    return {
        assets,
        allAssets,
        brandTags,
        refetchAssets,
        downloadAll,
        allBrands,
        isLoading,
        deleteAssets,
        textSearchValue,
        setTextSearchValue,
        editingKey,
        setEditingKey,
        tags,
        saveAssetsApi,
        workspaces,
        filterData,
        setFilterData,
        filterValues,
        setFilterValues,
        filters,
        setFilters,
        lockedAssets,
        setLockedAssets,
        handleLockedAssets,
        silentlyRefreshAssets,
    };
};

export default useAssetsApi;
