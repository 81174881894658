//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    validateFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';
import {filterMap, mapGroupBy} from 'src/utils/general';
import {getContactDisplayBySource, getContactNameForCreator} from 'src/lib/asset-helper';

//specific
import {ApiAsset} from 'src/api/Assets/api-asset';

interface DataBuilder {
    allAssets: ApiAsset[]
}

export default buildFilter({
    key: 'assetSource',
    name: 'Source',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters, {assetSource: assetSources}: Record<'assetSource', ApiAsset[]>) => {
        return validateFilter(
            _.uniqBy(filterMap(appliedFilters, (filter) => filter.creator || filter.contactCreator), 'id'),
            filterMap(assetSources, (filter) => filter.creator || filter.contactCreator)
        );
    },
    dataBuilder: ({allAssets}: DataBuilder) => {
        const sourceUsers = _.uniqBy(filterMap(allAssets, (asset) => {
            if (asset.creator) {
                asset.creator.type = 'user';
            }
            return asset.creator;
        }), 'id');
        const sourceContacts = _.uniqBy(filterMap(allAssets, (asset) => {
            if (asset.contactCreator) {
                asset.contactCreator.type = 'contact';
            }
            return asset.contactCreator;
        }), 'id');
        return _.sortBy([...sourceUsers, ...sourceContacts], (source) => _.toLower(getContactDisplayBySource(source)));
    },
    detailRenderer: (asset: ApiAsset) => getContactNameForCreator(asset),
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {assetSource}: Record<string, any>
    ) => {

        return (
            <FilterMultiSelect
                compactLabel='Sources'
                disabled={!(assetSource && assetSource.length)}
                labelKey='id'
                labelTextRenderer={getContactNameForCreator}
                onChange={handleOnChangeFactory('assetSource', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('assetSource', onChange, values)}
                options={assetSource}
                placeholder='Type to Search Sources'
                value={(values && values.assetSource) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (assetSources: any[], searchObject, key: string) => {
        const sources = mapGroupBy(assetSources, 'id', 'type');
        if (!_.isEmpty(sources)) {
            searchObject[key] = sources;
        }
        return searchObject;
    },
    buildValuesFromQueryString: ({allAssets}, {assetSource: selectedSourceIds}) => {
        const valueUserIdSet = selectedSourceIds ? new Set(selectedSourceIds.user) : new Set();
        const valueContactIdSet = selectedSourceIds ? new Set(selectedSourceIds.contact) : new Set();

        const validUserAssets = filterMap(allAssets,
            (asset) => valueUserIdSet.has(
                _.get(asset, 'creator.id')
            ),
            (asset) => {
                asset.creator.type = 'user';
                return asset;
            }
        );
        const validContactAssets = filterMap(allAssets,
            (asset) => valueContactIdSet.has(
                _.get(asset, 'contactCreator.id')
            ),
            (asset) => {
                asset.contactCreator.type = 'contact';
                return asset;
            }
        );
        return [...validUserAssets, ...validContactAssets];
    },
    enabled: ({allAssets}: DataBuilder) => _.some(allAssets, 'creator') || _.some(allAssets, 'contactCreator'),
});
