import React, {useState} from 'react';
import {UploadableFile} from 'src/Components/DropZone';
import EntityImport from 'src/Components/EntityImport';
import ImportResult from 'src/Components/EntityImport/ImportResult';
import PreviewFileStep from 'src/Components/EntityImport/PreviewStep';
import UploadFileStep from 'src/Components/EntityImport/UploadFileStep';
import useGetFilesHrefs from 'src/Views/Store/Brands/ImportExportBrandModal/useGetFilesHrefs';
import useSubmit from 'src/Views/Store/Brands/ImportExportBrandModal/useSubmit';
import useValidation from 'src/Views/Store/Brands/ImportExportBrandModal/useValidation';
import Pings from 'src/lib/pings';
interface Props {
    open: boolean
    onCancel: () => void
    refetch: () => void
}

enum StepsEnum {
    Step1 = 'Upload',
    Step2 = 'Preview',
    Step3 = 'Results',
}

const ImportExportBrandModal: React.FC<Props> = ({open, onCancel, refetch}) => {
    const stepsNames = Object.values(StepsEnum);
    const title: JSX.Element = <p className='header'> Import Brands</p>;
    const [files, setFiles] = useState<Array<UploadableFile>>([]);
    const {headers, data, validateHandler, requiredColumns, errors, setErrors} = useValidation();
    const {submitFileHandler, importFileHandler, createdCount, updatedCount, errors: importErrors, isProcessing} = useSubmit(refetch);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const {template} = useGetFilesHrefs();

    const cancelButtonHandler = () => {
        setFiles([]);
        setCurrentStep(1);
        setErrors([]);
        onCancel();
        Pings.removePing('importBrandCardsPing');
    };

    return (
        <EntityImport className='import-export-brand-modal' open={open} title={title} cancelButtonHandler={cancelButtonHandler}>
            {currentStep === 1 ? (
                <UploadFileStep
                    stepsNames={stepsNames}
                    requiredColumns={requiredColumns}
                    currentStep={currentStep}
                    isNextStepDisabled={Boolean(errors.length) || !files.length}
                    files={files}
                    errors={errors}
                    validateHandler={validateHandler}
                    nextStepHandler={() => setCurrentStep(2)}
                    submitFileHandler={() => submitFileHandler(files[0].file)}
                    downloadSampleHref={template}
                    setFiles={setFiles}
                />
            ) : null}
            {currentStep === 2 ? (
                <PreviewFileStep
                    stepsNames={stepsNames}
                    headers={headers}
                    currentStep={currentStep}
                    tableData={data}
                    disableImport={isProcessing}
                    nextStepHandler={() => setCurrentStep(3)}
                    prevStepHandler={() => {
                        setCurrentStep(1);
                        Pings.removePing('importBrandCardsPing');
                    }}
                    importFileHandler={importFileHandler}
                    newFilesValue={createdCount}
                    updatedFilesValue={updatedCount}
                />
            ) : null}
            {currentStep === 3 ? (
                <ImportResult
                    stepsNames={stepsNames}
                    errors={importErrors}
                    currentStep={currentStep}
                    totalImported={data.length}
                    createdSuccess={createdCount}
                    updatedSuccess={updatedCount}
                    buttonHandler={cancelButtonHandler}
                />
            ) : null}
        </EntityImport>
    );
};

export default ImportExportBrandModal;
