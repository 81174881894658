import apiHelper from 'src/api/api-helper';
export interface ImportInfo {
    updated: string
    new: string
    skipped: string
    rowCount: number
    isComplete: boolean
}

export const postFileForImport = (storeId: string | number, file: File) => {
    const formData = new FormData();
    formData.append('csv', file);

    const endpoint = `/import/stores/${storeId}/brand_cards`;
    return apiHelper.post(endpoint, formData, {'Content-Type': 'multipart/form-data'});
};

export const getImportInfo = (importId: string): Promise<ImportInfo> => {
    const endpoint = `/import/${importId}/brand_cards/info`;
    return apiHelper.get(endpoint);
};

export const getBrandCardsForImport = (importId: string): Promise<[]> => {
    const endpoint = `/import/${importId}/brand_cards`;
    return apiHelper.get(endpoint);
};

export const moveImportedBrandsToStore = (importId: string) => {
    const endpoint = `/import/${importId}/brand_cards`;
    return apiHelper.put(endpoint, {});
};
