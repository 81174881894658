import React, {useRef} from 'react';
import './styles.sass';
import {classnames, _get} from 'src/utils/general';
import Button from 'src/Components/Button';
import PopIcon from 'src/Components/PopIcon';
import {BrandCard as BrandCardType} from 'src/api/BrandCard/api-brand-card';
import {genericFn, ReactTextareaEvent} from 'src/Types/CommonTypes';
import {CheckOutlined} from '@ant-design/icons';
import pen from 'src/assets/icons/pen.svg';
import tipIcon from 'src/assets/icons/tip-hex.svg';

interface Props {
    readOnly: boolean
    brandInfo: BrandCardType | undefined
    isEditing: boolean
    setIsEditing: genericFn
    onSaveBrandCard: genericFn
    onChange: genericFn
}


const BrandInfo: React.FC<Props> = ({readOnly, brandInfo, isEditing, setIsEditing, onSaveBrandCard, onChange}) => {
    const notesRef = useRef<HTMLImageElement>(null);
    const warrantyRef = useRef<HTMLImageElement>(null);

    const isLoading = !(typeof brandInfo === 'undefined');
    const handleEditToggle = () => {
        if (isEditing) {
            onSaveBrandCard({warrantyNotes: _get(brandInfo, 'warrantyNotes'), notes: _get(brandInfo, 'notes')});
        } else {
            if (notesRef && notesRef.current) {
                notesRef.current.scrollTo(0, 0);
            }
            if (warrantyRef && warrantyRef.current) {
                warrantyRef.current.scrollTo(0, 0);
            }
        }
        setIsEditing(!isEditing);
    };
    const handleChangeNotes = (event: ReactTextareaEvent) => {
        const updatedBrandInfo = Object.assign({}, brandInfo, {notes: event.target.value});
        onChange(updatedBrandInfo);
    };
    const handleChangeWarranty = (event: ReactTextareaEvent) => {
        const updatedBrandInfo = Object.assign({}, brandInfo, {warrantyNotes: event.target.value});
        onChange(updatedBrandInfo);
    };
    return (
        <div className='brand-info'>
            {isLoading
                ? (
                    <>
                        <div className='info-title pop-label'>
                            <label className='form-label' htmlFor='notes'>
                                Notes
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content='Use the notes section to document important information about this brand or keep track of details for your team on how to access certain information.'
                                origin={<img src={tipIcon} />} />
                        </div>
                        <div
                            className={classnames(
                                'brand-info-container brand-info-notes',
                                {'is-editing-container': isEditing}
                            )}
                            ref={notesRef}>
                            <div
                                className={classnames(
                                    'info-block',
                                    {'is-editing-block': isEditing}
                                )}>
                                {_get(brandInfo, 'notes', '-')}
                                {isEditing && (
                                    <textarea value={_get(brandInfo, 'notes')} id='notes' onChange={handleChangeNotes} />
                                )}
                            </div>
                        </div>
                        <div className='info-title pop-label'>
                            <label className='form-label' htmlFor='warranty'>
                                Warranty
                            </label>
                            <PopIcon
                                className='btn-tip'
                                type='tip'
                                content='Use the warranty section to document how your store handles warranty for this particular brand or where to easily find the proper documentation.'
                                origin={<img src={tipIcon} />} />
                        </div>
                        <div
                            className={classnames(
                                'brand-info-container brand-info-warranty',
                                {'is-editing-container': isEditing}
                            )}
                            ref={warrantyRef}>
                            <div
                                className={classnames(
                                    'info-block',
                                    {'is-editing-block': isEditing}
                                )}>
                                {_get(brandInfo, 'warrantyNotes', '-')}
                                {isEditing && (
                                    <textarea value={_get(brandInfo, 'warrantyNotes')} id='warranty' onChange={handleChangeWarranty} />
                                )}
                            </div>
                        </div>
                        {!readOnly && (
                            <Button
                                className={classnames(
                                    'btn-icon brand-info-edit-button',
                                    {'btn-secondary': isEditing},
                                    {'btn-primary': !isEditing}
                                )}
                                onClick={handleEditToggle}>
                                {isEditing && (
                                    <CheckOutlined />
                                )}
                                {!isEditing && (
                                    <img className='svg-icon' src={pen} />
                                )}
                            </Button>
                        )}
                    </>
                )
                : (
                    <div>LOADING</div>
                )
            }
        </div>
    );
};

export default BrandInfo;
