import * as Yup from 'yup';

const validateSchema = () =>
    Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Required'),
        password: Yup.string().required('Required'),
    });

export const defaultSchema = validateSchema();

const useValidationSchema = validateSchema;

export type FormValues = Yup.InferType<typeof defaultSchema>;
export default useValidationSchema;
