import {nanoid} from 'nanoid';
import {useEffect, useRef, useState, useCallback} from 'react';
import {useLocation, NavigateFunction} from 'react-router-dom';
import {BrandCard, getBrandCards, ParamsForBrandCard, deleteBrandCards as handleDeleteBrandCards} from 'src/api/BrandCard/api-brand-card';
import {getAllWorkspaces, ApiWorkspace} from 'src/api/Workspace/api-workspace';
import {getTags} from 'src/api/Tags/api-tags';
import {ApiTag} from 'src/Types/Tags/types';
import {Filter, handleFilterValidations, GLOBAL_FILTER_DEBOUNCE_RATE} from 'src/lib/filter-helper';
import {getQueryParams, HistoryHelperFactory} from 'src/lib/url';
import {ID} from 'src/Types/CommonTypes';
import _ from 'lodash';
import brandTagFilter from 'src/lib/filters/brand-tag';
import newContentFilter from 'src/lib/filters/new-content';
import workspaceFilter from 'src/lib/filters/workspace';
import {debouncePromise} from 'src/utils/general';
import {getStoreStatus} from 'src/redux/storeStatus/actions';
import {useDispatch} from 'react-redux';

// interface BrandCardUseType {
//     isLoading: boolean,
//     setNextPage,
//     brandCards: Array<BrandCard>,
//     totalsPages: number,
//     currentPage: number,
//     refetchBrands,
//     setSearch,
//     search,
// }

interface FilterDependencies {
    brandTags?: ApiTag[]
    workspaces?: ApiWorkspace[]
    allBrands?: BrandCard[]
}

const useBrandCards = (storeId: ID, navigate: NavigateFunction) => {
    const historyHelper = HistoryHelperFactory(navigate);
    const queryParams = getQueryParams(useLocation());
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    // const [pageSize, setPageSize] = useState(150);
    const [pageSize, setPageSize] = useState(5000);
    const [search, setSearch] = useState(queryParams.filterByName || '');
    const [brandCards, setBrandCards] = useState<BrandCard[]>();
    const [allBrandCards, setAllBrandCards] = useState<BrandCard[]>([]);
    const [brandTags, setBrandTags] = useState<ApiTag[]>();
    const [workspaces, setWorkspaces] = useState<ApiWorkspace[]>();

    const rawFilters = _.keyBy([
        workspaceFilter,
        brandTagFilter,
        newContentFilter,
    ], 'key');

    const filterDependencies: FilterDependencies = {
        allBrands: allBrandCards,
        brandTags,
        workspaces,
    };

    const [filterData, setFilterData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>({});
    const [filters, setFilters] = useState<Record<string, Filter>>(
        _.omitBy(rawFilters, (filter) => {
            return !filter.enabled(filterDependencies) && !filter.show(filterDependencies);
        })
    );

    const getFilters = useCallback((filterDependencies: FilterDependencies) => {
        const filters = _.omitBy(
            rawFilters,
            (filter) => {
                return !filter.enabled(filterDependencies);
            }
        );
        setFilters(filters);
        return filters;
    }, [rawFilters]);

    const callId = useRef<string | null>(null);

    const fetchBrands = useCallback(async(currentCallId?: string, isInitialLoad = false) => {
        if (callId.current === currentCallId) {
            setIsLoading(true);

            const initialSearchObject = {
                [ParamsForBrandCard.Assets]: true,
                [ParamsForBrandCard.Contacts]: true,
                [ParamsForBrandCard.Workspaces]: true,
                page: 1,
                perpage: pageSize,
            };
            const searchObject = _.cloneDeep(initialSearchObject);
            if (search) {
                searchObject.filterByName = search;
            }
            for (const [filterKey, filterValue] of Object.entries(filterValues)) {
                if (_.get(filters, [filterKey, 'transformForAPI'])) {
                    filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies);
                }
            }

            if (brandCards && brandTags && workspaces && !isInitialLoad) {
                const {data} = await getBrandCards(
                    storeId,
                    searchObject
                );
                const queryStringObject: Record<string, any> = Object.assign({}, searchObject);
                delete queryStringObject[ParamsForBrandCard.Assets];
                delete queryStringObject[ParamsForBrandCard.Contacts];
                delete queryStringObject[ParamsForBrandCard.Workspaces];
                delete queryStringObject.page;
                delete queryStringObject.perpage;
                historyHelper.updateQueryParams(queryStringObject);
                setBrandCards(data);
            } else if (!brandCards || isInitialLoad) {
                const [
                    brandTags,
                    {data: allData},
                    workspaces,
                ] = await Promise.all([
                    getTags('brand_cards', 'store', storeId),
                    getBrandCards(storeId, Object.assign({}, initialSearchObject, {perpage: 5000})),
                    getAllWorkspaces(storeId),
                ]);

                setBrandTags(brandTags);
                setWorkspaces(workspaces);
                setAllBrandCards(allData);

                const filters = getFilters({
                    brandTags,
                    workspaces,
                    allBrands: allData,
                });
                const {
                    updatedQueryParamObject,
                    urlFilterValues,
                } = handleFilterValidations(
                    filters,
                    {
                        brandTags,
                        workspaces,
                        allBrands: allData,
                    },
                    queryParams
                );

                setFilterValues(urlFilterValues);
                const {data} = await getBrandCards(
                    storeId,
                    {...updatedQueryParamObject, ...searchObject}
                );
                setBrandCards(data);
                historyHelper.updateQueryParams(updatedQueryParamObject, false);
            }
            setIsLoading(false);
        }
    }, [storeId, pageSize, search, brandCards, getFilters, brandTags, workspaces, filterValues, filters, queryParams, historyHelper]);


    const reFetchBrands = async() => {
        setIsLoading(true);
        dispatch(getStoreStatus());

        const initialSearchObject = {
            [ParamsForBrandCard.Assets]: true,
            [ParamsForBrandCard.Contacts]: true,
            [ParamsForBrandCard.Workspaces]: true,
            page: 1,
            perpage: pageSize,
        };
        const searchObject = _.cloneDeep(initialSearchObject);
        if (search) {
            searchObject.filterByName = search;
        }
        for (const [filterKey, filterValue] of Object.entries(filterValues)) {
            if (_.get(filters, [filterKey, 'transformForAPI'])) {
                filters[filterKey].transformForAPI(filterValue, searchObject, filterKey, filterDependencies);
            }
        }

        // We need all brands, tags and workspaces to update filters :(
        const [
            brandTags,
            {data: allData},
            workspaces,
        ] = await Promise.all([
            getTags('brand_cards', 'store', storeId),
            getBrandCards(storeId, Object.assign({}, initialSearchObject, {perpage: 5000})),
            getAllWorkspaces(storeId),
        ]);

        setBrandTags(brandTags);
        setWorkspaces(workspaces);
        setAllBrandCards(allData);

        const currentFilters = getFilters({
            brandTags,
            workspaces,
            allBrands: allData,
        });
        const {
            updatedQueryParamObject,
            urlFilterValues,
        } = handleFilterValidations(
            currentFilters,
            {
                brandTags,
                workspaces,
                allBrands: allData,
            },
            queryParams
        );

        setFilterValues(urlFilterValues);
        const {data} = await getBrandCards(
            storeId,
            {...updatedQueryParamObject, ...searchObject}
        );
        setBrandCards(data);
        setIsLoading(false);
    };

    const deleteBrandCards = async(ids: ID[]) => {
        setIsLoading(true);
        await handleDeleteBrandCards(storeId, ids);
        await reFetchBrands();
    };


    useEffect(() => {
        const currentCallId = nanoid();
        callId.current = currentCallId;
        setPageSize(150);
        fetchBrands(currentCallId, true);
    }, [storeId]);

    //if filterValues change, get new brandCard list
    useEffect(() => {
        if (
            brandCards
            && !isLoading
        ) {
            const currentCallId = nanoid();
            callId.current = currentCallId;
            debouncePromise(
                GLOBAL_FILTER_DEBOUNCE_RATE,
                () => fetchBrands(currentCallId, false)
            )();
        }
    }, [filterValues, search]);

    return {
        allBrandCards,
        isLoading,
        brandCards,
        deleteBrandCards,
        refetchBrands: reFetchBrands,
        setSearch,
        search,
        filterData,
        setFilterData,
        filterValues,
        setFilterValues,
        filters,
        setFilters,
        brandTags,
        workspaces,
    };
};

export default useBrandCards;
