import _ from 'lodash';
import {classnames} from 'src/utils/general';
import React from 'react';
import './styles.sass';
import {plans as subscriptionPlans} from 'src/constants/subscriptionPlans';

//icons
import {DeleteOutlined} from '@ant-design/icons';
import {Checkmark} from 'src/lib/svg-icon-helper';

//components
import Button from 'src/Components/Button';

//types
import {genericFn} from 'src/Types/CommonTypes/index';

//libs


interface Props {
    className?: string
    hidePlans?: string[]
    disabledPlans?: string[]
    onPlanSelect?: genericFn
    onTerminateAccount?: genericFn
}

const NiftyCheckmark = (): JSX.Element => {
    return <span className='nifty-checkmark'><Checkmark /></span>;
};

const Plans: React.FC<Props> = ({
    className = '',
    hidePlans = [],
    disabledPlans = [],
    onPlanSelect = _.noop,
    onTerminateAccount = _.noop,
}): JSX.Element => {
    const computedClassName = classnames(
        'Plans modal-body',
        {
            [className]: className,
        }
    );
    const disabledPlanSet = new Set(disabledPlans);

    // const handleSelectEnterprise = () => {
    //     console.log('engage');
    // };

    const plans = _.filter(subscriptionPlans, (plan) => !_.includes(hidePlans, plan.name));

    return (
        <>
            <div className={computedClassName}>
                <div className='Plan-modal-header'>
                    Plan Selection
                </div>
                <div className='Plan--header'>
                    Choose a plan that best fits the needs of your store.
                </div>
                <div className='Plan--body'>
                    {Object.values(plans).map((plan) => (
                        <div className={classnames(
                            'plan-container',
                            {'disabled-plan': disabledPlanSet.has(plan.name)}
                        )} key={plan.name}>
                            <div className='plan-name primary-background'>{plan.name}</div>
                            <div className='plan-cost'>
                                <div className='plan-cost-amount'>
                                    ${plan.cost / 100}
                                </div>
                                <div className='plan-cost-interval'>
                                    | monthly
                                </div>
                            </div>
                            <div className='plan-description'>
                                <div className='plan-blurb'>
                                    {plan.blurb}
                                </div>
                                <div className='plan-info'>
                                    {plan.features.map((Feature, key) => (
                                        <div className='plan-feature' key={key}>
                                            <NiftyCheckmark /> {_.isString(Feature) ? Feature : <Feature />}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Button className='btn-secondary select-plan-button' onClick={() => onPlanSelect(plan)}>
                                {plan.selectButtonText ? plan.selectButtonText : `Select ${plan.name} Plan`}
                            </Button>
                        </div>
                    ))}
                    {/* <div className='plan-container theme-purple'>
                        <div className='plan-name theme-background'>Enterprise Plan</div>
                        <div className='enterprise-plan-description'>
                            All the bells and the whistles and whatever else you want to bang on.
                        </div>
                        <Button className='btn-custom theme-background select-plan-button' onClick={handleSelectEnterprise}>
                            Contact Us
                        </Button>
                    </div> */}
                    <div className='Plan--footer'>
                        <a className='delete-color' onClick={onTerminateAccount}>
                            <DeleteOutlined className='icon left-icon' /> Delete Account
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Plans;
