import {Typography} from 'antd';
import React, {useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {Store} from 'src/redux/stores/actionTypes';
import './styles.sass';
import useOutsideClick from 'src/hooks/useOutsideClick';
import {genericFn, ReactAnchorEvent} from 'src/Types/CommonTypes';
import _ from 'lodash';
import {PushpinFilled, PushpinOutlined} from '@ant-design/icons';
import Button from 'src/Components/Button';

//libs
import {classnames, filterMap} from 'src/utils/general';

interface Props {
    store: Store
    showMenu: boolean
    onHideMenu: genericFn
    storeId: string | number
    subMenu: Array<{
        name: string
        type: string
        redirectHandler: (id: string | number) => void
        shouldHide?: genericFn
        unmountHandler?: genericFn
    }>
    isSidebarPinnedOpen: boolean
    onTogglePinSidebar: genericFn
}
const SideBarMenu: React.FC<Props> = ({
    subMenu,
    storeId,
    showMenu = false,
    onHideMenu = _.noop,
    store,
    isSidebarPinnedOpen = false,
    onTogglePinSidebar = _.noop,
}) => {
    const location = useLocation();
    const sideBarRef = useRef<HTMLSpanElement>(null);

    useOutsideClick(
        sideBarRef,
        onHideMenu,
        showMenu
    );

    const handlePinSidebar= () => {
        onTogglePinSidebar();
    };

    const activeTab = _.find(subMenu, (element) => _.get(location, 'state.from') === element.type || location.pathname.includes(element.type));

    return storeId ? (
        <span ref={sideBarRef}>
            <div
                className={classnames(
                    'sidebarMenuWrapper ant-collapse ant-collapse-icon-position-end ant-collapse-borderless',
                    {'is-toggle-menu': !isSidebarPinnedOpen},
                    {'show-toggle-menu': isSidebarPinnedOpen || showMenu}
                )}>
                <div>
                    {filterMap(
                        subMenu,
                        (element) => element.shouldHide ? element.shouldHide(store.id) : true,
                        (element) => {
                            return (
                                <Typography.Link
                                    onClick={(event: ReactAnchorEvent) => {
                                        event.stopPropagation();
                                        event.nativeEvent.stopImmediatePropagation();
                                        onHideMenu();
                                        element.redirectHandler(store.id);
                                        if (activeTab?.type !== element.type && activeTab?.unmountHandler) {
                                            activeTab.unmountHandler(storeId);
                                        }
                                    }}
                                    key={element.name}
                                    className={classnames(
                                        'subMenu',
                                        {
                                            'subMenuSelected': activeTab?.type === element.type,
                                        }
                                    )}>
                                    {element.name}
                                </Typography.Link>
                            );
                        }
                    )}
                </div>
                <div className='pin-button'>
                    <Button
                        className='btn-icon pin-button btn-white'
                        onClick={handlePinSidebar}>
                        {isSidebarPinnedOpen && (
                            <PushpinFilled />
                        )}
                        {!isSidebarPinnedOpen && (
                            <PushpinOutlined />
                        )}
                    </Button>
                </div>
            </div>
        </span>
    ) : null;
};

export default SideBarMenu;
