import React, {useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import logo from 'src/assets/images/logo-stacked.svg';
import Button from 'src/Components/Button';
import Loading from 'src/Components/Loading';
import useSubmit from 'src/Views/Login/Forms/ConfirmSignUp/useSubmit';

const ConfirmSignUp: React.FC = () => {
    const {submitHandler, message, isError, resendEmailVerify, isResent} = useSubmit();
    const {code} = useParams<{code: string}>();
    const email = new URLSearchParams(useLocation().search).get('email');

    useEffect(() => {
        if (code) {
            submitHandler(code);
        }
    }, [code]);

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    if (message && isError) {
        return (
            <>
                <div className='formBackground' />
                <div className='formWrapper'>
                    <div className='formBody'>
                        <img src={logo} className='logo' />
                        <div className='message-verify'>{message}</div>
                        {isResent && (<div className='message-verify'>Sent!</div>)}
                        {!isResent && email && (
                            <Button className='btn-submit btn-secondary' onClick={() => resendEmailVerify(email)} >
                                Resend Verification Email
                            </Button>
                        )}
                    </div>
                </div>
            </>
        );
    }

    return <Loading fill size={56} />;
};

export default ConfirmSignUp;
