import _ from 'lodash';
import React, {useRef, useState} from 'react';

//components
import Input from 'src/Components/Input';

//types
import {genericFn, ReactEvent} from 'src/Types/CommonTypes/index';
import {classnames} from 'src/utils/general';

interface Props {
    onChangeMonth?: genericFn
    onChangeYear?: genericFn
    month?: string
    year?: string
    monthInputName?: string
    yearInputName?: string
}

const ExpiresDate: React.FC<Props> = ({
    onChangeMonth = _.noop,
    onChangeYear = _.noop,
    month,
    year,
    monthInputName = '',
    yearInputName= '',
}): JSX.Element => {
    const yearRef = useRef<any>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleChangeMonth = (event: ReactEvent) => {
        if (event.target.value.toString().length === 2) {
            yearRef.current.focus();
        }
        onChangeMonth(event);
    };

    return (
        <div className='ExpiresDate'>
            <label className='form-label' htmlFor={monthInputName}>
                Expiration Date <span className='is-required'> *</span>
            </label>
            <div
                className={classnames(
                    'ExpiresDate--container',
                    {'has-value': month}
                )}>
                <Input
                    className={classnames(
                        'expires-month',
                        {'is-focused': isFocused},
                        {'is-hovered': isHovered}
                    )}
                    name={monthInputName}
                    value={month}
                    autoComplete='cc-exp-month'
                    maxLength={2}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                        setIsFocused(false);
                        setIsHovered(false);
                    }}
                    onChange={handleChangeMonth} />
                <Input
                    className={classnames(
                        'expires-year',
                        {'is-focused': isFocused},
                        {'is-hovered': isHovered}
                    )}
                    name={yearInputName}
                    value={year}
                    autoComplete='cc-exp-year'
                    maxLength={2}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                        setIsFocused(false);
                        setIsHovered(false);
                    }}
                    ref={yearRef}
                    onChange={onChangeYear} />
            </div>
        </div>
    );
};

export default ExpiresDate;
