import {ApiAsset, AssetCreator} from 'src/api/Assets/api-asset';
import _ from 'lodash';

export enum AssetSourceTypes {
    KEEPMAIL = 'KEEPMAIL',
    DROPBOX = 'DROPBOX',
    GOOGLEDRIVE = 'GOOGLEDRIVE',
}

const nameFormatter = (nameObj?: AssetCreator) => {
    if (nameObj && (nameObj.firstName || nameObj.lastName)) {
        return [nameObj.firstName, nameObj.lastName].join(' ');
    }
    return false;
};

export function getContactNameForAsset(asset: ApiAsset): string {
    if (asset) {
        return (nameFormatter(_.get(asset, 'creator')) || _.get(asset, 'creator.email'))
            || (nameFormatter(_.get(asset, 'contactCreator')) || _.get(asset, 'contactCreator.email'))
            || _.get(asset, 'rawCreatedBy')
            || '';
    }
    return '';
}

export function getContactNameForCreator(creator: AssetCreator): string {
    if (creator) {
        return (nameFormatter(creator) || _.get(creator, 'email'))
            || '';
    }
    return '';
}

export function getContactEmailForAsset(asset: ApiAsset): string {
    if (asset) {
        return _.get(asset, 'creator.email')
            || _.get(asset, 'contactCreator.email')
            || _.get(asset, 'rawCreatedBy')
            || '';
    }
    return '';
}

export function getContactEmailForCreator(creator: AssetCreator): string {
    if (creator) {
        return _.get(creator, 'email') || '';
    }
    return '';
}

const displayBySource = {
    [AssetSourceTypes.KEEPMAIL]: getContactEmailForAsset,
    [AssetSourceTypes.DROPBOX]: getContactNameForAsset,
    [AssetSourceTypes.GOOGLEDRIVE]: getContactNameForAsset,
    ENVOY: () => 'Envoy B2B',
};

export function getContactDisplayBySource(asset: ApiAsset): string {
    return asset.source
        ? displayBySource[asset.source](asset)
        : getContactNameForAsset(asset);
}
