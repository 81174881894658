import {useEffect} from 'react';
import {initialize, pageview} from 'react-ga';
import {useLocation} from 'react-router-dom';

const usePageTracking = (): void => {
    const location = useLocation();

    useEffect(() => {
        const GA_TRACKING_ID = process.env.REACT_GA_TRACKING_ID;
        const env = process.env.NODE_ENV;
        if (GA_TRACKING_ID && env === 'PRODUCTION') {
            initialize(GA_TRACKING_ID);
            pageview(location.pathname + location.search);
        }
    }, [location]);
};

export default usePageTracking;
