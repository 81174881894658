/* globals document */

import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import store from './redux/store';
import {createRoot} from 'react-dom/client';
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);
import './styles/index.sass';

declare global {
    let MACRO_BASE_URL: any;
    let BASE_URL: any;
    let ENV_VARS: any;
    let BASE_API: any;
    let PDF_URL: string;
}

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
