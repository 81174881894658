import {PlusOutlined, MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import './styles.sass';
import _ from 'lodash';
import * as Yup from 'yup';
import {message} from 'antd';
import {useFormik, FormikHelpers} from 'formik';
import DropboxChooser from 'react-dropbox-chooser';
// import GooglePicker from 'react-google-picker';
import GooglePicker from 'src/Components/GooglePicker';
import Button from 'src/Components/Button';
import DropdownButton from 'src/Components/DropdownButton';
import DropZone, {UploadableFile} from 'src/Components/DropZone';
import Input from 'src/Components/Input';
import Loading from 'src/Components/Loading';
import Modal from 'src/Components/Modal';
import PopIcon from 'src/Components/PopIcon';
import TagsEditable from 'src/Components/TagsEditable';
import {ApiTag} from 'src/Types/Tags/types';
import {getTags, createTag, Tag} from 'src/api/Tags/api-tags';
import googleLogo from 'src/assets/images/google-logo.png';
import dropboxLogo from 'src/assets/images/dropbox-logo.svg';
import {
    postWorkspaceAssetFileFromLink,
    postWorkspaceDriveFile,
    createAssetLink,
    createAssetFiles,
    createAssetDropbox,
    createAssetDrive,
    createBrandAssetLink,
    createBrandAssetFiles,
    createBrandAssetDropbox,
    createBrandAssetDrive,
    postWorkspaceAssetFiles,
} from 'src/api/Assets/api-asset';
import {postWorkspaceLink} from 'src/api/WorkspaceLink/api-workspace-link';
import {genericFn, ID} from 'src/Types/CommonTypes';
import tipIcon from 'src/assets/icons/tip-hex.svg';
import {RootState} from 'src/redux/rootReducer';
import {useSelector, useDispatch} from 'react-redux';
import {getErrorMessage} from 'src/utils/general';
import {clearServerErrors} from 'src/redux/global/actions';

interface Props {
    className?: string
    disabled?: boolean
    onCreate?: genericFn
    workspaceId?: ID
    brandCardId?: ID
    actionText?: string
    id?: string
    googleToken?: string
}

const DropdownBtnCreateAsset: React.FC<Props> = ({className, disabled, onCreate, workspaceId, brandCardId, actionText, id, googleToken}) => {
    const classes = _.compact(['DropdownBtnCreateAsset btn-primary', className]).join(' ');
    const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);
    const [fileModalIsOpen, setFileModalIsOpen] = useState(false);
    const [isSubmittingFiles, setIsSubmittingFiles] = useState(false);
    const [fileAssetsForUpload, setFileAssetsForUpload] = useState<UploadableFile[]>([]);
    const [selectedAssetTags, setSelectedAssetTags] = useState<Tag[][]>([[], []]);
    const [assetTags, setAssetTags] = useState<ApiTag[]>();
    const {storeId} = useParams<{storeId: string}>();
    const DROPBOX_APP_KEY = ENV_VARS.dropBoxAppKey;
    const serverErrors = useSelector((state: RootState) => state.global.serverErrors);
    const dispatch = useDispatch();

    const handleAddLink = () => {
        setLinkModalIsOpen(true);
    };

    const handleAddFile = () => {
        setFileModalIsOpen(true);
    };

    const fetchTags = async() => {
        const tags = await getTags('assets', 'store', storeId);
        setAssetTags(tags);
    };

    useEffect(() => {
        if (linkModalIsOpen) {
            fetchTags();
        }
    }, [linkModalIsOpen]);

    useEffect(() => {
        const serverError = serverErrors
            && (getErrorMessage('storesAssetsLinks', serverErrors)
             || getErrorMessage('storesBrandCardsAssetsLinks', serverErrors)
             || getErrorMessage('storesAssetsFiles', serverErrors)
             || getErrorMessage('storesSeasonsDrive', serverErrors)
             || getErrorMessage('storesAssetsDrive', serverErrors)
             || getErrorMessage('storesBrandCardsAssetsDrive', serverErrors)
             || getErrorMessage('storesSeasonsFilelinks', serverErrors)
             || getErrorMessage('storesAssetsDropbox', serverErrors)
             || getErrorMessage('storesBrandCardsAssetsDropbox', serverErrors));

        if (serverError) {
            message.error(serverError, 4, () => {
                dispatch(clearServerErrors());
            });
        }
    }, [serverErrors, linkModalIsOpen]);

    /********** LINKS **********/
    interface LinkType {
        name?: string
        url?: string
        tags?: number[]
    }

    const handleHideLinkModal = () => {
        dispatch(clearServerErrors('storesAssetsLinks'));
        //eslint-disable-next-line @typescript-eslint/no-use-before-define
        resetForm();
        setSelectedAssetTags([[], []]);
        setLinkModalIsOpen(false);
    };

    const handleSubmitLinks = async(values: Record<'links', LinkType[]>, actions: FormikHelpers<any>) => {
        const tagsById = _.keyBy(assetTags, 'id');
        const links = values.links;
        const payloadForSaveTags: Tag[] = [];
        const errors = [];

        //each link will be a new asset
        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            if (link.url) {
                const selectedTags = selectedAssetTags[i];
                for (const recordTag of selectedTags || []) {
                    if (recordTag && (!recordTag.id || !tagsById[recordTag.id])) {
                        delete recordTag.id;
                        payloadForSaveTags.push({
                            refType: 'assets',
                            scope: 'store',
                            scopeId: Number(storeId),
                            title: recordTag.title || '',
                        });
                    }
                }

                const tagPromises: Promise<any>[] = [];
                payloadForSaveTags?.forEach((tag) => {
                    tagPromises.push(createTag(tag));
                });

                const savedTags = await Promise.all(tagPromises);
                const savedTagsByTitle = _.keyBy(savedTags, 'title');
                link.tags = _.map(selectedTags, (recordTag) => {
                    if (!recordTag.id && savedTagsByTitle[recordTag.title]) {
                        return savedTagsByTitle[recordTag.title].id;
                    } else {
                        return recordTag.id;
                    }
                });

                //if provided, attach asset to workspace
                let resp = null;
                if (workspaceId && workspaceId !== 'ALL') {
                    resp = await postWorkspaceLink(workspaceId, link);
                } else if (workspaceId === 'ALL') {
                    resp = await createBrandAssetLink(brandCardId, link);
                } else {
                    resp = await createAssetLink(storeId, link);
                }
                if (_.get(resp, 'errors') && _.get(resp, 'combinedMessage')) {
                    errors.push(_.get(resp, 'combinedMessage'));
                }
            }
        }

        actions.setSubmitting(false);

        if (onCreate) {
            onCreate();
        }
        handleHideLinkModal();
    };

    const validateSchema = () => Yup.object().shape({
        links: Yup.array().of(
            Yup.object().shape({
                name: Yup.string(),
                url: Yup.string()
                    .when('name', {
                        is: (name: string | undefined) => Boolean(name),
                        then: Yup.string().required('URL Link is required'),
                    }),
            }, [['url', 'name']])
        ),
    });
    const defaultSchema = validateSchema();

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        resetForm,
        setValues,
    } = useFormik({
        initialValues: {
            links: [
                {
                    name: undefined,
                    url: undefined,
                },
                {
                    name: undefined,
                    url: undefined,
                },
            ],
        },
        validationSchema: defaultSchema,
        onSubmit: handleSubmitLinks,
        enableReinitialize: true,
    });

    const handleSelectTags = (index: number) => {
        return (UNUSED: any, selectedTags: Tag[]) => {
            const currentSelectedAssetTags = selectedAssetTags ? _.cloneDeep(selectedAssetTags) : [];
            if (currentSelectedAssetTags[index]) {
                currentSelectedAssetTags[index] = selectedTags;
            } else {
                currentSelectedAssetTags.push(selectedTags);
            }
            setSelectedAssetTags(currentSelectedAssetTags);
        };
    };
    const handleAddLinkRow = () => {
        const links = _.cloneDeep(values.links);
        links.push({
            name: undefined,
            url: undefined,
        });
        setValues({links});
    };

    /********** FILES **********/

    const handleHideFileModal = () => {
        setFileAssetsForUpload([]);
        setFileModalIsOpen(false);
    };

    const submitFilesForUpload = async() => {
        const errorFiles = _.filter(fileAssetsForUpload, {isHaveErrors: true});
        if (errorFiles.length) {
            const errors = [];
            for (const fileAsset of errorFiles) {
                for (const error of fileAsset.errors) {
                    if (error.code === 'file-too-large') {
                        errors.push(`${fileAsset.file.name} over limit of ${ENV_VARS.allowedAssetSize}`);
                    } else {
                        errors.push(error.message);
                    }
                }
            }
            message.error(errors.join(', '));
            return;
        }

        const hide = message.loading('Uploading files...', 0);
        try {
            setIsSubmittingFiles(true);
            const formData = new FormData();
            let response;
            for (const fileAsset of fileAssetsForUpload) {
                formData.append('files[]', fileAsset.file);
            }
            if (workspaceId && workspaceId !== 'ALL') {
                response = await postWorkspaceAssetFiles(workspaceId, formData);
            } else if (workspaceId === 'ALL') {
                response = await createBrandAssetFiles(brandCardId, formData);
            } else {
                response = await createAssetFiles(storeId, formData);
            }
            if (onCreate) {
                onCreate();
            }
            let hasError = false;
            for (const asset of response) {
                if (asset.error) {
                    hasError = true;
                    message.error(asset.error, 5);
                }
            }
            message.success(hasError ? 'Upload complete, with errors' : 'Upload complete', 5);
        } catch (e) {
            // Catching error so we can hide the uploading message
        }
        handleHideFileModal();
        setIsSubmittingFiles(false);
        hide();
    };

    /********** DROPBOX **********/

    const addFilesFromDropbox = async(files: any[]) => {
        const hide = message.loading('Uploading files from Dropbox...', 0);
        //if provided, attach asset to workspace
        try {
            let response;
            if (workspaceId && workspaceId !== 'ALL') {
                for (const file of files) {
                    response = await postWorkspaceAssetFileFromLink(workspaceId, file);
                }
            } else if (workspaceId === 'ALL') {
                response = await createBrandAssetDropbox(brandCardId, files);
            } else {
                response = await createAssetDropbox(storeId, files);
            }
            if (onCreate) {
                onCreate();
            }
            let hasError = false;
            for (const asset of response) {
                if (asset.error) {
                    hasError = true;
                    message.error(asset.error, 5);
                }
            }
            message.success(hasError ? 'Upload complete, with errors' : 'Upload complete', 5);
        } catch (e) {
            // Catching error so we can hide the uploading message
        }
        hide();
    };

    /********** GOOGLE DRIVE **********/

    const storeGoogleToken = (token: string) => {
        googleToken = token;
    };

    const addFileFromGoogle = async(data: any) => {
        if (data.action === 'picked') {
            const hide = message.loading('Uploading files from Google Drive...', 0);
            const files: any[] = [];

            for (const doc of data.docs) {
                const mimeType = doc.mimeType;
                if (mimeType.includes('application/vnd.google-apps')) {
                    let exportMimeType = 'UNSUPPORTED';
                    let extension = '';
                    switch (mimeType) {
                        case 'application/vnd.google-apps.document':
                            exportMimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                            extension = '.docx';
                            break;
                        case 'application/vnd.google-apps.spreadsheet':
                            exportMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                            extension = '.xlsx';
                            break;
                        case 'application/vnd.google-apps.presentation':
                            exportMimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
                            extension = '.pptx';
                            break;
                        case 'application/vnd.google-apps.drawing':
                            exportMimeType = 'application/pdf';
                            extension = '.pdf';
                            break;
                    }
                    files.push({
                        link: `https://www.googleapis.com/drive/v3/files/${doc.id}/export`,
                        authorization: `Bearer ${googleToken}`,
                        name: doc.name + extension,
                        export_mime_type: exportMimeType,
                        api_key: ENV_VARS.googleApiKey.toString(),
                    });
                } else {
                    files.push({
                        link: `https://www.googleapis.com/drive/v3/files/${doc.id}`,
                        authorization: `Bearer ${googleToken}`,
                        name: doc.name,
                        api_key: ENV_VARS.googleApiKey.toString(),
                    });
                }
            }

            try {
                let response = '';
                if (files.length) {
                    if (workspaceId && workspaceId !== 'ALL') {
                        response = await postWorkspaceDriveFile(workspaceId, files);
                    } else if (workspaceId === 'ALL') {
                        response = await createBrandAssetDrive(brandCardId, files);
                    } else {
                        response = await createAssetDrive(storeId, files);
                    }
                }

                if (onCreate) {
                    onCreate();
                }
                hide();
                if (response.includes('Error')) {
                    message.error(response, 5);
                } else {
                    message.success(response, 4);
                }
            } catch (e) {
                hide();
            }
        }
    };

    return (
        <>
            <DropdownButton
                title={actionText || 'Asset'}
                placement='bottomRight'
                id={id}
                primaryIcon={actionText ? undefined : <PlusOutlined className='icon left-icon' />}
                items={[
                    {
                        key: 'link',
                        onClick: handleAddLink,
                        text: 'Add Link',
                    },
                    {
                        key: 'file',
                        onClick: handleAddFile,
                        text: 'Add File',
                    },
                    {
                        key: 'dropbox',
                        optionRenderer: () => (
                            <DropboxChooser appKey={DROPBOX_APP_KEY} success={addFilesFromDropbox} multiselect={true} linkType={'direct'}>
                                Add from <img src={dropboxLogo} className='ext-logo' /> Dropbox&#8482;
                            </DropboxChooser>
                        ),
                    },
                    {
                        key: 'drive',
                        optionRenderer: () => (
                            <div>
                                <GooglePicker
                                    onChange={addFileFromGoogle}
                                    onAuthenticate={storeGoogleToken} >
                                    Add from <img src={googleLogo} className='ext-logo' /> Google Drive&#8482;
                                </GooglePicker>
                            </div>
                        ),
                    },
                ]}
                disabled={disabled}
                className={classes} />
            <Modal
                className='add-asset-link-modal'
                open={linkModalIsOpen}
                title='Add Link'
                footer={false}
                onCancel={handleHideLinkModal}
                destroyOnClose>
                <form onSubmit={handleSubmit} id='submit-add-link' className='add-asset-link-form'>
                    <div className='modal-body'>
                        {values.links && values.links.map((link: LinkType, index: number) => (
                            <React.Fragment key={index}>
                                <Input
                                    errors={_.get(touched, ['links', index, 'name']) && _.get(errors, ['links', index, 'name'])}
                                    name={`links.${index}.name`}
                                    infoPopContent={
                                        index === 0
                                            ? 'Use this field to enter the name of the tag that will show up in your assets dashboard.'
                                            : undefined
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Name'
                                    label='Name'
                                    value={values.links[index].name} />
                                <Input
                                    errors={_.get(touched, ['links', index, 'url']) && _.get(errors, ['links', index, 'url'])}
                                    name={`links.${index}.url`}
                                    infoPopContent={index === 0
                                        ? 'This is the destination for your link. Enter a website you know or copy a web address from your browser bar and paste it here.'
                                        : undefined
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Link'
                                    label='URL Link'
                                    value={values.links[index].url} />
                                <span>
                                    <div className='tags-label'>
                                        <label className='form-label' htmlFor='tags'>Category</label>
                                        {index === 0 && (
                                            <PopIcon
                                                className='btn-tip'
                                                type='tip'
                                                content='Asset Category tags allow you to organize your assets to ensure you can find them quickly. Some common asset categories are: Workbook, Annotated Workbook, Order Form, Completed Order Form, Order Confirmation, UPC list, MAP Policy, Terms Sheet, Product Imagery.'
                                                origin={<img src={tipIcon} />} />
                                        )}
                                    </div>
                                    <TagsEditable
                                        notFoundContent={(
                                            <div>No Categories added yet</div>
                                        )}
                                        tags={assetTags}
                                        placeholder='Category'
                                        id='tags'
                                        isEdit={true}
                                        onChange={handleSelectTags(index)}
                                        selectedTags={selectedAssetTags && selectedAssetTags[index]} />
                                    <span
                                        className='remove-link-button'
                                        onClick={() => {
                                            const links = _.cloneDeep(values.links);
                                            const currentSelectedAssetTags = selectedAssetTags ? _.cloneDeep(selectedAssetTags) : [];
                                            if (index === 0) {
                                                links[0] = {
                                                    name: undefined,
                                                    url: undefined,
                                                };
                                                setValues({
                                                    links,
                                                });
                                                currentSelectedAssetTags[0] = [];
                                                setSelectedAssetTags(currentSelectedAssetTags);
                                            } else {
                                                links.splice(index, 1);
                                                setValues({
                                                    links,
                                                });
                                                if (currentSelectedAssetTags[index]) {
                                                    currentSelectedAssetTags.splice(index, 1);
                                                    setSelectedAssetTags(currentSelectedAssetTags);
                                                }
                                            }
                                        }}>
                                        <MinusCircleOutlined />
                                    </span>
                                </span>
                            </React.Fragment>
                        ))}
                        <div className='add-row-button'
                            onClick={handleAddLinkRow}>
                            <div className='spar' />
                            <div className='add-row-button-text'>Add More <PlusCircleOutlined /></div>
                            <div className='spar' />
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <div
                            className='cancel-button'
                            onClick={handleHideLinkModal}>
                            Cancel
                        </div>
                        <Button
                            type='submit'
                            className='btn-secondary submit-button'
                            form='submit-add-link'
                            disabled={isSubmitting}>
                            {isSubmitting && (
                                <Loading inline />
                            )}
                            Create
                        </Button>
                    </div>
                </form>
            </Modal>
            <Modal
                className='add-asset-file-modal'
                open={fileModalIsOpen}
                title={<>
                    <span>Add Files</span>
                    <PopIcon
                        className='btn-tip'
                        type='tip'
                        content='BrandKeep allows you to upload almost any file from your computer. File upload size is limited to 1GB per file.'
                        origin={<img src={tipIcon} />} />
                </>}
                footer={false}
                onCancel={handleHideFileModal}
                destroyOnClose>
                <DropZone
                    className='dragAndDrop'
                    maxFileSize={parseInt(ENV_VARS.allowedAssetSize) * 1073741824}
                    maxFilesQuantity={0}
                    isAddToExistingFiles={true}
                    uploadHandler={(files: Array<UploadableFile>) => {
                        setFileAssetsForUpload(files);
                    }}>
                    <p className='dragAndDrop_title'>
                        Click or drag file(s) to this area to upload
                        <div className='detail-blurb'>
                            <span className='italics'>Max file size: 1GB</span> | <span className='italics'> Max total upload size: 2GB</span>
                        </div>
                    </p>
                    {fileAssetsForUpload && fileAssetsForUpload.length
                        ? fileAssetsForUpload.map((file) => {
                            return (
                                <p className='uploadedFile' key={file.id}>
                                    {' '}
                                    Uploaded file: {file.file.name}
                                </p>
                            );
                        })
                        : ''}
                </DropZone>
                <div className='modal-footer'>
                    <div
                        className='cancel-button'
                        onClick={handleHideFileModal}>
                        Cancel
                    </div>
                    <Button
                        onClick={submitFilesForUpload}
                        className='btn-secondary submit-button'
                        disabled={isSubmittingFiles}>
                        {isSubmittingFiles && (
                            <Loading inline />
                        )}
                        Upload
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default DropdownBtnCreateAsset;
