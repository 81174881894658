import {useFormik} from 'formik';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import logo from 'src/assets/images/logo-stacked.svg';
import Button from 'src/Components/Button';
import Input from 'src/Components/Input';
import {RootState} from 'src/redux/rootReducer';
import useFormValues from 'src/Views/Login/Forms/SignInForm/useFormValues';
import useSubmit from 'src/Views/Login/Forms/SignInForm/useSubmit';
import useValidationSchema from 'src/Views/Login/Forms/SignInForm/useValidationSchema';
import useDocumentTitle from 'src/hooks/useDocumentTitle';
import _ from 'lodash';
import Loading from 'src/Components/Loading';

const SignInForm: React.FC = () => {
    const location = useLocation();
    const submitHandler = useSubmit();
    const initialValues = useFormValues();
    const navigate = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const confirmedEmail = Boolean(urlParams.get('confirmedEmail'));

    useDocumentTitle('Sign In');

    useEffect(() => {
        const redirect = urlParams.get('redirect');
        //redirect is a catch-all stop-gap that prevents redirect loops
        if (auth.isSignedIn && redirect !== 'false') {
            const requestedUrl = _.get(location, 'state.requestedPath');
            navigate(requestedUrl || '/');
        }
    }, [auth, location, navigate]);


    const signUp = () => {
        navigate('/signUp');
    };

    const forgotPassword = () => {
        navigate('/forgotPassword');
    };

    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

    const {
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        getFieldProps,
    } = useFormik({
        initialValues,
        validationSchema: useValidationSchema,
        onSubmit: submitHandler,
    });

    return (
        <>
            <div className='formWrapper'>
                <div className='formBody'>
                    <a href='https://www.brandkeep.com'>
                        <img src={logo} className='logo' />
                    </a>
                    {confirmedEmail && (
                        <div className='formHeader-center'>
                            Your email has been verified! Please sign in below.
                        </div>
                    )}
                    <div className='form'>
                        <form onSubmit={handleSubmit} id='sign-in-form'>
                            <div>
                                <div className='inputWrapper'>
                                    <Input
                                        errors={errors.email && touched.email && errors.email}
                                        required
                                        autoFocus
                                        placeholder='youremail@example.com'
                                        label='Email'
                                        {...getFieldProps('email')} />
                                </div>
                                <div className='inputWrapper'>
                                    <Input
                                        errors={errors.password && touched.password && errors.password}
                                        placeholder='*******'
                                        type='password'
                                        label='Password'
                                        {...getFieldProps('password')} />
                                </div>
                            </div>
                            <div className='errors'>
                                {errors && !_.isEmpty(errors) && errors.error && (
                                    <div className='error'>
                                        {errors.error}
                                    </div>
                                )}
                            </div>
                            <div className='forgotPassword' onClick={forgotPassword}>
                                Forgot your password?
                            </div>
                            <Button
                                type='submit'
                                className='btn-secondary submit-button'
                                form='sign-in-form'
                                disabled={isSubmitting || !_.isEmpty(errors)}>
                                {isSubmitting && (
                                    <Loading inline />
                                )}
                                Sign in
                            </Button>
                        </form>
                    </div>
                    <div className='formFooter'>
                        Need an account?{' '}
                        <span className='link' onClick={signUp}>
                            Sign up
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignInForm;

