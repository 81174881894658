import React from 'react';
import TipIcon from 'src/assets/images/tipIcon.png';
import 'src/Components/Tip/styles.sass';

interface Props {
    className?: string
    children: React.ReactNode
}

const Tip: React.FC<Props> = ({children, className}) => {
    return (
        <div className={['tip', className].join(' ')}>
            <img src={TipIcon} className='icon' />
            {children}
        </div>
    );
};

export default Tip;
