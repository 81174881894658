/*
  * this file contains display-only, marketing-style data.
  * regulation of features/data by a plan should come entirely
  * from the backend
*/

type Feature = (() => JSX.Element) | string;

export interface PlanGroup {
    name: string
    cost: number
    blurb?: string
    features: Feature[]
    selectButtonText?: string | JSX.Element | Element
}

export const plans: Record<string, PlanGroup> = {
    free: {
        name: 'free',
        cost: 0,
        features: [
            (): JSX.Element => <span className='bold'>1 &#34;Contributor&#34; user</span>,
            (): JSX.Element => <span className='bold'>10 Brands</span>,
            (): JSX.Element => <span className='bold'>1 GB of storage</span>,
            (): JSX.Element => <><span className='bold'>Our powerful Brand Card system.</span> Get organized and centralize everything.</>,
            (): JSX.Element => <><span className='bold'>Quick search, sorting, and filtering.</span> Find what you need when you need it.</>,
            (): JSX.Element => <><span className='bold'>Bring it all together.</span> Easy uploads from your computer, Box, and Google Drive.</>,
            (): JSX.Element => <>Our robust <span className='bold'>retailer-centric knowledge base.</span></>,
        ],
    },
    base: {
        name: 'base',
        cost: 4500,
        features: [
            (): JSX.Element => <span className='bold'>3 &#34;Contributor&#34; users</span>,
            (): JSX.Element => <span className='bold'>Unlimited &#34;Viewer&#34; users</span>,
            (): JSX.Element => <span className='bold'>50 Brands</span>,
            (): JSX.Element => <span className='bold'>1 TB of storage</span>,
            (): JSX.Element => <><span className='bold'>Our powerful Brand Card system.</span> Get organized and centralize everything.</>,
            (): JSX.Element => <><span className='bold'>Quick search, sorting, and filtering.</span> Find what you need when you need it.</>,
            (): JSX.Element => <><span className='bold'>Bring it all together.</span> Easy uploads from your computer, Box, and Google Drive.</>,
            (): JSX.Element => <><span className='bold'>Empower your team.</span> They can view Brand Cards and contribute too!</>,
            (): JSX.Element => <><span className='bold'>KeepMail:</span> Your own email to easily traffic assets right into Brand Cards.</>,
            (): JSX.Element => <><span className='bold'>Our simple task system</span> alongside brand assets helps you get work done.</>,
            (): JSX.Element => <>Our robust <span className='bold'>retailer-centric knowledge base.</span></>,
            (): JSX.Element => <><span className='bold'>Priority email and chat support.</span></>,
        ],
    },
    pro: {
        name: 'pro',
        cost: 7500,
        features: [
            (): JSX.Element => <span className='bold'>Unlimited &#34;Contributor&#34; users</span>,
            (): JSX.Element => <span className='bold'>Unlimited &#34;Viewer&#34; users</span>,
            (): JSX.Element => <span className='bold'>Unlimited Brands</span>,
            (): JSX.Element => <span className='bold'>5 TB ($3/TB/month after)</span>,
            (): JSX.Element => <><span className='bold'>Our powerful Brand Card system.</span> Get organized and centralize everything.</>,
            (): JSX.Element => <><span className='bold'>Quick search, sorting, and filtering.</span> Find what you need when you need it.</>,
            (): JSX.Element => <><span className='bold'>Bring it all together.</span> Easy uploads from your computer, Box, and Google Drive.</>,
            (): JSX.Element => <><span className='bold'>Empower your team.</span> They can view Brand Cards and contribute too!</>,
            (): JSX.Element => <><span className='bold'>KeepMail:</span> Your own email to easily traffic assets right into Brand Cards.</>,
            (): JSX.Element => <><span className='bold'>Our simple task system</span> alongside brand assets helps you get work done.</>,
            (): JSX.Element => <>Our robust <span className='bold'>retailer-centric knowledge base.</span></>,
            (): JSX.Element => <><span className='bold'>Priority email and chat support.</span></>,
        ],
    },
};
