import React from 'react';
import Button from 'src/Components/Button';
import './styles.sass';
import Steps from 'src/Components/Step';
import {ImportError} from 'src/Views/Store/Brands/ImportExportBrandModal/useSubmit';

interface Props {
    importType?: string
    stepsNames: string[]
    errors: ImportError[]
    currentStep: number
    totalImported: number
    createdSuccess: number
    updatedSuccess: number
    buttonHandler: () => void
}

const ImportResult: React.FC<Props> = ({
    importType = 'brand',
    stepsNames,
    currentStep,
    totalImported,
    createdSuccess,
    updatedSuccess,
    errors,
    buttonHandler,
}) => {
    return (
        <div className='modalBody'>
            <div className='topRow'>
                <div className='status'>
                    <div className='statusIcon'></div>
                    <div className='value'>
                        {createdSuccess + updatedSuccess === totalImported ? 'Success' : ''}
                        {createdSuccess + updatedSuccess >= 0 && createdSuccess + updatedSuccess < totalImported
                            ? 'Partially failed'
                            : ''}
                        {createdSuccess + updatedSuccess === 0 ? 'Failed' : ''}
                    </div>
                </div>
                <div className='status'>
                    <div className='value'>{`${createdSuccess + updatedSuccess}/${totalImported}`}</div> rows imported
                </div>
                <div className='status'>
                    <div className='value'>{createdSuccess}</div> new {importType}s created successfully
                </div>
                <div className='status'>
                    <div className='value'>{updatedSuccess}</div> existing {importType}s updated successfully
                </div>
            </div>
            <div className='errors'>
                {errors.map((error) => (
                    <div key={error.rowId} className='error'>
                        {`${error.name ? error.name : 'Brand'} on row ${error.rowId} failed to import: ${error.message}`}
                    </div>
                ))}
            </div>

            <Steps
                steps={stepsNames}
                currentStep={currentStep}
                rightElement={
                    <div className='button_wrapper'>
                        <Button type='secondary' className='button' onClick={buttonHandler}>
                            Ok
                        </Button>
                    </div>
                }
                leftElement={<div className='button_wrapper' />}
            />
        </div>
    );
};

export default ImportResult;
