import {TokenState, TokenActions, TokenActionTypes} from './actionTypes';

const initialState: TokenState = {
    token: {
        accessToken: window.localStorage.getItem('ACCESS_TOKEN'),
    },
};

const TokenReducer = (state = initialState, action: TokenActions): TokenState => {
    switch (action.type) {
        case TokenActionTypes.GET_TOKEN: {
            return {...state};
        }

        case TokenActionTypes.GET_TOKEN_SUCCESS: {
            return {
                ...state,
                token: action.payload.token,
            };
        }

        case TokenActionTypes.CLEAR_TOKEN: {
            return {
                ...state,
                token: undefined,
            };
        }

        default:
            return state;
    }
};

export default TokenReducer;
