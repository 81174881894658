import apiHelper from 'src/api/api-helper';
export interface ImportInfo {
    updated: string
    new: string
}

export const postFileForImport = (storeId: string | number, file: File) => {
    const formData = new FormData();
    formData.append('csv', file);

    return apiHelper.post(
        `import/stores/${storeId}/contacts`,
        formData,
        {
            'Content-Type': 'multipart/form-data',
        },
        {
            refType: 'brand_cards',
        }
    );
};

export const getImportInfo = (importId: string, storeId: string): Promise<ImportInfo> => {
    return apiHelper.get(`/import/${importId}/contacts/info`, {refType: 'brand_cards', storeId});
};

export const getContactsForImport = (importId: string, storeId: string): Promise<[]> => {
    return apiHelper.get(`/import/${importId}/contacts`, {refType: 'brand_cards', storeId});
};

export const moveContactsToStore = (importId: string) => {
    const endpoint = `/import/${importId}/contacts`;
    return apiHelper.put(endpoint, {});
};
