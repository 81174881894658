//filter common
import _ from 'lodash';
import FilterMultiSelect from 'src/Components/FilterMultiSelect';
import {
    buildFilter,
    validateFilter,
    handleOnChangeFactory,
    handleOnChangeCheckboxFactory,
} from 'src/lib/filter-helper';

//specific
import {NormalizedTag} from 'src/Types/Tags/types';
import {BrandCard} from 'src/api/BrandCard/api-brand-card';

interface DataBuilder {
    tags: NormalizedTag[]
    allBrands: BrandCard[]
}

export default buildFilter({
    key: 'brandTag',
    name: 'Brand Tags',
    preserveFormatting: true,
    appliedFilterValidation: (appliedFilters, {brandTag: brandTags}: Record<'brandTag', NormalizedTag[]>) => validateFilter(appliedFilters, brandTags),
    dataBuilder: ({brandTags, allBrands}: DataBuilder) => {
        //filter out any tag not assigned to an available asset
        const usedTagSet = new Set(
            _(allBrands)
                .map('tags')
                .flatten()
                .map('id')
                .compact()
                .value()
        );
        return _.filter(brandTags, (tag) => usedTagSet.has(tag.id || ''));
    },
    detailRenderer: (brandTag: NormalizedTag) => brandTag.title,
    //eslint-disable-next-line react/display-name
    component: (
        values,
        onChange,
        {brandTag: brandTags}: Record<string, any>
    ) => {
        return (
            <FilterMultiSelect
                compactLabel='Brand Tags'
                disabled={!(brandTags && brandTags.length)}
                labelKey='title'
                labelTextRenderer={(brandTag: NormalizedTag) => brandTag.title}
                onChange={handleOnChangeFactory('brandTag', onChange, values)}
                onCheckboxChange={handleOnChangeCheckboxFactory('brandTag', onChange, values)}
                options={_.sortBy(brandTags, (tag) => _.toLower(tag.title))}
                placeholder='Type to Search Brand Tags'
                value={(values && values.brandTag) || []}
                valueKey='id' />
        );
    },
    transformForAPI: (brandTags: NormalizedTag[], searchObject, key: string) => {
        searchObject[key] = _.map(brandTags, 'id');
        return searchObject;
    },
    buildValuesFromQueryString: ({brandTags}, {brandTag}) => {
        const valueIdSet = new Set(brandTag);
        return _.filter(brandTags, (tag) => valueIdSet.has(tag.id));
    },
    enabled: ({brandTags}: Record<'brandTags', NormalizedTag[]>) => Boolean(brandTags && brandTags.length),
});
