import {all, call, put, takeEvery} from 'redux-saga/effects';
import apiHelper from '../../api/api-helper';
import {createStoreFailure, createStoreSuccess} from './actions';
import {ICreateStore, Store, StoreActionTypes} from './actionTypes';

const createStoreApi = (storeName: string) => {
    return apiHelper.post('/stores', {title: storeName});
};

function* createStore(action: ICreateStore) {
    try {
        const store: Store = yield call(createStoreApi, action.payload.label);
        yield put(createStoreSuccess(store));
    } catch (error) {
        yield put(createStoreFailure(error.message));
    }
}

function* watchCreateStore() {
    yield takeEvery(StoreActionTypes.CREATE_STORE, createStore);
}

export default function* storeSagas() {
    yield all([watchCreateStore()]);
}
