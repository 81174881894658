import {Modal as AntModal, ModalProps} from 'antd';
import _ from 'lodash';
import React, {useState} from 'react';
import xIcon from 'src/assets/icons/x.svg';
import Button from 'src/Components/Button';
import './styles.sass';

interface Props extends ModalProps {
    hideXButton?: boolean
}

const Modal: React.FC<Props> = ({children, className, closable = false, width = 850, hideXButton = false, ...props}) => {
    const [shouldOverflow, setShouldOverflow] = useState(true);
    const firstChild = children && children[0];
    const hasModalBody = firstChild && _.get(firstChild, 'props.className', '').includes('modal-body');

    return (
        <AntModal
            wrapClassName={['Modal', className].join(' ')}
            closable={closable}
            width={width}
            maskClosable={false}
            keyboard={Boolean(props.onCancel)}
            {...props}>
            <>
                {(closable || props.onCancel) && !hideXButton && (
                    <Button className='btn-circle btn-white btn-icon close-btn' onClick={props.onCancel}>
                        <img className='svg-icon' src={xIcon} />
                    </Button>
                )}
                {children && hasModalBody && React.Children.map(
                    children,
                    (child, index) => index === 0
                        ? React.cloneElement(
                            child,
                            {
                                /*
                                    bit of a mess, this is all in place to add/remove 'overflow: auto' from .modal-body.
                                    this allows things like dropdowns to overlap outside of smaller modals
                                */
                                ref: (node) => {
                                    if (shouldOverflow && node && node.scrollHeight <= node.clientHeight) {
                                        setShouldOverflow(false);
                                    }
                                    if (!shouldOverflow && node && node.scrollHeight > node.clientHeight) {
                                        setShouldOverflow(true);
                                    }
                                },
                                className: [child.props.className, shouldOverflow ? 'modal-body--overflow': undefined].join(' '),
                            }
                        )
                        : child
                )}
                {!hasModalBody && children}
            </>
        </AntModal>
    );
};

export default Modal;
