import React from 'react';
import './styles.sass';
import EmptyStateIcon from '/src/assets/images/empty-state-icon.png';

const NoResults: React.FC = () => {
    return (
        <div className='no-results'>
            <img className='no-results-icon' src={EmptyStateIcon} />
            <div className='no-results-content'>
                <div className='no-results-header left'>We couldn&#39;t find anything matching your search</div>
                <div className='no-results-body left'>Try again with a different search or filter.</div>
            </div>
        </div>
    );
};

export default NoResults;
