import React from 'react';
import BrandCard from 'src/Components/BrandCard';
import useDocumentTitle from 'src/hooks/useDocumentTitle';

const Brands: React.FC = () => {

    useDocumentTitle('Brands');

    return (
        <div className='brandcard-dashboard'>
            <BrandCard />
        </div>
    );
};

export default Brands;
