export const assetsOnboarding = {
    addAsset: {
        header: 'Use the ‘+ Asset’ button to upload assets.',
        text: 'Workbooks, pricelists, MAP policies, order forms, order confirmations, UPC lists, links to media sites, pictures, images, videos... and anything else you can think of via Dropbox or directly from your computer.',
        knowledgebaseLink: '',
        targetId: 'create-asset-button',
        targetClassesToApply: ['pulse'],
    },
    multiSelectAssets: {
        header: 'If you have uploaded multiple files, organize them faster.',
        text: 'The multi-select Actions button allows you to apply changes to, download, or delete multiple assets at once.',
        knowledgebaseLink: '',
        targetId: 'multi-select-asset-button',
        targetClassesToApply: ['pulse-large'],
    },
};
