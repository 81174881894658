import React from 'react';
import {
    PdfViewerComponent,
    Toolbar,
    Magnification,
    Navigation,
    LinkAnnotation,
    BookmarkView,
    ThumbnailView,
    Print,
    TextSelection,
    TextSearch,
    Annotation,
    FormFields,
    Inject,
    AnnotationToolbarItem,
} from '@syncfusion/ej2-react-pdfviewer';
// import {PdfAnnotationBaseModel} from '@syncfusion/ej2-pdfviewer/src/pdfviewer/drawing/pdf-annotation-model';
import _ from 'lodash';
import './styles.sass';

import {genericFn} from 'src/Types/CommonTypes';

import {classnames} from 'src/utils/general';

interface Props {
    annotationAuthor?: string
    pdfUrl?: string
    className?: string
    editMode?: boolean
    file: any
    name: string
    show?: boolean
    zoomFactor?: number
    onLoad?: genericFn
    readOnly?: boolean
}
const AssetPdf: React.FC<Props> = React.forwardRef(({
    annotationAuthor,
    className = '',
    editMode = false,
    onLoad = _.noop,
    readOnly = false,
    pdfUrl,
}, ref: React.ForwardedRef<PdfViewerComponent>) => {
    const classes = classnames(
        'AssetPdf',
        {[className]: className},
        {'syncfusion-dark-mode': editMode},
        {'edit-mode': editMode}
    );

    const handleOnLoad = () => {
        onLoad();
    };

    const services = [Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields];
    const annotationToolbarItems: AnnotationToolbarItem[] = [
        'HighlightTool',
        'UnderlineTool',
        'StrikethroughTool',
        'ShapeTool',
        // 'CalibrateTool',
        'FreeTextAnnotationTool',
        // 'StampAnnotationTool',
        'HandWrittenSignatureTool',
        'InkAnnotationTool',
        'FontFamilyAnnotationTool',
        'FontSizeAnnotationTool',
        'FontColorAnnotationTool',
        'FontAlignAnnotationTool',
        'FontStylesAnnotationTool',
        'ColorEditTool',
        'StrokeColorEditTool',
        'ThicknessEditTool',
        'OpacityEditTool',
        'AnnotationDeleteTool',
        'CommentPanelTool',
    ];

    return (
        <div className={classes}>
            {pdfUrl && readOnly && (
                <PdfViewerComponent
                    id='pdf-container'
                    toolbarSettings={{
                        toolbarItems: [
                            // 'OpenOption',
                            'PageNavigationTool',
                            'MagnificationTool',
                            'PanTool',
                            'SelectionTool',
                            'SearchOption',
                            'PrintOption',
                        ],
                    }}
                    documentPath={pdfUrl}
                    documentLoad={handleOnLoad}
                    serviceUrl={PDF_URL}
                    style={{height: '100%'}}>
                    <Inject services={services} />
                </PdfViewerComponent>
            )}
            {pdfUrl && !readOnly && (
                <PdfViewerComponent
                    id='pdf-container'
                    ref={ref}
                    // isAnnotationToolbarVisible={true}
                    toolbarSettings={{
                        toolbarItems: [
                            // 'OpenOption',
                            'PageNavigationTool',
                            'MagnificationTool',
                            'PanTool',
                            'SelectionTool',
                            'SearchOption',
                            'PrintOption',
                            // 'DownloadOption',
                            'UndoRedoTool',
                            // 'AnnotationEditTool',
                            // 'FormDesignerEditTool',
                            'CommentTool',
                            // 'SubmitForm',

                            //these were in an example straight from SF, but types say no
                            // 'FreeTextAnnotationOption',
                            // 'InkAnnotationOption',
                            // 'ShapeAnnotationOption',
                        ],
                        annotationToolbarItems,
                    }}
                    // annotationCollection
                    // importAnnotations
                    // exportAnnotations
                    // annotations={annotations}
                    // annotationAdd={handleAnnotationAdd}
                    // annotationPropertiesChange={handleAnnoChange}
                    // annotationRemove={handleAnnoRemove}
                    // annotationResize={handleAnnoResize}
                    // annotationUnSelect={handleAnnoUnselect}
                    // annotationMouseLeave={handleAnnoMouseLeave}
                    // annotationMove={handleAnnoMoveEnd}
                    // pageChange={handlePageChange}
                    annotationSettings={{
                        author: annotationAuthor,
                    }}
                    documentPath={pdfUrl}
                    documentLoad={handleOnLoad}
                    serviceUrl={PDF_URL}
                    style={{height: '100%'}}>
                    <Inject services={services} />
                </PdfViewerComponent>
            )}
        </div>
    );
});

export default AssetPdf;
